import moment from "moment";
import React from "react";
import DocumentTitle from "react-document-title";
import { useHistory } from "react-router";
import Auth from "../../Auth/Auth";
import SignaturesService, { ISignature } from "../../services/SignaturesService";
import scrollToTop from "../../utils/scrollToTop";
import * as E from '../Elements/ElementStyles';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SignatureComponent } from "../../components/Signature/SignatureComponent";
import ModalElementSigned from "./ModalElementSigned";

const ElementSignatureConfirm = (props: any) =>
{
    const history = useHistory();
    const user = Auth.getUserProfile();
    const [modalShow, setModalShow] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [signatures, setSignatures] = React.useState<ISignature[]>([]);
    
    React.useEffect(() =>
    {
        scrollToTop();
    }, []);

    React.useEffect(() =>
    {
        if(signatures.length === 0)
        {
            SignaturesService.createSignatureNoPlan(user.id).then((response: any) =>
            {
                if(response.data.id !== null)
                {
                    setSignatures([response.data]);
                    setIsLoaded(true);
                }
            });
        }
    }, [signatures, user.id, modalShow]);

    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }
    const handleSubmit = () =>
    {
        if(signatures.length > 0 || !modalShow)
        {
            scrollToTop();
            setModalShow(true)
        }
    };

    return(
        <DocumentTitle title='IronIA - Elements - Firma de Contrato'>
            <React.Fragment>
                <E.HelperStyle />
                <E.ContainerSignature>
                    <E.MainContainerSignature>
                        <h2 style={{margin: 0}}>Elements - Firma de Contrato</h2>
                        <Separator />
                        {!isLoaded &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {isLoaded &&
                        (
                            <SignatureComponent
                                signatures={signatures}
                                alreadySignedText=" "
                                submitButtonText="Firmar contrato"
                                cancelButtonText="Volver"
                                headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX"+signatures[0].phone?.slice(signatures[0].phone.length - 3)+" por SMS para firmar el contrato."}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push('/Elements/Signature')}
                                onCancelButtonClicked={() => history.push('/Elements/Signature')}
                                requestNewCodeButtonText="Recibir nuevo código"
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSigned={handleSubmit}
                                isElementSigning={true}
                            />
                        )}
                    </E.MainContainerSignature>
                </E.ContainerSignature>
                {modalShow && <ModalElementSigned setModalShow={setModalShow} />}
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ElementSignatureConfirm;