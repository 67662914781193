import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { UserIdentityType } from "./UserIdentitiesService";
import { ISignature } from "./SignaturesService";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

export interface IOption {
    key?: string,
    text?: string
}

export interface IQuestion {
    id: number,
    name: string,
    text: string,
    type: string,
    options: IOption[],
    title?: string,
    img?: string
}

export interface IQuestionGroup {
    id?: number,
    title?: string,
    description?: string,
    questions?: IQuestion[]
}

export interface ISuitabilityTestVersion {
    id: number,
    fnmId: string,
    test: ISuitabilityTestVersionTest,
    version: number
}

export interface ISuitabilityTestVersionTest {
    _id: number,
    clientType: string,
    entity: string,
    creationDate: string,
    creationDateAsString: string,
    values: ITestVersionValue[],
    riskRanges: IRiskRanges[]
    ranges: any[],
    extraData: IExtraData[]
    constraints: IConstraint[],
    blocks: IBlock[],
    ownRisk: boolean,
    __v: number
}

export interface IBlock {
    name: string,
    _id: string,
    translations: string[],
    subBlock: ISubBlock[],
    profile: IFnmProfile
}

export interface IFnmProfile {
    result: string,
    profileType: string[],
    description: string[],
    sign: boolean
}

export interface ISubBlock {
    name: string,
    _id: string,
    description?: string,
    translations: string[],
    questions: IQuestion[]
}

export interface IQuestion {
    text: string,
    order: number,
    questionType: string,
    key: string,
    _id: string,
    values: any[],
    indentation: boolean,
    breakPage: boolean,
    translations: string[],
    answers: IQuestionAnswer[],
    table: IQuestionTable,
    info: any[],
    extraData: string[],
    constraint: boolean,
    description: string,
    complex: boolean,
    recommendation: boolean,
    financialSituation: boolean,
    horizon: boolean,
    dependency?: number
}

export interface IQuestionTableRow {
    _id: string,
    cols: IQuestionTableCol[]
}

export interface IQuestionTableCol {
    text: string,
    _id: string,
    order?: number,
    value?: number
}

export interface IQuestionTableHeader {
    text: string,
    answerType: string,
    _id: string
}

export interface IQuestionTable {
    rows: IQuestionTableRow[],
    headers: IQuestionTableHeader[]
}

export interface IQuestionAnswer {
    title?: string,
    text: string,
    order: number,
    value: number,
    _id: string,
    indentation: boolean,
    translations: string[]
}

export interface IConstraint {
    constraintType: string,
    res: number,
    notProfiled: boolean
}

export interface IExtraData {
    dataType?: string,
    key: string,
    data?: IExtraData[],
    ids?: string[],
    min: number,
    max: number,
    average: boolean
}

export interface IRiskRanges {
    orderRisk: number,
    min: number,
    max: number,
    ranges: any[],
    translations: []
}

export interface ITestVersionValue {
    key: string,
    weight: number
}

export interface IAnswer {
    id?: string,
    questionId: string,
    originalQuestionId?: string,
    res?: number,
    key?: string,
    order?: number,
    inputRes?: number,
    value?: number
}

export interface IBitacora {
    _id: string,
    date: string,
    user: string,
    status: string,
    userName: string
}

export interface IFnmTestResult {
    extraResults: IExtraData[],
    score: number,
    profile: string
}

export interface IFnmTestMIFIDResult {
    results: IFnmTestResult,
    reason: string,
    savedBlocks: IBlock[],
    _id: string,
    testMIFID: string,
    status: string,
    answers: IAnswer[],
    testType: string,
    client: string,
    bitacora: IBitacora,
    user: string,
    entity: string,
    creationDate: string,
    referenceNum: string,
    __v: string
}

export interface ISuitabilityTest {
    id?: number,
    suitabilityTestVersionId?: number,
    suitabilityTestVersion?: ISuitabilityTestVersion,
    userIdentityId?: number,
    userIdentity?: UserIdentityType,
    signatureId?: number,
    signature?: ISignature,
    status?: string,
    answers?: IAnswer[],
    results?: IFnmTestMIFIDResult,
    createdAt?: string | Date,
    updatedAt?: string | Date
}

class SuitabilityTestsService {
    public getLastSuitabilityTestVersion = async (): Promise<ISuitabilityTestVersion> => {
        let url: string = `${proxy}/suitabilityTestVersion/last`
        const req = await axios.get(url)
        return req.data
    }

    public createSuitabilityTest = async (suitabilityTest: ISuitabilityTest): Promise<ISuitabilityTest> => {
        let url: string = `${proxy}/suitabilityTests`
        const req = await axios.post(url, suitabilityTest)
        return req.data
    }

    public createSuitabilityTests = async (suitabilityTests: ISuitabilityTest[]): Promise<ISuitabilityTest[]> => {
        let url: string = `${proxy}/createSuitabilityTests`
        const req = await axios.post(url, suitabilityTests)
        return req.data
    }

    public updateSuitabilityTest = async (suitabilityTest: ISuitabilityTest): Promise<ISuitabilityTest> => {
        let url: string = `${proxy}/suitabilityTests/${suitabilityTest.id}`
        const req = await axios.put(url, suitabilityTest)
        return req.data
    }

    public patchSuitabilityTest = async (suitabilityTestId: number, suitabilityTest: ISuitabilityTest): Promise<ISuitabilityTest> => {
        let url: string = `${proxy}/suitabilityTests/${suitabilityTestId}`
        const req = await axios.patch(url, suitabilityTest)
        return req.data
    }

    public getBySavingsPlanId = async (savingsPlanId: number): Promise<ISuitabilityTest[]> => {
        let url: string = `${proxy}/suitabilityTests/getBySavinsPlanId/${savingsPlanId}`
        const req = await axios.get(url)
        return req.data
    }
}

const suitabilityService = new SuitabilityTestsService();
export default suitabilityService;