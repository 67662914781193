import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { SavingsPlanUserIdentityType } from "./SavingsPlanUserIdentityService";
import { BmePortfolioType } from "./BmePortfolioService";
import { ManagedPortfolioList, ManagedPortfolioType } from "./ManagedPortfolioService";
import { FnmContract } from "./FnmContractService";
import { ITableSPlansActive } from "../components/SavePlans/TableActiveSavingPlans";
import { UserType } from "./UserService";
import { SavingsPlanProxyType } from "./SavingsPlanProxyService";
import { UsersSubscriptionType } from "./UsersSubscriptionService";
import { OrderType } from "./OrderService";
import { ITableSPlansRelational } from "../components/SavePlans/TableRelationPlans";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

export enum SavingsPlanTypes
{
    Saver,
    Alchemy,
    Advanced,
    Promoted,
    Experience
}
interface ISavingsPlan
{
    id?: number,
    userId?: number,
    user?: UserType,
    name: string,
    initialContribution?: number,
    periodicContribution?: number,
    periodicContributionPeriod?: number,
    configurationMapStep?: string,
    investForFree?: boolean,
    transferPortfolio?: boolean,
    normalInvestment?: boolean,
    managementInvestment?: boolean,
    managerId?: number,
    advisedInvestment?: boolean,
    adviserId?: number,
    createdAt?: string,
    type: SavingsPlanTypes,
    endDate?: string,
    categories?: any[],
    profitability?: number,
    status?: number,
    savingsPlansUserIdentities?: any[],
    bmePortfolioId?: number,
    bmePortfolio?: BmePortfolioType,
    managedPortfolioId?: number,
    managedPortfolio?: ManagedPortfolioType,
    fnmContractId?: number,
    fnmContract?: FnmContract,
    savingsPlansProxies?: SavingsPlanProxyType[],
    usersSubscription?: UsersSubscriptionType
}
export type SavingsPlanType = ISavingsPlan;

interface IDefaultSavingsPlan
{
    id: number,
    name: string,
    userId: number,
    fundName: string,
    isin: string,
    fundDescription: string,
    minAmount: number
}
export type DefaultSavingPlan = IDefaultSavingsPlan;

interface IReadySavingPlan
{
    hasPortfolio: boolean,
    readySavingPlan: boolean
}
export type ReadySavingPlan = IReadySavingPlan;

interface IPlansAndIdentities
{
    id: number,
    planName: string,
    identities: Identities[]
}

interface IIdentities
{
    name: string,
    dni: string,
}

interface IItems
{
    key: string,
    name: string,
    dni: string
}

interface IItemsAndGroup
{
    key: string,
    name: string,
    dni: string,
    startIndex: number,
    count: number
}

interface IAuxIdentities
{
    key: string,
    startIndex: number,
    count: number,
    name: string
}

interface ISavingsPlanSameIdentities
{
    userId: number,
    subscriptionId: number
}

interface IMapConfigurationData
{
    isMapEnabled: boolean,
    status: number,
    isPlanValidAndPaid: boolean,
    isSaverPlan: boolean,
    isAlchemyPlan: boolean,
    isAdvancedPlan: boolean,
    isSponsoredPlan: boolean,
    isExperiencePlan: boolean,
    mapStep: string,
    revisionTime: string | undefined,
    repaymentObject: IRepaymentObject
}

interface IRepaymentObject
{
    subscriptionId: number,
    userSubscriptionId: number,
    limitAmount: number,
    type: string
}

export type MapConfigurationData = IMapConfigurationData;
export type MapConfigurationRepaymentObject = IRepaymentObject;
export type SavingsPlanSameIdentities = ISavingsPlanSameIdentities;
export type PlansAndIdentities = IPlansAndIdentities;
export type Identities = IIdentities;
export type AuxIdentities = IAuxIdentities;
export type Items = IItems;
export type ItemGroup = IItemsAndGroup;

interface IActiveSubscriptionAndSavingPlans
{
    id: number,
    planName: string,
    type: number,
    selected: boolean,
    subscriptionId: number,
    fnmContractId: number,
    finametrixContract: string,
    createdAt?: Date,
    finametrixId: string,
    allUserIdentitiesHaveSuitabilityTest: boolean
}
export type ActiveSubscriptionAndSavingPlans = IActiveSubscriptionAndSavingPlans;

class SavingsPlanService
{
    public checkPermissionOnSavingPlans = async (savingsPlanId: number, userId: number): Promise<boolean> =>
    {
        let url: string = `${proxy}/checkSavingPlan/${savingsPlanId}/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getSavingsPlan = async (savingsPlanId: number): Promise<SavingsPlanType> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlanId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getSavingsPlansAndStatus = async (userId: number): Promise<ITableSPlansActive[]> =>
    {
        let url: string = `${proxy}/savingsPlans/getSavingsPlansAndStatus/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getRelationalPlans = async (userId: number): Promise<ITableSPlansRelational[]> =>
    {
        let url: string = `${proxy}/savingsPlans/relationalSavingPlan/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getActiveSavingPlans = async (userId: number): Promise<ITableSPlansActive[]> =>
    {
        let url: string = `${proxy}/savingsPlans/getActiveSavingPlans/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getFullActivePlanById = async (id: number): Promise<ITableSPlansActive> =>
    {
        let url: string = `${proxy}/GetFullActivePlanById/${id}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getActiveSubscriptionAndSavingPlan = async (userId: number): Promise<ActiveSubscriptionAndSavingPlans[]> =>
    {
        let url: string = `${proxy}/savingsPlans/getActiveSubscriptionAndSavingPlan/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getConfiguringSavingPlans = async (userId: number): Promise<ITableSPlansActive[]> =>
    {
        let url: string = `${proxy}/savingsPlans/getConfiguringSavingPlans/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getPromotionedSavingPlan = async (userId: number): Promise<DefaultSavingPlan> =>
    {
        let url: string = `${proxy}/promotionedSavingPlan/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getReadyPromotionedPlan = async (userId: number, id: number): Promise<IReadySavingPlan> =>
    {
        let url: string = `${proxy}/readySavingPlan/${userId}/${id}`;
        const req = await axios.get(url);
        return req.data;
    }
    public createSavingsPlan = async (savingsPlan: SavingsPlanType): Promise<SavingsPlanType> =>
    {
        let url: string = `${proxy}/savingsPlans`;
        const req = await axios.post(url, savingsPlan);
        return req.data;
    }
    public updateSavingsPlan = async (savingsPlan: SavingsPlanType): Promise<SavingsPlanType> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlan.id}`;
        const req = await axios.put(url, savingsPlan);
        return req.data;
    }
    public patchSavingsPlan = async (savingsPlanId: number, savingsPlan: any): Promise<SavingsPlanType> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlanId}`;
        const req = await axios.patch(url, savingsPlan);
        return req.data;
    }
    public updateSavingPlanName = async (planId: number, userId: number, planName: string): Promise<boolean> =>
    {
        let url: string = `${proxy}/UpdateSavingPlan/${userId}/${planId}`;
        const req = await axios.patch(url, planName);
        return req.data;
    }
    public getSavingsPlansByUserId = async (userId: number): Promise<SavingsPlanType[]> =>
    {
        let url: string = `${proxy}/savingsPlans/getByUserId/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getSavingsPlansByType = async (userId: number, type: number): Promise<PlansAndIdentities[]> =>
    {
        let url: string = `${proxy}/savingsPlans/getByType/${userId}/${type}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getCompleteSavingsPlansByUserId = async (userId: number): Promise<SavingsPlanType[]> =>
    {
        return this.getSavingsPlansByUserIdAndStatus(userId, 1);
    }
    public getIncompleteSavingsPlansByUserId = async (userId: number): Promise<SavingsPlanType[]> =>
    {
        return this.getSavingsPlansByUserIdAndStatus(userId, 0);
    }
    private getSavingsPlansByUserIdAndStatus = async (userId: number, status: number): Promise<SavingsPlanType[]> =>
    {
        let url: string = `${proxy}/savingsPlans/getByUserId/${userId}?status=${status}`;
        const req = await axios.get(url);
        return req.data;
    }
    public addSignaturesToSavingsPlanUserIdentities = async (savingsPlanId: number): Promise<SavingsPlanUserIdentityType[]> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlanId}/signatures`;
        const req = await axios.post(url);
        return req.data;
    }
    public addManagementAuthorizationSignaturesToSavingsPlanUserIdentities = async (savingsPlanId: number): Promise<SavingsPlanUserIdentityType[]> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlanId}/managementAuthorizationSignatures`;
        const req = await axios.post(url);
        return req.data;
    }
    public addSuitabilityTestSignaturesToSavingsPlanUserIdentities = async (savingsPlanId: number): Promise<SavingsPlanUserIdentityType[]> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlanId}/suitabilityTestSignatures`;
        const req = await axios.post(url);
        return req.data;
    }
    public getLastNewSavingsPlanByUsersSubscriptionId = async (usersSubscriptionId: number): Promise<SavingsPlanType> =>
    {
        let url: string = `${proxy}/savingsPlans/getLastNewByUsersSubscriptionId/${usersSubscriptionId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public addSavingPlanSameIdentities = async (savingsPlanId: number): Promise<any> =>
    {
        let url: string = `${proxy}/savingPlans/${savingsPlanId}/sameIdentities`;
        const req = await axios.post(url, {});
        return req.data;
    }
    public getMapConfigurationData = async (userId: number, savingPlanId: number): Promise<MapConfigurationData> =>
    {
        let url: string = `${proxy}/mapConfigurationData/${userId}/${savingPlanId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public deleteSavingPlanOnConfigure = async (savingsPlanId: number): Promise<void> =>
    {
        let url: string = `${proxy}/savingsPlan/${savingsPlanId}/onConfigure`;
        const req = await axios.delete(url);
        return req.data;
    }
    public getDistributionFromSavingPlanId = async (savingPlanId: number): Promise<ManagedPortfolioList[]> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingPlanId}/currentDistribution`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getHTMLContract = async (savingsPlanId: number): Promise<string> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlanId}/html`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getHTMLContractBySignatureGuid = async (signatureGuid: string): Promise<string> =>
    {
        let url: string = `${proxy}/savingsPlans/getHTMLContractBySignatureGuid/${signatureGuid}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getHTMLTripartiteContract = async (signatureGuid: string): Promise<string> =>
    {
        let url: string = `${proxy}/savingsPlans/getHtmlTripartiteContractBySignatureGuid/${signatureGuid}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public rebalance = async (savingsPlanId: number, modelPortfolioId: number): Promise<OrderType[]> =>
    {
        let url: string = `${proxy}/savingsPlans/${savingsPlanId}/rebalance/${modelPortfolioId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
}

const savingsPlans = new SavingsPlanService();
export default savingsPlans;