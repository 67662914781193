import { useHistory } from "react-router-dom";
import DocumentTitle from 'react-document-title';
import { DefaultButton } from 'office-ui-fabric-react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { ReactComponent as ProfessionalImage } from '../../images/i_am_professional.svg';

const NewProfessionalSavingsPlan = () =>
{
    let history = useHistory();

    const handleCommandClick = (event: any) =>
    {
        const url = new URL(event.currentTarget.href);
        history.push(url.href);
    };

    return(
        <DocumentTitle title='IronIA - Nuevo plan de ahorro'>
            <div className="ms-Grid-row savings-plans">
                <h2>Selecciona tu perfil ahorro</h2>
                <p className="description">Selecciona bajo qué tipo de perfil te gustaría realizar
                    un plan de ahorro y configura tu cartera de inversiones.</p>
                <Separator className="separator" />
                <div className="ms-Grid-col ms-sm12 ms-md4">
                    <div className="shadow">
                        <ProfessionalImage />
                        <h3>Soy profesional</h3>
                        <p>Controlo el riesgo, conozco los mercados y necesito información diferencial para
                            analizar lo que yo quiero, cuando yo quiero.</p>
                        <DefaultButton onClick={handleCommandClick} href="/savingsPlans/iAmProfessional">
                            Ver alternativas
                        </DefaultButton>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default NewProfessionalSavingsPlan;