export interface CustomIconProps
{
    iconName: string,
    size: string,
    color?: string,
    marginLeft?: string,
    marginRight?: string,
    paddingTop?: string,
    paddingLeft?: string,
    paddingBottom?: string,
    cursor?: string,
    fontWeight?: string,
    verticalAlign?: string,
    onClick?: (event: any) => any,
}

export const CustomIcon = (props: CustomIconProps) =>
{
    const handleClick: CustomIconProps["onClick"] = (event: any) =>
    {
        if(props.onClick !== undefined)
        {
            props.onClick(event);
        }
    }

    var cursorValue: any = props.cursor;
    if(cursorValue === undefined || cursorValue === "")
    {
        cursorValue = "pointer";
    }

    return <i className={`ms-Icon ms-Icon--${props.iconName}`} onClick={handleClick} style={
        {
            cursor: `${cursorValue}`,
            fontSize: `${props.size}px`,
            fontWeight: `${props.fontWeight}`,
            color: `${props.color}`,
            paddingTop: `${props.paddingTop}`,
            paddingLeft: `${props.paddingLeft}`,
            paddingBottom: `${props.paddingBottom}`,
            marginLeft: `${props.marginLeft}`,
            marginRight: `${props.marginRight}`,
            verticalAlign: `${props.verticalAlign}`,
        }
    }/>
}

export default CustomIcon;