import { useContext, useEffect, useState } from 'react';
import { DefaultButton, mergeStyleSets, Modal, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import { ThemeContext } from '@fluentui/react-theme-provider';
import Auth from "../../../Auth/Auth";
import FinametrixService, { IOperations } from "../../../services/FinametrixService";
import moment from "moment";
import { decimalFormatter, setCurrency, setLongCurrency } from '../../../utils/numberFormatter';
import DocumentTitle from "react-document-title";
import PortfolioOperationsTable from './PortfolioOperationsTable';
import { ActionsContainer, CounterContainer, Header, ParticipationsContainer, SearchContainer, TableWrapper } from './PortfolioOperationsStyles';
import { useBoolean } from '@fluentui/react-hooks';
import { ReactComponent as PdfIcon } from '../../../icons/circlePDF.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PaginationTable } from '../../../components/Pagination';
import { ExportIndex } from '../../../components/ExportIndex';

const itemsPerPage = 100;

const exportHeaders = [
    'Fecha VL',
    'Fecha Liquidación',
    'ISIN',
    'Producto',
    'Descripción',
    'Importe Total',
    'Precio',
    'Tipo de cambio',
    'Participaciones'
];

const PortfolioOperations = () =>
{
    const theme = useContext(ThemeContext);
    let history = useHistory();
    const [error, setError] = useState({ message: '', status: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const [operationsRaw, setOperationsRaw] = useState<IOperations[]>([]);
    const [operations, setOperations] = useState<any[]>([]);
    const [filteredOperations, setFilteredOperations] = useState<IOperations[]>([]);
    const date_from = (function(date: Date){date.setFullYear(date.getFullYear() - 1); return date;}).call(new Date(), new Date());
    const date_to = (function(){return new Date()}).call(new Date());
    const date_from_text = date_from.getFullYear().toString() + ("0" + (date_from.getMonth() + 1)).slice(-2) + ("0" + date_from.getDate()).slice(-2);
    const date_to_text = date_to.getFullYear().toString() + ("0" + (date_to.getMonth() + 1)).slice(-2) + ("0" + date_to.getDate()).slice(-2);
    let activePlan = Auth.getActivePlan();
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const [showAll, setShowAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() =>
    {
        FinametrixService.getOperations(activePlan.portfolio.finametrixId, {from: date_from_text, to: date_to_text}).then((data) =>
        {
            setIsLoading(false);
            setOperationsRaw(data);
        },
        (error) =>
        {
            setIsLoading(false);
            setError(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date_from_text, date_to_text]);

    const handleClick = () =>
    {
        setShowAll(true);
        setCurrentPage(0);
        setIsLoading(true);
        FinametrixService.getOperations(activePlan.portfolio.finametrixId).then((data) =>
        {
            setIsLoading(false);
            setOperationsRaw(data);
        },
        (error) =>
        {
            setIsLoading(false);
            setError(error);
        });
    }

    useEffect(() =>
    {
        if(operationsRaw.length > 0)
        {
            const operationsAux = operationsRaw.map((operation: any) =>
            (
                {
                    id: operation.id,
                    operationAt: moment(operation.operationAt).format('DD/MM/YYYY'),
                    settlementDate: moment(operation.settlementDate).format('DD/MM/YYYY'),
                    operationInstrument:
                    {
                        isin: operation.operationInstrument.isin,
                        name: operation.operationInstrument.name
                    },
                    description: operation.cashIn ? operation.description : operation.title,
                    amount: operation.amount,
                    nav: operation.nav,
                    exchangeRate: operation.exchangeRate === 1 ? operation.exchangeRate : decimalFormatter.format(operation.exchangeRate),
                    titles: operation.titles,
                    currency: operation.currency
                }
            ));
            setOperations(operationsAux);
        }
    }, [operationsRaw]);

    useEffect(() =>
    {
        setFilteredOperations(operations);
    }, [operations]);

    const downloadBase64Pdf = (event: any, operationId: string) =>
    {
        event.preventDefault();
        showModal();

        FinametrixService.getOperationBase64Pdf(operationId).then((response: any) =>
        {
            var downloadLink = document.createElement("a");
            downloadLink.download = `voucher-${operationId}.pdf`;
            downloadLink.href = response;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            hideModal();
        })
        .catch((error) =>
        {
            console.log(error);
        });
    };

    const contentStyles = mergeStyleSets(
    {
        container:
        {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            padding: '45px',
            borderRadius: '16px',
            selectors:
            {
                h3:
                {
                    textAlign: 'center',
                    marginBottom: '20px'
                }
            },
        }
    });

    const filter = (value: string) =>
    {
        if(value !== undefined)
        {
            setCurrentPage(0);
            var result: IOperations[] = [];
            for(var item in operations)
            {
                const text =
                [
                    operations[item].description !== null ? operations[item].description : '',
                    operations[item].operationInstrument.isin,
                    operations[item].operationInstrument.name,
                    moment(operations[item].operationAt).format('DD/MM/YYYY')
                ].join(" ");

                if(text.toUpperCase().indexOf(value.toUpperCase()) !== -1)
                {
                    result.push(operations[item]);
                }
            }
            setFilteredOperations(result);
        }
    }

    const getDataToExport = () =>
    {
        return filteredOperations.map(operation => (
        [
            operation.operationAt,
            operation.settlementDate,
            operation.operationInstrument.isin,
            operation.operationInstrument.name,
            operation.description,
            operation.amount,
            operation.nav,
            operation.exchangeRate,
            operation.titles
        ]));
    };

    if(error.status)
    {
        return(
            <DocumentTitle title="IronIA - Resumen de cartera">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }
    else
    {
        if(isLoading)
        {
            return <Spinner size={SpinnerSize.large} />
        }
        else
        {
            return(
                <DocumentTitle title="IronIA - Resumen de cartera">
                    <React.Fragment>
                        <div style={{marginTop: '10px', marginBottom: '20px'}}>
                            <h1 style={{margin: '0'}}>Operaciones realizadas</h1>
                            <Separator />
                        </div>
                        {operations.length > 0 &&
                        (
                            <TableWrapper theme={theme}>
                                <Header className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <CounterContainer theme={theme}>
                                            <h3>{`Operaciones realizadas${showAll ? '' : ' en el último año'}` || "Resultados de la búsqueda"}</h3>
                                            <span>({operations.length})</span>
                                        </CounterContainer>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 flex10">
                                        {!showAll &&
                                        (
                                            <DefaultButton className="button mini-primary-button" onClick={handleClick}>
                                                Ver todo
                                            </DefaultButton>
                                        )}
                                        <SearchContainer theme={theme}>
                                            <img src="/icons/search.svg" alt="Search Icon" />
                                            <input placeholder="Buscar..." onChange={val => filter(val.target.value)} />
                                        </SearchContainer>
                                        <ActionsContainer theme={theme} className="ms-hiddenLgDown">
                                            <ExportIndex headers={exportHeaders} getDataToExport={getDataToExport} name={activePlan.name} />
                                        </ActionsContainer>
                                    </div>
                                </Header>
                                <PortfolioOperationsTable data={filteredOperations.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)}
                                    columns={[
                                    {
                                        id: 'Fecha VL',
                                        title: 'Fecha VL',
                                        source: 'operationAt',
                                        customCell: (value: any) =>
                                        {
                                            return <div>{value.value}</div>
                                        }
                                    },
                                    {
                                        id: 'Fecha Liquidacion',
                                        title: 'Fecha Liquidación',
                                        source: 'settlementDate',
                                        customCell: (value: any) =>
                                        {
                                            return <div style={{minWidth: "120px", textAlign: "center"}}>{value.value}</div>
                                        }
                                    },
                                    {
                                        id: 'Producto',
                                        title: 'Producto',
                                        source: 'operationInstrument',
                                        customCell: (value: any) =>
                                        {
                                            return <p style={{color: "rgb(66,132,230)", cursor: "pointer"}} onClick={() => history.push(`/${value.value.isin}`)}>
                                                {value.value.isin}{" - "}{value.value.name}</p>
                                        },
                                    },
                                    {
                                        id: 'Descripcion',
                                        title: 'Descripción',
                                        source: 'description',
                                        customCell: (value: any) =>
                                        {
                                            return <p>{value.value}</p>
                                        },
                                    },
                                    {
                                        id: 'Importe Total',
                                        title: 'Importe Total',
                                        source: 'amount',
                                        customCell: (value: any) =>
                                        {
                                            return <p style={{minWidth: "100px", textAlign: "center"}}>{setCurrency(value.row.currency, (value.row.amount))}</p>;
                                        },
                                    },
                                    {
                                        id: 'Precio',
                                        title: 'Precio',
                                        source: 'nav',
                                        customCell: (value: any) =>
                                        {
                                            return <p style={{textAlign: "center"}}><span className="price-no-wrap">{setLongCurrency(value.row.currency, (value.row.nav))}</span></p>;
                                        },
                                    },
                                    {
                                        id: 'Tipo de cambio',
                                        title: 'Tipo de cambio',
                                        source: 'exchangeRate',
                                        customCell: (value: any) =>
                                        {
                                            return <p style={{minWidth: "100px", textAlign: "center"}}>{value.value}</p>;
                                        },
                                    },
                                    {
                                        id: 'Participaciones',
                                        title: 'Participaciones',
                                        source: 'titles',
                                        customCell: (value: any) =>
                                        {
                                            return <p style={{minWidth: "100px", textAlign: "center"}}>{decimalFormatter.format(value.value)}</p>;
                                        },
                                    },
                                    {
                                        id: 'actions',
                                        title: '',
                                        source: '',
                                        customCell: (data: any) =>
                                        {
                                            return(
                                                <React.Fragment>          
                                                    <ParticipationsContainer theme={theme}>
                                                        <a href={'#' + data?.row?.description} onClick={(event) => downloadBase64Pdf(event, data?.row?.id)}>
                                                            <PdfIcon />
                                                        </a>
                                                    </ParticipationsContainer>
                                                </React.Fragment>
                                            );
                                        },
                                    }]}
                                />
                            </TableWrapper>
                        )}
                        <div className="full-width text-center">
                            {showAll &&
                            (
                                <PaginationTable onChangePage={(newPage: number) => {setCurrentPage(newPage);}}
                                    totalPages={filteredOperations.length / itemsPerPage}
                                    currentPage={currentPage}
                                />
                            )}
                        </div>
                        {isModalOpen &&
                        (
                            <Modal isOpen={isModalOpen}
                                onDismiss={hideModal}
                                containerClassName={contentStyles.container}
                                isBlocking={false}
                            >
                                <h3>Descargando archivo</h3>
                                <Spinner size={SpinnerSize.large} />
                            </Modal>
                        )}
                    </React.Fragment>
                </DocumentTitle>
            );
        }
    }
};

export default PortfolioOperations;