import * as S from '../../components/SuitabilityTests/SuitabilityTest.style';
import { DetailsList, IColumn, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect, useState } from 'react';
import Moment from 'moment';
import FinametrixService, { RiskType } from '../../services/FinametrixService';
import { ManagedPortfolioType, PortfolioCompositionItemType, PortfolioDistributionItemType, QuantType } from '../../services/ManagedPortfolioService';
import SavingsPlanService, { SavingsPlanType } from '../../services/SavingsPlanService';
import PieHighstock from '../Highstock/PieHighstock';
import NumberFormat from 'react-number-format';
import React from 'react';
import { SavingsPlanUserIdentityType } from '../../services/SavingsPlanUserIdentityService';

const PortfolioSummary = (props:
{
    managedPortfolio: ManagedPortfolioType,
    suitabilityTestsRiskProfile: RiskType,
    risks: RiskType[],
    savingsPlan: SavingsPlanType,
    setSavingsPlan: Function,
    savingsPlanUserIdentities: SavingsPlanUserIdentityType[]
}) =>
{
    const [error, setError] = useState({status: false, message: ''});
    const [performance1YData, setPerformance1YData] = useState(0);
    const [future, setFuture] = useState(0);
    const [modelPortfolioComposition, setModelPortfolioComposition] = useState<PortfolioCompositionItemType[]>([]);
    const [modelPortfolioDistribution, setModelPortfolioDistribution] = useState<PortfolioDistributionItemType[]>([]);
    const [isLoadedModelPortfolioComposition, setIsLoadedModelPortfolioComposition] = useState(false);
    const [suitabilityTestRiskProfile, setSuitabilityTestRiskProfile] = useState<RiskType>();
    const [canFinish, setCanFinish] = useState(false);
    const columns: IColumn[] = [
    {
        key: 'name',
        name: 'Fondo',
        fieldName: 'name',
        minWidth: 300
    },
    {
        key: 'asset_class',
        name: 'Categoría',
        fieldName: 'category',
        minWidth: 200,
        onRender: (item: PortfolioCompositionItemType) =>
        {
            return <span>{item.category}</span>
        },
    },
    {
        key: 'asset_subclass',
        name: 'Subcategoría',
        fieldName: 'subcategory',
        minWidth: 200,
        onRender: (item: PortfolioCompositionItemType) =>
        {
            return <span>{item.subcategory}</span>
        },
    },
    {
        key: 'weight',
        name: 'Peso',
        fieldName: 'weight',
        minWidth: 100,
        onRender: (item: PortfolioCompositionItemType) =>
        {
            return <span>{item.weight}%</span>
        },
    }];

    useEffect(() =>
    {
        if(props.managedPortfolio !== undefined)
        {
            FinametrixService.getModelPortfolio(props.managedPortfolio.finametrixId).then((modelPortfolio: any) =>
            {
                let maxDate = Moment();
                let minDate = Moment().subtract(5, 'years');
                var maxDateTxt = maxDate.format("YYYYMMDD");
                var minDateTxt = minDate.format("YYYYMMDD");
                var lastComposition = modelPortfolio.compositions[modelPortfolio.compositions.length - 1];

                FinametrixService.postBenchmarksQuant(modelPortfolio.compositions, minDateTxt, maxDateTxt).then((quant: QuantType) =>
                {
                    setPerformance1YData(quant.quant.performance1Y * 100);
                },
                (error: any) =>
                {
                    setError(error);
                });

                var instrumentIds = lastComposition.items.map((instrument: any) => instrument.instrumentId);
                FinametrixService.getInstruments(instrumentIds).then((items: any) =>
                {
                    var aux: any = [];
                    var proccesed = 0;
                    lastComposition.items.forEach(async (compositionItem: any) =>
                    {
                        compositionItem.name = items[compositionItem.instrumentId].name;
                        compositionItem.isin = items[compositionItem.instrumentId].isin;

                        await FinametrixService.getInstrumentAssetclass(compositionItem.instrumentId).then((assetclass: any) =>
                        {
                            compositionItem.category = assetclass?.relationData?.parent?.name;
                            compositionItem.subcategory = assetclass?.relationData?.name;

                            var existentCategory = aux.find((item: any) => item.name === compositionItem.category);
                            if(existentCategory?.level2 !== undefined)
                            {
                                existentCategory.value += compositionItem.weight;
                                var existentSubcategory = existentCategory.level2.find((item: any) => item.name === compositionItem.subcategory);
                                if(existentSubcategory)
                                {
                                    existentSubcategory.value += compositionItem.weight;
                                }
                                else
                                {
                                    existentCategory.level2.push({ name: compositionItem.subcategory, value: compositionItem.weight });
                                }
                            }
                            else
                            {
                                aux.push({name: compositionItem.category, value: compositionItem.weight, level2: [{name: compositionItem.subcategory, value: compositionItem.weight}]});
                            }
                            proccesed++;
                        });

                        if(Object.keys(items).length === proccesed)
                        {
                            setModelPortfolioDistribution(aux);
                        }
                    });

                    setModelPortfolioComposition(lastComposition.items);
                    setIsLoadedModelPortfolioComposition(true);
                },
                (error: any) =>
                {
                    setIsLoadedModelPortfolioComposition(true);
                    setError(error);
                });
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    }, [props.managedPortfolio]);

    useEffect(() =>
    {
        var suitabilityTestRiskProfile = props.risks?.find((risk: RiskType) => risk._id === props.managedPortfolio.riskProfile);
        setSuitabilityTestRiskProfile(suitabilityTestRiskProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.managedPortfolio]);

    useEffect(() =>
    {
        if(props.savingsPlan.initialContribution !== undefined)
        {
            setFuture(props.savingsPlan.initialContribution * (1 + performance1YData / 100));
        }
    }, [performance1YData, props.savingsPlan.initialContribution]);

    useEffect(() =>
    {
        var managedPortfolioRiskProfile = props.risks?.find((risk: RiskType) => risk._id === props.managedPortfolio.riskProfile);
        if(managedPortfolioRiskProfile === undefined)
        {
            setCanFinish(false);
        }
        else
        {
            setCanFinish(managedPortfolioRiskProfile.order <= props.suitabilityTestsRiskProfile.order);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.managedPortfolio, props.suitabilityTestsRiskProfile]);

    return(
        <React.Fragment>
            <div className="ms-Grid-row savings-plans options">
                <Separator className="separator" />
                <div className="ms-Grid-col ms-sm8">
                    <h3>{props.managedPortfolio.name} {suitabilityTestRiskProfile?.name}
                        {" "}
                        {(suitabilityTestRiskProfile?.order || 0)+ 1}/4
                    </h3>
                    <h3>
                        Invirtiendo
                        <NumberFormat thousandSeparator="."
                            decimalSeparator=","
                            suffix="€"
                            value={props.savingsPlan.initialContribution}
                            decimalScale={2}
                            displayType="text"
                            style={TitleWithoutMargins}
                        />
                        hubiese recibido
                        <NumberFormat thousandSeparator="."
                            decimalSeparator=","
                            // @ts-ignore
                            suffix="€"
                            value={future}
                            decimalScale={2}
                            displayType="text"
                            style={TitleWithoutMargins}
                        />*
                    </h3>
                </div>
                <div className="ms-Grid-col ms-sm4 text-right">
                    <S.ContinueBtn className="button primary-button"
                        onClick={async (event) =>
                        {
                            if(props.savingsPlan.id !== undefined)
                            {
                                await SavingsPlanService.patchSavingsPlan(props.savingsPlan.id, {managedPortfolioId: props.managedPortfolio.id}).then((savingsPlan: SavingsPlanType) =>
                                {
                                    props.setSavingsPlan(savingsPlan);
                                },
                                (error) =>
                                {
                                    setError(error);
                                });
                            }
                        }}
                        disabled={!canFinish || !isLoadedModelPortfolioComposition}
                    >
                        Cambiar cartera
                    </S.ContinueBtn>
                    {!canFinish &&
                    (
                        <p>(No disponible para el nivel de riesgo de los intervinientes que se han incluido en el plan de ahorro)</p>
                    )}
                </div>
            </div>
            <div className="ms-Grid-row savings-plans options">
                <div className="ms-Grid-col ms-sm8">
                    {modelPortfolioComposition.length === 0 && (<Spinner size={SpinnerSize.large} />)}
                    {modelPortfolioComposition.length !== 0 &&
                    (
                        <DetailsList items={modelPortfolioComposition} columns={columns} selectionMode={0} />
                    )}
                </div>
                <div className="ms-Grid-col ms-md4">
                    <h3 className="text-center">Tipología de inversiones</h3>
                    {modelPortfolioDistribution.length === 0 && (<Spinner size={SpinnerSize.large} />)}
                    {modelPortfolioDistribution.length !== 0 && (<PieHighstock chartSerie={modelPortfolioDistribution} options={{showSubcategories: true}} />)}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PortfolioSummary;

const TitleWithoutMargins = {margin: '0 5px'};