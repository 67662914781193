import { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, DirectionalHint, ITooltipProps, Spinner, SpinnerSize, TooltipDelay, TooltipHost } from '@fluentui/react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { useBoolean, useId } from '@fluentui/react-hooks';
import Moment from 'moment';
import DocumentTitle from 'react-document-title';
import Auth from "../../Auth/Auth";
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import PaymentService from '../../services/PaymentService';
import InespayService, { BankType, InespayNoPrice, InespayType } from '../../services/InespayService';
import './Payments.sass';
import CustomSimpleDropdown, { IItem } from '../dropdown/CustomSimpleDropdown';
import * as Style from './RePaymentSubscriptionStyle';
import React from 'react';
import { createIntl, createIntlCache } from '@formatjs/intl';
import Spanish from '../../translations/es.json';
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../services/TelemetryService';
import UsersSubscriptionService from '../../services/UsersSubscriptionService';
import ElementService from '../../services/ElementsService';
import ElementsWaterIcon from "../../images/water.svg";
import ElementsIAIcon from "../../images/ia.svg";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { decimalFormatter, longDecimalFormatter } from '../../utils/numberFormatter';

const cache = createIntlCache();
const intl = createIntl(
    {
        locale: 'es',
        messages: Spanish,
    },
    cache
);

const stackTokens = { childrenGap: 15 };
const ibanRegex = RegExp(/^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/);

const tooltipProps: ITooltipProps =
{
    onRenderContent: () =>
    (
        <div>
            El pago periódico realizará una transferencia automática en el periodo especificado.
            El pago puntual sólo realiza el pago de este periodo de tiempo.
            Si no encuentra su entidad bancaria en el pago periódico, realice un pago puntual.
        </div>
    ),
};

interface RouteParams{};
interface Props extends RouteComponentProps<RouteParams>{};

const RePaymentSubscription: React.FunctionComponent<Props> = () =>
{
    const appInsights = ai.reactPlugin;
    const [error, setError] = useState({status: false, message: ''});
    const [isLoadedBanks, setIsLoadedBanks] = useState(true);
    const country = useRef<string>('ES');
    const [banks, setBanks] = useState<IItem[]>([]);
    const [bank, setBank] = useState('');
    const [subscription, setSubscription] = useState<Partial<SubscriptionType>>({});
    const [oldSubscription, setOldSubscription] = useState<Partial<SubscriptionType>>({});
    const [account, setAccount] = useState('');
    const [isError, setIsError] = useState<any>({country: '', bank: '', account: ''});
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { state } = useLocation<any>();
    const subscriptionId = useRef<any>(state?.subscriptionId);
    const subscriptionType = useRef<any>(state?.subscriptionType);
    const subscriptionLimit = useRef<any>(state?.limitAmount);
    const usersSubscriptionId = useRef<any>(state?.userSubscriptionId);
    const updateRenew = useRef<any>(state?.renew);
    const firstTimeLoadingElements = useRef<boolean>(true);
    const firstTimeLoadingOutdated = useRef<boolean>(true);
    const [areElementsApplied, setAreElementsApplied] = useState<boolean>(false);
    // const [elementsFire, setElementsFire] = useState<number>(0);
    // const [elementsAir, setElementsAir] = useState<number>(0);
    // const [elementsEarth, setElementsEarth] = useState<number>(0);
    const [elementsWater, setElementsWater] = useState<number>(0);
    const [elementsIA, setElementsIA] = useState<number>(0);
    const [elementWaterPrice, setElementWaterPrice] = useState<any>();
    const [maxElementsWater, setMaxElementsWater] = useState<number>(0);
    const [maxElementsIA, setMaxElementsIA] = useState<number>(0);
    const [elementsWaterUsed, setElementsWaterUsed] = useState<number>(0);
    const [elementsIAUsed, setElementsIAUsed] = useState<number>(0);
    const [lastDiscount, setLastDiscount] = useState<number>(0);
    const [isOutdated, setIsOutdated] = useState<boolean>(false);
    const [textOutdated, setTextOutdated] = useState<string>("");
    const [taxOutdated, setTaxOutdated] = useState<number>(0);
    const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);
    const [loadingPrice, setLoadingPrice] = useState<boolean>(true);
    const [alreadyModifiedPrice, setAlreadyModifiedPrice] = useState<boolean>(false);
    const oldPrice = useRef<number>(0);
    const [priceInfo, setPriceInfo] = useState<string>("");
    const user = Auth.getUserProfile();
    const userElementsStatus = Auth.getUserElementsStatus();
    const search = useLocation().search;
    const initialContribution = (new URLSearchParams(search).get('initialContribution')) || "";
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const todayText = Moment(today).format('YYYY-MM-DD');
    const reference = useRef<string>(`${user.id}-S${subscriptionId.current}-${today.valueOf().toString()}`);
    const [price, setPrice] = useState<number>(0);
    const [priceSubscription, setPriceSubscription] = useState<number>(0);
    const [frequency, setFrequency] = useState<string>();
    const [recursivePayment, {setTrue: setRecursivePayment, setFalse: setPuntualPayment}] = useBoolean(false);
    const themeContext = React.useContext(ThemeContext);
    const tooltipId = useId('tooltip');
    const trackClicks = useTrackEvent(
        appInsights,
        "Más Suscripciones",
        subscriptionId.current !== undefined
        && subscriptionId.current === 4
        && subscription !== undefined
        && subscription.name
    );
    const performTracking = () =>
    {
        trackClicks("Más Suscripciones");
    };
    useEffect(() =>
    {
        if(firstTimeLoadingElements.current === true)
        {
            firstTimeLoadingElements.current = false;

            if(userElementsStatus.isSubscribed)
            {
                ElementService.getOnlyElementsAmountByUserId(user.id).then((respuesta: any) =>
                {
                    if(respuesta !== undefined)
                    {
                        // if(respuesta.elementAir !== 0)
                        // {
                        //     setElementsAir(respuesta.elementAir);
                        // }
                        // if(respuesta.elementFire !== 0)
                        // {
                        //     setElementsFire(respuesta.elementFire);
                        // }
                        // if(respuesta.elementEarth !== 0)
                        // {
                        //     setElementsEarth(respuesta.elementEarth);
                        // }
                        if(respuesta.elementWater !== 0)
                        {
                            setElementsWater(respuesta.elementWater);
                        }
                        if(respuesta.elementIA !== 0)
                        {
                            setElementsIA(respuesta.elementIA);
                        }

                        ElementService.getLastElementsPrice().then((price: any) =>
                        {
                            setElementWaterPrice(price);
                        });
                    }
                });
            }
        }
    }, [user.id, userElementsStatus.isSubscribed]);
    useEffect(() =>
    {
        if(elementWaterPrice !== undefined && elementsWater !== undefined && priceSubscription !== undefined && firstTimeLoadingOutdated.current === false)
        {
            if(isOutdated === true && alreadyModifiedPrice === false)
            {
                let priceUpdated: number = priceSubscription+taxOutdated;
                let infoPrice: string = `${decimalFormatter.format((priceSubscription))} + ${taxOutdated.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(priceUpdated)}`;
                setPriceInfo(infoPrice);
                let allCombinedWater: number = parseFloat((priceUpdated / elementWaterPrice).toFixed(2));
                let allCombinedIA: number = parseFloat((priceUpdated / 0.01).toFixed(2));
                setMaxElementsWater(Math.round(allCombinedWater));
                setMaxElementsIA(Math.round(allCombinedIA));
                setPriceSubscription(priceUpdated);
                setPrice(priceUpdated);
                setLoadingPrice(false);
            }
            else
            {
                let infoPrice: string = `${decimalFormatter.format((priceSubscription))}`;
                setPriceInfo(infoPrice);
                let waterValue: number = parseFloat((priceSubscription / elementWaterPrice).toFixed(2));
                let iaValue: number = parseFloat((priceSubscription / 0.01).toFixed(2));
                setMaxElementsWater(Math.round(waterValue));
                setMaxElementsIA(Math.round(iaValue));
                setLoadingPrice(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementWaterPrice, elementsWater]);
    if(usersSubscriptionId.current !== undefined)
    {
        localStorage.setItem("RePaymentSubscriptionId", usersSubscriptionId.current);
    }
    useEffect(() =>
    {
        if(firstTimeLoadingOutdated.current === true && subscription !== undefined && (priceSubscription !== 0 || subscriptionType.current === 3))
        {
            firstTimeLoadingOutdated.current = false;

            UsersSubscriptionService.getOutdatedPriceBySubscriptionId(usersSubscriptionId.current).then((response: any) =>
            {
                if(response !== undefined)
                {
                    setIsOutdated(response.outdatedLongTime);
                    setTextOutdated(response.textOutdated);
                    let tax: number = parseFloat(((priceSubscription / 12) * response.monthsOutdated).toFixed(2));
                    setTaxOutdated(tax);

                    if(response.outdatedLongTime)
                    {
                        let priceUpdated: number = priceSubscription + tax;
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription))} + ${tax.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(priceUpdated)}`;
                        setPriceInfo(infoPrice);
                        setLoadingPrice(false);
                        setPriceSubscription(priceUpdated);
                        setPrice(priceUpdated);
                        setLoadingPrice(false);
                        setAlreadyModifiedPrice(true);
                    }
                    else
                    {
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription))}`;
                        setPriceInfo(infoPrice);
                        setLoadingPrice(false);
                    }
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription, priceSubscription, user]);
    useEffect(() =>
    {
        setIsLoadedBanks(false);
        InespayService.getBanks().then((data: any) =>
        {
            setIsLoadedBanks(true);
            setBanks(data.filter(function (bank: any)
            {
                return bank.country === country.current
                    && bank.frequencyPeriodicPayment !== null
                    && bank.frequencyPeriodicPayment.length > 0;
            })
            .map((bank: BankType) =>
            {
                return {
                    key: bank.bankId,
                    text: bank.name,
                    value: bank.bankId,
                    maxAllowedPeriod: bank.frequencyPeriodicPayment.length > 0
                        ? bank.bankId === "BES0049"
                            ? "Monthly"
                            : bank.frequencyPeriodicPayment[bank.frequencyPeriodicPayment.length - 1]
                        : null
                }
            }));
        },
        (error: any) =>
        {
            setIsLoadedBanks(true);
            setError(error);
        });

        if(Auth.getRepaymentSubscription() === "" || Auth.getRepaymentSubscription() === null)
        {
            SubscriptionService.getSubscription(parseInt(subscriptionId.current)).then((data: SubscriptionType) =>
            {
                setSubscription(data);
                setOldSubscription(data);
                if(data.type === "promotion")
                {
                    SubscriptionService.getDefaultSubscription().then((dataDefault: any) =>
                    {
                        setSubscription(dataDefault);

                        switch(subscription.type)
                        {
                            case "saver":
                                subscriptionType.current = 0;
                                break;
                            case "alchemy":
                                subscriptionType.current = 1;
                                break;
                            case "advanced":
                                subscriptionType.current = 2;
                                break;
                            case "promotioned":
                                subscriptionType.current = 4;
                                break;
                            case "experience":
                                subscriptionType.current = 4;
                                break;
                            case "educational":
                                subscriptionType.current = 5;
                                break;
                            default:
                                subscriptionType.current = 4;
                                break;
                        }
                    });
                }
            },
            (error) =>
            {
                setError(error);
            });
        }
        else
        {
            if(usersSubscriptionId.current === undefined)
            {
                usersSubscriptionId.current = localStorage.getItem("RePaymentSubscriptionId");
            }

            var subId: string = String(Auth.getRepaymentSubscription());
            SubscriptionService.getSubscription(parseInt(subId)).then((data: SubscriptionType) =>
            {
                setSubscription(data);
                setOldSubscription(data);
                if(subscriptionId.current === undefined)
                {
                    subscriptionId.current = data.id;
                }
                if(subscriptionType.current === undefined)
                {
                    switch(subscription.type)
                    {
                        case "saver":
                            subscriptionType.current = 0;
                            break;
                        case "alchemy":
                            subscriptionType.current = 1;
                            break;
                        case "advanced":
                            subscriptionType.current = 2;
                            break;
                        case "promotioned":
                            subscriptionType.current = 4;
                            break;
                        case "experience":
                            subscriptionType.current = 4;
                            break;
                        case "educational":
                            subscriptionType.current = 5;
                            break;
                        default:
                            subscriptionType.current = 4;
                            break;
                    }
                }
                reference.current = `${user.id}-S${subscriptionId.current}-${new Date().setDate(new Date().getDate() + 1).valueOf().toString()}`;

                UsersSubscriptionService.getLimitAmount(usersSubscriptionId.current).then((response: number) =>
                {
                    subscriptionLimit.current = response;
                });
            },
            (error) =>
            {
                setError(error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionId, user.id, usersSubscriptionId]);
    const changeBank = (option: any) =>
    {
        if(subscription?.period && subscription.period === "year")
        {
            setFrequency(option.maxAllowedPeriod);
        }

        setBank(option.key);
        const bankError = (option.key === null || option.key === "") ? 'Debe seleccionar un banco' : '';

        setIsError((prevIsError: any) =>
        ({
            ...prevIsError,
            bank: bankError
        }));
        validateForm('bank', option.key, bankError);
    };
    useEffect(() =>
    {
        if(subscription?.period)
        {
            switch(subscription.period)
            {
                case "year":
                    setFrequency("Annual");
                    break;
                case "month":
                    setFrequency("Monthly");
                    break;
            }
        }
    }, [subscription]);
    useEffect(() =>
    {
        if(frequency && subscription?.price !== undefined && subscription?.price !== null)
        {
            if(subscription.period === "year")
            {
                switch(frequency)
                {
                    case 'Annual':
                    {
                        setPrice(parseFloat(subscription.price.toFixed(2)));
                        setPriceSubscription(parseFloat(subscription.price.toFixed(2)));
                        break;
                    }
                    case 'SemiAnnual':
                    {
                        setPrice(parseFloat((subscription.price / 2).toFixed(2)));
                        setPriceSubscription(parseFloat((subscription.price / 2).toFixed(2)));
                        break;
                    }
                    case 'Quarterly':
                    {
                        setPrice(parseFloat((subscription.price / 4).toFixed(2)));
                        setPriceSubscription(parseFloat((subscription.price / 4).toFixed(2)));
                        break;
                    }
                    case 'EveryTwoMonths':
                    {
                        setPrice(parseFloat((subscription.price / 6).toFixed(2)));
                        setPriceSubscription(parseFloat((subscription.price / 6).toFixed(2)));    
                        break;
                    }
                    case 'Monthly':
                    {
                        setPrice(parseFloat((subscription.price / 12).toFixed(2)));
                        setPriceSubscription(parseFloat((subscription.price / 6).toFixed(2)));
                        break;
                    }
                    default:
                    {
                        setPrice(0);
                        setPriceSubscription(subscription.price);
                        break;
                    }
                }
            }
            else
            {
                setPrice(parseFloat(subscription.price.toFixed(2)));
                setPriceSubscription(parseFloat(subscription.price.toFixed(2)));
            }
        }
    }, [frequency, subscription]);
    const changeAccount = (event: any) =>
    {
        const value = event.target.value;
        setAccount(value);
        const accountError = (value === null || value === "")
            ? 'El IBAN es obligatorio'
            : (value.length === 24 && ibanRegex.test(value) ?
                '' :
                'El formato del IBAN no es válido. E.j. ES3456789012345678901234');

        setIsError((prevIsError: any) =>
        ({
            ...prevIsError,
            account: accountError
        }));
        validateForm('account', value, accountError);
    };
    const validateForm = (key: any, value: any, error: any) =>
    {
        var invalidatedFields = Object.keys(isError).filter(function (field: any)
        {
            /*eslint-disable no-eval */
            var fieldValue = eval(field);
            var fieldError = isError[field];
            if(key === field)
            {
                fieldValue = value;
                fieldError = error;
            }
            return fieldValue === '' || fieldError !== '';
        });
        setCanSubmit(invalidatedFields.length === 0);
    };
    const sendRecursivePayment = async (event: any) =>
    {
        event.preventDefault();
        setIsSubmitted(true);

        if(subscriptionType.current !== subscription.id)
        {
            await UsersSubscriptionService.patchUsersSubscription(
            {
                id: usersSubscriptionId.current,
                subscriptionId: subscription.id
            });
        }

        PaymentService.createPayment(
        {
            amount: price,
            frequency: frequency,
            reference: reference.current,
            bank: bank,
            account: account,
            subject: `${user.id}-${subscription.id}`,
            startDate: todayText,
            status: 1,
            usersSubscriptionId: parseInt(usersSubscriptionId.current),
            platform: "INESPAY"
        })
        .then(() =>
        {
            var inespayData: InespayType =
            {
                subject: `${user.id}-${subscription.id}`,
                amount: (price * 100).toString(),
                frequency: frequency,
                reference: reference.current,
                account: account,
                startDate: todayText,
                subscription: true,
                renovation: true,
                oldSubscriptionId: oldSubscription.id,
                usersSubscriptionId: subscription.id
            };
            if(updateRenew.current !== undefined)
            {
                inespayData.isRenew = updateRenew.current;
            }

            if(initialContribution !== "")
            {
                inespayData.initialContribution = parseFloat(initialContribution)
            }

            sendToInespay(inespayData);
        },
        (error: any) =>
        {
            setError(error);
        });
    };
    const sendPuntualPayment = async (event: any) =>
    {
        event.preventDefault();
        setIsSubmitted(true);

        if(subscription?.price === undefined || subscription?.price === null)
        {
            setError({status: true, message: "La suscripción elegida no tiene precio definido"});
            setIsSubmitted(false);
        }
        else if(price === undefined || price === null)
        {
            setError({status: true, message: "La suscripción elegida no tiene precio definido"});
            setIsSubmitted(false);
        }
        else
        {
            var puntualPrice: number = parseFloat(price.toFixed(2));
            PaymentService.createPayment(
            {
                amount: puntualPrice,
                reference: reference.current,
                subject: `${user.id}-${subscription.id}`,
                status: 1,
                usersSubscriptionId: parseInt(usersSubscriptionId.current),
                platform: "INESPAY"
            })
            .then(() =>
            {
                var inespayData: InespayType =
                {
                    subject: `${user.id}-${subscription.id}`,
                    reference: reference.current,
                    amount: (puntualPrice * 100).toString(),
                    subscription: true,
                    renovation: true,
                    oldSubscriptionId: oldSubscription.id,
                    usersSubscriptionId: subscription.id,
                    userId: user.id
                };

                if(userElementsStatus.isSubscribed)
                {
                    inespayData.elements =
                    {
                        water: -1 * (elementsWaterUsed || 0),
                        ia: -1 * (elementsIAUsed || 0),
                        air: 0,
                        earth: 0,
                        fire: 0
                    }
                }
                if (updateRenew.current !== undefined) {
                    inespayData.isRenew = updateRenew.current;
                }

                if(initialContribution !== "")
                {
                    inespayData.initialContribution = parseFloat(initialContribution)
                }

                sendToInespay(inespayData);
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    };
    const sendToInespay = (inespayData: InespayType) =>
    {
        if(userElementsStatus.isSubscribed && price === 0)
        {
            InespayService.postNoPriceInespay(inespayData).then((dataInespay: InespayNoPrice) =>
            {
                if(dataInespay.isError === true)
                {
                    setError(
                    {
                        status: false,
                        message: dataInespay.statusMessage
                    });

                    setIsSubmitted(false);
                }
                else
                {
                    if(dataInespay.statusMessage === "Inespay")
                    {
                        InespayService.postInespay(inespayData).then((dataInespay: any) =>
                        {
                            const inespay_result = JSON.parse(dataInespay.Result);
                            if(inespay_result.status === '200')
                            {
                                window.location.href = inespay_result.url;
                            }
                            else
                            {
                                setError(
                                {
                                    status: false,
                                    message: inespay_result.description
                                });

                                setIsSubmitted(false);
                            }
                        },
                        (error: any) =>
                        {
                            setError(error);
                        });
                    }
                    else
                    {
                        window.location.href = dataInespay.statusMessage;
                    }
                }
            });
        }
        else
        {
            if(parseFloat(inespayData.amount) > 0 && parseFloat(inespayData.amount) < 100)
            {
                setError(
                {
                    status: false,
                    message: "El valor de compra con Elements debe ser completamente 0€ o mayor de 1€ para poder procesar la transacción. Los valores entre 0.01€ y 1€ no son aceptados por la plataforma PSD2."
                });
                setIsSubmitted(false);
            }
            else
            {
                InespayService.postInespay(inespayData).then((dataInespay: any) =>
                {
                    const inespay_result = JSON.parse(dataInespay.Result);
                    if(inespay_result.status === '200')
                    {
                        window.location.href = inespay_result.url;
                    }
                    else
                    {
                        setError(
                        {
                            status: false,
                            message: inespay_result.description
                        });
        
                        setIsSubmitted(false);
                    }
                },
                (error: any) =>
                {
                    setError(error);
                });
            }
        }
    };
    const restoreElementsWaterUsed = () =>
    {
        if(elementsWaterUsed === 0)
        {
            setInfoMessage("¡Introduce algún Element Agua antes de intentar 'Reestablecer' los Elements a 0!");

            setTimeout(() =>
            {
                setInfoMessage(undefined);
            }, 5000);
        }
        else
        {
            if(elementsIAUsed !== undefined && elementsIAUsed === 0)
            {
                setElementsWaterUsed(0);
                setPrice(priceSubscription);
                setAreElementsApplied(false);
                oldPrice.current = priceSubscription;
                setPriceInfo("");
            }
            else
            {
                setElementsWaterUsed(0);
                setAreElementsApplied(true);
                let elementsUsed: number = elementsIAUsed;
                var valueDiscount = (0.01 * elementsUsed);
                var newPrice = parseFloat((priceSubscription - valueDiscount).toFixed(2));
                var elementsDiscount = priceSubscription - newPrice;
                if(newPrice <= 0)
                {
                    newPrice = 0;
                }
                setPrice(newPrice);
                let totalDiscount = elementsDiscount;
                setLastDiscount(totalDiscount);
                if(isOutdated)
                {
                    let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                    setPriceInfo(infoPrice);
                }
                else
                {
                    let infoPrice: string = `${decimalFormatter.format(priceSubscription)} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                    setPriceInfo(infoPrice);
                }
            }
        }
    }
    const restoreElementsIAUsed = () =>
    {
        if(elementsIAUsed === 0)
        {
            setInfoMessage("¡Introduce algún Element IA antes de intentar 'Reestablecer' los Elements a 0!");

            setTimeout(() =>
            {
                setInfoMessage(undefined);
            }, 5000);
        }
        else
        {
            if(elementsWaterUsed !== undefined && elementsWaterUsed === 0)
            {
                setElementsIAUsed(0);
                setPrice(priceSubscription);
                setAreElementsApplied(false);
                oldPrice.current = priceSubscription;
                setPriceInfo("");
            }
            else
            {
                setElementsIAUsed(0);
                setAreElementsApplied(true);
                let elementsUsed: number = elementsWaterUsed;
                var valueDiscount = (elementWaterPrice * elementsUsed);
                var newPrice = parseFloat((priceSubscription - valueDiscount).toFixed(2));
                var elementsDiscount = priceSubscription - newPrice;
                if(newPrice <= 0)
                {
                    newPrice = 0;
                }
                setPrice(newPrice);
                let totalDiscount = elementsDiscount;
                setLastDiscount(totalDiscount);
                if(isOutdated)
                {
                    let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                    setPriceInfo(infoPrice);
                }
                else
                {
                    let infoPrice: string = `${decimalFormatter.format(priceSubscription)} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                    setPriceInfo(infoPrice);
                }
            }
        }
    }
    const handleWaterInputChange = (value: any) =>
    {
        var elements: number = value.target.value;

        if(elements > elementsWater)
        {
            setElementsWaterUsed(elementsWater);
        }
        else
        {
            setElementsWaterUsed(elements);
        }
    }
    const handleIAInputChange = (value: any) =>
    {
        var elements: number = value.target.value;

        if(elements > elementsIA)
        {
            setElementsIAUsed(elementsIA);
        }
        else
        {
            setElementsIAUsed(elements);
        }
    }
    const fullWaterElements = () =>
    {
        if(elementsWater === 0)
        {
            setInfoMessage("¡No dispones de Elements Agua para utilizar!");

            setTimeout(() =>
            {
                setInfoMessage(undefined);
            }, 5000);
        }
        else
        {
            if(elementsIAUsed !== undefined && elementsIAUsed !== 0)
            {
                if(price <= 0)
                {
                    setInfoMessage("¡Los Elements seleccionados ya cubren el importe completo!");
    
                    setTimeout(() =>
                    {
                        setInfoMessage(undefined);
                    }, 5000);
                }
                else
                {
                    let priceTemp: number = price;
                    let elementsNeeded: number = Math.round(parseFloat((priceTemp / elementWaterPrice).toFixed(2))); 
                    let elementsUsed: number = 0;
    
                    if(elementsWater <= elementsNeeded)
                    {
                        elementsUsed = elementsWater;
                    }
                    else
                    {
                        elementsUsed = elementsNeeded;
                    }
    
                    setAreElementsApplied(true);
                    var valueDiscount = (elementWaterPrice * elementsUsed);
                    var newPrice = parseFloat((priceTemp - valueDiscount).toFixed(2));
                    var elementsDiscount = priceTemp - newPrice;
                    if(newPrice <=0)
                    {
                        newPrice = 0;
                    }
                    setPrice(newPrice);
                    let totalDiscount = lastDiscount+elementsDiscount;
                    setLastDiscount(totalDiscount);
                    if(isOutdated)
                    {
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription-taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                        setPriceInfo(infoPrice);
                    }
    
                    oldPrice.current = priceTemp;
                    setElementsWaterUsed(elementsUsed);
                }
            }
            else
            {
                if(elementsWaterUsed !== 0)
                {
                    setAreElementsApplied(true);
                    if(elementsWaterUsed === maxElementsWater || elementsWaterUsed === elementsWater)
                    {
                        setInfoMessage("¡Ya has introducido el máximo de Elements Agua!");
    
                        setTimeout(() =>
                        {
                            setInfoMessage(undefined);
                        }, 5000);
                    }
                    else
                    {
                        let usedElements: number = 0;
        
                        if(maxElementsWater > elementsWater)
                        {
                            usedElements = elementsWater;
                        }
                        else
                        {
                            usedElements = maxElementsWater;
                        }
        
                        var value2Discount = (elementWaterPrice * usedElements);
                        var newPrice2 = parseFloat((priceSubscription - value2Discount).toFixed(2));
                        var elementsDiscountPrice = priceSubscription - newPrice2;
                        if(newPrice2 <= 0)
                        {
                            newPrice2 = 0;
                        }
                        setPrice(newPrice2);
                        if(isOutdated)
                        {
                            let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${elementsDiscountPrice.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice2)}`;
                            setPriceInfo(infoPrice);
                        }
                        else
                        {
                            let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${elementsDiscountPrice.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice2)}`;
                            setPriceInfo(infoPrice);
                        }
                        oldPrice.current = priceSubscription;
                        setElementsWaterUsed(usedElements);
                        setElementsIAUsed(0);
                    }
                }
                else
                {
                    if(elementsWater !== undefined && maxElementsWater !== undefined)
                    {
                        let usedElements: number = 0;
            
                        if(maxElementsWater > elementsWater)
                        {
                            usedElements = elementsWater;
                        }
                        else
                        {
                            usedElements = maxElementsWater;
                        }
            
                        setAreElementsApplied(true);
                        var valueDiscounted = (elementWaterPrice * usedElements);
            
                        if(oldPrice.current !== 0)
                        {
                            var newFinalPrice = parseFloat((oldPrice.current - valueDiscounted).toFixed(2));
                            var elementsDiscounted = oldPrice.current - newFinalPrice;
                            if(newFinalPrice <=0)
                            {
                                newFinalPrice = 0;
                            }
                            setPrice(newFinalPrice);
                            setLastDiscount(elementsDiscounted);
                            if(isOutdated)
                            {
                                let infoPrice: string = `${decimalFormatter.format((priceSubscription-taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${elementsDiscounted.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newFinalPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                            else
                            {
                                let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${elementsDiscounted.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newFinalPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                        }
                        else
                        {
                            var firstNewPrice = parseFloat((price - valueDiscounted).toFixed(2));
                            oldPrice.current = price;
                            var elementsDisc = price - firstNewPrice;
                            if(firstNewPrice <= 0)
                            {
                                firstNewPrice = 0;
                            }
                            setPrice(firstNewPrice);
                            setLastDiscount(elementsDisc);
                            if(isOutdated)
                            {
                                let infoPrice: string = `${decimalFormatter.format((priceSubscription-taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${elementsDisc.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(firstNewPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                            else
                            {
                                let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${elementsDisc.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(firstNewPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                        }
            
                        setElementsWaterUsed(usedElements);
                        setElementsIAUsed(0);
                    }
                }
            }
        }
    }
    const fullIAElements = () =>
    {
        if(elementsIA === 0)
        {
            setInfoMessage("¡No dispones de Elements IA para utilizar!");

            setTimeout(() =>
            {
                setInfoMessage(undefined);
            }, 5000);
        }
        else
        {
            if(elementsWaterUsed !== undefined && elementsWaterUsed !== 0)
            {
                if(price <= 0)
                {
                    setInfoMessage("¡Los Elements seleccionados ya cubren el importe completo!");
    
                    setTimeout(() =>
                    {
                        setInfoMessage(undefined);
                    }, 5000);
                }
                else
                {
                    let priceTemp: number = price;
                    let elementsNeeded: number = Math.round(parseFloat((priceTemp / 0.01).toFixed(2))); 
                    let elementsUsed: number = 0;
    
                    if(elementsIA <= elementsNeeded)
                    {
                        elementsUsed = elementsIA;
                    }
                    else
                    {
                        elementsUsed = elementsNeeded;
                    }
    
                    setAreElementsApplied(true);
                    var valueToDiscount =  0.01* elementsUsed;
                    var newPrice = parseFloat((priceTemp - valueToDiscount).toFixed(2));
                    var elementsDiscountPrice = priceTemp - newPrice;
                    if(newPrice <=0)
                    {
                        newPrice = 0;
                    }
                    setPrice(newPrice);
                    let totalDiscount = lastDiscount+elementsDiscountPrice;
                    setLastDiscount(totalDiscount);
                    if(isOutdated)
                    {
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription-taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${totalDiscount.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = priceTemp;
        
                    setElementsIAUsed(elementsUsed);
                }
            }
            else
            {
                if(elementsIAUsed !== 0)
                {
                    setAreElementsApplied(true);
                    if(elementsIAUsed === maxElementsIA || elementsIAUsed === elementsIA)
                    {
                        setInfoMessage("¡Ya has introducido el máximo de Elements IA!");
    
                        setTimeout(() =>
                        {
                            setInfoMessage(undefined);
                        }, 5000);
                    }
                    else
                    {
                        let usedElements: number = 0;
        
                        if(maxElementsIA > elementsIA)
                        {
                            usedElements = elementsIA;
                        }
                        else
                        {
                            usedElements = maxElementsIA;
                        }
        
                        var valueDiscounted = (0.01 * usedElements);
                        var finalPrice = parseFloat((priceSubscription - valueDiscounted).toFixed(2));
                        var elementDiscountPrice = priceSubscription - finalPrice;
                        if(finalPrice <= 0)
                        {
                            finalPrice = 0;
                        }
                        setPrice(finalPrice);
                        if(isOutdated)
                        {
                            let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${elementDiscountPrice.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                            setPriceInfo(infoPrice);
                        }
                        else
                        {
                            let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${elementDiscountPrice.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                            setPriceInfo(infoPrice);
                        }
                        oldPrice.current = priceSubscription;
                        setElementsWaterUsed(0);
                        setElementsIAUsed(usedElements);
                    }
                }
                else
                {
                    if(elementsIA !== undefined && maxElementsIA !== undefined)
                    {
                        let usedElements: number = 0;
            
                        if(maxElementsIA > elementsIA)
                        {
                            usedElements = elementsIA;
                        }
                        else
                        {
                            usedElements = maxElementsIA;
                        }
            
                        setAreElementsApplied(true);
                        var valueDisc = (0.01 * usedElements);
            
                        if(oldPrice.current !== 0)
                        {
                            var newFinalPrice = parseFloat((oldPrice.current - valueDisc).toFixed(2));
                            var elementsDiscountFinalPrice = oldPrice.current - newFinalPrice;
                            if(newFinalPrice <=0)
                            {
                                newFinalPrice = 0;
                            }
                            setPrice(newFinalPrice);
                            setLastDiscount(elementsDiscountFinalPrice);
                            if(isOutdated)
                            {
                                let infoPrice: string = `${decimalFormatter.format((priceSubscription-taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${elementsDiscountFinalPrice.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newFinalPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                            else
                            {
                                let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${elementsDiscountFinalPrice.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(newFinalPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                        }
                        else
                        {
                            var firstNewPrice = parseFloat((price - valueDisc).toFixed(2));
                            oldPrice.current = price;
                            var elementsDisc = price - firstNewPrice;
                            if(firstNewPrice <= 0)
                            {
                                firstNewPrice = 0;
                            }
                            setPrice(firstNewPrice);
                            setLastDiscount(elementsDisc);
                            if(isOutdated)
                            {
                                let infoPrice: string = `${decimalFormatter.format((priceSubscription-taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${elementsDisc.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(firstNewPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                            else
                            {
                                let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${elementsDisc.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(firstNewPrice)}`;
                                setPriceInfo(infoPrice);
                            }
                        }
            
                        setElementsIAUsed(usedElements);
                        setElementsWaterUsed(0);
                    }
                }
            }
        }
    }
    const applyWaterElements = () =>
    {
        if(elementsWaterUsed !== undefined && elementsWaterUsed !== 0 && maxElementsWater !== undefined)
        {
            if(elementsIAUsed !== 0)
            {
                //existen elementos IA usados y ya hay elements agua usados.
                setAreElementsApplied(true);
                let priceWithOnlyWater: number = parseFloat((elementWaterPrice*elementsWaterUsed).toFixed(2));
                let priceWithOnlyIA: number = parseFloat((0.01*elementsIAUsed).toFixed(2));
                let priceCombined: number = parseFloat((priceWithOnlyWater+priceWithOnlyIA).toFixed(2));
                let finalPrice: number = parseFloat((priceSubscription-priceCombined).toFixed(2));
                if(finalPrice < 0)
                {
                    finalPrice = 0;
                }

                let usedElements: number = 0;

                if(elementsWaterUsed > maxElementsWater)
                {
                    usedElements = maxElementsWater;
                }
                else
                {
                    usedElements = elementsWaterUsed;
                }

                usedElements = parseInt((usedElements).toString());
                
                if(priceCombined < priceSubscription)
                {
                    //el importe es inferior aún al precio de la suscripcion.
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setElementsWaterUsed(usedElements);
                }
                else if(priceCombined === priceSubscription)
                {
                    //el importe cubre exactamente el precio de la suscripcion
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setElementsWaterUsed(usedElements);
                }
                else
                {
                    ///el importe sobrepasa el precio de la suscripcion. Reducimos el precio necesario solo para Water.
                    usedElements = Math.round((priceSubscription - priceWithOnlyIA) / elementWaterPrice);
                    priceCombined = parseFloat((priceWithOnlyWater + parseFloat((elementWaterPrice*usedElements).toFixed(2))).toFixed(2));
                    
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceSubscription.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceSubscription.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setElementsWaterUsed(usedElements);
                }
            }
            else
            {
                //No existen elementos IA usados y ya hay elements agua usados.

                setAreElementsApplied(true);
                let priceWithOnlyWater: number = parseFloat((elementWaterPrice * elementsWaterUsed).toFixed(2));
                let finalPrice: number = parseFloat((priceSubscription - priceWithOnlyWater).toFixed(2));
                let usedElements: number = 0;

                if(elementsWaterUsed > maxElementsWater)
                {
                    usedElements = maxElementsWater;
                }
                else
                {
                    usedElements = elementsWaterUsed;
                }

                if(finalPrice < 0)
                {
                    finalPrice = 0;
                }

                usedElements = parseInt((usedElements).toString());

                if(priceWithOnlyWater < priceSubscription)
                {
                    //el importe es inferior aún al precio de la suscripcion.
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceWithOnlyWater.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceWithOnlyWater.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setPrice(finalPrice);
                    setElementsWaterUsed(usedElements);
                }
                else
                {
                    if(oldPrice.current === 0)
                    {
                        setInfoMessage("¡Los Elements seleccionados ya cubren el importe completo!");

                        setTimeout(() =>
                        {
                            setInfoMessage(undefined);
                        }, 5000);
                    }
                    else
                    {
                        //el importe cubre exacta o superiormente el precio de la suscripcion
                        if(isOutdated)
                        {
                            //esta caducado el plan
                            let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceWithOnlyWater.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                            setPriceInfo(infoPrice);
                        }
                        else
                        {
                            //no esta caducado el plan
                            let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceWithOnlyWater.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                            setPriceInfo(infoPrice);
                        }
                        oldPrice.current = price;
                        setLastDiscount(priceWithOnlyWater);
                        setElementsWaterUsed(usedElements);
                    }
                }
            }
        }
        else
        {
            if(elementsWater === 0)
            {
                setInfoMessage("¡No dispones de Elements Agua para utilizar!");
            }
            else
            {
                setInfoMessage("¡No has seleccionado Elements Agua para utilizar!");
            }

            setTimeout(() =>
            {
                setInfoMessage(undefined);
            }, 5000);
        }
    }
    const applyIAElements = () =>
    {
        if(elementsIAUsed !== undefined && elementsIAUsed !== 0 && maxElementsIA !== undefined)
        {
            if(elementsWaterUsed !== 0)
            {
                //existen elementos agua usados y ya hay elements IA usados.
                setAreElementsApplied(true);
                let priceWithOnlyWater: number = parseFloat((elementWaterPrice*elementsWaterUsed).toFixed(2));
                let priceWithOnlyIA: number = parseFloat((0.01*elementsIAUsed).toFixed(2));
                let priceCombined: number = parseFloat((priceWithOnlyWater+priceWithOnlyIA).toFixed(2));
                let finalPrice: number = parseFloat((priceSubscription-priceCombined).toFixed(2));
                if(finalPrice < 0)
                {
                    finalPrice = 0;
                }

                let usedElements: number = 0;

                if(elementsIAUsed > maxElementsIA)
                {
                    usedElements = maxElementsIA;
                }
                else
                {
                    usedElements = elementsIAUsed;
                }

                usedElements = parseInt((usedElements).toString());
                
                if(priceCombined < priceSubscription)
                {
                    //el importe es inferior aún al precio de la suscripcion.
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setElementsIAUsed(usedElements);
                }
                else if(priceCombined === priceSubscription)
                {
                    //el importe cubre exactamente el precio de la suscripcion
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setElementsIAUsed(usedElements);
                }
                else
                {
                    ///el importe sobrepasa el precio de la suscripcion. Reducimos el precio necesario solo para el IA.
                    usedElements = Math.round((priceSubscription - priceWithOnlyWater) / 0.01);
                    priceCombined = parseFloat((priceWithOnlyWater + parseFloat((0.01*usedElements).toFixed(2))).toFixed(2));
                    
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceCombined.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setElementsIAUsed(usedElements);
                }
            }
            else
            {
                //No existen elementos agua usados y ya hay elements IA usados.
                setAreElementsApplied(true);
                let priceWithOnlyIA: number = parseFloat((0.01*elementsIAUsed).toFixed(2));
                let finalPrice: number = parseFloat((priceSubscription-priceWithOnlyIA).toFixed(2));
                let usedElements: number = 0;

                if(elementsIAUsed > maxElementsIA)
                {
                    usedElements = maxElementsIA;
                }
                else
                {
                    usedElements = elementsIAUsed;
                }

                if(finalPrice < 0)
                {
                    finalPrice = 0;
                }
                
                usedElements = parseInt((usedElements).toString());

                if(priceWithOnlyIA < priceSubscription)
                {
                    //el importe es inferior aún al precio de la suscripcion.
                    if(isOutdated)
                    {
                        //esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceWithOnlyIA.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    else
                    {
                        //no esta caducado el plan
                        let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceWithOnlyIA.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                        setPriceInfo(infoPrice);
                    }
                    oldPrice.current = price;
                    setPrice(finalPrice);
                    setElementsIAUsed(usedElements);
                }
                else
                {
                    if(oldPrice.current === 0)
                    {
                        setInfoMessage("¡Los Elements seleccionados ya cubren el importe completo!");

                        setTimeout(() =>
                        {
                            setInfoMessage(undefined);
                        }, 5000);
                    }
                    else
                    {
                        //el importe cubre exacta o superiormente el precio de la suscripcion
                        if(isOutdated)
                        {
                            //esta caducado el plan
                            let infoPrice: string = `${decimalFormatter.format((priceSubscription - taxOutdated))} + ${taxOutdated.toFixed(2).replace('.', ',')} - ${priceWithOnlyIA.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                            setPriceInfo(infoPrice);
                        }
                        else
                        {
                            //no esta caducado el plan
                            let infoPrice: string = `${decimalFormatter.format(oldPrice.current)} - ${priceWithOnlyIA.toFixed(2).replace('.', ',')} = ${decimalFormatter.format(finalPrice)}`;
                            setPriceInfo(infoPrice);
                        }
                        oldPrice.current = price;
                        setLastDiscount(priceWithOnlyIA);
                        setElementsIAUsed(usedElements);
                    }
                }
            }
        }
        else
        {
            if(elementsWater === 0)
            {
                setInfoMessage("¡No dispones de Elements IA para utilizar!");
            }
            else
            {
                setInfoMessage("¡No has seleccionado Elements IA para utilizar!");
            }

            setTimeout(() =>
            {
                setInfoMessage(undefined);
            }, 5000);
        }
    }
    if(error.status)
    {
        return(
            <DocumentTitle title='IronIA - Pago Suscripción'>
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }

    return(
        <DocumentTitle title='IronIA - Pago Suscripción'>
            <React.Fragment>
                <Style.DivRowPrincipal className="ms-Grid-row">
                    <Style.PrincipalHeader>
                        Detalles de pago de la Suscripción
                    </Style.PrincipalHeader>
                    <Style.PrincipalDescription>
                        Este método de pago conecta con tu banca online utilizando las claves de acceso de tu banca online.
                    </Style.PrincipalDescription>
                    {userElementsStatus.isSubscribed && (
                        <Style.PrincipalElementsDescription>
                            Si deseas canjear Elements en tu suscripción, realiza un pago puntual.
                        </Style.PrincipalElementsDescription>
                    )}
                    {error.message !== "" &&
                    (
                        <Style.Error>
                            Se ha producido un error: {error.message}
                        </Style.Error>
                    )}
                    <Separator className="separatorRepayment" style={{marginTop: "0 !important"}} />
                </Style.DivRowPrincipal>
                <Style.DivRowBody className="ms-Grid-row">
                    {!recursivePayment && userElementsStatus.isSubscribed && (
                        <React.Fragment>
                            <Style.DivRowElementsWater className="ms-Grid-row">
                                <Style.DivColElementsImg className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl1 ms-xxxl1">
                                    <img src={ElementsWaterIcon} alt="" />
                                </Style.DivColElementsImg>
                                <Style.DivColElementsPrice className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl4 ms-xxl4 ms-xxxl4">
                                    <Style.DescriptionElementsPrice>
                                        <b>¿Deseas utilizar Elements Agua?</b> Dispones de {decimalFormatter.format(elementsWater)} Elements Agua.
                                    </Style.DescriptionElementsPrice>
                                    {elementWaterPrice === undefined &&
                                    (
                                        <Style.DescriptionPrice>
                                            Cargando el precio del Element Agua hoy...
                                        </Style.DescriptionPrice>
                                    )}
                                    {elementWaterPrice !== undefined &&
                                    (
                                        <Style.DescriptionPrice>
                                            Precio Element Agua hoy: {longDecimalFormatter.format(elementWaterPrice)}€.
                                        </Style.DescriptionPrice>
                                    )}
                                    <Style.DescriptionMaxElements>
                                        Elements Agua necesarios para pagar el importe hoy: <b>{decimalFormatter.format(maxElementsWater)} Elements Agua.</b>
                                    </Style.DescriptionMaxElements>
                                </Style.DivColElementsPrice>
                                <Style.DivColInput className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl3 ms-xxl4 ms-xxxl4">
                                    <Style.InputElements type="number" min={0} max={maxElementsWater} onChange={handleWaterInputChange} value={elementsWaterUsed} />
                                </Style.DivColInput>
                                {elementWaterPrice === undefined &&
                                (
                                    <React.Fragment>
                                        <Style.DivButtonFull className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonFullDisabled>
                                                Máximo
                                            </Style.ButtonFullDisabled>
                                        </Style.DivButtonFull>
                                        <Style.DivButtonApply className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonUseElementsDisabled>
                                                Utilizar
                                            </Style.ButtonUseElementsDisabled>
                                        </Style.DivButtonApply>
                                        <Style.DivButtonRestore className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl2 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonRestoreDisabled>
                                                Restablecer
                                            </Style.ButtonRestoreDisabled>
                                        </Style.DivButtonRestore>
                                    </React.Fragment>
                                )}
                                {elementWaterPrice !== undefined &&
                                (
                                    <React.Fragment>
                                        <Style.DivButtonFull className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonFull onClick={fullWaterElements}>
                                                Máximo
                                            </Style.ButtonFull>
                                        </Style.DivButtonFull>
                                        <Style.DivButtonApply className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonUseElements onClick={applyWaterElements}>
                                                Utilizar
                                            </Style.ButtonUseElements>
                                        </Style.DivButtonApply>
                                        <Style.DivButtonRestore className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl2 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonRestore onClick={restoreElementsWaterUsed}>
                                                Restablecer
                                            </Style.ButtonRestore>
                                        </Style.DivButtonRestore>
                                    </React.Fragment>
                                )}
                            </Style.DivRowElementsWater>
                            <Style.DivRowElementsIA className="ms-Grid-row">
                                <Style.DivColElementsImg className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 ms-xl1 ms-xxl1 ms-xxxl1">
                                    <img src={ElementsIAIcon} alt="" />
                                </Style.DivColElementsImg>
                                <Style.DivColElementsPrice className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 ms-xl4 ms-xxl4 ms-xxxl4">
                                    <Style.DescriptionElementsPrice>
                                        <b>¿Deseas utilizar Elements IA?</b> Dispones de {decimalFormatter.format(elementsIA)} Elements IA.
                                    </Style.DescriptionElementsPrice>
                                    <Style.DescriptionPrice>
                                        El precio del Element IA es fijo, de 0,01€.
                                    </Style.DescriptionPrice>
                                    <Style.DescriptionMaxElements>
                                        Elements IA necesarios para pagar el importe: <b>{decimalFormatter.format(maxElementsIA)} Elements IA.</b>
                                    </Style.DescriptionMaxElements>
                                </Style.DivColElementsPrice>
                                <Style.DivColInput className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl3 ms-xxl4 ms-xxxl4">
                                    <Style.InputElements type="number" min={0} max={maxElementsIA} onChange={handleIAInputChange} value={elementsIAUsed} />
                                </Style.DivColInput>
                                {elementWaterPrice === undefined &&
                                (
                                    <React.Fragment>
                                        <Style.DivButtonFull className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonFullDisabled>
                                                Máximo
                                            </Style.ButtonFullDisabled>
                                        </Style.DivButtonFull>
                                        <Style.DivButtonApply className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonUseElementsDisabled>
                                                Utilizar
                                            </Style.ButtonUseElementsDisabled>
                                        </Style.DivButtonApply>
                                        <Style.DivButtonRestore className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl2 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonRestoreDisabled>
                                                Restablecer
                                            </Style.ButtonRestoreDisabled>
                                        </Style.DivButtonRestore>
                                    </React.Fragment>
                                )}
                                {elementWaterPrice !== undefined &&
                                (
                                    <React.Fragment>
                                        <Style.DivButtonFull className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonFull onClick={fullIAElements}>
                                                Máximo
                                            </Style.ButtonFull>
                                        </Style.DivButtonFull>
                                        <Style.DivButtonApply className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl1 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonUseElements onClick={applyIAElements}>
                                                Utilizar
                                            </Style.ButtonUseElements>
                                        </Style.DivButtonApply>
                                        <Style.DivButtonRestore className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl2 ms-xxl1 ms-xxxl1">
                                            <Style.ButtonRestore onClick={restoreElementsIAUsed}>
                                                Restablecer
                                            </Style.ButtonRestore>
                                        </Style.DivButtonRestore>
                                    </React.Fragment>
                                )}
                            </Style.DivRowElementsIA>
                            {infoMessage !== undefined &&
                            (
                                <React.Fragment>
                                    <Style.DivRowInfoElements className="ms-Grid-row">
                                        <Style.DivColInfoElements className="ms-Grid-col ms-smOffset5">
                                            <Style.InfoElements>
                                                {infoMessage}
                                            </Style.InfoElements>
                                        </Style.DivColInfoElements>
                                    </Style.DivRowInfoElements>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    <Style.DivColInfo className="ms-Grid-col ms-sm12 ms-md12 ms-lg5 ms-xl5 ms-xxl5 ms-xxxl5">
                        <Style.DivCardInfo theme={themeContext}>
                            <Style.DivRowCard className="ms-Grid-row">
                                {subscriptionType !== undefined && (subscriptionType.current === 4) &&
                                (
                                    <Style.DivLinkRePayment className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                        <Link to={
                                        {
                                            pathname: "/Repayment/morePlans", state:
                                            {
                                                subscriptionId: subscriptionId.current,
                                                subscriptionType: subscriptionType.current,
                                                usersSubscriptionId: usersSubscriptionId.current,
                                                limitAmount: subscriptionLimit
                                            }
                                        }}>
                                            <Style.ButtonMoreSubscription onClick={performTracking}>
                                                Pulsa para más opciones de Renovación
                                            </Style.ButtonMoreSubscription>
                                        </Link>
                                    </Style.DivLinkRePayment>
                                )}
                                <Style.DivColTitleBeneficiario className="ms-Grid-col ms-sm12">
                                    Beneficiario
                                </Style.DivColTitleBeneficiario>
                                <Style.DivColBeneficiario className="ms-Grid-col ms-sm12">
                                    <Style.UL>
                                        <Style.LI>DIAPHANUM VALORES, SV, S.A.U | A87637450</Style.LI>
                                        <Style.LI>Maria de Molina 40, Planta 7 - Madrid 28006</Style.LI>
                                    </Style.UL>
                                </Style.DivColBeneficiario>
                                <Style.DivColTitleReference className="ms-Grid-col ms-sm12">
                                    Referencia
                                </Style.DivColTitleReference>
                                <Style.DivColReference className="ms-Grid-col ms-sm12">
                                    <Style.UL>
                                        <Style.LI>
                                            Orden IronIA {subscriptionId.current !== undefined && reference.current}
                                        </Style.LI>
                                    </Style.UL>
                                </Style.DivColReference>
                                <Style.DivColTitleSubscription className="ms-Grid-col ms-sm12">
                                    Nombre de la Suscripción
                                </Style.DivColTitleSubscription>
                                <Style.DivColSubscription className="ms-Grid-col ms-sm12">
                                    <Style.UL>
                                        <Style.LI>
                                            {(loadingPrice || textOutdated === "") &&
                                            (
                                                <React.Fragment>
                                                    Cargando los datos asociados a la suscripción...
                                                </React.Fragment>
                                            )}
                                            {!loadingPrice && textOutdated !== "" &&
                                            (
                                                <React.Fragment>
                                                    {oldSubscription.name} - ({textOutdated})
                                                </React.Fragment>
                                            )}
                                        </Style.LI>
                                    </Style.UL>
                                </Style.DivColSubscription>
                                <Style.DivColTitlePrice className="ms-Grid-col ms-sm12">
                                    Importe Total
                                </Style.DivColTitlePrice>
                                <Style.DivColPrice className="ms-Grid-col ms-sm12">
                                    <Style.UL>
                                        {loadingPrice &&
                                        (
                                            <React.Fragment>
                                                <Style.LI>
                                                    Cargando el importe de la renovación...
                                                </Style.LI>
                                            </React.Fragment>
                                        )}
                                        {!loadingPrice &&
                                        (
                                            <React.Fragment>
                                                <Style.LI>
                                                    {isOutdated &&
                                                    (
                                                        <React.Fragment>
                                                            {recursivePayment && areElementsApplied &&
                                                            (
                                                                (decimalFormatter.format(price) || decimalFormatter.format(subscription.price as number))
                                                            )}
                                                            {recursivePayment && !areElementsApplied &&
                                                            (
                                                                (decimalFormatter.format(price) || decimalFormatter.format(subscription.price as number))
                                                            )}
                                                            {!recursivePayment && !areElementsApplied &&
                                                            (
                                                                <React.Fragment>
                                                                    {decimalFormatter.format(subscription.price as number)} + {decimalFormatter.format(taxOutdated)} = {decimalFormatter.format(price)}
                                                                </React.Fragment>
                                                            )}
                                                            {!recursivePayment && areElementsApplied &&
                                                            (
                                                                <React.Fragment>
                                                                    {priceInfo}
                                                                </React.Fragment>
                                                            )}
                                                            €
                                                        </React.Fragment>
                                                    )}
                                                    {!isOutdated &&
                                                    (
                                                        <React.Fragment>
                                                            {recursivePayment && areElementsApplied &&
                                                            (
                                                                (decimalFormatter.format(price) || decimalFormatter.format(subscription.price as number))
                                                            )}
                                                            {recursivePayment && !areElementsApplied &&
                                                            (
                                                                (decimalFormatter.format(price) || decimalFormatter.format(subscription.price as number))
                                                            )}
                                                            {!recursivePayment && !areElementsApplied &&
                                                            (
                                                                (decimalFormatter.format(price))
                                                            )}
                                                            {!recursivePayment && areElementsApplied &&
                                                            (
                                                                (priceInfo) 
                                                            )}
                                                            €
                                                        </React.Fragment>
                                                    )}
                                                </Style.LI>
                                            </React.Fragment>
                                        )}
                                    </Style.UL>
                                </Style.DivColPrice>
                                {isOutdated &&
                                (
                                    <React.Fragment>
                                        <Style.DivColOutdatedInfo className='ms-Grid-col ms-sm12'>
                                            <Style.OutdatedParagraph>
                                                El importe añadido por retraso en la renovación es de <b>{decimalFormatter.format(taxOutdated)} €</b>.
                                            </Style.OutdatedParagraph>
                                        </Style.DivColOutdatedInfo>
                                    </React.Fragment>
                                )}
                                {bank !== '' && frequency === null &&
                                (
                                    <React.Fragment>
                                        <Style.DivColPeriodBank className="ms-Grid-col ms-sm12">
                                            <i className={'ironia-icon warning-alert'} />
                                            El banco seleccionado no admite pagos periódicos
                                        </Style.DivColPeriodBank>
                                        <Style.DivColSelectBank className="ms-Grid-col ms-sm12">
                                            Seleccione otro banco de la lista
                                        </Style.DivColSelectBank>
                                    </React.Fragment>
                                )}
                            </Style.DivRowCard>
                            {bank !== '' && subscription.period === 'year' && frequency !== null && frequency !== 'Annual' &&
                            (
                                <React.Fragment>
                                    <Style.DivColAnnualBank className="ms-Grid-col ms-sm12">
                                        <i className={'ironia-icon warning-alert'} />
                                        El banco seleccionado no admite la frecuencia de pago anual
                                    </Style.DivColAnnualBank>
                                    <Style.DivColSubscriptionPeriodMessage className="ms-Grid-col ms-sm12">
                                        La suscripción se pagará con frecuencia {intl.messages[`${frequency}`]}
                                    </Style.DivColSubscriptionPeriodMessage>
                                </React.Fragment>
                            )}
                        </Style.DivCardInfo>
                    </Style.DivColInfo>
                    <Style.DivColBanks className="ms-Grid-col ms-sm12 ms-md12 ms-lg7 ms-xl7 ms-xxl7 ms-xxxl7">
                        <Style.DivContainerPayment className="payment">
                            <Style.DivTabs className="ms-Tabs-Container">
                                <Style.ButtonPuntual className={"ms-Tab" + (recursivePayment ? '' : ' active')} onClick={() => setPuntualPayment()}>
                                    <i className="ironia-icon calendar" />
                                    Pago puntual
                                </Style.ButtonPuntual>
                                <Style.ButtonPeriodic className={"ms-Tab" + (recursivePayment ? ' active' : '')} onClick={() => setRecursivePayment()}>
                                    <i className="ironia-icon refresh" />
                                    Pagos periódicos
                                </Style.ButtonPeriodic>
                            </Style.DivTabs>
                            <Style.Form>
                                <Stack tokens={stackTokens}>
                                    {!recursivePayment &&
                                    (
                                        <React.Fragment>
                                            <Stack.Item style={{paddingTop: "2em", fontSize: "22px", cursor: "default", textAlign: "justify"}} grow>
                                                Los pagos puntuales te permiten renovar tu suscripción utilizando Elements.
                                                Si quieres realizar un pago periódico no podrás utilizar los Elements y no tendrás que preocuparte de nada más.
                                                Las transferencias se harán automáticamente.
                                            </Stack.Item>
                                            <Stack.Item style={{marginTop: "3em"}} grow className="text-right">
                                                {loadingPrice &&
                                                (
                                                    <React.Fragment>
                                                        <Style.ButtonPayDisabled disabled={true}>
                                                            <p style={{margin: 0}}>
                                                                Pagar
                                                            </p>
                                                        </Style.ButtonPayDisabled>
                                                    </React.Fragment>
                                                )}
                                                {!loadingPrice &&
                                                (
                                                    <React.Fragment>
                                                        {isSubmitted && 
                                                        (
                                                            <React.Fragment>
                                                                <Style.ButtonPayDisabled disabled={true}>
                                                                    <Spinner size={SpinnerSize.small} className="button-spinner" />
                                                                    <p style={{margin: 0}}>
                                                                        Pagando...
                                                                    </p>
                                                                </Style.ButtonPayDisabled>
                                                            </React.Fragment>
                                                        )}
                                                        {!isSubmitted &&
                                                        (
                                                            <React.Fragment>
                                                                <Style.ButtonPay onClick={async (event: any) => await sendPuntualPayment(event)}>
                                                                    Pagar
                                                                </Style.ButtonPay>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </Stack.Item>
                                        </React.Fragment>
                                    )}
                                    {recursivePayment &&
                                    (
                                        <React.Fragment>
                                            <Stack.Item style={{marginTop: "1rem"}} grow>
                                                <CustomSimpleDropdown label="Entidad bancaria"
                                                    title="Selecciona un entidad bancaria"
                                                    options={banks}
                                                    id="bank"
                                                    name="bank"
                                                    loading={!isLoadedBanks}
                                                    onChange={changeBank}
                                                    disabled={isSubmitted}
                                                    showSummary={false}
                                                    hasMarginTop={true}
                                                />
                                                {isLoadedBanks &&
                                                (
                                                    <TooltipHost tooltipProps={tooltipProps}
                                                        delay={TooltipDelay.zero}
                                                        id={tooltipId}
                                                        directionalHint={DirectionalHint.topCenter}
                                                        className="ironia-tooltip"
                                                    >
                                                        <DefaultButton aria-describedby={tooltipId}>
                                                            <i className="ironia-icon information" />
                                                        </DefaultButton>
                                                    </TooltipHost>
                                                )}
                                            </Stack.Item>
                                            <Stack.Item grow>
                                                <TextField label="IBAN"
                                                    placeholder="Ingresa el IBAN de tu cuenta bancaria"
                                                    name="iban"
                                                    value={account}
                                                    onChange={changeAccount}
                                                    errorMessage={isError.account.length > 0 ? isError.account : undefined}
                                                    disabled={isSubmitted}
                                                    pattern="[A-Z]{2}[0-9]{18}"
                                                />
                                            </Stack.Item>
                                            <Stack.Item style={{marginTop: "2em"}} grow className="text-right">
                                                {(!canSubmit || frequency === null || isSubmitted) &&
                                                (
                                                    <Style.ButtonPayPeriodDisabled disabled={true}>
                                                        {(isSubmitted) &&
                                                        (
                                                            <React.Fragment>
                                                                <Spinner size={SpinnerSize.small} />
                                                                Pagando...
                                                            </React.Fragment>
                                                        )}
                                                        Pagar
                                                    </Style.ButtonPayPeriodDisabled>
                                                )}
                                                {canSubmit && frequency !== null && !isSubmitted &&
                                                (
                                                    <Style.ButtonPayPeriod onClick={async (event: any) => await sendRecursivePayment(event)}>
                                                        Pagar
                                                    </Style.ButtonPayPeriod>
                                                )}
                                            </Stack.Item>
                                        </React.Fragment>
                                    )}
                                </Stack>
                            </Style.Form>
                        </Style.DivContainerPayment>
                    </Style.DivColBanks>
                </Style.DivRowBody>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default RePaymentSubscription;