import * as S from './ManagementAuthorization.style';
import ModalComplete from './ModalComplete';
import { useEffect, useState } from 'react';
import Moment from 'moment';
import { UserIdentityType } from '../../services/UserIdentitiesService';
import SavingsPlanService, { SavingsPlanType } from '../../services/SavingsPlanService';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { SignatureComponent } from '../../components/Signature/SignatureComponent';
import moment from "moment";
import { ISignature } from "../../services/SignaturesService";
import { Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../utils/scrollToTop';
import React from 'react';

const ManagementAuthorizationConfirm = () =>
{
    const { savingsPlanId } = useParams<any>();
    const history = useHistory();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();
    const { state } = useLocation<any>();

    const processExpirationDate = (signatures: ISignature[]) =>
    {
        if(signatures !== undefined && signatures.length > 0)
        {
            signatures.forEach((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    let defaultSignatures: any = state?.signatures;
    processExpirationDate(defaultSignatures);

    const [signatures, setSignatures] = useState(defaultSignatures);

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingsPlan: SavingsPlanType) =>
        {
            setSavingsPlan(savingsPlan);
        },
        (error) =>
        {
            setError(error);
            setIsLoaded(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(savingsPlan !== undefined && savingsPlan.savingsPlansUserIdentities !== undefined)
        {
            var currentDate = Moment();
            setUserIdentities(savingsPlan.savingsPlansUserIdentities.filter(sPUI =>
            {
                var birthdayDate = Moment(sPUI.userIdentity.birthday);
                return currentDate.diff(birthdayDate, 'years') > 17;
            }).map(savingsPlanUserIdentity => savingsPlanUserIdentity.userIdentity));
            
            setIsLoaded(true);
        }
    }, [savingsPlan]);

    useEffect(() =>
    {
        setPhoneNumbers(userIdentities.map((userIdentity: any) =>
            "X".repeat(userIdentity.phone.length - 3) + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleSubmit = () =>
    {
        if(savingsPlan !== undefined)
        {
            SavingsPlanService.patchSavingsPlan(savingsPlanId, { configurationMapStep: "2.5" }).then(() =>
            {
                history.push('/configurationMap',
                {
                    savingPlanId: savingsPlan.id,
                    savingPlanName: savingsPlan.name,
                });
            },
            (error: any) =>
            {
                setError(error);
            });
        }

        if(!state?.signatures || !modalShow)
        {
            scrollToTop();
            setModalShow(true)
        }
    };

    return(
        <React.Fragment>
            <S.HelperStyle />
            <S.Cont>
                <S.MainCont>
                    {!isLoaded &&
                    (
                        <Spinner size={SpinnerSize.large} />
                    )}
                    {isLoaded &&
                    (
                        <SignatureComponent
                            signatures={signatures}
                            userIdentities={userIdentities}
                            title="Firma del contrato"
                            submitButtonText="Firmar contrato"
                            cancelButtonText="Volver"
                            alreadySignedText="Enhorabuena, ¡ya tienes tu plan de ahorro!"
                            headerTextArea={"A continuación te incluimos los detalles del contrato de autorización de gestión en la plataforma. Léelo y "
                                + "cuando estés listo, dale al botón de \"solicitar firma\". Te enviaremos un "
                                + "código SMS por cada apoderado y titular "
                                + (userIdentities.length > 1 ? ` a los números ${phoneNumbers} ` : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " ")
                                + "para realizar la firma del contrato."}
                            backButtonText="Volver"
                            onBackButtonClicked={() => history.push('/contract/' + savingsPlanId)}
                            onCancelButtonClicked={() => history.push('/contract/' + savingsPlanId)}
                            requestNewCodeButtonText="Recibir nuevo código"
                            onNewCodeSended={(signatures: any) =>
                            {
                                processExpirationDate(signatures);
                                setSignatures(signatures);
                            }}
                            onSignatureChanged={(signatures: any) =>
                            {
                                processExpirationDate(signatures);
                                setSignatures(signatures);
                            }}
                            onSigned={handleSubmit}
                        />
                    )}
                    {error &&
                    (
                        <div>Alguno de los códigos enviados no es válido</div>
                    )}
                </S.MainCont>
            </S.Cont>
            {modalShow && <ModalComplete setModalShow={setModalShow} />}
        </React.Fragment>
    );
};

export default ManagementAuthorizationConfirm;