import { useState, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { DetailsList, IColumn, Link, Separator, Spinner, SpinnerSize } from "@fluentui/react";
import Auth from "../../Auth/Auth";
import FirstSteps from "./FirstSteps";
import SavingsPlanService, { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import SubscriptionService from '../../services/SubscriptionService';
import FundService from '../../services/FundService';
import { ReactComponent as DecideImage } from '../../images/i_like_to_decide.svg';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCart, addLine } from '../../store/cart/actions';
import "./DecideSavingsPlan.sass";
import { InstrumentPriceType, PortfolioCompositionItemType, PortfolioDistributionItemType, QuantType } from '../../services/ManagedPortfolioService';
import FinametrixService from '../../services/FinametrixService';
import Moment from 'moment'
import Highstock, { GraphicType } from '../Highstock/Highstock';
import { useConst } from '@fluentui/react-hooks';
import PieHighstock from '../Highstock/PieHighstock';
import { percentFormatter } from '../../utils/numberFormatter';

interface IInfoFund
{
    allFundsId: number,
    benchmarkFinametrixId: number,
    isin: string,
    managementFee?: number,
    minimunInitialInvestment?: number,
    minimunAdditionalInvestment?: number,
    noMin?: boolean,
    ongoingCharges?: number,
    rebate?: number
}

const DecideSavingsPlan = () =>
{
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState({ message: false });
    const [isLoaded, setIsLoaded] = useState(false);
    const user = Auth.getUserProfile();
    const [subscription, setSubscription] = useState();
    const { savingsPlanId } = useParams<any>();
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>(
    {
        userId: user.id ? user.id : null,
        name: "Me gusta decidir: Alchemy portfolio",
        initialContribution: 10000,
        periodicContribution: 0,
        periodicContributionPeriod: 1,
        type: SavingsPlanTypes.Alchemy,
        status: 0
    });
    const [modelPortfolioSerie, setModelPortfolioSerie] = useState<InstrumentPriceType[]>([]);
    const isLoadedModelPortfolioSerie: React.MutableRefObject<boolean> = useRef<boolean>(false);
    const [modelPortfolioComposition, setModelPortfolioComposition] = useState<PortfolioCompositionItemType[]>([]);
    const [isLoadedModelPortfolioComposition, setIsLoadedModelPortfolioComposition] = useState(false);
    const [modelPortfolioDistribution, setModelPortfolioDistribution] = useState<PortfolioDistributionItemType[]>([]);

    const highstockOptions = useConst(
    {
        rangeSelector:
        {
            selected: 6,
            buttons:
            [
                {
                    type: 'year',
                    count: 1,
                    text: '1y'
                },
                {
                    type: 'year',
                    count: 2,
                    text: '2y'
                },
                {
                    type: 'year',
                    count: 3,
                    text: '3y'
                },
                {
                    type: 'year',
                    count: 4,
                    text: '4y'
                },
                {
                    type: 'year',
                    count: 5,
                    text: '5y'
                },
                {
                    type: 'ytd',
                    text: 'YTD'
                },
                {
                    type: 'all',
                    text: 'Todo'
                }
            ]
        }
    });

    const columns: IColumn[] =
    [
        {
            key: 'name',
            name: 'Fondo',
            fieldName: 'name',
            minWidth: 300
        },
        {
            key: 'asset_class',
            name: 'Categoría',
            fieldName: 'category',
            minWidth: 200,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{item.category}</span>
            },
        },
        {
            key: 'asset_subclass',
            name: 'Subcategoría',
            fieldName: 'subcategory',
            minWidth: 200,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{item.subcategory}</span>
            },
        },
        {
            key: 'weight',
            name: 'Peso',
            fieldName: 'weight',
            minWidth: 100,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{percentFormatter.format(item.weight/100)}</span>
            },
        }
    ];

    useEffect(() =>
    {
        if(subscription === undefined)
        {
            SubscriptionService.getSubscriptionsByType("alchemy").then((subscriptions: any) =>
            {
                if(subscriptions.length > 0)
                {
                    setSubscription(subscriptions[0]);
                }
            },
            (error) =>
            {
                setError(error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(savingsPlanId)
        {
            SavingsPlanService.getSavingsPlan(savingsPlanId).then((storedSavingsPlan: any) =>
            {
                setSavingsPlan(storedSavingsPlan);
                setIsLoaded(true);
            },
            (error) =>
            {
                setIsLoaded(true);
                setError(error);
            });
        }
        else
        {
            setIsLoaded(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(savingsPlan !== undefined && savingsPlan.managedPortfolio !== undefined)
        {
            FinametrixService.getModelPortfolio(savingsPlan.managedPortfolio.finametrixId).then((modelPortfolio: any) =>
            {
                let maxDate = Moment();
                let minDate = Moment().subtract(5, 'years');

                var maxDateTxt = maxDate.format("YYYYMMDD");
                var minDateTxt = minDate.format("YYYYMMDD");

                var lastComposition = modelPortfolio.compositions[modelPortfolio.compositions.length - 1];

                FinametrixService.postBenchmarksQuant(modelPortfolio.compositions, minDateTxt, maxDateTxt).then((quant: QuantType) =>
                {
                    var modelPortfolioPriceData: InstrumentPriceType =
                    {
                        name: savingsPlan?.managedPortfolio?.name,
                        value: modelPortfolio.finametrixId,
                        // @ts-ignore
                        data: quant.serie.map((price) =>
                        {
                            let date = Moment(price.dateAsString);
                            let serieItem: [number, number] = [date.valueOf(), price.value];
                            return serieItem;
                        })
                    }
                    var aux: InstrumentPriceType[] = [modelPortfolioPriceData];
                    setModelPortfolioSerie(aux);
                    isLoadedModelPortfolioSerie.current = true;
                },
                (error: any) =>
                {
                    setError(error);
                    isLoadedModelPortfolioSerie.current = true;
                });

                var instrumentIds = lastComposition.items.map((instrument: any) => instrument.instrumentId);
                FinametrixService.getInstruments(instrumentIds).then((items: any) =>
                {
                    var aux: any = [];
                    var proccesed = 0;
                    lastComposition.items.forEach(async (compositionItem: any) =>
                    {
                        compositionItem.name = items[compositionItem.instrumentId].name;
                        compositionItem.isin = items[compositionItem.instrumentId].isin;

                        await FinametrixService.getInstrumentAssetclass(compositionItem.instrumentId).then((assetclass: any) =>
                        {
                            compositionItem.category = assetclass?.relationData?.parent?.name;
                            compositionItem.subcategory = assetclass?.relationData?.name;

                            var existentCategory = aux.find((item: any) => item.name === compositionItem.category);
                            if(existentCategory?.level2 !== undefined)
                            {
                                existentCategory.value += compositionItem.weight;
                                var existentSubcategory = existentCategory.level2.find((item: any) => item.name === compositionItem.subcategory);
                                if(existentSubcategory)
                                {
                                    existentSubcategory.value += compositionItem.weight;
                                }
                                else
                                {
                                    existentCategory.level2.push({name: compositionItem.subcategory, value: compositionItem.weight});
                                }
                            }
                            else
                            {
                                aux.push({ name: compositionItem.category, value: compositionItem.weight, level2: [{name: compositionItem.subcategory, value: compositionItem.weight}] });
                            }
                            proccesed++;
                        });

                        if(Object.keys(items).length === proccesed)
                        {
                            setModelPortfolioDistribution(aux);
                            setModelPortfolioComposition(lastComposition.items);
                            setIsLoadedModelPortfolioComposition(true);
                        }
                    });
                },
                (error: any) =>
                {
                    setError(error);
                });
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    }, [savingsPlan]);

    const onChangeQuantity = (values: any) =>
    {
        const { value } = values;
        if(savingsPlan.initialContribution === undefined || value !== savingsPlan.initialContribution.toString())
        {
            setSavingsPlan((prevState: any) =>
            (
                {
                    ...prevState,
                    initialContribution: parseFloat(value)
                }
            ));
        }
    };

    const onChangePeriodicQuantity = (event: any) =>
    {
        setSavingsPlan(prevState =>
        (
            {...prevState, periodicContribution: event.target.value}
        ))
    };

    const onChangeContributionPeriod = (event: any) =>
    {
        setSavingsPlan(prevState =>
        (
            {...prevState, periodicContributionPeriod: event.target.value}
        ))
    };

    const addFundsToCart = async () =>
    {
        var infoFunds: IInfoFund[];
        if(savingsPlan.managedPortfolioId !== undefined)
        {
            var instrumentIsins = modelPortfolioComposition.map((instrument: any) => instrument.isin);
            infoFunds = await FundService.getMultipleFunds(instrumentIsins);
            await dispatch(clearCart());
            modelPortfolioComposition.forEach(async (item: any) =>
            {
                var fund = infoFunds.find(infoFund => infoFund.isin === item.isin);
                await dispatch(addLine(
                {
                    fund: fund,
                    amount: (savingsPlan.initialContribution || 0) * item.weight / 100,
                    readOnly: true,
                    savingsPlanId: parseInt(savingsPlanId),
                    source: "rebalancing"
                }));
            });
            return history.push("/cart");
        }
        else
        {
            var allfunds_ids = savingsPlan.bmePortfolio?.distribution.map((instrument: any) => instrument.id);
            if(allfunds_ids !== undefined)
            {
                infoFunds = await FundService.getMultipleFundsByAllfundsIds(allfunds_ids);
                await dispatch(clearCart());
                savingsPlan.bmePortfolio?.distribution.forEach(async (item: any) =>
                {
                    var fund = infoFunds.find(infoFund => infoFund.allFundsId === parseInt(item.id));
                    dispatch(addLine(
                    {
                        fund: fund,
                        amount: (savingsPlan.initialContribution || 0) * item.weight,
                        readOnly: true,
                        savingsPlanId: parseInt(savingsPlanId),
                        source: "proposal"
                    }));
                });
                return history.push("/cart");
            }
        }
    };

    if(error.message)
    {
        return (<div>Error: {error.message}</div>)
    }

    return(
        <DocumentTitle title='IronIA - Configurando plan de ahorro'>
            <div className="decide-savings-plan-container">
                <Link to="/savingsPlans/new" className="back-link">Volver atrás</Link>
                <div className="ms-Grid-row savings-plans section-header">
                    <div className="ms-Grid-col ms-md3">
                        <DecideImage />
                    </div>
                    <div className="ms-Grid-col ms-md9">
                        <h2 className="highlighted">Me gusta decidir</h2>
                        <h2>Alchemy portfolio</h2>
                        <p className="description">Es un simulador de creación de carteras basado en tus gustos,
                            simplemente contesta a las preguntas y obtén una cartera simulada. Recuerda, la cartera
                            se basa en tus respuestas no en tu perfil real de inversor.</p>
                        <div className="number-control big-font">
                            <label className="ms-Label">¿Cuál sería tu inversión inicial?</label>
                            {!isLoaded &&
                            (
                                <Spinner size={SpinnerSize.large} />
                            )}
                            {isLoaded &&
                            (
                                <NumberFormat thousandSeparator="."
                                    decimalSeparator=","
                                    // @ts-ignore
                                    label="¿Cuál es tu dinero base?"
                                    placeholder="Escribe una cantidad..."
                                    value={savingsPlan.initialContribution}
                                    onValueChange={(values) => onChangeQuantity(values)}
                                    suffix="€"
                                    className="ms-TextField-field"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row savings-plans options">
                    <Separator className="separator" />
                    <div className="ms-Grid-col ms-sm12">
                        <h3>Cartera de inversiones:</h3>
                        {!isLoadedModelPortfolioSerie.current &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {isLoadedModelPortfolioSerie.current &&
                        (
                            <Highstock series={modelPortfolioSerie} customOptions={highstockOptions} graphicType={GraphicType.linearGraphic} />
                        )}
                    </div>
                </div>
                <Separator className="separator" />
                <div className="ms-Grid-row savings-plans options">
                    <div className="ms-Grid-col ms-sm8">
                        {modelPortfolioComposition.length === 0 &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {modelPortfolioComposition.length !== 0 &&
                        (
                            <DetailsList items={modelPortfolioComposition} columns={columns} selectionMode={0} />
                        )}
                    </div>
                    <div className="ms-Grid-col ms-md4">
                        <h3 className="text-center">Tipología de inversiones</h3>
                        {modelPortfolioDistribution.length === 0 &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {modelPortfolioDistribution.length !== 0 &&
                        (
                            <PieHighstock chartSerie={modelPortfolioDistribution} options={{showSubcategories: true}} />
                        )}
                    </div>
                </div>
                <div className="ms-Grid-row savings-plans options">
                    <FirstSteps savingsPlan={savingsPlan}
                        subscription={subscription}
                        isSavingsPlanReady={savingsPlan.managedPortfolioId === undefined ? (subscription !== undefined) : (savingsPlan.managedPortfolio !== undefined)}
                        onChangeQuantity={onChangeQuantity}
                        onChangePeriodicQuantity={onChangePeriodicQuantity}
                        onChangeContributionPeriod={onChangeContributionPeriod}
                        user={user}
                        savingsPlanIsCompleted={true}
                        addFundsToCart={addFundsToCart}
                        publicVersion={false}
                        isLoadedModelPortfolioComposition={isLoadedModelPortfolioComposition}
                    />
                </div>
            </div>
        </DocumentTitle>
    );
}

export default DecideSavingsPlan;