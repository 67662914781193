import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Auth from "../../Auth/Auth";
import { UserType } from '../../services/UserService';
import SavingsPlans, { SavingsPlanType } from '../../services/SavingsPlanService';
import { completeUserDataRedux } from "../../store/auth/actions";
import { Link } from 'react-router-dom';
import ProccesingModalComponent from "./ProccesingModalComponent";
import { useBoolean } from '@fluentui/react-hooks';
import './Payments.sass';
import React from 'react';

const PaymentOk = () =>
{
    const dispatch = useDispatch();
    const user: UserType = Auth.getUserProfile();
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [tryings, setTryings] = useState(0);
    const maxTryings = 15;
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const firstTime = useRef<boolean>(true);

    if(user.druId !== undefined && firstTime.current)
    {
        firstTime.current = false;
        dispatch(completeUserDataRedux(user.druId));
    }

    const search = useLocation().search;
    const usersSubscriptionId = new URLSearchParams(search).get('usersSubscriptionId');

    useEffect(() =>
    {
        if(usersSubscriptionId !== null && !isLoaded)
        {
            showModal();
            if(tryings === 0)
            {
                var activePlan = Auth.getActivePlan();
                if(usersSubscriptionId === activePlan.userSubscriptionId)
                {
                    activePlan.statusName = 4;
                    activePlan.outdated = false;
                    activePlan.cancelled = false;
                    Auth.setActivePlan(activePlan);
                }
            }
            if(tryings < maxTryings)
            {
                SavingsPlans.getLastNewSavingsPlanByUsersSubscriptionId(parseInt(usersSubscriptionId)).then((data: (SavingsPlanType | string)) =>
                {
                    if(typeof data === "string")
                    {
                        setTimeout(() =>
                        {
                            setTryings((prevState: number) => prevState + 1)
                        }, 1000);
                    }
                    else
                    {
                        setSavingsPlan(data);
                        setIsLoaded(true);
                        hideModal();
                    }
                },
                () =>
                {
                    setIsLoaded(true);
                    hideModal();
                });
            }
            else
            {
                hideModal();
                setIsLoaded(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersSubscriptionId, tryings]);

    return (
        <DocumentTitle title='IronIA - Pago correcto'>
            <React.Fragment>
                <h3>Pago realizado</h3>
                <p className="description">El pago se ha realizado correctamente</p>
                <Separator className="separator" />
                {usersSubscriptionId !== null &&
                (
                    <React.Fragment>
                        {savingsPlan === undefined && tryings === maxTryings &&
                        (
                            <p className="text-right">
                                <Link className="button primary-button" to={'/savingsPlans'}>
                                    Planes de ahorro en configuración
                                </Link>
                            </p>
                        )}
                        {savingsPlan !== undefined &&
                        (
                            <p className="text-right">
                                <Link className="button primary-button" to={
                                {
                                    pathname: '/configurationMap', state:
                                    {
                                        savingPlanId: savingsPlan.id,
                                        savingPlanName: savingsPlan.name,
                                    }
                                }}>
                                    Continuar configurando el plan de ahorro
                                </Link>
                            </p >
                        )}
                    </React.Fragment>
                )}
                <ProccesingModalComponent isModalOpen={isModalOpen} hideModal={hideModal} />
            </React.Fragment>
        </DocumentTitle>
    );
};

export default PaymentOk;