import { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import DocumentTitle from 'react-document-title';
import NumberFormat from "react-number-format";
import Auth from "../../Auth/Auth";
import FirstSteps from "./FirstSteps";
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import "./DecideSavingsPlan.sass";
import { ReactComponent as DecideImage } from '../../images/i_like_to_decide.svg';
import ManagedPortfolioService, { CompleteManagedPortfolioType, PortfolioCompositionItemType } from '../../services/ManagedPortfolioService';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { connect } from 'react-redux';
import { DefaultButton, DetailsList, DirectionalHint, IColumn, ITooltipProps, Link, Separator, Slider, Spinner, SpinnerSize, TooltipDelay, TooltipHost } from '@fluentui/react';
import PieHighstock from '../Highstock/PieHighstock';
import Highstock, { GraphicType } from "../Highstock/Highstock";
import ProcessingModalComponent from '../Modals/ProcessingModalComponent';
import RiskRateComponent from '../../screen/Portfolios/Summary/RiskRateComponent';
import React from 'react';
import { percentFormatter } from '../../utils/numberFormatter';

const debounce = require('lodash/debounce');

const highstockOptions =
{
    rangeSelector:
    {
        selected: 6,
        buttons:
        [
            {
                type: 'year',
                count: 1,
                text: '1y'
            },
            {
                type: 'year',
                count: 2,
                text: '2y'
            },
            {
                type: 'year',
                count: 3,
                text: '3y'
            },
            {
                type: 'year',
                count: 4,
                text: '4y'
            },
            {
                type: 'year',
                count: 5,
                text: '5y'
            },
            {
                type: 'ytd',
                text: 'YTD'
            },
            {
                type: 'all',
                text: 'Todo'
            }
        ]
    }
};

const columns: IColumn[] =
[
    {
        key: 'name',
        name: 'Fondo',
        fieldName: 'name',
        minWidth: 300
    },
    {
        key: 'asset_class',
        name: 'Categoría',
        fieldName: 'category',
        minWidth: 200,
        onRender: (item: PortfolioCompositionItemType) =>
        {
            return <span>{item.category}</span>
        },
    },
    {
        key: 'asset_subclass',
        name: 'Subcategoría',
        fieldName: 'subcategory',
        minWidth: 200,
        onRender: (item: PortfolioCompositionItemType) =>
        {
            return <span>{item.subcategory}</span>
        },
    },
    {
        key: 'weight',
        name: 'Peso',
        fieldName: 'weight',
        minWidth: 100,
        onRender: (item: PortfolioCompositionItemType) =>
        {
            return <span>{percentFormatter.format(item.weight/100)}</span>
        },
    }
];

interface INewSaverSavingsPlanProps
{
    publicVersion: boolean;
    connectionReference: string;
}

function mapStateToProps(state: any, props: any)
{
    return{
        connectionReference: state.ui.connectionReference
    };
}

const tooltipProps: ITooltipProps =
{
    onRenderContent: () =>
    (
        <div>Cálculos realizados con la información de los últimos 5 años</div>
    ),
};

const NewDecideSavingsPlan = (props: INewSaverSavingsPlanProps) =>
{
    const history = useHistory();
    const [error, setError] = useState({ message: false });
    const user = Auth.getUserProfile();
    const [subscription, setSubscription] = useState<SubscriptionType>();
    const [completeManagedPortfolios, setCompleteManagedPortfolios] = useState<CompleteManagedPortfolioType[]>([]);
    const [isLoadedCompleteModelPortfolios, setIsLoadedCompleteModelPortfolios] = useState(false);
    const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<number>();
    const [selectedCompleteModelPortfolio, setSelectedCompleteModelPortfolio] = useState<CompleteManagedPortfolioType>();
    const [isLoadedModelPortfolioSerie, setIsLoadedModelPortfolioSerie] = useState(false);
    const [isLoadedModelPortfolioComposition, setIsLoadedModelPortfolioComposition] = useState(false);
    const [isLoadedModelPortfolioDistribution, setIsLoadedModelPortfolioDistribution] = useState(false);
    const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [future, setFuture] = useState(0);
    const tooltipId = useId('tooltip');
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>(
    {
        userId: user.id ? user.id : null,
        name: "Me gusta decidir: Alchemy portfolio",
        initialContribution: 10000,
        periodicContribution: 0,
        periodicContributionPeriod: 1,
        savingsPlansUserIdentities: [],
        type: SavingsPlanTypes.Alchemy,
        status: 0
    });

    useEffect(() =>
    {
        if(subscription === undefined)
        {
            SubscriptionService.getSubscriptionsByType("alchemy").then((subscriptions: any) =>
            {
                if(subscriptions.length > 0)
                {
                    setSubscription(subscriptions[0]);
                }
            },
            (error) =>
            {
                setError(error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeQuantity = (values: any) =>
    {
        const { value } = values;
        if(savingsPlan.initialContribution === undefined || value !== savingsPlan.initialContribution.toString())
        {
            setSavingsPlan((prevState: any) =>
            (
            {
                ...prevState,
                initialContribution: parseFloat(value)
            }));
        }
    }

    const onChangePeriodicQuantity = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContribution: event.target.value }
        ))
    };

    const onChangeContributionPeriod = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContributionPeriod: event.target.value }
        ))
    };

    const setModelPortfoliosData = (modelPortfoliosTxt: string) =>
    {
        var auxModelPortfolios = JSON.parse(modelPortfoliosTxt);
        setCompleteManagedPortfolios(auxModelPortfolios);
        var defaultModelPortfolio = auxModelPortfolios.find((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.default);
        setSelectedCompleteModelPortfolio(defaultModelPortfolio);

        if(auxModelPortfolios.length > 5)
        {
            auxModelPortfolios = auxModelPortfolios.filter((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.showOnCover);
        }

        setIsLoadedCompleteModelPortfolios(true);
        setIsLoadedModelPortfolioDistribution(true);
        setIsLoadedModelPortfolioSerie(true);
        setIsLoadedModelPortfolioComposition(true);
    }

    useEffect(() =>
    {
        if(!isLoadedCompleteModelPortfolios)
        {
            var modelPortfoliosTxt = localStorage.getItem("modelPortfolios");
            if(modelPortfoliosTxt !== null)
            {
                setModelPortfoliosData(modelPortfoliosTxt);
            }
            else
            {
                showModal();
                var timeOutId = setTimeout(() => checkStorage(), 30000);
                setTimeOutId(timeOutId);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkStorage = () =>
    {
        if(!isLoadedCompleteModelPortfolios)
        {
            var modelPortfoliosTxt = localStorage.getItem('modelPortfolios');
            if(modelPortfoliosTxt !== null)
            {
                hideModal();
                setModelPortfoliosData(modelPortfoliosTxt);
                setIsLoadedCompleteModelPortfolios(true);
            }
            else
            {
                ManagedPortfolioService.getCompletedManagedPortfoliosForTheCover().then((auxModelPortfolios: CompleteManagedPortfolioType[]) =>
                {
                    setCompleteManagedPortfolios(auxModelPortfolios);
                    var defaultModelPortfolio = auxModelPortfolios.find((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.default);
                    if(defaultModelPortfolio !== undefined)
                    {
                        setSelectedCompleteModelPortfolio(defaultModelPortfolio);
                    }
                    localStorage.setItem("modelPortfolios", JSON.stringify(auxModelPortfolios));
                });
            }
        }
    }

    useEffect(() =>
    {
        document.addEventListener('storageChange', () =>
        {
            if(!isLoadedCompleteModelPortfolios)
            {
                var modelPortfoliosTxt = localStorage.getItem('modelPortfolios');
                if(modelPortfoliosTxt !== null)
                {
                    if(timeOutId)
                    {
                        clearTimeout(timeOutId);
                    }
                    setModelPortfoliosData(modelPortfoliosTxt);
                    setIsLoadedCompleteModelPortfolios(true);
                    hideModal();
                    history.push(history.location);
                }
            }
        }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() =>
    {
        if(savingsPlan.initialContribution !== undefined && selectedCompleteModelPortfolio !== undefined && selectedCompleteModelPortfolio.performance !== undefined)
        {
            setFuture(savingsPlan.initialContribution * (1 + selectedCompleteModelPortfolio.performance / 100) - (5 * (selectedCompleteModelPortfolio.managedPortfolio.subscription.price ?? 0)));
        }
    }, [selectedCompleteModelPortfolio, savingsPlan.initialContribution]);

    useEffect(() =>
    {
        if(selectedCompleteModelPortfolio !== undefined)
        {
            setSelectedModelPortfolio(selectedCompleteModelPortfolio.managedPortfolio.id);
            setSavingsPlan((prevState: SavingsPlanType) =>
            (
                {
                    ...prevState,
                    managedPortfolioId: selectedCompleteModelPortfolio.managedPortfolio.id,
                    managedPortfolio: selectedCompleteModelPortfolio.managedPortfolio
                }
            ))
        }
    }, [selectedCompleteModelPortfolio]);

    if(error.message)
    {
        return (<div>Error: {error.message}</div>)
    }

    return(
        <DocumentTitle title='IronIA - Nuevo plan de ahorro'>
            <div className="decide-savings-plan-container">
                {!props.publicVersion && <Link to="/savingsPlans/new" className="back-link">Volver atrás</Link>}
                <div className="ms-Grid-row savings-plans section-header">
                    <div className="ms-Grid-col ms-md3">
                        <DecideImage />
                    </div>
                    <div className="ms-Grid-col ms-md9">
                        <h2 className="highlighted">Me gusta decidir</h2>
                        <h2>Alchemy portfolio</h2>
                        <p className="description">En esta modalidad, eres tú quien toma las decisiones, con nuestras
                            propuestas. Sólo tienes que escribir el dinero que te gustaría invertir para hacerte una
                            estimación de la rentabilidad que hubioera obtrenido en base  a los resultados histíoricos
                            de los fondos que componen las diferentes carteras.</p>
                        <p className="description">
                            Ten en cuenta que las rentabilidades pasadas no garantizan rentabilidadades futuras.
                        </p>
                        <div className="number-control big-font">
                            <label className="ms-Label">¿Cuál sería tu inversión inicial?</label>
                            <NumberFormat thousandSeparator="."
                                decimalSeparator=","
                                placeholder="Escribe una cantidad..."
                                value={savingsPlan.initialContribution}
                                onValueChange={debounce((values: any) => onChangeQuantity(values), 500)}
                                suffix="€"
                                className="ms-TextField-field"
                            />
                            {selectedModelPortfolio !== undefined &&
                            (
                                <React.Fragment>
                                    <span>Con esta inversión hubieses tenido
                                        {!isLoadedModelPortfolioSerie &&
                                        (
                                            <Spinner size={SpinnerSize.small} className="button-spinner" />
                                        )}
                                    </span>
                                    {isLoadedModelPortfolioSerie &&
                                    (
                                        <NumberFormat  thousandSeparator="."
                                            decimalSeparator=","
                                            suffix="€"
                                            value={future}
                                            decimalScale={2}
                                            displayType="text"
                                            className="ms-TextField-text"
                                        />
                                    )}
                                    <TooltipHost tooltipProps={tooltipProps}
                                        delay={TooltipDelay.zero}
                                        id={tooltipId}
                                        directionalHint={DirectionalHint.topCenter}
                                        className="ironia-tooltip"
                                    >
                                        <DefaultButton aria-describedby={tooltipId}>
                                            <i className="ironia-icon information" />
                                        </DefaultButton>
                                    </TooltipHost>
                                </React.Fragment>
                            )}
                        </div>
                        <p className="description">
                            El importe que figura estimado refleja la rentabilidad neta que hubieras obtenido al incluir
                            ya descontados los costes de servicio y de los productos (e.g. comisión de gestión) que
                            componen las diferentes carteras.
                        </p>
                    </div>
                </div>
                <Separator className="separator" />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md4">
                        <p className="description">Pulse sobre el nivel de riesgo para determinar la composición de su cartera:</p>
                        {completeManagedPortfolios.length > 0 && selectedCompleteModelPortfolio?.managedPortfolio.riskProfile !== undefined &&
                        (
                            <div style={{"width": '400px', position: 'relative'}}>
                                <RiskRateComponent risk={selectedCompleteModelPortfolio.modelPortfolio?.risk.name ?? ''}
                                    riskValue={0}
                                    rate={['Baja', 'Media Baja', 'Media Alta', 'Alta'][selectedCompleteModelPortfolio.modelPortfolio?.risk.order ?? 3]}
                                    rateValue={(selectedCompleteModelPortfolio.modelPortfolio?.risk.order ?? 3) + 1}
                                    initials={user.initials}
                                />
                                <Slider min={0} max={3} step={1} defaultValue={3}
                                    showValue={false}
                                    onChanged={(event: Event, value: number) =>
                                    {
                                        var selectedModelPortfolio = completeManagedPortfolios.find((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.id === completeManagedPortfolios[value].managedPortfolio.id);
                                        if(selectedModelPortfolio !== undefined)
                                        {
                                            setSelectedCompleteModelPortfolio(selectedModelPortfolio);
                                        }
                                    }}
                                    className="riskSlider"
                                    styles={
                                    {
                                        root:
                                        {
                                            width: '400px',
                                            position: 'absolute',
                                            zIndex: '100',
                                            top: '85px',
                                            height: '37px',
                                            opacity: '0',
                                            cursor: 'pointer'
                                        }
                                    }}
                                />
                            </div>
                        
                        )}
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md8">
                        {!isLoadedModelPortfolioComposition &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {isLoadedModelPortfolioComposition &&
                        (
                            <DetailsList
                                // @ts-ignore
                                items={selectedCompleteModelPortfolio.composition}
                                columns={columns}
                                selectionMode={0}
                            />
                        )}
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Separator className="separator" />
                        </div>
                    </div>
                    {selectedCompleteModelPortfolio &&
                    (
                        <div className="ms-Grid-row savings-plans options">
                            <div className="ms-Grid-col ms-sm8">
                                {isLoadedModelPortfolioSerie && selectedModelPortfolio !== undefined &&
                                (
                                    <Highstock graphicType={GraphicType.linearGraphic}
                                        series=
                                        {
                                            completeManagedPortfolios.filter(cMP => selectedModelPortfolio === cMP.managedPortfolio.id)
                                                .map(cMP => cMP.serie)
                                                .map(serie =>
                                                ({
                                                    ...(serie !== undefined ? serie[0] : {}),
                                                    name: (serie !== undefined ? serie[0].name?.replace("We Will Rock You - 1977", "Alchemy Portfolio") : '')
                                                }))
                                        }
                                        customOptions={highstockOptions}
                                    />
                                )}
                            </div>
                            <div className="ms-Grid-col ms-md4">
                                <h3 className="text-center">Tipología de inversiones</h3>
                                {!isLoadedModelPortfolioDistribution &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                                {isLoadedModelPortfolioDistribution &&
                                (
                                    <PieHighstock chartSerie={selectedCompleteModelPortfolio.distribution} options={{showSubcategories: true}} />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="ms-Grid-row savings-plans options">
                    <FirstSteps savingsPlan={savingsPlan}
                        subscription={subscription}
                        isSavingsPlanReady={subscription !== undefined}
                        onChangeQuantity={onChangeQuantity}
                        onChangePeriodicQuantity={onChangePeriodicQuantity}
                        onChangeContributionPeriod={onChangeContributionPeriod}
                        publicVersion={props.publicVersion}
                        user={user}
                        savingsPlanIsCompleted={true}
                    />
                </div>
                <ProcessingModalComponent isModalOpen={isModalOpen}
                    hideModal={hideModal}
                    blocking={true}
                    title='Recuperando información'
                    description='Estamos recuperando los datos de las carteras modelo'
                />
            </div>
        </DocumentTitle>
    );
}

export default connect(mapStateToProps)(NewDecideSavingsPlan);