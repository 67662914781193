import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

class AttachmentService
{
    public downloadIroniaStoreBase64Pdf = async (event: any, data: any) => {
        return this.getBlobAttachment(data.file)
            .then((response) => {
                const file = new Blob(
                    [ response.data ],
                    { type: response.headers['content-type'] });

                var downloadLink = document.createElement("a");
                downloadLink.download = data.name;
                downloadLink.href = URL.createObjectURL(file);
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
    };

    public getAttachment = async (filename: string): Promise<any> =>
    {
        let url: string = `${proxy}/attachments/${filename}`
        const req = await axios.get<any>(url);
        return req;
    }
    public getBlobAttachment = async (filename: string): Promise<any> =>
    {
        let url: string = `${proxy}/attachments/${filename}`
        const req = await axios.get<any>(url, {responseType: 'blob'});
        return req;
    }
    public getArrayBufferAttachment = async (filename: string): Promise<any> =>
    {
        let url: string = `${proxy}/attachments/${filename}`
        const req = await axios.get<any>(url, {responseType: 'blob'});
        const reader = new FileReader();
        return new Promise((resolve) =>
        {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsArrayBuffer(req.data)
        });
    }
    public getBase64Attachment = async (filename: string): Promise<any> =>
    {
        let url: string = `${proxy}/attachments/${filename}`
        const req = await axios.get<any>(url, {responseType: 'blob'});
        const reader = new FileReader();
        return new Promise((resolve) =>
        {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(req.data);
        });
    }
}

const Attachments = new AttachmentService();
export default Attachments;