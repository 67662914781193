import * as S from '../ConvenienceTest.style';
import CountryPrefixes from '../Utils/CountryPrefixes';
import Moment from 'moment';
import { DayPickerStrings } from '../Utils/DatesTranlateFluents';
import Select from 'react-dropdown-select';
import { useContext, useEffect, useState } from 'react';
import { DatePicker, TextField } from '@fluentui/react';
import { InputStyles, DatePickerStyle } from '../../GlobalStyle';
import { Attachment } from '../../Attachment';

const ConvenienceTestAccountHolderDataInputs = ({ // @ts-ignore
    handleChange, data, formVal, setFormVal
}) =>
{
    const {
        firstName,
        lastName,
        secondLastName,
        birthday,
        email,
        countryPrefix,
        phone,
        operationCountryPrefix,
        operationPhone,
        proofOfMinorGuardianDescription,
        main
    } = data;

    const [errors, setErrors] = useState(
    {
        firstName: "",
        lastName: "",
        birthday: "",
        email: "",
        countryPrefix: "",
        phone: "",
        operationCountryPrefix: "",
        operationPhone: "",
        proofOfMinorGuardianDescription: ""
        });

    const [isMinor, setIsMinor] = useState<boolean>(false);

    const getErrorMessage = (field: string, value: any) =>
    {
        switch (field)
        {
            case 'firstName':
                if(value === null || value === undefined) {
                    return <S.Error>El campo Nombre es obligatorio</S.Error>;
                }
                else {
                    return (value.length === 0)
                        ? <S.Error>El campo Nombre es obligatorio</S.Error>
                        : "";
                }

            case 'lastName':
                if(value === null || value === undefined) {
                    return <S.Error>El campo Primer apellido es obligatorio</S.Error>;
                }
                else {
                    return (value.length === 0)
                        ? <S.Error>El campo Primer apellido es obligatorio</S.Error>
                        : "";
                }

            case 'birthday':
                if(value === null || value === undefined) {
                    return <S.Error>El campo Fecha de nacimiento es obligatorio</S.Error>;
                }
                return '';

            case 'email':
                if(value === null || value === undefined) {
                    return <S.Error>El campo Correo electrónico es obligatorio</S.Error>;
                }

                if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value)) {
                    return <S.Error>El campo Correo electrónico debe tener un formato válido</S.Error>
                }
                return '';

            case 'countryPrefix':
                return "";

            case 'operationCountryPrefix':
                if(value === null || value === undefined) {
                    return <S.Error>El campo Prefijo (país) es obligatorio</S.Error>;
                }
                else {
                    return (value.length === 0)
                        ? <S.Error>El campo Prefijo (país) es obligatorio</S.Error>
                        : "";
                }

            case 'phone':
                return '';

            case 'operationPhone':
                if(value === null || value === undefined) {
                    return <S.Error>El campo Nº de teléfono móvil es obligatorio</S.Error>;
                }
                else {
                    return (value.length < 9)
                        ? <S.Error>El campo Nº de teléfono móvil es obligatorio</S.Error>
                        : "";
                }

            case 'proofOfMinorGuardianDescription':
                if(value === null || value === undefined) {
                    return <S.Error className="error">Debe acreditar que es el padre, la madre o el tutor legal del menor</S.Error>;
                }
                else {
                    return (isMinor && value.length === 0)
                        ? <S.Error className="error">Debe acreditar que es el padre, la madre o el tutor legal del menor</S.Error>
                        : "";
                }

            default:
                throw new Error('Error en el switch');
        }
    };
    useEffect(() =>
    {
        if(formVal !== undefined) {
            if(birthday !== undefined) {
                var currentDate = Moment();
                var birthdayDate = Moment(birthday);
                var isMinor = currentDate.diff(birthdayDate, 'years') < 18;
                setIsMinor(isMinor);
            }

            Object.keys(data).forEach((key) =>
            {
                if(key in formVal) {
                    if(data[key] === "" || data[key] === null) {
                        if((key === 'proofOfMinorGuardianDescription' && !isMinor)
                            || (['email', 'phone', 'operationPhone'].includes(key) && isMinor)
                        ) {
                            setFormVal(key, true);
                        }
                        else {
                            setFormVal(key, false);
                        }
                    }
                    else
                    {
                        var error = getErrorMessage(key, data[key]);
                        setErrors((prevState: any) => {
                            return {
                                ...prevState,
                                [key]: error
                            }
                        });
                        setFormVal(key, error === "");
                    }
                }
            });
        }
    }, [formVal, data]);

    const handleInputChange = (event: any) =>
    {
        if(event.target !== undefined) {
            var { name, value } = event.target;
            var error = getErrorMessage(name, value);
            setErrors((prevState: any) =>
            {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            handleChange(event);
        }
    };

    const handleDateInputChange = (name: string, value: any) =>
    {
        if(value !== undefined) {
            var error = getErrorMessage(name, value);
            setErrors((prevState: any) =>
            {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            handleChange({ target: { name: name, value: value, files: null } });


            var currentDate = Moment();
            var birthdayDate = name === 'birthday' ? Moment(value) : Moment(birthday);
            var isLocalMinor = currentDate.diff(birthdayDate, 'years') < 18;
            if(isLocalMinor) {
                var newError = (proofOfMinorGuardianDescription === null
                    || proofOfMinorGuardianDescription === undefined
                    || proofOfMinorGuardianDescription.length === 0)
                    ? <S.Error className="error">Debe acreditar que es el padre, la madre o el tutor legal del menor</S.Error>
                    : "";

                setErrors((prevState: any) => {
                    return {
                        ...prevState,
                        email: "",
                        countryPrefix: "",
                        phone: "",
                        operationCountryPrefix: "",
                        operationPhone: "",
                        proofOfMinorGuardianDescription: newError
                    }
                });
                setFormVal("email", true);
                setFormVal("countryPrefix", true);
                setFormVal("phone", true);
                setFormVal("operationCountryPrefix", true);
                setFormVal("operationPhone", true);
                setFormVal("proofOfMinorGuardianDescription", newError === "");
                setFormVal("minorTitular", true);
            }
            else {
                setErrors((prevState: any) => {
                    return {
                        ...prevState,
                        email: getErrorMessage("email", email),
                        countryPrefix: getErrorMessage("countryPrefix", countryPrefix),
                        phone: getErrorMessage("phone", phone),
                        operationCountryPrefix: getErrorMessage("operationCountryPrefix", operationCountryPrefix),
                        operationPhone: getErrorMessage("operationPhone", operationPhone)
                    }
                });
                setFormVal("email", getErrorMessage("email", email) === "");
                setFormVal("countryPrefix", getErrorMessage("countryPrefix", countryPrefix) === "");
                setFormVal("phone", getErrorMessage("phone", phone) === "");
                setFormVal("operationCountryPrefix", getErrorMessage("operationCountryPrefix", operationCountryPrefix) === "");
                setFormVal("operationPhone", getErrorMessage("operationPhone", operationPhone) === "");
                setFormVal("proofOfMinorGuardianDescription", true);
                setFormVal("minorTitular", true);
            }
        }
    };

    return(
        <>
            <S.FielSet>
                <S.FieldCont>
                    <TextField
                        label='Nombre'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'firstName'}
                        name={'firstName'}
                        type='text'
                        value={firstName}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.firstName !== "" && errors.firstName}
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Primer Apellido'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'lastName'}
                        name={'lastName'}
                        type='text'
                        value={lastName}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.lastName !== "" && errors.lastName}
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Segundo Apellido'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'secondLastName'}
                        name={'secondLastName'}
                        type='text'
                        value={secondLastName || ""}
                        onChange={handleChange}
                    />
                </S.FieldCont>
                <S.FieldDateCont>
                    <DatePicker
                        styles={DatePickerStyle}
                        label='Fecha de nacimiento'
                        id={'birthday'}
                        // @ts-ignore
                        name={'birthday'}
                        formatDate={(date) =>
                        {
                            return Moment(date).format('DD/MM/YYYY');
                        }}
                        onSelectDate={(date) => { handleDateInputChange('birthday', Moment(date).subtract(date?.getTimezoneOffset(), "minutes").toDate()) }}
                        value={new Date(birthday)}
                        isRequired
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        parseDateFromString={(dateStr) =>
                        {
                            var parts: string[] = dateStr.split('/');
                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                            return new Date(myDate)
                        }}
                    />
                    {errors.birthday !== "" && errors.birthday}
                </S.FieldDateCont>
            </S.FielSet>
            <S.FielSet>
                {!isMinor && (
                    <>
                        <S.FieldCont></S.FieldCont>
                        <S.FieldEmailCont>
                            <TextField
                                // @ts-ignore
                                styles={InputStyles}
                                label='Correo electrónico'
                                id={'email'}
                                name={'email'}
                                type='email'
                                value={email}
                                onChange={handleInputChange}
                                disabled={main}
                                required
                            />
                            {errors.email !== "" && errors.email}
                        </S.FieldEmailCont>
                        <S.FieldPrefixCont>
                            <S.Label>Prefijo (país) - Firma contratos</S.Label>
                            <Select
                                name={'countryPrefix'}
                                labelField='name'
                                options={CountryPrefixes}
                                onChange={(event: any) => handleInputChange({ target: { name: "countryPrefix", value: event.length === 0 ? "" : event[0].phone_code } })}
                                dropdownHeight='2000'
                                searchable={true}
                                searchBy="text"
                                noDataLabel={'No encontrado'}
                                values={CountryPrefixes.filter((cP) => cP.phone_code === (countryPrefix || "+34"))}
                                placeholder=''
                                itemRenderer={({ item, methods }) => (
                                    <span
                                        role="option"
                                        aria-selected="false"
                                        aria-label={item.value + ' ' + item.phone_code}
                                        // @ts-ignore
                                        tabIndex="-1"
                                        className="react-dropdown-select-item"
                                        onClick={() => methods.addItem(item)}
                                        key={'country-prefix-' + item.phone_code + '-' + item.iso3}
                                    >
                                        {item.value + ' ' + item.phone_code}
                                    </span>
                                )}
                                required
                            />
                            {errors.countryPrefix !== "" && errors.countryPrefix}
                        </S.FieldPrefixCont>
                        <S.FieldPhoneCont>
                            <TextField
                                // @ts-ignore
                                styles={InputStyles}
                                label='Número teléfono móvil para firmar contratos'
                                id={'phone'}
                                name={'phone'}
                                type='text'
                                value={phone}
                                onChange={handleInputChange}
                                required
                                validateOnFocusIn
                            />
                            {errors.phone !== "" && errors.phone}
                        </S.FieldPhoneCont>
                    </>
                )}
                {isMinor && (
                    <S.FieldFileCont>
                        <label className="inline">Documento que acredite que usted es el tutor legal del menor *</label>
                        <S.FileInput
                            id={'proofOfMinorGuardianDescription'}
                            name={'proofOfMinorGuardianDescription'}
                            type='file'
                            onChange={handleInputChange}
                        />
                        {proofOfMinorGuardianDescription !== undefined && typeof proofOfMinorGuardianDescription === "string" && (
                            <div>
                                <Attachment fileName={proofOfMinorGuardianDescription} className="image-preview" />
                            </div>
                        )}
                        {errors.proofOfMinorGuardianDescription !== "" && errors.proofOfMinorGuardianDescription}
                    </S.FieldFileCont>
                )}
            </S.FielSet>
            {!isMinor && (
                <S.FielSet>
                    <S.EmptyHalf />
                    <S.FieldPrefixCont>
                        <S.Label>Prefijo (país) - Firma operaciones</S.Label>
                        <Select
                            name={'operationCountryPrefix'}
                            labelField='name'
                            options={CountryPrefixes}
                            onChange={(event: any) => handleInputChange({ target: { name: "operationCountryPrefix", value: event.length === 0 ? "" : event[0].phone_code } })}
                            dropdownHeight='2000'
                            searchable={true}
                            searchBy='text'
                            noDataLabel={'No encontrado'}
                            values={CountryPrefixes.filter((countryPrefix) => countryPrefix.phone_code === (operationCountryPrefix || "+34"))}
                            placeholder=''
                            itemRenderer={({ item, methods }) => (
                                <span
                                    role="option"
                                    aria-selected="false"
                                    aria-label={item.value + ' ' + item.phone_code}
                                    // @ts-ignore
                                    tabIndex="-1"
                                    className="react-dropdown-select-item"
                                    onClick={() => methods.addItem(item)}
                                    key={'country-prefix-' + item.phone_code + '-' + item.iso3}
                                >
                                    {item.value + ' ' + item.phone_code}
                                </span>
                            )}
                            required
                        />
                        {errors.operationCountryPrefix !== "" && errors.operationCountryPrefix}
                    </S.FieldPrefixCont>
                    <S.FieldPhoneCont>
                        <TextField
                            // @ts-ignore
                            styles={InputStyles}
                            label='Número teléfono móvil para firmar operaciones'
                            id={'operationPhone'}
                            name={'operationPhone'}
                            type='text'
                            value={operationPhone}
                            onChange={handleInputChange}
                            required
                            validateOnFocusIn
                        />
                        {errors.operationPhone !== "" && errors.operationPhone}
                    </S.FieldPhoneCont>
                </S.FielSet>
            )}
        </>
    );
};

export default ConvenienceTestAccountHolderDataInputs;