import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface PortfolioModelData
{
    portfolioModelId: number,
    portfolioModelName: string
}
export interface PortfolioModelAssociated
{
    portfolioModelCount: number,
    portfolioModelData: PortfolioModelData[]
}
interface IModelWallet
{
    id: number,
    name: string,
    savingPlanId: number,
    savingPlanType: number,
    userSubscriptionId?: number,
    contract?: string,
    jsonFundsData: DataAndCartLines[]
}
export type ModelWallet = IModelWallet;

interface IModelWalletSelector
{
    key: string,
    text: string,
    id: string
}
export type ModelWalletSelector = IModelWalletSelector;

type saveWalletBodyData =
{
    name: string,
    jsonFundsData?: DataAndCartLines[]
}
export type DataAndCartLines =
{
    fund: CartLinesFromDB,
    savingPlanId: number,
    readOnly: boolean,
    modelPercentage: number
}
export type CartLinesFromDB =
{
    name: string,
    available?: boolean,
    isin: string,
    titles: number,
    bought: string,
    valuation: string,
    revaluation: string,
    revaluationPercentage: string,
    weight: string,
    vLiquid: string,
    exchangeRate: number,
    liquidDate: string,
    benchmarkFinametrixId: string,
    managementFee: number,
    noMin: boolean,
    allFundsId: number,
    minimumInvestment: number,
    ongoingCharges: number,
    rebate: number,
    complexity: boolean,
    finametrixId?: string,
    instrumentId?: string
}

class PortfolioModelWalletService
{
    public getModelWallet = async (savingPlanId: number, portfolioModelId: number): Promise<ModelWallet> =>
    {
        let url: string = `${proxy}/portfolioModel/${savingPlanId}/${portfolioModelId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public saveModelWallet = async (savingPlanId: number, walletName: string, portfolioModelId: number, cartLines: DataAndCartLines[]): Promise<ModelWallet> =>
    {
        let url: string = `${proxy}/portfolioModel/${savingPlanId}/${portfolioModelId}`;
        let body: saveWalletBodyData = {name: walletName, jsonFundsData: cartLines};
        const req = await axios.post<ModelWallet>(url, JSON.stringify(body));
        return req.data;
    }
    public updateModelWallet = async (savingPlanId: number, walletName: string, portfolioModelId: number, cartLines: DataAndCartLines[]): Promise<ModelWallet> =>
    {
        let url: string = `${proxy}/portfolioModel/${savingPlanId}/${portfolioModelId}`;
        let body: saveWalletBodyData = { name: walletName, jsonFundsData: cartLines };
        const req = await axios.patch<ModelWallet>(url, JSON.stringify(body));
        return req.data;
    }
    public renameModelWallet = async (savingPlanId: number, name: string, portfolioModelId: number): Promise<ModelWallet> =>
    {
        let url: string = `${proxy}/portfolioModel/${savingPlanId}/${portfolioModelId}`;
        let body: saveWalletBodyData = {name: name};
        const req = await axios.patch(url, body);
        return req.data;
    }
    public deleteModelWallet = async (savingPlanId: number, portfolioModelId: number): Promise<ModelWallet> =>
    {
        let url: string = `${proxy}/portfolioModel/${savingPlanId}/${portfolioModelId}`;
        const req = await axios.delete(url);
        return req.data;
    }
    public savingPlanHasPortfolioModel = async (savingPlanId: number): Promise<PortfolioModelAssociated> =>
    {
        let url: string = `${proxy}/SavingPlanHasPortfolioModel/${savingPlanId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getModelWallets = async (savingPlanId: number): Promise<ModelWallet[]> =>
    {
        let url: string = `${proxy}/portfolioModels/${savingPlanId}`;
        const req = await axios.get(url);
        return req.data;
    }
}

const portfolioModelWallets = new PortfolioModelWalletService();
export default portfolioModelWallets;