import * as S from '../ConvenienceTest.style';
import ConvenienceTestFinanceInputs from './ConvenienceTestFinanceInputs';
import { useHistory, useParams } from 'react-router-dom';
import UserIdentitiesService, { UserIdentityType } from '../../../services/UserIdentitiesService';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import React from 'react';
import SavingsPlanService, { SavingsPlanType } from '../../../services/SavingsPlanService';
import DocumentTitle from 'react-document-title';
import { ThemeContext } from '@fluentui/react-theme-provider';

const ConvenienceTestFinance = () =>
{
    const history = useHistory();
    const { savingsPlanId } = useParams<any>();
    const { userIdentityId } = useParams<any>();
    const theme = useContext(ThemeContext);
    const [error, setError] = useState({ message: '' });
    const [userIdentity, setUserIdentity] = useState<UserIdentityType>();
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [canSubmit, setCanSubmit] = useState(false);
    const [userIdentityForm, setUserIdentityForm] = useState<any>();
    const [sending, setSending] = useState(false);

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        if(userIdentityId !== null && userIdentityId !== undefined)
        {
            UserIdentitiesService.getUserIdentity(userIdentityId).then((userIdentity: UserIdentityType) =>
            {
                setUserIdentityForm(
                {
                    economicActivity: false,
                    proofOfIncome: false,
                    gafiQuestion: false,
                    publicPosition: false
                });

                setUserIdentity(userIdentity);
            },
            (error) =>
            {
                setError(error);
            });
        }
    }, [userIdentityId]);

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingsPlan: any) =>
        {
            setSavingsPlan(savingsPlan);
        },
        (error: any) =>
        {
            setError(error);
        })
    }, [savingsPlanId]);

    const handleFinanceChange = (event: any) =>
    {
        var {name, value}: {name: string, value: any} = event.target;
        var realValue = value;
        if(event.target.files !== undefined && event.target.files !== null && event.target.files.length)
        {
            realValue = event.target.files[0];
        }
        else
        {
            if(['gafiQuestion', 'publicPosition'].includes(name))
            {
                realValue = value === "true";
            }
        }
        setUserIdentity((prevState: any) =>
        ({
                ...prevState,
                [name]: realValue
            }
        ));
    };

    const btnSubmit = useCallback(() =>
    {
        if(userIdentityForm !== undefined)
        {
            setCanSubmit(Object.values(userIdentityForm).every((x) => x === true));
        }
    }, [userIdentityForm]);

    useEffect(() =>
    {
        btnSubmit();
    }, [btnSubmit, userIdentity]);

    const handleSubmit = (event: any) =>
    {
        event.preventDefault();
        if(userIdentity !== undefined)
        {
            setSending(true);
            UserIdentitiesService.updateUserIdentity(userIdentity).then(() =>
            {
                if(savingsPlan !== undefined)
                {
                    history.push('/configurationMap',
                    {
                        savingPlanId: savingsPlan.id,
                        savingPlanName: savingsPlan.name,
                    });
                }
            },
            (error: any) =>
            {
                setError(error)
            });
        }
    };

    return(
        <DocumentTitle title={`IronIA - Documentación Financiera`}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont theme={theme}>
                    <S.MainCont>
                        <S.Steps>
                            <h3>Paso 4 <span>de 4</span></h3>
                        </S.Steps>
                        <S.Title>
                            <h1>Información financiera</h1>
                            <h2>
                                Con motivo de llevar a cabo el proceso de identificación fiscal y prevención
                                de blanqueo de capitales, necesitamos que nos aportes algo más de
                                información.
                            </h2>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar />
                            <S.ProsBar />
                        </S.BarCont>
                        {userIdentity === undefined &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {userIdentity !== undefined &&
                        (
                            <S.TabletCont theme={theme}>
                                <S.SubTitle>
                                    Actividad económica y Justificante de ingresos del
                                    <span> Interviniente - {[userIdentity.firstName, userIdentity.lastName, userIdentity.secondLastName].join(" ")}</span>
                                </S.SubTitle>
                                <S.Phrase>
                                    Necesitamos un justificante que acredite el origen de su patrimonio, para
                                    ello sirve la declaración de la renta, una nómina, justificante de venta de
                                    inmueble, etc.
                                </S.Phrase>
                                <ConvenienceTestFinanceInputs data={userIdentity}
                                    handleChange={(event: any) => handleFinanceChange(event)}
                                    formVal={userIdentityForm}
                                    setFormVal={(name: string, value: any) => setUserIdentityForm((prevState: any) => ({ ...prevState, [name]: value }))}
                                />
                            </S.TabletCont>
                        )}
                        <S.BtnCont>
                            {error.message !== "" &&
                            (
                                <S.TabletCont theme={theme}>
                                    <S.Error>{error.message}</S.Error>
                                </S.TabletCont>
                            )}
                            <DefaultButton disabled={userIdentity === undefined}
                                onClick={() => history.push('/id/' + savingsPlanId + '/' + userIdentityId)}
                                className="button tiny-secondary-button"
                            >
                                Volver
                            </DefaultButton>
                            <DefaultButton onClick={(event) => handleSubmit(event)}
                                disabled={userIdentity === undefined || !canSubmit || sending || error.message !== ""}
                                className="button tiny-primary-button"
                            >
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </DefaultButton>
                        </S.BtnCont>
                    </S.MainCont>
                </S.Cont>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ConvenienceTestFinance;