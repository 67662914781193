import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { StatusBadge, Table, TableContainer, Wrapper, ActionsCell } from "./PortfolioOrdersStyle";
import { parseNumber } from "../../../utils/toFixedDecimals";
import { Link, useHistory } from "react-router-dom";
import { ActivateOrdersIcon, DeleteIcon } from "@fluentui/react-icons-mdl2";
import { TeachingBubble, TooltipHost } from "@fluentui/react";
import config from "../../../config";
import { decimalFormatter } from "../../../utils/numberFormatter";

const PortfolioOrdersTable = (props: any) =>
{
    const theme = useContext(ThemeContext);
    let history = useHistory();
    const [incidenceTooltip, setIncidenceTooltip] = useState("");
    const typeOfCancelDevolution: number = config.type_of_cancel_devolution;

    useEffect(() =>
    {
        caches.keys().then((names) =>
        {
            names.forEach((name) =>
            {
                caches.delete(name);
            });
        });
    }, []);

    return(
        <Wrapper>
            <TableContainer>
                <Table theme={theme}>
                    <thead>
                        <tr>
                            {props.columns.map((column: any, columnIndex: any) =>
                            {
                                return <th key={"head-" + columnIndex}>{column.title}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((row: any, rowIndex: any) =>
                        {
                            let amount;

                            if(row.amount === "Total")
                            {
                                amount = "Total";
                            }
                            else
                            {
                                if(row.amount.includes("participaciones"))
                                {
                                    amount = decimalFormatter.format(parseFloat(row.amount.match(/[\d.]+/)[0])) + " participaciones";
                                }
                                else
                                {
                                    amount = decimalFormatter.format(parseNumber(row.amount, 2))+"€";
                                }
                            }
                            return(
                                <tr key={"row-"+rowIndex} style={{verticalAlign: "middle"}}>
                                    <td style={{padding: "1em"}}>{row.date}</td>
                                    <td style={{paddingRight: "15px"}}>
                                        <StatusBadge className={row.status.name.toLowerCase()} theme={theme}>
                                            {row.status.name}
                                        </StatusBadge>
                                    </td>
                                    <td>{row.status.message}</td>
                                    <td style={{padding: "1em"}}>
                                        <p>{row.typeText}</p>
                                    </td>
                                    <td style={{padding: "1em"}}>
                                        <p>
                                            <span className="price-no-wrap">
                                                {amount}
                                            </span>
                                        </p>
                                    </td>
                                    <td style={{padding: "1em"}}>
                                        <div>
                                            {row.type === "subscription" &&
                                            (
                                                <p>
                                                    <span className="fund-name" style={{color: "rgb(66,132,230)", cursor: "pointer"}}
                                                        onClick={() => history.push(`/${row.fund.isinOrig}`)}
                                                    >
                                                        {row.fund.isinOrig}
                                                        {" - "}
                                                        {row.fund.nameOrig}
                                                    </span>
                                                </p>
                                            )}
                                            {row.type === "refund" &&
                                            (
                                                <>
                                                    <p>
                                                        Origen:{" "}
                                                        <span className="fund-name" style={{color: "rgb(66,132,230)", cursor: "pointer"}}
                                                            onClick={() => history.push(`/${row.fund.isinOrig}`)}
                                                        >
                                                            {row.fund.isinOrig}
                                                            {" - "}
                                                            {row.fund.nameOrig}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        IBAN Destino:{" "}
                                                        <span className="iban">{row.iban}</span>
                                                    </p>
                                                </>
                                            )}
                                            {!["subscription", "refund"].includes(row.type) &&
                                            (
                                                <React.Fragment>
                                                    <p>
                                                        Origen:{" "}
                                                        <span className="fund-name" style={{color: "rgb(66,132,230)", cursor: "pointer"}}
                                                            onClick={() => history.push(`/${row.fund.isinOrig}`)}
                                                        >
                                                            {row.fund.isinOrig}
                                                            {" - "}
                                                            {row.fund.nameOrig}
                                                        </span>
                                                    </p>
                                                    {row.fund.nameDesty === "" ? null :
                                                    (
                                                        <p>
                                                            Destino:{" "}
                                                            <span
                                                                className="fund-name"
                                                                style={{color: "rgb(66,132,230)", cursor: "pointer"}}
                                                                onClick={() => history.push(`/${row.fund.isinDesty}`)}
                                                            >
                                                                {row.fund.isinDesty}
                                                                {" - "}
                                                                {row.fund.nameDesty}
                                                            </span>
                                                        </p>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </td>
                                    <ActionsCell theme={theme}>
                                        {row.incidence &&
                                        (
                                            <>
                                                <Link to={`/portfolio/orders/${row.finametrixId}`}
                                                    onMouseEnter={() => setIncidenceTooltip(row.finametrixId)}
                                                    onMouseLeave={() => setIncidenceTooltip("")}
                                                >
                                                    <ActivateOrdersIcon id={`orderIncidence${rowIndex}`} />
                                                </Link>
                                                {row.finametrixId === incidenceTooltip &&
                                                (
                                                    <TeachingBubble target={`#orderIncidence${rowIndex}`}
                                                        hasCloseButton={false}
                                                        headline="Orden con incidencia"
                                                    >
                                                        Esta orden tiene una incidencia, pulsa para
                                                        resolverla.
                                                    </TeachingBubble>
                                                )}
                                            </>
                                        )}
                                        {row.status.name === "Validada" && (typeOfCancelDevolution === 1 ?
                                        (
                                            <Link to={
                                                {
                                                    pathname: `/portfolio/orders/cancel/a/${row.finametrixId}`,
                                                    state:
                                                    {
                                                        date: row.date,
                                                        status: row.status.name,
                                                        statusMessage: row.status.message,
                                                        typeText: row.typeText,
                                                        amount: row.amount,
                                                        isinOrig: row.fund.isinOrig,
                                                        nameOrig: row.fund.nameOrig,
                                                        isinDesty: row.fund.isinDesty,
                                                        nameDesty: row.fund.nameDesty,
                                                        type: row.type,
                                                        iban: row.iban,
                                                    }
                                                }}
                                                onMouseEnter={() => setIncidenceTooltip(row.finametrixId)}
                                                onMouseLeave={() => setIncidenceTooltip("")}
                                            >
                                                <TooltipHost content="Cancelar Orden" id={`order-cancel`}>
                                                    <DeleteIcon id={`orderCancel${rowIndex}`} />
                                                </TooltipHost>
                                            </Link>
                                        ) :
                                        (
                                            <Link to={{pathname: `/portfolio/orders/cancel/m/${row.finametrixId}`,
                                                state:
                                                {
                                                    date: row.date,
                                                    status: row.status.name,
                                                    statusMessage: row.status.message,
                                                    typeText: row.typeText,
                                                    amount: row.amount,
                                                    isinOrig: row.fund.isinOrig,
                                                    nameOrig: row.fund.nameOrig,
                                                    isinDesty: row.fund.isinDesty,
                                                    nameDesty: row.fund.nameDesty,
                                                    type: row.type,
                                                    iban: row.iban,
                                                }}}
                                                onMouseEnter={() => setIncidenceTooltip(row.finametrixId)}
                                                onMouseLeave={() => setIncidenceTooltip("")}
                                            >
                                                <TooltipHost content="Cancelar Orden" id={`order-cancel`}>
                                                    <DeleteIcon id={`orderCancel${rowIndex}`} />
                                                </TooltipHost>
                                            </Link>
                                        ))}
                                    </ActionsCell>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </TableContainer>
        </Wrapper>
    );
};

export default PortfolioOrdersTable;