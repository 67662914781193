import React from 'react';
import * as S from './ManagementAuthorization.style';
import SavingsPlanUserIdentityService, { SavingsPlanUserIdentityType } from '../../services/SavingsPlanUserIdentityService';
import { UserIdentityType } from '../../services/UserIdentitiesService';
import SavingsPlanService from '../../services/SavingsPlanService';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DefaultButton, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../utils/scrollToTop';
import Moment from 'moment';

import FinametrixService, { IAddressType, ICountry } from '../../services/FinametrixService';

const ManagementAuthorizationContract = () =>
{
	const { savingsPlanId } = useParams<any>();
	const history = useHistory();
	const [savingsPlansUserIdentities, setSavingsPlansUserIdentities] = useState<SavingsPlanUserIdentityType[]>([]);
	const [userIdentitiesProxies, setUserIdentitiesProxies] = useState<UserIdentityType[]>([]);
	const [userIdentitiesNotProxies, setUserIdentitiesNotProxies] = useState<UserIdentityType[]>([]);
	const [signatures, setSignatures] = useState<any[]>([]);
	const [phoneNumbers, setPhoneNumbers] = useState<string>("");
	const [countries, setCountries] = useState<ICountry[]>([]);
	const [addressTypes, setAddressTypes] = useState<IAddressType[]>([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [error, setError] = useState({ message: '' });

	useEffect(() =>
    {
		scrollToTop();
	}, []);

	useEffect(() =>
    {
		FinametrixService.getCountries().then((result: ICountry[]) =>
        {
            setCountries(result);
        },
        (error: any) =>
        {
            setError(error);
        });
	}, []);

	useEffect(() =>
    {
		FinametrixService.getAddressTypes().then((response: IAddressType[]) =>
        {
			setAddressTypes(response);
        }).catch((error) =>
        {
            console.log(error);
        });
	}, []);

	useEffect(() =>
    {
		SavingsPlanUserIdentityService.getSavingsPlanUserIdentitiesBySavingsPlanId(savingsPlanId).then((savingsPlansUserIdentities: SavingsPlanUserIdentityType[]) => {
			var currentDate = Moment();
			setSavingsPlansUserIdentities(savingsPlansUserIdentities.filter((uISP: SavingsPlanUserIdentityType) =>
            {
				var birthdayDate = Moment(uISP.userIdentity?.birthday);
				return currentDate.diff(birthdayDate, 'years') > 17
			}));
			setIsLoaded(true);
		},
        (error) =>
        {
            setError(error);
            setIsLoaded(true);
        });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() =>
    {
		setPhoneNumbers(
			savingsPlansUserIdentities.map((savingsPlansUserIdentity: SavingsPlanUserIdentityType) =>
				(savingsPlansUserIdentity.userIdentity?.phone !== undefined
					? ("X".repeat(savingsPlansUserIdentity.userIdentity.phone.length - 3) + savingsPlansUserIdentity.userIdentity.phone.slice(savingsPlansUserIdentity.userIdentity.phone.length - 3, savingsPlansUserIdentity.userIdentity.phone.length))
				: "")
			).join(", ")
		);
	}, [savingsPlansUserIdentities]);

	useEffect(() =>
    {
		if(savingsPlansUserIdentities.length > 0)
        {
			var userIdentitiesProxies = savingsPlansUserIdentities
				.filter(sPUI => sPUI.relation === 5 && sPUI.userIdentity !== undefined)
				.map(sPUI => sPUI.userIdentity);
			if(userIdentitiesProxies !== undefined)
            {
				// @ts-ignore
				setUserIdentitiesProxies(userIdentitiesProxies)
			}
		}
	}, [savingsPlansUserIdentities]);

	useEffect(() =>
    {
		if(savingsPlansUserIdentities.length > 0)
        {
			var userIdentities = savingsPlansUserIdentities
				.filter(sPUI => sPUI.relation !== 5 && sPUI.userIdentity !== undefined)
				.map(sPUI => sPUI.userIdentity);
			if(userIdentities !== undefined)
            {
				// @ts-ignore
				setUserIdentitiesNotProxies(userIdentities);
			}
		}
	}, [savingsPlansUserIdentities]);

    const handleSubmit = () =>
    {
        setIsSending(true);
		if(savingsPlansUserIdentities.length > 0)
        {
            SavingsPlanService.addManagementAuthorizationSignaturesToSavingsPlanUserIdentities(savingsPlanId).then((signatures: any) =>
            {
                setSignatures(signatures);
                setIsSending(false);
            });
        }
    };

    useEffect(() =>
	{
		if(savingsPlansUserIdentities.length > 0 && savingsPlansUserIdentities.length === signatures.length)
        {
            history.push('/savingsPlans/' + savingsPlanId  + '/managementAuthorizationConfirm',
            {
                signatures: signatures
            });
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savingsPlansUserIdentities, signatures]);

    const [bottom, setBottom] = useState(false);

    const handleScroll = (event: any) =>
    {
        const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 1;
        setBottom(bottom)
    };

    return(
        <React.Fragment>
            <S.HelperStyle />
            <S.Cont>
                <S.MainCont>
                    <S.Title>
						<h1>Firma del Contrato</h1>
                        <h2>
							A continuación te incluimos los detalles del contrato de autorización de gestión en la plataforma. Léelo y
                            cuando estés listo, dale al botón de "solicitar firma". Te enviaremos un
                            código SMS por cada apoderado y titular
							{savingsPlansUserIdentities.length > 1
                                ? ` a los números ${phoneNumbers} `
								: savingsPlansUserIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " "
                            }
                            para realizar la firma del contrato.
                        </h2>
                    </S.Title>
                    <S.BarCont>
                        <S.BgBar/>
                        <S.ProsBar/>
                    </S.BarCont>
                    <S.ScrollableTabletCont onScroll={handleScroll}>
						<h1>Poder</h1>
						<h2>Personas físicas</h2>
						<h3>Nombre del/de los Titular/es de la cuenta:{userIdentitiesNotProxies.map(uI => [uI.firstName, uI.lastName, uI.secondLastName].join(" ")).join(", ")}</h3>
						<p>El/Los abajo firmantes (en adelante, el/los poderdante/s), titular/es de la/las cuenta/s abierta/s
							a través de DIAPHANUM VALORES, SV, S.A.U. con el número indicado anteriormente, designan a la/s
							siguiente/s persona/s como apoderado/s con las facultades que se establecen a continuación.</p>
						<p>El/los apoderado/s declara/n de manera expresa haber tomado conocimiento del presente poder, así como
							de las obligaciones contractuales con DIAPHANUM VALORES, SV, S.A.U. y reconoce/n, también de manera expresa,
							aceptar plenamente la competencia de los órganos jurisdiccionales de España y la aplicación del derecho
							español para resolver cualquier controversia que pudiera surgir con motivo de la interpretación, validez y
							ejecución del presente poder, y se compromete/n a informar a DIAPHANUM VALORES, SV, S.A.U. con carácter
							inmediato y por escrito, de cuantos cambios afecten al presente poder y, en su defecto, a asumir la plena
							responsabilidad y cualesquiera daños y perjuicios que pudieran derivarse del incumplimiento de dicha
							obligación. DIAPHANUM VALORES, SV, S.A.U. no será responsable ni asumirá responsabilidad alguna,
							independientemente de su fundamento, por la ausencia o retraso en la información por parte del/de
							los mandatario/s o de su/s poderdante/s.</p>

						{userIdentitiesProxies.map((userIdentityProxy: UserIdentityType, userIdentityProxyIndex: number) =>
						(
							<div key={'userIdentityProxyIndex' + userIdentityProxyIndex}>
								<h3>Apoderado {userIdentityProxyIndex + 1}:</h3>
								<dl>
									<dt>Nombre y apellidos:</dt>
									<dd>{[userIdentityProxy.firstName, userIdentityProxy.lastName, userIdentityProxy.secondLastName].join(" ")}</dd>
									<dt>Dirección / Domicilio legal:</dt>
									<dd>
										{(countries.length === 0 || addressTypes.length === 0) && (<Spinner size={SpinnerSize.small} />)}
										{countries.length > 0 && addressTypes.length > 0 &&
                                        (
											FinametrixService.getAddressText(
												userIdentityProxy.fiscalAddress !== null
													? userIdentityProxy.fiscalAddress
													: userIdentityProxy.postalAddress,
												addressTypes,
												countries
											)
										)}
									</dd>
									<dt>País:</dt>
									<dd>{countries.find((countryAux: any) => countryAux.value === userIdentityProxy.nationalCountry)?.text}</dd>
									<dt>Nacionalidad:</dt>
									<dd>{countries.find((countryAux: any) => countryAux.value === userIdentityProxy.birthdayCountry)?.text}</dd>
									<dt>DNI:</dt>
									<dd>{userIdentityProxy.idDocument} válido hasta el {Moment(userIdentityProxy.idExpiration).format('DD/MM/YYYY')}</dd>
								</dl>
								<Separator className="separator" />
							</div>
						))}

						{userIdentitiesNotProxies.map((userIdentity: UserIdentityType, userIdentityIndex: number) =>
						(
							<div key={'userIdentityIndex' + userIdentityIndex}>
								<h3>Titular {userIdentityIndex + 1}:</h3>
								<dl>
									<dt>Nombre y apellidos:</dt>
									<dd>{[userIdentity.firstName, userIdentity.lastName, userIdentity.secondLastName].join(" ")}</dd>
									<dt>Dirección / Domicilio legal:</dt>
									<dd>
										{(countries.length === 0 || addressTypes.length === 0) && (<Spinner size={SpinnerSize.small} />)}
										{countries.length > 0 && addressTypes.length > 0 && (
											FinametrixService.getAddressText(
												userIdentity.fiscalAddress !== null
													? userIdentity.fiscalAddress
													: userIdentity.postalAddress,
												addressTypes,
												countries
											)
										)}
									</dd>
									<dt>País:</dt>
									<dd>{countries.find((countryAux: any) => countryAux.value === userIdentity.nationalCountry)?.text}</dd>
									<dt>Nacionalidad:</dt>
									<dd>{countries.find((countryAux: any) => countryAux.value === userIdentity.birthdayCountry)?.text}</dd>
									<dt>DNI:</dt>
									<dd>{userIdentity.idDocument} válido hasta el {Moment(userIdentity.idExpiration).format('DD/MM/YYYY')}</dd>
								</dl>
								<Separator className="separator" />
							</div>
						))}

						<div className="checkboxes">
							<label>
								<input type="checkbox" checked={true} disabled={true} />
								Primer poder
							</label>
							<br />
							<label>
								<input type="checkbox" disabled={true} />
								Los poderes que se hayan otorgado con anterioridad al presente documento:
							</label>
							<blockquote>
								<label>
									<input type="checkbox" disabled={true} />
									quedan revocados
								</label>
								<br />
								<label>
									<input type="checkbox" disabled={true} />
									siguen estando vigentes y completan el mismo
								</label>
							</blockquote>
						</div>

						<p>En virtud del presente poder, el/los mandatario/s anteriormente citado/s podrá/n, de conformidad con las
							opciones señaladas en relación con el poder de firma indistinta, representar
							sin facultad de sustitución y actuar en nombre y por cuenta del/de los poderdante/s arriba mencionado/s en
							todas sus relaciones con DIAPHANUM VALORES, SV, S.A.U. relativas a contrato de este plan de ahorro.
							Actuando en dicha calidad, el/los mandatario/s estará/n facultado/s para</p>
						<table>
							<tbody>
								<tr>
									<td>1. dar órdenes de compra y venta</td>
									<td>
										<label>
											<input type="radio" checked={true} disabled={true} />
											Sí
										</label>
										<label>
											<input type="radio" disabled={true} />
											No
										</label>
									</td>
								</tr>
								<tr>
									<td>2. realizar depósitos, arbitrajes, conversiones, suscripciones o retirar valores o dinero en efectivo</td>
									<td>
										<label>
											<input type="radio" checked={true} disabled={true} />
											Sí
										</label>
										<label>
											<input type="radio" disabled={true} />
											No
										</label>
									</td>
								</tr>
							</tbody>
						</table>
						<p>y, en general, además de todo lo anteriormente enunciado, realizar cuantos actos el/los apoderado/s
							considere/n útiles y precisos para la plena efectividad de las facultades anteriores para que su/s
							poderdante/s dé/den su visto bueno y proceda/n a su ratificación; y, en particular, independientemente
							de la opción señalada en relación con los poderes de firma anteriores, el/los apoderado/s podrá/n
							solicitar extractos y copias de los movimientos del contrato de este plan de ahorro.</p>
						<p>El/Los apoderado/s no está/n autorizado/s a cancelar el contrato del/de los poderdante/s en referencia a este plan de ahorro, salvo
							que éste/éstos dé/den instrucciones especiales a tal efecto, por escrito y de forma expresa.</p>
						<p>Los poderes otorgados al/los apoderado/s quedarán revocados en caso de fallecimiento, desaparición,
							pérdida de la capacidad de obrar, liquidación o quiebra del/de los poderdante/s. No obstante, dicha
							revocación no será oponible a DIAPHANUM VALORES, SV, S.A.U. hasta que ésta haya sido informada por
							escrito por el/los poderdante/s o por las personas legalmente facultadas para hacerlo en su calidad de
							derechohabientes, herederos, albaceas, curadores, administradores de la quiebra, liquidadores u otras
							personas relacionadas con el/los titular/es, recayendo la carga de la prueba de dicha información y de
							la fecha en la que esta última se facilitó a DIAPHANUM VALORES, SV, S.A.U. en dichas personas.</p>
                    </S.ScrollableTabletCont>
                    {!isLoaded && (
                        <Spinner size={SpinnerSize.large} />
                    )}
                    <S.BtnCont>
                        <DefaultButton
                            onClick={() => history.goBack()}
                            className="button tiny-secondary-button"
                        >
                            Volver
                        </DefaultButton>
                        <DefaultButton
                            className="button tiny-primary-button"
                            onClick={handleSubmit}
                            disabled={!isLoaded || !bottom}
                        >
                            {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                            Solicitar Firma
                        </DefaultButton>
                    </S.BtnCont>
                </S.MainCont>
            </S.Cont>
        </React.Fragment>
    );
};

export default ManagementAuthorizationContract;