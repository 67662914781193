import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Moment from 'moment';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, DirectionalHint, ITooltipProps, Spinner, SpinnerSize, TooltipDelay, TooltipHost } from '@fluentui/react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import DocumentTitle from 'react-document-title';
import Auth from "../../Auth/Auth";
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import PaymentService from '../../services/PaymentService';
import InespayService, { BankType, InespayType } from '../../services/InespayService';
import './Payments.sass';
import { useBoolean, useConst, useId } from '@fluentui/react-hooks';
import CustomSimpleDropdown, { IItem } from '../dropdown/CustomSimpleDropdown';
import styled from "styled-components"
import { createIntl, createIntlCache } from '@formatjs/intl';
import Spanish from '../../translations/es.json';
import React from 'react';

const cache = createIntlCache();
const intl = createIntl(
    {
        locale: 'es',
        messages: Spanish,
    },
    cache
);

const stackTokens = { childrenGap: 20 };
const ibanRegex = RegExp(/^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/);

export const Error = styled.span`
    position: absolute;
    display: block;
    width: fit-content;
    top: 83%;
    left: 0;
    transform: translate(0, -17%);
    margin: 0;
    color: hsl(0, 100%, 63%);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
`;

const tooltipProps: ITooltipProps =
{
    onRenderContent: () => (
        <div>
            El pago periódico realizará una transferencia automática en el periodo especificado.
            El pago puntual solo realiza el pago de este periodo de tiempo.
            Si no encuentra su entidad bancaria en el pago periódico realice un pago puntual.
        </div>
    ),
};

const Payment = () =>
{
    const country = 'ES';
    const [error, setError] = useState({ status: false, message: '' });
    const [isLoadedBanks, setIsLoadedBanks] = useState(true);
    const [banks, setBanks] = useState<IItem[]>([]);
    const [bank, setBank] = useState('');
    const [subscription, setSubscription] = useState<Partial<SubscriptionType>>({});
    const [account, setAccount] = useState('');
    const [isError, setIsError] = useState<any>({ country: '', bank: '', account: '' });
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [price, setPrice] = useState<number>(0);
    const [frequency, setFrequency] = useState<string>();
    const [recursivePayment, { setTrue: setRecursivePayment, setFalse: setPuntualPayment }] = useBoolean(true);
    const tooltipId = useId('tooltip');
    const user = Auth.getUserProfile();
    const search = useLocation().search;
    const subscriptionId = new URLSearchParams(search).get('subscriptionId') || "";
    const usersSubscriptionId = new URLSearchParams(search).get('usersSubscriptionId') || "";
    const initialContribution = (new URLSearchParams(search).get('initialContribution')) || "";
    const managedPortfolioId = (new URLSearchParams(search).get('managedPortfolioId')) || "";
    const bmePortfolioId = (new URLSearchParams(search).get('bmePortfolioId')) || "";
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const todayText = Moment(today).format('YYYY-MM-DD');
    const reference = useConst(`${user.id}-S${subscriptionId}-${today.valueOf().toString()}`);

    useEffect(() =>
    {
        setIsLoadedBanks(false);
        InespayService.getBanks().then((data: any) =>
        {
            setIsLoadedBanks(true);
            setBanks(data
                .filter(function (bank: any)
                {
                    return bank.country === country
                        && bank.frequencyPeriodicPayment !== null
                        && bank.frequencyPeriodicPayment.length > 0;
                })
                .map((bank: BankType) =>
                {
                    return {
                        key: bank.bankId,
                        text: bank.name,
                        value: bank.bankId,
                        maxAllowedPeriod: bank.frequencyPeriodicPayment.length > 0
                            ? bank.bankId === "BES0049"
                                ? "Monthly"
                                : bank.frequencyPeriodicPayment[bank.frequencyPeriodicPayment.length - 1]
                            : null
                    }
                })
            );
        },
        (error: any) =>
        {
            setIsLoadedBanks(true);
            setError(error);
        });


        SubscriptionService.getSubscription(parseInt(subscriptionId)).then((data: SubscriptionType) =>
        {
            setSubscription(data);
        },
        (error) =>
        {
            setError(error);
        });
    }, [subscriptionId]);

    const changeBank = (option: any) =>
    {
        if(subscription?.period && subscription.period === "year")
        {
            setFrequency(option.maxAllowedPeriod);
        }

        setBank(option.key);
        const bankError = (option.key === null || option.key === "")
            ? 'Debe seleccionar un banco'
            : '';
        setIsError((prevIsError: any) =>
        ({
            ...prevIsError,
            bank: bankError
        }));
        validateForm('bank', option.key, bankError);
    };

    useEffect(() =>
    {
        if(subscription?.period)
        {
            switch(subscription.period)
            {
                case "year":
                    setFrequency("Annual");
                    break;
                case "month":
                    setFrequency("Monthly");
                    break;
            }
        }
    }, [subscription]);

    useEffect(() =>
    {
        if(frequency && subscription?.price !== undefined && subscription?.price !== null)
        {
            if(subscription.period === "year")
            {
                switch(frequency)
                {
                    case 'Annual': setPrice(parseFloat(subscription.price.toFixed(2))); break;
                    case 'SemiAnnual': setPrice(parseFloat((subscription.price / 2).toFixed(2))); break;
                    case 'Quarterly': setPrice(parseFloat((subscription.price / 4).toFixed(2))); break;
                    case 'EveryTwoMonths': setPrice(parseFloat((subscription.price / 6).toFixed(2))); break;
                    case 'Monthly': setPrice(parseFloat((subscription.price / 12).toFixed(2))); break;
                    default: setPrice(0); break;
                }
            }
            else
            {
                setPrice(parseFloat(subscription.price.toFixed(2)));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frequency]);

    const changeAccount = (event: any) =>
    {
        const value = event.target.value;
        setAccount(value);
        const accountError = (value === null || value === "")
            ? 'El IBAN es obligatorio'
            : (value.length === 24 && ibanRegex.test(value) ? '' : 'El formato del IBAN no es válido. E.j. ES3456789012345678901234');
        setIsError((prevIsError: any) =>
        ({
            ...prevIsError,
            account: accountError
        }));
        validateForm('account', value, accountError);
    };

    const validateForm = (key: any, value: any, error: any) =>
    {
        var invalidatedFields = Object.keys(isError).filter(function (field: any)
        {
            /*eslint-disable no-eval */
            var fieldValue = eval(field);
            var fieldError = isError[field];
            if(key === field)
            {
                fieldValue = value;
                fieldError = error;
            }
            return fieldValue === '' || fieldError !== ''
        });
        setCanSubmit(invalidatedFields.length === 0);
    };

    const sendRecursivePayment = (event: any) =>
    {
        event.preventDefault();
        setIsSubmitted(true);

        PaymentService.createPayment(
        {
            reference: reference,
            amount: price,
            frequency: frequency,
            bank: bank,
            account: account,
            subject: `${user.id}-${subscription.id}`,
            startDate: todayText,
            status: 1,
            usersSubscriptionId: parseInt(usersSubscriptionId),
            platform: "INESPAY"
        })
        .then(() =>
        {
            var inespayData: InespayType =
            {
                subject: `${user.id}-${subscription.id}`,
                reference: reference,
                amount: (price * 100).toString(),
                frequency: frequency,
                account: account,
                startDate: todayText,
                subscription: true,
                usersSubscriptionId: parseInt(usersSubscriptionId)
            };

            if(initialContribution !== "")
            {
                inespayData.initialContribution = parseFloat(initialContribution)
            }
            if(managedPortfolioId !== "")
            {
                inespayData.managedPortfolioId = parseInt(managedPortfolioId)
            }
            if(bmePortfolioId !== "")
            {
                inespayData.bmePortfolioId = parseInt(bmePortfolioId)
            }

            sendToInespay(inespayData);
        },
        (error: any) =>
        {
            setError(error);
        });
    };

    const sendPuntualPayment = (event: any) =>
    {
        event.preventDefault();
        setIsSubmitted(true);

        if(subscription?.price === undefined || subscription?.price === null)
        {
            setError({ status: true, message: "La suscripción elegida no tiene precio definido" });
            setIsSubmitted(false);
        }
        else
        {
            var puntualPrice = subscription?.price;
            PaymentService.createPayment(
            {
                reference: reference,
                amount: puntualPrice,
                subject: `${user.id}-${subscription.id}`,
                status: 1,
                usersSubscriptionId: parseInt(usersSubscriptionId),
                platform: "INESPAY"
            }).then(() =>
            {
                var inespayData: InespayType =
                {
                    subject: `${user.id}-${subscription.id}`,
                    reference: reference,
                    amount: (puntualPrice * 100).toString(),
                    subscription: true,
                    usersSubscriptionId: parseInt(usersSubscriptionId)
                };

                if(initialContribution !== "")
                {
                    inespayData.initialContribution = parseFloat(initialContribution)
                }
                if(managedPortfolioId !== "")
                {
                    inespayData.managedPortfolioId = parseInt(managedPortfolioId)
                }
                if(bmePortfolioId !== "")
                {
                    inespayData.bmePortfolioId = parseInt(bmePortfolioId)
                }

                sendToInespay(inespayData);
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    };

    const sendToInespay = (inespayData: InespayType) =>
    {
        InespayService.postInespay(inespayData).then((dataInespay: any) =>
        {
            const inespay_result = JSON.parse(dataInespay.Result);
            if(inespay_result.status === '200')
            {
                window.location.href = inespay_result.url;
            }
            else
            {
                setError(
                {
                    status: false,
                    message: inespay_result.description
                });
                setIsSubmitted(false);
            }
        },
        (error: any) =>
        {
            setError(error);
        });
    };

    if(error.status)
    {
        return(
            <DocumentTitle title='IronIA - Pago'>
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }

    return(
        <DocumentTitle title='IronIA - Pago'>
            <React.Fragment>
                <div className="ms-Grid-row tight">
                    <h2>Detalles de pago</h2>
                    <p className="description">Este método de pago conecta con su banca online utilizando las claves de acceso de su banca online: </p>
                    <Separator className="separator" />
                </div>
                <div className="ms-Grid-row tight">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg5">
                            <div className="blue payment-data">
                                <dl>
                                    <dt>Beneficiario</dt>
                                    <dd>
                                        <ul>
                                            <li>DIAPHANUM VALORES, SV, S.A.U | A87637450</li>
                                            <li>Maria de Molina 40, Planta 7 - Madrid 28006</li>
                                        </ul>
                                    </dd>
                                    <dt>Referencia</dt>
                                    <dd>Orden IronIA {reference}</dd>
                                    <dt>Nombre de la Suscripción</dt>
                                    <dd>{subscription.name}</dd>
                                    <dt>Importe</dt>
                                    <dd>{price || subscription.price} €</dd>
                                    {bank !== '' && frequency === null &&
                                    (
                                        <React.Fragment>
                                            <dt>
                                                <i className={'ironia-icon warning-alert'} />
                                                El banco seleccionado no admite pagos periódicos
                                            </dt>
                                            <dd>Seleccione otro banco de la lista</dd>
                                        </React.Fragment>
                                    )}
                                    {bank !== '' && subscription.period === 'year'
                                        && frequency !== null && frequency !== 'Annual' &&
                                    (
                                        <React.Fragment>
                                            <dt>
                                                <i className={'ironia-icon warning-alert'} />
                                                El banco seleccionado no admite la frecuencia de pago anual
                                            </dt>
                                            <dd>La suscripción se pagará con frecuencia {intl.messages[`${frequency}`]}</dd>
                                        </React.Fragment>
                                    )}
                                </dl>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg7">
                            <div className="payment">
                                <div className="ms-Tabs-Container">
                                    <button className={"ms-Tab" + (recursivePayment ? ' active' : '')} onClick={() => setRecursivePayment()}>
                                        <i className="ironia-icon refresh" />
                                        Pagos periódicos
                                    </button>
                                    <button className={"ms-Tab" + (recursivePayment ? '' : ' active')} onClick={() => setPuntualPayment()}>
                                        <i className="ironia-icon calendar" />
                                        Pago puntual
                                    </button>
                                </div>
                                <form>
                                    <Stack tokens={stackTokens}>
                                        {recursivePayment &&
                                        (
                                            <React.Fragment>
                                                <Stack.Item grow>
                                                    <CustomSimpleDropdown
                                                        label="Entidad bancaria"
                                                        title="Selecciona un entidad bancaria"
                                                        options={banks}
                                                        id="bank"
                                                        name="bank"
                                                        loading={!isLoadedBanks}
                                                        onChange={changeBank}
                                                        disabled={isSubmitted}
                                                        showSummary={false}
                                                        hasMarginTop={true}
                                                    />
                                                    {isLoadedBanks &&
                                                    (
                                                        <TooltipHost tooltipProps={tooltipProps}
                                                            delay={TooltipDelay.zero}
                                                            id={tooltipId}
                                                            directionalHint={DirectionalHint.topCenter}
                                                            className="ironia-tooltip"
                                                        >
                                                            <DefaultButton aria-describedby={tooltipId}>
                                                                <i className="ironia-icon information" />
                                                            </DefaultButton>
                                                        </TooltipHost>
                                                    )}
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <TextField label="IBAN"
                                                        placeholder="Ingresa el IBAN de tu cuenta bancaria"
                                                        name="iban"
                                                        value={account}
                                                        onChange={changeAccount}
                                                        errorMessage={isError.account.length > 0 ? isError.account : undefined}
                                                        disabled={isSubmitted}
                                                        pattern="[A-Z]{2}[0-9]{18}"
                                                    />
                                                </Stack.Item>
                                                <Stack.Item grow className="text-right">
                                                    <button className={"button primary-button " + ((!canSubmit || frequency === null) ? 'disabled' : '')}
                                                        onClick={(event: any) => sendRecursivePayment(event)}
                                                        disabled={!canSubmit || frequency === null || isSubmitted}
                                                    >
                                                        {isSubmitted && (<Spinner size={SpinnerSize.small} className="button-spinner" />)}
                                                        Pagar
                                                    </button>
                                                </Stack.Item>
                                            </React.Fragment>
                                        )}
                                        {!recursivePayment &&
                                        (
                                            <React.Fragment>
                                                <Stack.Item grow>
                                                    Si tu entidad no aparece en el selector de entidades bancarias que admiten pagos
                                                    periódicos puedes realizar pagos puntuales (con frecuencia anual) para pagar tu suscripción.
                                                </Stack.Item>
                                                <Stack.Item grow className="text-right">
                                                    <button className="button primary-button"
                                                        onClick={(event: any) => sendPuntualPayment(event)}
                                                        disabled={isSubmitted}
                                                    >
                                                        {isSubmitted && (<Spinner size={SpinnerSize.small} className="button-spinner" />)}
                                                        Pagar
                                                    </button>
                                                </Stack.Item>
                                            </React.Fragment>
                                        )}
                                    </Stack>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default Payment;