import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import DocumentTitle from "react-document-title";
import { Separator } from "office-ui-fabric-react/lib/Separator";
import Highstock, { GraphicType } from "../../../components/Highstock/Highstock";
import PieHighstock from "../../../components/Highstock/PieHighstock";
import Auth from "../../../Auth/Auth";
import { DefaultButton, DirectionalHint, Dropdown, IDropdownOption, IDropdownStyles, Modal, Spinner, SpinnerSize, TeachingBubble, Toggle } from "@fluentui/react";
import PortfolioTable from "./PortfolioTable";
import FinametrixService, { IPositionType } from "../../../services/FinametrixService";
import FundService, { IInfoFundPortfolio } from "../../../services/FundService";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { clearCart, addLine, updateModelPercentageTotal, cartState, updateModelPortfolioName } from "../../../store/cart/actions";
import NumberFormat from "react-number-format";
import { decimalFormatter, percentFormatter } from "../../../utils/numberFormatter";
import styled from "styled-components";
import "./Portfolio.sass";
import Moment from "moment";
import { useBoolean, useConst } from "@fluentui/react-hooks";
import RiskRateComponent from "./RiskRateComponent";
import config from "../../../config";
import CardsSection from "./CardsSection";
import { Redirect } from "react-router";
import UserService from "../../../services/UserService";
import SavingsPlanService, { ActiveSubscriptionAndSavingPlans } from "../../../services/SavingsPlanService";
import debounce from "lodash/debounce";
import service from "../../../services/SignaturesService";
import { ThemeContext } from "@fluentui/react-theme-provider";
import FundDetails from "../../../services/FundDetails";
import PortfolioActions from "../../../components/Portfolio/index";
import { ExportIndex } from "../../../components/ExportIndex";
import PortfolioBalancer from "../../../components/Portfolio/Balancer/PortfolioBalancer";
import MultiFundsAndBenchmarksDropDown from "../../../components/dropdown/MultiFundsAndBenchmarksDropDown/MultiFundsAndBenchmarksDropDown";
import { ai } from "../../../services/TelemetryService";
import { CartModes } from "../../Cart/CartScreen";
import moment from "moment";
import Highcharts from "highcharts";
import MoneyFlagHighStock from "../../../components/Highstock/MoneyFlagHighstock";
import { setActiveSavingsPlan } from '../../../store/auth/actions';
import portfolioModelWallets, { DataAndCartLines, ModelWallet, PortfolioModelAssociated } from "../../../services/PortfolioModelWalletService";
import { CompleteManagedPortfolioType } from "../../../services/ManagedPortfolioService";
import suitabilityService, { ISuitabilityTest } from "../../../services/SuitabilityTestsService";
import PortfolioModelWalletService from "../../../services/PortfolioModelWalletService";
import { InfoIcon } from "@fluentui/react-icons-mdl2";

const exportHeaders = [
    'Nombre Fondo',
    'Isin',
    'Comprado',
    'Títulos',
    'Valoración',
    'Fecha VL',
    'Revalorización',
    'Peso',
    'V. Liq',
    'Currency',
    'TC'
];
export const FilterContainer = styled.div`
    width 100%;
    > div
    {
        button
        {
            min-width: 100%;
            > div
            {
                color: #95A0A1 !important;
                position: relative;
                span
                {
                    position: absolute;
                    right: -3px;
                }
                .ironia-icon
                {
                    position: absolute;
                    right: -25px;
                    top: -9px;
                    background: #CC214F;
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    &:after
                    {
                        font-size: 17px;
                        line-height: 32px;
                    }
                }
            }
        }
        .menu-footer
        {
            button
            {
                min-width: unset;
                width: 50%;
            }
        }
        span
        {
            border-radius: 100px;
        }
    }

    i
    {
        color: white;
    }
`;
export const ComparerTitle = styled.h3`
    margin-bottom: 0;
    cursor: default;
`;
export const ComparerDescription = styled.p`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: #95A0A1;
    margin: 10px 0;
    cursor: default;
`;
export const RowComparer = styled.div`
`;
export const ColComparerTitle = styled.div``;
export const ColComparerDescription = styled.div`
    padding-top: 1em;
`;
export const ColComparerInput = styled.div`
    padding-top: 2em;
`;
export const MoneyToggle = styled(Toggle)`
    display: inline-block;
    margin-bottom: 0 !important;
    margin-top: 0.25em;
`;
export const ToggleLabel = styled.p`
    margin: 0;
    font-size: 15px;
    font-weight: 500;
`;
export const RowFirstBlock = styled.div`
`;
export const ColFirstGraphic = styled.div`
    min-height: 855px;
`;
export const FirstShadow = styled.div`
    min-height: 850px;
`;
export const RowGraphicSelector = styled.div``;
export const ColLinearGraphic = styled.div``;
export const TitleLinearGraphic = styled.h3`
    cursor: default;
`;
export const ColBlackInfo = styled.div`
    margin-top: 4px;
`;
export const ColWhiteInfo = styled.div`
    margin-top: 4px;
`;
export const ColMoneyGraphic = styled.div``;
export const TitleMoneyGraphic = styled.h3`
    cursor: default;
`;
export const ColLeftToggleTitle = styled.div`
    cursor: default;
    margin-top: 0.25em;
`;
export const ColToggle = styled.div`
    cursor: pointer;
    text-align: center;
`;
export const ColRightToggleTitle = styled.div`
    cursor: default;
    margin-top: 0.25em;
`;
export const GraphicContainer = styled.div``;
export const DivGraphic = styled.div``;

type ActivePlansToAgregate =
{
    id: string,
    finametrixId: string,
    createdAt: Date,
    isSelected: boolean,
    finametrixContract: string,
    fnmContractId: number
};
interface OptionType
{
    label: string,
    value: string
}
const initialReporting =
{
    hasError: false,
    positions:
    {
        positionsTo:
        {
            positions: [],
            aggregation: []
        }
    },
    performance:
    {
        serie: [{ dateAsString: "", accReturn: 0 }]
    }
};

const Portfolio = () =>
{
    const [error, setError] = useState({ status: false, message: "" });
    const [isLoadedBenchmarks, setIsLoadedBenchmarks] = useState(false);
    const [finametrixPortfolio, setFinametrixPortfolio] = useState(
    {
        creationDate: "",
        finametrixId: "",
        amount: 0,
        cost: 0
    });
    const [reporting, setReporting] = useState(initialReporting);
    const [reportingData, setReportingData] = useState(
    {
        performance: 0,
        performance1M: 0,
        performance1Y: 0,
        performance2Y: 0,
        performance3M: 0,
        performance3Y: 0,
        performance5Y: 0,
        performance6M: 0,
        performanceInceptionDate: 0,
        performanceMTD: 0,
        performanceYTD: 0
    });
    const isLoadedReporting = useRef<boolean>(true);
    const [isLoadingReporting, setIsLoadingReporting] = useState<boolean>(false);
    const isFinametrixPortfolioLoaded = useRef<boolean>(false);
    const [funds, setFunds] = useState<IInfoFundPortfolio[]>([]);
    const [hasFunds, setHasFunds] = useState<boolean>(false);
    const [isLoadedFunds, setIsLoadedFunds] = useState(false);
    const [benchmarks, setBenchmarks] = useState([]);
    const [selectedBenchmarks, setSelectedBenchmarks] = useState<OptionType[]>([]);
    const [filteredBenchmarks, setFilteredBenchmarks] = useState<any>([]);
    const [portfolioSeries, setPortfolioSeries] = useState<any[]>([]);
    const [series, setSeries] = useState<any[]>([]);
    const [moneySeries, setMoneySeries] = useState<any[]>([]);
    const [moneyGraphic, setMoneyGraphic] = useState<boolean>(false);
    const [isLoadedSeries, setIsLoadedSeries] = useState(false);
    const [chartSerie, setChartSerie] = useState([]);
    const [chartStartDate, setChartStartDate] = useState("");
    const [isLoadedRiskRate, setIsLoadedRiskRate] = useState(false);
    const [risk, setRiskValue] = useState("");
    const [riskValue, setNumericRisk] = useState(0);
    const [rate, setRateValue] = useState("");
    const [rateValue, setNumericRate] = useState(0);
    const [ytd, setYtd] = useState(0.0);
    const [period, setPeriod] = useState(0.0);
    const [year, setYear] = useState(0.0);
    const [global, setGlobal] = useState(0.0);
    const history = useHistory();
    const firstLoad = useRef<boolean>(true);
    const [options, setOptions] = useState<IDropdownOption[]>();
    const [isLoadingChange, setLoadingChange] = useState<boolean>(false);
    const isLoadedModelPortfolioComposition = useRef<boolean>(false);
    const [isSendingToCart, setIsSendingToCart] = useState(false);
    const [puntualContribution, setPuntualContribution] = useState(1000);
    const [allActivePlanToAgregate, setAllActivePlanToAgregate] = React.useState<ActivePlansToAgregate[]>([]);
    const [selectedActivePlansIds, setSelectedActivePlansIds] = React.useState<number[]>([]);
    const [selectedFinametrixContracts, setSelectedFinametrixContracts] = React.useState<string[]>([]);
    const [modelFunds, setModelFunds] = useState<any[]>([]);
    const [dataFlag, setDataFlag] = useState<any[]>([]);
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const [isWarningModalOpen, {setTrue: showWarningModal, setFalse: hideWarningModal}] = useBoolean(false);
    const [tooltipOptimizer, setTooltipOptimizer] = useState(false);
    const dispatch = useDispatch();
    const appInsights = ai.reactPlugin;
    Moment.locale("es");
    const date_to = function (this: any)
    {
        this.setDate(this.getDate());
        return this;
    }.call(new Date());
    const date_to_text = useConst(date_to.getFullYear().toString() + ("0" + (date_to.getMonth() + 1)).slice(-2) + ("0" + date_to.getDate()).slice(-2));
    const user = Auth.getUserProfile();
    var activePlan = Auth.getActivePlan();
    const subscribed = Auth.isSubscribed();
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();
    const userElementsStatus = Auth.getUserElementsStatus();
    const theme = window.localStorage.getItem("theme");
    const themeContext = useContext(ThemeContext);
    
    useEffect(() =>
    {
        setIsLoadingReporting(isLoadedReporting.current === false)
    }, [isLoadedReporting]);
    useEffect(() =>
    {
        if(firstLoad.current === true)
        {
            firstLoad.current = false;

            let allPlanToAgregate: ActivePlansToAgregate[] = [];
            let selectedActivePlanIds: number[] = [];

            SavingsPlanService.getActiveSubscriptionAndSavingPlan(user.id).then((response: ActiveSubscriptionAndSavingPlans[]) =>
            {
                var aux: IDropdownOption[] = [];
                for(var i = 0; i < response.length; i++)
                {
                    aux.push(
                    {
                        key: response[i].id,
                        text: response[i].planName,
                        selected: response[i].selected,
                        id: response[i].id.toString()
                    });

                    let planToAgregate: ActivePlansToAgregate =
                    {
                        id: response[i].id.toString(),
                        createdAt: response[i].createdAt!,
                        isSelected: response[i].selected,
                        finametrixId: response[i].finametrixId,
                        finametrixContract: response[i].finametrixContract,
                        fnmContractId: response[i].fnmContractId
                    };
                    
                    allPlanToAgregate.push(planToAgregate);

                    if(response[i].selected)
                    {
                        let selectedlanId = response[i].id;
                        selectedActivePlanIds.push(selectedlanId);
                    }
                }
                Auth.setActiveSubscriptions(aux);
                setOptions(aux);
            });
            setAllActivePlanToAgregate(allPlanToAgregate);
            setSelectedActivePlansIds(selectedActivePlanIds);

            service.getProcessHours().then((response: any) =>
            {
                if(response.status === 200 && response.data.length > 0)
                {
                    Auth.setProcessHours(response.data);
                }
            });
        }
    }, [user]);
    const fetchFinametrixReporting = useCallback(async (portfolioId: string, date_from: string, date_to: string) =>
    {
        if(isLoadedReporting.current)
        {
            isLoadedReporting.current = false;
            await FinametrixService.getReporting(portfolioId, date_from, date_to).then((data: any) =>
            {
                var realPlan = Auth.getActivePlan();
                FinametrixService.getOperations(realPlan.portfolio.finametrixId).then((ops: any) =>
                {
                    let opArray: any[] = [];
                    let dateFlag: any[] = [];

                    ops.forEach((op: any) =>
                    {
                        let description: string = op.cashIn ? op.description : op.title;
                        opArray.push(
                        {
                            id: op.id,
                            operationAt: moment(op.operationAt).format('YYYY-MM-DD'),
                            settlementDate: moment(op.settlementDate).format('YYYY-MM-DD'),
                            operationInstrument:
                            {
                                isin: op.operationInstrument.isin,
                                name: op.operationInstrument.name
                            },
                            description: description,
                            amount: op.amount,
                            nav: op.nav,
                            exchangeRate: op.exchangeRate === 1 ? op.exchangeRate : decimalFormatter.format(op.exchangeRate),
                            titles: op.titles,
                            currency: op.currency
                        });
                    });

                    opArray.forEach((op: any) =>
                    {
                        let offset = Moment().utcOffset();
                        let date = Moment(op.operationAt).add(offset, "minutes").locale("es");
                        let dateString = new Date(op.operationAt).toLocaleDateString();
                        let isin = op.operationInstrument.isin;
                        let name = op.operationInstrument.name;
                        let color: string = '#4284e6';

                        if((dateFlag.findIndex((i: any) => i.date === dateString) !== -1))
                        {
                            let idx = dateFlag.findIndex((i: any) => i.date === dateString);

                            if(!(dateFlag[idx].operations.findIndex((j: any) => j.isin === isin) !== -1 && dateFlag[idx].operations.findIndex((k: any) => k.operationId === op.operationId) !== -1))
                            {
                                dateFlag[idx].operations.push(
                                {
                                    text: op.description,
                                    fundName: name,
                                    amount: op.amount,
                                    isin: isin,
                                    titles: op.titles,
                                    currency: op.currency,
                                    operationId: op.id,
                                    contract: realPlan.finametrixContract
                                });
                            }
                        }
                        else
                        {
                            dateFlag.push(
                            {
                                x: date.valueOf(),
                                date: dateString,
                                title: 'i',
                                operations:
                                [
                                    {
                                        text: op.description,
                                        fundName: name,
                                        isin: isin,
                                        amount: op.amount,
                                        titles: op.titles,
                                        currency: op.currency,
                                        operationId: op.id,
                                        contract: realPlan.finametrixContract
                                    }
                                ],
                                lineColor: color,
                                color: color,
                                fillColor: color,
                                stroke: '#000',
                                shape: 'circlepin'
                            });
                        }
                    });

                    setDataFlag(dateFlag);
                    setReporting(data);
                    setReportingData(data.performance.data);
                    setRiskRateValues(data.profileRisk.name);
                    setYtd(data.performance.data.performanceYTD);
                    setPeriod(data.performance.data.performance);
                    setGlobal(data.performance.data.performanceInceptionDate);
                    setYear(data.performance.data.performance1Y);
                    setIsLoadedRiskRate(true);
                });
            },
            error =>
            {
                portfolioDataWithErrorsStatuses();
            })
            .catch((() =>
            {
                portfolioDataWithErrorsStatuses();
            }))
            .finally(() =>
            {
                setIsLoadedFunds(true);
                isLoadedReporting.current = true;
                setIsLoadingReporting(false)
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setRiskRateValues = (risk: string) =>
    {
        if(risk === "Agresivo")
        {
            setRiskValue(risk);
            setNumericRisk(4);
            setNumericRate(4);
            setRateValue("Alta");
        }
        else if(risk === "Equilibrado")
        {
            setRiskValue(risk);
            setNumericRisk(3);
            setNumericRate(3);
            setRateValue("Media Alta");
        }
        else if(risk === "Moderado")
        {
            setRiskValue(risk);
            setNumericRisk(2);
            setNumericRate(2);
            setRateValue("Media Baja");
        }
        else if(risk === "Conservador")
        {
            setRiskValue(risk);
            setNumericRisk(1);
            setNumericRate(1);
            setRateValue("Baja");
        }
        else
        {
            setNumericRisk(0);
            setNumericRate(0);
            setRateValue("");
        }
    };
    const handleCard = (buttonPressed: number) =>
    {
        switch(buttonPressed)
        {
            case 0:
            {
                setPeriod(reportingData.performance1M);
                break;
            }
            case 1:
            {
                setPeriod(reportingData.performance3M);
                break;
            }
            case 2:
            {
                setPeriod(reportingData.performance6M);
                break;
            }
            case 3:
            {
                setPeriod(reportingData.performance1Y);
                break;
            }
            case 4:
            {
                setPeriod(reportingData.performanceYTD);
                break;
            }
            case 5:
            {
                setPeriod(reportingData.performanceInceptionDate);
                break;
            }
            default:
                setPeriod(reportingData.performance);
                break;
        }
    };
    const fetchFinametrixPortfolio = async (portfolioId: string) =>
    {
        if(isFinametrixPortfolioLoaded.current === false)
        {
            isFinametrixPortfolioLoaded.current = true;
            await FinametrixService.getPortfolio(portfolioId).then((finametrixData: any) =>
            {
                if(finametrixData === "Not Found")
                {
                    setChartSerie([]);
                    setSeries([]);
                    setReporting(initialReporting);
                    portfolioDataWithErrorsStatuses();
                }
                else
                {
                    setFinametrixPortfolio(finametrixData);
                }
            },
            error =>
            {
                setChartSerie([]);
                setSeries([]);
                setReporting(initialReporting);
                portfolioDataWithErrorsStatuses();
            })
            .catch(() =>
            {
                setChartSerie([]);
                setSeries([]);
                setReporting(initialReporting);
                portfolioDataWithErrorsStatuses();
            });
        }
    };
    useEffect(() =>
    {
        if(activePlan?.id !== undefined)
        {
            notifyChangedActivePlan(activePlan);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan.id]);
    useEffect(() =>
    {
        if(activePlan?.portfolio?.finametrixId !== undefined)
        {
            fetchFinametrixPortfolio(activePlan.portfolio.finametrixId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan]);
    useEffect(() =>
    {
        if(isLoadedReporting.current)
        {
            if(finametrixPortfolio && finametrixPortfolio !== undefined && finametrixPortfolio.creationDate !== "" && finametrixPortfolio.creationDate !== undefined && activePlan?.portfolio?.finametrixId !== undefined)
            {
                let lowestDate = getLowestSelectedPlanCreationDate();
                if(lowestDate && lowestDate instanceof Date && !isNaN(lowestDate.valueOf()))
                {
                    setChartStartDate(lowestDate.toISOString().slice(0, 10));
                }
                else
                {
                    if(finametrixPortfolio?.creationDate && finametrixPortfolio?.creationDate.length > 0)
                    {
                        setChartStartDate(finametrixPortfolio.creationDate.substring(0, 10));
                    }
                    else
                    {
                        var yearToDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
                        setChartStartDate(yearToDate.toISOString().slice(0, 10));
                    }
                }

                setSelectedFinametrixContracts([activePlan.finametrixContract]);
                fetchFinametrixReporting(activePlan?.portfolio?.finametrixId, finametrixPortfolio.creationDate.substring(0, 10).replace(/-/g, ""), date_to_text);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finametrixPortfolio, date_to_text, activePlan?.portfolio?.finametrixId, isLoadedReporting, fetchFinametrixReporting, activePlan.finametrixContract]);
    const portfolioDataWithErrorsStatuses = () =>
    {
        setError(error);
        isLoadedReporting.current = true;
        setIsLoadedSeries(true);
        setIsLoadedFunds(true);
        setIsLoadedRiskRate(true);
        setLoadingChange(false);
    }
    useEffect(() =>
    {
        if(selectedBenchmarks.length > 0)
        {
            setLoadingChange(true);
            FundDetails.getSeries(selectedBenchmarks.map(b => b.value)).then((prices: any) =>
            {
                var aux = selectedBenchmarks.map((benchmark: any) =>
                {
                    var filteredPrices = Object.entries(prices[benchmark.value]).filter((serie: [Moment.MomentInput, any]) =>
                    {
                        return Moment(serie[0]) >= Moment(chartStartDate);
                    });
                    
                    return{
                        name: benchmark.label,
                        value: benchmark.value,
                        data: filteredPrices.map((serie: [Moment.MomentInput, any]) =>
                        {
                            var date = Moment(serie[0]);
                            return [date.valueOf(), serie[1]];
                        }),
                        showInLegend: true,
                        tooltip:
                        {
                            valueDecimals: 2
                        }
                    };
                });

                setFilteredBenchmarks(aux.map(function(p: any){return p.name}));
                setSeries(prevState => [...portfolioSeries, ...aux]);
                setLoadingChange(false);
            },
            error =>
            {
                setError(error);
            });
        }
        else if(selectedBenchmarks.length === 0)
        {
            setSeries(prevState => [...portfolioSeries]);
        }
    }, [selectedBenchmarks, benchmarks, portfolioSeries, chartStartDate]);
    useEffect(() =>
    {
        async function setReportingData()
        {
            if(reporting.positions && reporting.positions.positionsTo.positions.length > 0)
            {
                var isins = reporting.positions.positionsTo.positions.filter((pos : any) => pos.isin !== null).map((position: any, index: number, items: any) =>
                {
                    return position.isin;
                });
                
                if(isins.length === 0)
                {
                    setFunds([]);
                }
                else
                {
                    var result: IInfoFundPortfolio[] = await FundService.getInfoFundsPortfolio(isins);
                    setFunds(result);
                }
                
                setIsLoadedFunds(true);
            }
            if(reporting.performance && reporting.performance.serie[0].dateAsString !== "")
            {
                setIsLoadedSeries(false);
                var chartSerie: any = [];
                var chartMoneySerie: any = [];
                reporting.performance.serie.forEach((serie: {dateAsString: Moment.MomentInput; accReturn: any, amount?: any}) =>
                {
                    var offset = Moment().utcOffset();
                    let date = Moment(serie.dateAsString).add(offset, "minutes").locale("es");
                    chartSerie.push([date.valueOf(), serie.accReturn * 100]);
                    chartMoneySerie.push([date.valueOf(), serie.amount]);
                });

                setPortfolioSeries(
                [
                    {
                        name: "Cartera Rentabilidad",
                        data: chartSerie,
                        showInLegend: true,
                        tooltip:
                        {
                            valueDecimals: 2
                        }
                    }
                ]);

                setSeries(
                [
                    {
                        name: "Cartera",
                        data: chartSerie,
                        showInLegend: true,
                        tooltip:
                        {
                            valueDecimals: 2
                        }
                    }
                ]);

                setMoneySeries(
                [
                    {
                        name: "Cartera en Euros",
                        id: 'portfolio',
                        data: chartMoneySerie,
                        showInLegend: true,
                        tooltip:
                        {
                            valueDecimals: 2
                        }
                    },
                    {
                        name: 'Operaciones Realizadas',
                        type: 'flags',
                        showInLegend: true,
                        onSeries: 'portfolio',
                        style:
                        {
                            color: 'white',
                            fontFamily: 'Roboto, monospace',
                            fontSize: '10px'
                        },
                        states:
                        {
                            hover:
                            {
                                fillColor: '#CC214F',
                                lineColor: 'white'
                            }
                        },
                        width: 10,
                        height: 10,
                        turboThresold: dataFlag.length,
                        accesibility:
                        {
                            exposeAsGroupOnly: true
                        },
                        data: dataFlag,
                        tooltip:
                        {
                            pointFormatter: function (this: Highcharts.TooltipFormatterContextObject)
                            {
                                const point = this as any;
                                let message: string = "";
                                point.operations.map((operation: any) =>
                                {
                                    return message += '<span style="font-size: 14px">'+operation.fundName+'</span><br/>'+
                                                        '<p style="margin: 0; font-size: 12px">'+operation.text+'</p><br/>'+
                                                        '<p style="margin: 0; font-size: 12px">'+operation.isin+' - Importe: '+
                                                        decimalFormatter.format(operation.amount)+" "+operation.currency+
                                                        ' - Participaciones: '+decimalFormatter.format(operation.titles)+'</p><br/>'+
                                                        'Contrato: '+operation.contract+'<br/>';
                                });

                                let tooltip: string = message;

                                return tooltip;
                            }
                        }
                    }
                ]);

                setIsLoadedSeries(true);
            }
            if(reporting.positions && reporting.positions.positionsTo.positions.length > 0)
            {
                var aux: any = [];
                reporting.positions.positionsTo.aggregation.forEach((serie: any) =>
                {
                    aux.push(
                    {
                        name: serie.name,
                        value: isNaN(serie.total.weight) ? 0 : Number((parseFloat(serie.total.weight) * 100).toFixed(2)),
                        color: serie.color,
                        level2: []
                    });

                    if(serie.level2.length === 0)
                    {
                        aux[aux.length - 1]["level2"].push(
                        {
                            name: serie.name,
                            value: aux[aux.length - 1].value
                        });
                    }
                    else
                    {
                        serie.level2.forEach((level: any) =>
                        {
                            var value = isNaN(level.total.weight) ? 0 : Number((parseFloat(level.total.weight) * 100).toFixed(2));
                            aux[aux.length - 1]["level2"].push(
                            {
                                name: level.name,
                                value: value
                            });
                        });
                    }
                });
                setChartSerie(aux);
            }
            if(reporting.positions.positionsTo.positions.length === 0)
            {
                setHasFunds(false);
            }
            else
            {
                setHasFunds(true);
            }
        }
        setReportingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reporting]);
    const toggleMoneyChange = (ev: any, checked?: boolean) =>
    {
        if(checked !== undefined)
        {
            setMoneyGraphic(checked);
        }
    }
    const addManagedPortfolioToCart = async () =>
    {
        FinametrixService.getModelPortfolio(activePlan.portfolio.managedPortfolioFinametrixId).then((modelPortfolio: any) =>
        {
            var lastComposition = modelPortfolio.compositions[modelPortfolio.compositions.length - 1];
            var ids = lastComposition.items.map((instrument: any) => instrument.instrumentId);
            FundService.getMultipleFundsByIds(ids).then((items: any) =>
            {
                lastComposition.items.forEach(async (compositionItem: any) =>
                {
                    var selectedItem = items.find((item: any) => item.finametrixId === compositionItem.instrumentId);
                    dispatch(addLine(
                    {
                        fund: selectedItem,
                        amount: ((puntualContribution || 0) * compositionItem.weight) / 100,
                        readOnly: true,
                        source: "rebalancing"
                    }));
                });
                return history.push("/cart");
            },
            (error: any) =>
            {
                setError(error);
                setIsSendingToCart(false);
            });
        });
    }
    const addBmePortfolioToCart = async () =>
    {
        var lastComposition = activePlan.portfolio.bmePortfolio.distribution;
        var allfundsIds = lastComposition.map((item: any) => item.id);

        FundService.getMultipleFundsByAllfundsIds(allfundsIds).then((items: any) =>
        {
            lastComposition.forEach(async (compositionItem: any) =>
            {
                var selectedItem = items.find((item: any) => parseInt(item.allfundsId) === parseInt(compositionItem.id));
                dispatch(addLine(
                {
                    fund: selectedItem,
                    amount: (puntualContribution || 0) * compositionItem.weight,
                    readOnly: true,
                    source: "proposal"
                }));
            });
            return history.push("/cart");
        },
        (error: any) =>
        {
            setError(error);
            setIsSendingToCart(false);
        });
    }
    const addFundsToCart = async () =>
    {
        setIsSendingToCart(true);
        dispatch(clearCart());

        switch(activePlan.type)
        {
            case 0:
                if(activePlan.portfolio.managedPortfolioFinametrixId !== undefined && activePlan.portfolio.managedPortfolioFinametrixId !== null)
                {
                    addManagedPortfolioToCart();
                }
                break;

            case 1:
                if (activePlan.portfolio.managedPortfolioFinametrixId !== undefined && activePlan.portfolio.managedPortfolioFinametrixId !== null)
                {
                    addManagedPortfolioToCart();
                }
                else if(activePlan.portfolio.bmePortfolio !== undefined && activePlan.portfolio.bmePortfolio !== null)
                {
                    addBmePortfolioToCart();
                }
                break;

            default:
                setError(
                {
                    status: true,
                    message: "Este plan de ahorro no admite aportaciones puntuales"
                });
                setIsSendingToCart(false);
        }
    };
    const updateSelectedActivePlanKeys = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void =>
    {
        if(item)
        {
            setAllActivePlanToAgregate(prevState =>
            {
                const newState = prevState.map(obj =>
                {
                    if(obj.id === item.key.toString()!)
                    {
                        return { ...obj, isSelected: item.selected ? item.selected : false };
                    }
                    return obj;
                });

                return newState;
            });
            if(item.selected)
            {
                setSelectedActivePlansIds(state => [...state, Number(item.id)!]);
            }
            else
            {
                let newState = selectedActivePlansIds.filter(x => x !== Number(item.id)!);
                setSelectedActivePlansIds(newState);
            }
        }
    };
    const handleRenderTitle = (items: IDropdownOption[] | undefined): ReactElement<any, any> =>
    {
        if(items)
        {
            if(items.length === 1)
            {
                return <React.Fragment>{`${items[0].text}`}</React.Fragment>;
            }
            return <React.Fragment>{"Cartera resumen"}</React.Fragment>;
        }
        return <React.Fragment></React.Fragment>;
    };
    const getDateAsString = (date: Date) =>
    {
        return date.getFullYear().toString() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
    };
    const getLowestSelectedPlanCreationDate = () =>
    {
        //get lowest date_from from selected plans to be agregated
        var date = new Date(Math.min(...allActivePlanToAgregate.filter(x => x.isSelected === true).map(element =>
        {
            return new Date(element.createdAt).getTime();
        })));

        return date;
    };
    const notifyChangedActivePlan = async (activePlan: any) =>
    {
        await dispatch(setActiveSavingsPlan({ ...activePlan, sync: true }));
    }
    const updateDataOnClickHandler = async () =>
    {
        setSelectedFinametrixContracts([]);
        let selectedActivePlans = allActivePlanToAgregate.filter(x => x.isSelected === true);
        let lowestDate_from = getLowestSelectedPlanCreationDate();
        setChartStartDate(lowestDate_from.toISOString().slice(0, 10));

        if(selectedActivePlans.length === 1)
        {
            let id = selectedActivePlans[0].id;
            if(id !== activePlan.id)
            {
                setLoadingChange(true);
                UserService.selectActivePlan(user.id, Number(id)).then((response: boolean) =>
                {
                    if(response === true)
                    {
                        UserService.getUserLastActivePlan(user.id).then((element: any) =>
                        {
                            Auth.setActivePlan(element);
                            notifyChangedActivePlan(element);
                            activePlan = Auth.getActivePlan();
                            setFinametrixPortfolio((prevState: any) =>
                            ({
                                ...prevState,
                                creationDate: ""
                            }));
                            setSelectedFinametrixContracts([activePlan.finametrixContract]);
                            isFinametrixPortfolioLoaded.current = false;
                            isLoadedModelPortfolioComposition.current = false;
                            setChartSerie([]);
                            setIsLoadedBenchmarks(false);
                            setIsLoadedRiskRate(false);

                            FinametrixService.getOperations(selectedActivePlans[0].finametrixId).then((ops: any) =>
                            {
                                let opArray: any[] = [];
                                let dateFlag: any[] = [];
            
                                ops.forEach((op: any) =>
                                {
                                    let description: string = op.cashIn ? op.description : op.title;
                                    opArray.push(
                                    {
                                        id: op.id,
                                        operationAt: moment(op.operationAt).format('YYYY-MM-DD'),
                                        settlementDate: moment(op.settlementDate).format('YYYY-MM-DD'),
                                        operationInstrument:
                                        {
                                            isin: op.operationInstrument.isin,
                                            name: op.operationInstrument.name
                                        },
                                        description: description,
                                        amount: op.amount,
                                        nav: op.nav,
                                        exchangeRate: op.exchangeRate === 1 ? op.exchangeRate : decimalFormatter.format(op.exchangeRate),
                                        titles: op.titles,
                                        currency: op.currency
                                    });
                                });
            
                                opArray.forEach((op: any) =>
                                {
                                    let offset = Moment().utcOffset();
                                    let date = Moment(op.operationAt).add(offset, "minutes").locale("es");
                                    let dateString = new Date(op.operationAt).toLocaleDateString();
                                    let isin = op.operationInstrument.isin;
                                    let name = op.operationInstrument.name;
                                    let color: string = '#4284E6';
            
                                    if((dateFlag.findIndex((i: any) => i.date === dateString) !== -1))
                                    {
                                        let idx = dateFlag.findIndex((i: any) => i.date === dateString);
            
                                        if(!(dateFlag[idx].operations.findIndex((j: any) => j.isin === isin) !== -1 && dateFlag[idx].operations.findIndex((k: any) => k.operationId === op.operationId) !== -1))
                                        {
                                            dateFlag[idx].operations.push(
                                            {
                                                text: op.description,
                                                fundName: name,
                                                amount: op.amount,
                                                isin: isin,
                                                titles: op.titles,
                                                currency: op.currency,
                                                operationId: op.id,
                                                contract: selectedActivePlans[0].finametrixContract
                                            });
                                        }
                                    }
                                    else
                                    {
                                        dateFlag.push(
                                        {
                                            x: date.valueOf(),
                                            date: dateString,
                                            title: 'i',
                                            operations:
                                            [
                                                {
                                                    text: op.description,
                                                    fundName: name,
                                                    isin: isin,
                                                    amount: op.amount,
                                                    titles: op.titles,
                                                    currency: op.currency,
                                                    operationId: op.id,
                                                    contract: selectedActivePlans[0].finametrixContract
                                                }
                                            ],
                                            lineColor: color,
                                            color: color,
                                            fillColor: color,
                                            stroke: '#000',
                                            shape: 'circlepin'
                                        });
                                    }
                                });
            
                                setDataFlag(dateFlag);
                            });
                        })
                        .finally(() =>
                        {
                            setIsLoadedFunds(false);
                            setIsLoadedSeries(false);
                            setLoadingChange(false);
                        });
                    }
                });
            }
        }
        else if(selectedActivePlans.length > 1)
        {
            setLoadingChange(true);

            let finametrixIds = selectedActivePlans.map(element =>
            {
                return element.finametrixId;
            });

            let finametrixContracts = selectedActivePlans.map(element =>
            {
                return element.finametrixContract;
            });

            setSelectedFinametrixContracts(finametrixContracts);

            isLoadedReporting.current = false
            //getting aggregated report
            await FinametrixService.getReporting(finametrixIds.join(","), getDateAsString(lowestDate_from), date_to_text).then((data: any) =>
            {
                setReporting(data);
                setReportingData(data.performance.data);
                setRiskRateValues(data.profileRisk.name);
                setYtd(data.performance.data.performanceYTD);
                setPeriod(data.performance.data.performance);
                setGlobal(data.performance.data.performanceInceptionDate);
                setYear(data.performance.data.performance1Y);
                setIsLoadedRiskRate(true);
            },
            (error: React.SetStateAction<{status: boolean; message: string}>) =>
            {
                setError(error);
            })
            .finally(() =>
            {
                isLoadedReporting.current = true;
            });

            let allSelectedPlanSeries: any[] = [];

            //getting chart serie foreach plan
            selectedActivePlans.forEach(async plan => await FinametrixService.getReporting(plan.finametrixId, getDateAsString(new Date(plan.createdAt)), date_to_text).then((data: any) =>
            {
                var chartSerie: any = [];
                data.performance.serie.forEach((serie: {dateAsString: Moment.MomentInput; accReturn: any}) =>
                {
                    var offset = Moment().utcOffset();
                    let date = Moment(serie.dateAsString).add(offset, "minutes").locale("es");
                    chartSerie.push([date.valueOf(), serie.accReturn * 100]);
                });

                let serie =
                {
                    name: plan.finametrixContract,
                    data: chartSerie,
                    showInLegend: true,
                    tooltip:
                    {
                        valueDecimals: 2
                    }
                };

                if(plan.finametrixId !== activePlan.portfolio.finametrixId)
                {
                    FinametrixService.getOperations(plan.finametrixId).then((ops: any) =>
                    {
                        let opArray: any[] = [];
                        let dateFlag: any[] = [];
    
                        ops.forEach((op: any) =>
                        {
                            let description: string = op.cashIn ? op.description : op.title;
                            opArray.push(
                            {
                                id: op.id,
                                operationAt: moment(op.operationAt).format('YYYY-MM-DD'),
                                settlementDate: moment(op.settlementDate).format('YYYY-MM-DD'),
                                operationInstrument:
                                {
                                    isin: op.operationInstrument.isin,
                                    name: op.operationInstrument.name
                                },
                                description: description,
                                amount: op.amount,
                                nav: op.nav,
                                exchangeRate: op.exchangeRate === 1 ? op.exchangeRate : decimalFormatter.format(op.exchangeRate),
                                titles: op.titles,
                                currency: op.currency
                            });
                        });
    
                        opArray.forEach((op: any) =>
                        {
                            let offset = Moment().utcOffset();
                            let date = Moment(op.operationAt).add(offset, "minutes").locale("es");
                            let dateString = new Date(op.operationAt).toLocaleDateString();
                            let isin = op.operationInstrument.isin;
                            let name = op.operationInstrument.name;
                            let color: string = '#4284E6';
    
                            if((dateFlag.findIndex((i: any) => i.date === dateString) !== -1))
                            {
                                let idx = dateFlag.findIndex((i: any) => i.date === dateString);
    
                                if(!(dateFlag[idx].operations.findIndex((j: any) => j.isin === isin) !== -1 && dateFlag[idx].operations.findIndex((k: any) => k.operationId === op.operationId) !== -1))
                                {
                                    dateFlag[idx].operations.push(
                                    {
                                        text: op.description,
                                        fundName: name,
                                        amount: op.amount,
                                        isin: isin,
                                        titles: op.titles,
                                        currency: op.currency,
                                        operationId: op.id,
                                        contract: plan.finametrixContract
                                    });
                                }
                            }
                            else
                            {
                                dateFlag.push(
                                {
                                    x: date.valueOf(),
                                    date: dateString,
                                    title: 'i',
                                    operations:
                                    [
                                        {
                                            text: op.description,
                                            fundName: name,
                                            isin: isin,
                                            amount: op.amount,
                                            titles: op.titles,
                                            currency: op.currency,
                                            operationId: op.id,
                                            contract: plan.finametrixContract
                                        }
                                    ],
                                    lineColor: color,
                                    color: color,
                                    fillColor: color,
                                    stroke: '#000',
                                    shape: 'circlepin'
                                });
                            }
                        });
    
                        setDataFlag([...dataFlag, ...dateFlag]);
                    });
                }
                allSelectedPlanSeries.push(serie);

                if(allSelectedPlanSeries.length === selectedActivePlans.length)
                {
                    setSeries(prevState => [...prevState, ...allSelectedPlanSeries]);
                    setPortfolioSeries(prevState => [...prevState, ...allSelectedPlanSeries]);
                    setLoadingChange(false);
                }
            },
            (error: React.SetStateAction<{ status: boolean; message: string }>) =>
            {
                setError(error);
            }));
        }
        else
        {
            console.log("Ha ocurrido un error, no existe ningún plan activo");
        }
    };
    const updateModelFunds = (items: any[]) =>
    {
        if(items)
        {
            setModelFunds(items);
        }
        return items;
    }
    const optimizer = () =>
    {
        history.push("/optimizer", {funds: modelFunds});
    }
    const addPortfolioDataToCart = () =>
    {
        setIsSendingToCart(true);
        dispatch(clearCart());
        let modelPercentageTotal = 0;
        let readOnlyCart: boolean = (activePlan.type === 0 || activePlan === 1);
        modelFunds.sort((a, b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0));
        modelFunds.forEach(function(fund, idx, array)
        {
            if(fund.vLiquid !== null && fund.exchangeRate !== null && fund.documents.length > 0 && fund.available !== undefined && fund.available === true)
            {
                let value = Number(fund.weight.replaceAll(" ", "").replaceAll("%", "").replaceAll(",", "."));
				modelPercentageTotal += value;
				if(idx === array.length - 1)
                {
					if(modelPercentageTotal < 100)
                    {        
						let toSum = Number((100 - modelPercentageTotal).toFixed(2));
						value += toSum;
						value = Number(value.toFixed(2));
						modelPercentageTotal += toSum;
					}
                    else if(modelPercentageTotal > 100)
                    {
						let toDeduct = Number((modelPercentageTotal - 100).toFixed(2)); 
						value -= toDeduct;
						value = Number(value.toFixed(2));
						modelPercentageTotal -= toDeduct;
					}
				}
                dispatch(addLine(
                {
                    fund: fund,
                    amount: 0,
                    modelPercentage: value,
                    readOnly: readOnlyCart,
                    savingsPlanId: activePlan.id
                }));
            }
        });
        let roundedTotalValue = Math.round(Number(modelPercentageTotal) * 1000) / 1000;
        dispatch(updateModelPercentageTotal(roundedTotalValue));
        dispatch(cartState(CartModes.CREATE_MODEL_PORTFOLIO));
        dispatch(updateModelPortfolioName(""));
        setIsSendingToCart(false);
        history.push({pathname: "/cart"});
    };
    const checkPortfolioModelLogic = () =>
    {
        showWarningModal();
        portfolioModelWallets.savingPlanHasPortfolioModel(activePlan.id).then((response: PortfolioModelAssociated) =>
        {
            if(response.portfolioModelCount > 0)
            {
                //Tiene PortfolioModel
                history.push({pathname: "/ModelPortfolio/PeriodicContributions/"+response.portfolioModelData[0].portfolioModelId, state: {portfolioModelId: response.portfolioModelData[0].portfolioModelId, portfolioModelName: response.portfolioModelData[0].portfolioModelName, contract: selectedFinametrixContracts.length > 0 ? selectedFinametrixContracts[0] : ""}});
            }
            else
            {
                //No tiene PortfolioModel. Hay que crear uno.
                var modelPortfoliosTxt = localStorage.getItem("modelPortfolios");
                if(modelPortfoliosTxt !== null)
                {
                    var auxModelPortfolios = JSON.parse(modelPortfoliosTxt);

                    if(auxModelPortfolios.length > 5)
                    {
                        auxModelPortfolios = auxModelPortfolios.filter((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.showOnCover);
                    }

                    suitabilityService.getBySavingsPlanId(activePlan.id).then(async (response: ISuitabilityTest[]) =>
                    {
                        if(response !== undefined && response.length > 0)
                        {
                            var risk: string = response[0].results?.results.profile as string;

                            var filtered = auxModelPortfolios.filter((mp: CompleteManagedPortfolioType) => mp.managedPortfolio.riskProfile === risk)[0];

                            var cartLines: DataAndCartLines[] = await convertToCartLines(filtered);

                            PortfolioModelWalletService.saveModelWallet(activePlan.id, "Aportación Periódica de "+activePlan.name, 0, cartLines).then((response: ModelWallet) =>
                            {
                                hideWarningModal();
                                history.push({pathname: "/ModelPortfolio/PeriodicContributions/"+response.id, state: {portfolioModelId: response.id, portfolioModelName: response.name, contract: selectedFinametrixContracts.length > 0 ? selectedFinametrixContracts[0] : ""}});
                            });
                        }
                    });
                }
                else
                {
                    //Habría que rellamar al ManagedPortfolio de SignalR.
                }
            }
        });
    }
    const convertToCartLines = async (modelPortfolio: CompleteManagedPortfolioType) =>
    {
        var result: DataAndCartLines[] = [];
        var isins: any[] = [];

        if(modelPortfolio.composition !== undefined)
        {
            for(let i=0; i<modelPortfolio.composition.length; i++)
            {
                isins.push(modelPortfolio.composition[i].isin);
            }

            var allFundsResult: any[] = await FundService.getInfoFundsPortfolio(isins);

            for(let v=0; v<allFundsResult.length; v++)
            {
                result.push(
                {
                    fund:
                    {
                        allFundsId: allFundsResult[v].allFundsId,
                        benchmarkFinametrixId: allFundsResult[v].benchmarkFinametrixId.toString(),
                        bought: "No Aplica",
                        valuation: "No Aplica",
                        revaluation: "No Aplica",
                        revaluationPercentage: "No Aplica",
                        weight: modelPortfolio.composition[v].weight.toString(),
                        vLiquid: "No Aplica",
                        exchangeRate: 1,
                        liquidDate: "No Aplica",
                        managementFee: allFundsResult[v].managementFee as number,
                        noMin: allFundsResult[v].noMin as boolean,
                        minimumInvestment: allFundsResult[v].minimumInitialInvestment as number,
                        ongoingCharges: allFundsResult[v].ongoingCharges as number,
                        rebate: allFundsResult[v].rebate as number,
                        complexity: allFundsResult[v].complexity as boolean,
                        finametrixId: allFundsResult[v].finametrixId as string,
                        instrumentId: modelPortfolio.composition[v].instrumentId,
                        isin: allFundsResult[v].isin,
                        name: modelPortfolio.composition[v].name as string,
                        titles: 0
                    },
                    modelPercentage: modelPortfolio.composition[v].weight,
                    readOnly: true,
                    savingPlanId: activePlan.id
                });
            }
        }

        return result;
    }
    const noFundsInPortfolio = async() =>
    {
        showModal();
    }
    const dropdownStyles: Partial<IDropdownStyles> =
    {
        caretDown:
        {
            color: "#cc214F",
            fontSize: "14px !important",
            fontWeight: 700
        },
        dropdownItems:
        {
            color: "#cc214F !important",
            borderColor: "transparent !important",
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "5px"
        },
        title:
        {
            color: "#cc214F !important",
            borderColor: "transparent !important",
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "5px"
        },
        dropdownItemSelected:
        {
            color: "rgb(204, 33, 79) !important",
            backgroundColor: "white"
        },
        dropdownOptionText: { color: "rgb(204, 33, 79) !important" }
    };
    const [tooltipRateShown, setTooltipRateShown] = useState(false);
    const rateTooltip = "Time-Weighted Rate of Return es el estándar de medición recomendado por las normas GIPS, ya que permite comparar los resultados de la gestión al eliminar el efecto de las aportaciones y reintegros de efectivo en el periodo de cálculo. La rentabilidad es calculada diariamente y compuesta según:\n (1+r1)x(1+r2)x(1+r3) x … x (1+ rn) = (1+r).\n Donde r son las rentabilidades diarias";
    const getDataToExport = () =>
    {
        if(reporting.positions.positionsTo.positions === undefined)
        {
            return [];
        }

        const positions = reporting.positions.positionsTo.positions;
        const rows = [];
        for(let i = 0; i < positions.length; i++)
        {
            var position: IPositionType = positions[i];
            if(position !== null)
            {
                var row: any = [];
                if(position.instrumentType !== undefined && position.instrumentType === 21)
                {
                    row =
                    [
                        position.productName,
                        position.isin,
                        parseFloat(position.cost.toFixed(4)),
                        position.titles,
                        parseFloat(position.value.toFixed(4)),
                        new Date(position.dateLastPrice).toLocaleDateString(),
                        parseFloat(position.plusMinus.toFixed(4)),
                        parseFloat((position.weight * 100).toFixed(4)),
                        parseFloat(position.lastPrice.toFixed(4)),
                        position.exchangeRate.from,
                        position.exchangeRate.value !== undefined ? parseFloat(position.exchangeRate.value.toFixed(4)) : 1
                    ];
                }
                else
                {
                    row =
                    [
                        position.productName,
                        "Operación Pendiente",
                        parseFloat(position.cost.toFixed(4)),
                        position.titles,
                        parseFloat(position.value.toFixed(4)),
                        new Date(position.date).toLocaleDateString(),
                        parseFloat(position.plusMinus.toFixed(4)),
                        parseFloat((position.weight * 100).toFixed(4)),
                        "Sin datos",
                        "Sin datos",
                        "Sin datos"
                    ];
                };

                rows.push(row);
            }
        }

        return rows;
    };
    const editPortfolio = () =>
    {
        appInsights.trackEvent({name: "Editar Plan Ahorro Cartera"});
        if(selectedActivePlansIds.length === 1 && options !== undefined)
        {
            let selectedActivePlan = options.find(o => o.selected);
            if(selectedActivePlan !== undefined)
            {
                history.push("/configurationMap",
                {
                    savingPlanId: selectedActivePlan.id,
                    savingPlanName: selectedActivePlan.text
                });
            }
        }
        else
        {
            history.push("/savingsPlans");
        }
    }
    if(error.status)
    {
        return(
            <DocumentTitle title="IronIA - Resumen de cartera">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }
    if(subscribed === false)
    {
        localStorage.clear();
        window.location.href = config.server_host + "/login";
        return <></>;
    }
    if(outdated === true || cancelled === true)
    {
        return <Redirect to="/savingsPlans" />;
    }
    return(
        <DocumentTitle title="IronIA - Resumen de cartera">
            <React.Fragment>
                {activePlan !== undefined &&
                (
                    <div className="ms-Grid-row header">
                        <h2 className="ms-Grid-col ms-sm10 ms-md10">
                            Resumen de cartera
                        </h2>
                        {options !== undefined &&
                        (
                            <React.Fragment>
                                {options.length > 0 &&
                                (
                                    <Dropdown placeholder={activePlan.name}
                                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ms-xxl5 ms-xxxl6"
                                        options={options as IDropdownOption[]}
                                        selectedKeys={selectedActivePlansIds}
                                        onRenderTitle={handleRenderTitle}
                                        onChange={(event, item) => updateSelectedActivePlanKeys(event, item)}
                                        styles={dropdownStyles}
                                        disabled={isLoadingChange}
                                        multiSelect
                                    />
                                )}
                            </React.Fragment>
                        )}
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl4 ms-xxxl4">
                            <PortfolioActions isLoadingChange={isLoadingChange}
                                isLoadedSeries={isLoadedSeries}
                                isLoadedFunds={isLoadedFunds}
                                options={options}
                                isElementsSubscribed={userElementsStatus.isSubscribed}
                                updateDataOnClickHandler={updateDataOnClickHandler}
                                addPortfolioDataToCart={addPortfolioDataToCart}
                                editPortfolio={editPortfolio}
                                checkPortfolioModelLogic={checkPortfolioModelLogic}
                                noFundsInPortfolio={noFundsInPortfolio}
                                hasFunds={hasFunds}
                                type={activePlan.type}
                            />
                        </div>
                        {activePlan.portfolio !== null && (activePlan.type === 0 || activePlan.type === 1) &&
                        (
                            <React.Fragment>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2 text-right">
                                    <div className="number-control big-font">
                                        <NumberFormat thousandSeparator="."
                                            decimalSeparator=","
                                            value={puntualContribution}
                                            onValueChange={debounce((value: any) =>
                                            {
                                                setPuntualContribution(value.floatValue);
                                            }, 500)}
                                            suffix="€"
                                            className="ms-TextField-field"
                                        />
                                    </div>
                                    <DefaultButton onClick={() => addFundsToCart()} className="button tiny-stretch-button" disabled={isSendingToCart}>
                                        {isSendingToCart && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                        Aportar
                                    </DefaultButton>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}
                {activePlan === undefined &&
                (
                    <h2>Resumen de cartera</h2>
                )}
                <Separator />
                <CardsSection reporting={reporting}
                    finametrixContracts={selectedFinametrixContracts}
                    finametrixPortfolio={finametrixPortfolio}
                    global={percentFormatter.format(global)}
                    period={percentFormatter.format(period)}
                    ytd={percentFormatter.format(ytd)}
                    year={percentFormatter.format(year)}
                    isElementsSubscribed={userElementsStatus.isSubscribed}
                    theme={theme}
                />
                <RowFirstBlock className="ms-Grid-row">
                    <ColFirstGraphic className="ms-Grid-col ms-sm12 ms-lg12 ms-xl12 ms-xxl9">
                        <FirstShadow className="shadow">
                            <RowGraphicSelector className="ms-Grid-row">
                                {moneyGraphic === true &&
                                (
                                    <React.Fragment>
                                        <ColMoneyGraphic className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl8 ms-xxl9 ms-xxxl9">
                                            <TitleMoneyGraphic>Evolución de tu cartera en Euros</TitleMoneyGraphic>
                                        </ColMoneyGraphic>
                                    </React.Fragment>
                                )}
                                {!moneyGraphic &&
                                (
                                    <React.Fragment>
                                        <ColLinearGraphic className="ms-Grid-col ms-sm8 ms-md8 ms-lg5 ms-xl4 ms-xxl3 ms-xxxl3">
                                            <TitleLinearGraphic>
                                                Rentabilidad de tu cartera (TWR)
                                            </TitleLinearGraphic>
                                        </ColLinearGraphic>
                                        {theme === "light" &&
                                        (
                                            <ColBlackInfo className="ms-Grid-col ms-sm4 ms-md4 ms-lg1 ms-xl4 ms-xxl6 ms-xxxl6">
                                                <img src="/icons/informationBlack.svg" alt="Información" id="titleRate" onMouseEnter={() => setTooltipRateShown(true)} onMouseLeave={() => setTooltipRateShown(false)} />
                                            </ColBlackInfo>
                                        )}
                                        {theme === "dark" &&
                                        (
                                            <ColWhiteInfo className="ms-Grid-col ms-sm4 ms-md4 ms-lg1 ms-xl4 ms-xxl6 ms-xxxl6">
                                                <img src="/icons/informationWhite.svg" alt="Información" id="titleRate" onMouseEnter={() => setTooltipRateShown(true)} onMouseLeave={() => setTooltipRateShown(false)} />
                                            </ColWhiteInfo>
                                        )}
                                        {tooltipRateShown === true &&
                                        (
                                            <TeachingBubble target="#titleRate" hasCloseButton={false} headline="Rentabilidad TWR" onDismiss={() => setTooltipRateShown(tooltipRateShown)}>
                                                {rateTooltip}
                                            </TeachingBubble>
                                        )}
                                    </React.Fragment>
                                )}
                                <ColLeftToggleTitle className="ms-Grid-col ms-sm7 ms-md5 ms-lg2 ms-xl2 ms-xxl1 ms-xxxl1">
                                    <ToggleLabel>Rentabilidad</ToggleLabel>
                                </ColLeftToggleTitle>
                                <ColToggle className="ms-Grid-col ms-sm3 ms-md4 ms-lg2 ms-xl1 ms-xxl1 ms-xxxl1">
                                    <MoneyToggle theme={themeContext} defaultChecked={moneyGraphic} onChange={toggleMoneyChange} />
                                </ColToggle>
                                <ColRightToggleTitle className="ms-Grid-col ms-sm2 ms-md3 ms-lg2 ms-xl1 ms-xxl1 ms-xxxl1">
                                    <ToggleLabel>Euros</ToggleLabel>
                                </ColRightToggleTitle>
                            </RowGraphicSelector>
                            <GraphicContainer>
                                {isLoadedSeries && reporting?.hasError === true &&
                                (
                                    <React.Fragment>
                                        <p className="description">Lo sentimos, ha ocurrido un error inesperado, pongase en contacto con el soporte.</p>
                                    </React.Fragment>
                                )}
                                {(!isLoadedSeries || !isLoadedFunds) &&
                                (
                                    <React.Fragment>
                                        <Highstock customOptions={{}} series={{}} graphicType={GraphicType.linearGraphic} />
                                    </React.Fragment>
                                )}
                                {isLoadedSeries &&
                                (
                                    <React.Fragment>
                                        {moneyGraphic === true &&
                                        (
                                            <DivGraphic>
                                                <MoneyFlagHighStock series={moneySeries} customOptions={{}}
                                                    onChange={(value: any) =>
                                                    {
                                                        handleCard(value);
                                                    }}
                                                />
                                            </DivGraphic>
                                        )}
                                        {moneyGraphic === false &&
                                        (
                                            <DivGraphic>
                                                <Highstock series={series} customOptions={{}} graphicType={GraphicType.linearGraphic}
                                                    onChange={(value: any) =>
                                                    {
                                                        handleCard(value);
                                                    }}
                                                />
                                            </DivGraphic>
                                        )}
                                    </React.Fragment>
                                )}
                            </GraphicContainer>
                            {!moneyGraphic &&
                            (
                                <React.Fragment>
                                    <Separator />
                                    <RowComparer className="ms-Grid-row">
                                        <ColComparerTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                            <ComparerTitle>
                                                Comparar con índices y fondos
                                            </ComparerTitle>
                                        </ColComparerTitle>
                                        <ColComparerDescription className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                            <ComparerDescription>
                                                Selecciona tantos índices y fondos como quieras y compara su rentabilidad en el tiempo con tu cartera de inversiones.
                                            </ComparerDescription>
                                        </ColComparerDescription>
                                        <ColComparerInput className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-12 ms-xxl12 ms-xxxl12">
                                            <FilterContainer>
                                                <MultiFundsAndBenchmarksDropDown scatterGraphic={false} onSave={(selectedBenchmarks: OptionType[]) =>
                                                    {
                                                        setSelectedBenchmarks(selectedBenchmarks)
                                                    }}
                                                />
                                            </FilterContainer>
                                        </ColComparerInput>
                                    </RowComparer>
                                </React.Fragment>
                            )}
                        </FirstShadow>
                    </ColFirstGraphic>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6 ms-xxl3">
                        <div className="shadow">
                            <h3>Tipología de inversiones</h3>
                            {isLoadingReporting && <Spinner size={SpinnerSize.large} />}
                            {!isLoadingReporting && <PieHighstock chartSerie={chartSerie} showSubcategories={true} />}
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6 ms-xxl3">
                        <div className="shadowNoMargin" style={{maxHeight: "335px", minHeight: "335px"}}>
                            {!isLoadedRiskRate && <Spinner size={SpinnerSize.large} style={{marginTop: "7em"}} />}
                            {isLoadedRiskRate &&
                            (
                                <RiskRateComponent risk={risk} riskValue={riskValue} rate={rate} rateValue={rateValue} initials={user.initials} />
                            )}
                        </div>
                    </div>
                </RowFirstBlock>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <div className="shadow">
						<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl3 ms-xxl3">
									<h3>Detalles de tu cartera</h3>
								</div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl6" style={{ display: "inline-block" }}>
                                    {modelFunds && modelFunds.length > 0 && selectedActivePlansIds?.length === 1 && activePlan && [3, 4].includes(activePlan?.type) &&
                                    (
                                        <PortfolioBalancer activePlan={activePlan} />
                                    )}
								</div>
								<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl3 ms-xxl3" style={{ marginBottom: "10px", display: "flex", justifyContent: "end" }}>
									<ExportIndex headers={exportHeaders} getDataToExport={getDataToExport} name={activePlan.name} />
                                    {modelFunds && modelFunds.length > 0 && selectedActivePlansIds?.length === 1 && activePlan && [3, 4].includes(activePlan?.type) &&
                                    (
										<div className="optimizer">
											<InfoIcon id="tooltipOptimizer"
												onMouseEnter={() => setTooltipOptimizer(true)}
												onMouseLeave={() => setTooltipOptimizer(false)}
											/>
											<DefaultButton className="button mini-primary-button" onClick={() => optimizer()}>
												Optimizar
											</DefaultButton>
											{tooltipOptimizer === true &&
                                            (
												<TeachingBubble
													target="#tooltipOptimizer"
													hasCloseButton={false}
													headline=""
													onDismiss={() => setTooltipOptimizer(false)}
													calloutProps={{ directionalHint: DirectionalHint.rightCenter }}
												>
													<div>¿Quieres optimizar tu cartera?</div>
													<div>
														Ironia Fintech te ofrece el servicio de optimización de carteras, aplicando la metodología del calculo
														de la frontera eficiente del modelo de Markowitz, pulsa el botón optimizar para saber más.
													</div>
												</TeachingBubble>
											)}
										</div>
									)}
								</div>
							</div>
                            <div className="ms-Grid-row">
                                {isLoadingReporting && <Spinner size={SpinnerSize.large} />}
                                {!isLoadingReporting &&
                                (
                                    <PortfolioTable reporting={reporting} funds={funds} fundsforModelData={items => updateModelFunds(items)} isResumeWallet={selectedFinametrixContracts.length > 1} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isWarningModalOpen} onDismiss={hideWarningModal} isBlocking={true}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12" style={{paddingLeft: "1em", cursor: "default"}}>
                            <h3>Creando la Cartera Modelo asociada...</h3>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{marginTop: "1em"}}>
                        <div className="ms-Grid-col ms-sm12" style={{paddingLeft: "1em"}}>
                            <p style={{fontSize: "18px", cursor: "default"}}>
                                Estamos creando todo lo necesario para preparar tu aportación periódica. Se redirigirá automáticamente en cuanto finalice el proceso.
                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={true}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12" style={{paddingLeft: "1em", cursor: "default"}}>
                            <h3>Acción denegada</h3>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{marginTop: "1em"}}>
                        <div className="ms-Grid-col ms-sm12" style={{paddingLeft: "1em"}}>
                            <p style={{fontSize: "18px", cursor: "default"}}>
                                No puede realizar ninguna aportación periódica de esta cartera porque no tiene fondos.
                            </p>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{marginTop: "0.5em", textAlign: "end"}}>
                        <DefaultButton className="button mini-primary-button" onClick={hideModal}>
                            Aceptar
                        </DefaultButton>
                    </div>
                </Modal>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default Portfolio;