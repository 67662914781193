import React, { useContext } from "react";
import * as L from './Styles/ApiTransferStyles';
import DocumentTitle from "react-document-title";
import { ThemeContext } from '@fluentui/react-theme-provider';
import { CaretHollowMirroredIcon } from '@fluentui/react-icons-mdl2';
import { Stack, TextField } from "@fluentui/react";
import { TransferTimeLine } from "../screen/PurchaseTransferScreen/components/TransferTimeLine";
import fundService, { TransferEstimationDates } from "../services/FundService";
import Api, { ApiTransferOrder } from "../services/ApiService";
import { SignatureComponent } from "../components/Signature/SignatureComponent";
import { ISignature } from "../services/SignaturesService";
import moment from "moment";
import ApiProcessingModal from "./ApiProcessingModal";
import { useBoolean } from "@fluentui/react-hooks";

interface InternalData
{
    urlOk: string,
    urlKo: string,
    activePlan: string,
    originIsin: string,
    originFundName: string,
    targetIsin: string,
    targetFundName: string,
    amount?: number,
    amountType: string,
}

const ApiInternalTransfer = (props: any) =>
{
    const theme = useContext(ThemeContext);
    const guid = window.location.pathname.split('/')[3];
    const fundOrigen = window.location.pathname.split('/')[4];
    const fundDestino = window.location.pathname.split('/')[5];
    const firstTimeLoading = React.useRef<boolean>(true);
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const [userConfirmation, setUserConfirmation] = React.useState({user: false, disclaimer: false});
    const [transferDates, setTransferDates] = React.useState<TransferEstimationDates>({compraLiquidacion: "", compraValor: "", finalProceso: "", ventaLiquidacion: "", ventaValor: ""});
    const [simulationAvailable, setSimulationAvailable] = React.useState<boolean>(true);
    const [sending, setSending] = React.useState<boolean>(true);
    const [simulation, setSimulation] = React.useState<boolean>(false);
    const [data, setData] = React.useState<InternalData>(
    {
        urlOk: "",
        urlKo: "",
        activePlan: "",
        amountType: "",
        originIsin: "",
        targetIsin: "",
        originFundName: "",
        targetFundName: ""
    });
    const [loadedSignature, setLoadedSignature] = React.useState<boolean>(false);
    const [signatures, setSignatures] = React.useState<any>();

    React.useEffect(() =>
    {
        if(firstTimeLoading.current === true)
        {
            firstTimeLoading.current = false;

            Api.getInternalTransferData(guid).then((response: any) =>
            {
                setData(response);
                setSimulationAvailable(true);

                if (response.amountType !== "Total" && response.amount === undefined)
                {
                    setSending(false);
                }
                else
                {
                    setSending(true);
                }
            });
        }
    }, [guid, data]);

    const loadSignature = () =>
    {
        Api.sendSignatureCode(guid).then((response: any) =>
        {
            if(response.length > 0)
            {
                setSignatures(response);
                setLoadedSignature(true);
            }
        });
    }
    const handleSimulation = () =>
    {
        if(simulation === true)
        {
            setSimulation(false);
        }
        else
        {
            if(fundOrigen !== undefined && fundDestino !== undefined)
            {
                fundService.getTransferEstimationDates(fundOrigen, fundDestino, false).then((response: any) =>
                {
                    if(response !== null && response !== undefined)
                    {
                        setTransferDates(
                        {
                            ventaValor: response.ventaValor,
                            ventaLiquidacion: response.ventaLiquidacion,
                            compraValor: response.compraValor,
                            compraLiquidacion: response.compraLiquidacion,
                            finalProceso: response.finalProceso
                        });
                    }
                });
    
                setSimulation(true);
            }
            else
            {
                setSimulation(false);
            }
        }
    }
    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }
    const handleSign = () =>
    {
        showModal();

        var model: ApiTransferOrder = 
        {
            transferGuid: guid,
            hasDisclaimerTime: userConfirmation.disclaimer === true ? 2 : 0
        };

        Api.postTransfer(model).then((response: boolean) =>
        {
            if(response === true)
            {
                window.location.href = data.urlOk;
            }
            else
            {
                hideModal();
                window.location.href = data.urlKo;
            }
        });
    }

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Traspaso Interno"}>
                <L.Main theme={theme}>
                    <L.BackButtonContainer>
                        <span>
                            <CaretHollowMirroredIcon />
                            <L.BackButton onClick={() => window.location.href = data.urlKo}>
                                Volver atrás
                            </L.BackButton>
                        </span>
                    </L.BackButtonContainer>
                    {data.activePlan !== "" &&
                    (
                        <L.DivRowActivePlan className="ms-Grid-row">
                            <L.DivColActivePlan className="ms-Grid-col">
                                <L.TitleContainer>
                                    <L.TextActivePlan>
                                        Aumenta tu inversión / ahorro
                                    </L.TextActivePlan>
                                </L.TitleContainer>
                            </L.DivColActivePlan>
                            <L.DivColActivePlanText className="ms-Grid-col">
                                <L.SubTitleContainer>
                                    {data.activePlan}
                                </L.SubTitleContainer>
                            </L.DivColActivePlanText>
                        </L.DivRowActivePlan>
                    )}
                    {data.activePlan === "" &&
                    (
                        <L.TitleContainer>
                            <h2>Aumenta tu inversión / ahorro</h2>
                        </L.TitleContainer>
                    )}
                    <L.SeparatorTop />
                    <L.DivGridMain className="ms-Grid restarted-ms-Grid">
                        <L.DivGridRow className="ms-Grid-row">
                            <L.DivGridOriginCol className="ms-Grid-col ms-sm12 ms-xl6">
                                <L.CardContainer theme={theme}>
                                    <L.CardHeader>
                                        <L.CardHeadingContainer>
                                            <L.CardHeaderH3>
                                                Datos de Origen
                                            </L.CardHeaderH3>
                                        </L.CardHeadingContainer>
                                    </L.CardHeader>
                                    <L.CardBody>
                                        <Stack>
                                            <Stack.Item>
                                                <TextField required label={"Isin de Origen"} value={data.originIsin} readOnly={true} />
                                                <TextField required label={"Nombre del Fondo"} value={data.originFundName} readOnly={true} />
                                            </Stack.Item>
                                        </Stack>
                                    </L.CardBody>
                                </L.CardContainer>
                            </L.DivGridOriginCol>
                            <L.DivGridTargetCol className="ms-Grid-col ms-sm12 ms-xl6">
                                <L.CardContainer theme={theme} style={{height: "322px"}}>
                                    <L.CardHeader>
                                        <L.CardHeadingContainer>
                                            <L.CardHeaderH3>
                                                Datos de Destino
                                            </L.CardHeaderH3>
                                        </L.CardHeadingContainer>
                                    </L.CardHeader>
                                    <L.CardBody>
                                        <Stack>
                                            <Stack.Item>
                                                <TextField required label={"Isin de Destino"} value={data.targetIsin} readOnly={true} />
                                                <TextField required label={"Nombre del Fondo"} value={data.targetFundName} readOnly={true} />
                                                <TextField required label={"Tipo de Importe"} value={data.amountType} readOnly={true} />
                                                {data.amount !== undefined &&
                                                (
                                                    <TextField required label={"Cantidad"} value={data.amount.toString()} readOnly={true} />
                                                )}
                                            </Stack.Item>
                                        </Stack>
                                    </L.CardBody>
                                </L.CardContainer>
                            </L.DivGridTargetCol>
                        </L.DivGridRow>
                    </L.DivGridMain>
                    <L.OuterFooterArea>
                        <L.CardButton size="lg" disabled={!simulationAvailable} type="button" style={{marginRight: "1em"}} onClick={handleSimulation}>
                            Simular
                        </L.CardButton>
                        <L.CardButton size="lg" disabled={!sending} type="button" onClick={loadSignature}>
                            Continuar
                        </L.CardButton>
                    </L.OuterFooterArea>
                    {simulation === true && simulationAvailable === true && transferDates !== undefined &&
                    (
                        <React.Fragment>
                            <TransferTimeLine transferEstimationDates={transferDates} />
                        </React.Fragment>
                    )}
                    {loadedSignature &&
                    (
                        <React.Fragment>
                            <SignatureComponent
                                signatures={signatures}
                                title={"Firma la Orden del Traspaso"}
                                submitButtonText={"Firmar"}
                                requestNewCodeButtonText={"Recibir nuevo código"}
                                headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX"+signatures[0].phone?.slice(signatures[0].phone.length - 3)+" por SMS para firmar la orden."}
                                onBackButtonClicked={() => window.location.href = data.urlKo}
                                onCancelButtonClicked={() => window.location.href = data.urlKo}
                                isElementSigning={false}
                                isApiSignature={true}
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                confirmElement={
                                {
                                    text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                                    value: userConfirmation.user,
                                    setValue: (value: boolean) => setUserConfirmation({ user: !!value, disclaimer: userConfirmation.disclaimer })
                                }}
                                disclaimerEnabled={
                                {
                                    text: "He sido informado de que alguna de las operaciones realizadas en este momento puede no tomar el valor liquidativo del día en curso.",
                                    processHours: transferDates,
                                    isins: [data.originIsin, data.targetIsin],
                                    value: userConfirmation.disclaimer,
                                    isTransfer: false,
                                    isMultiIsin: false,
                                    setValue: (value: boolean) => setUserConfirmation({user: userConfirmation.user, disclaimer: !!value})
                                }}
                                onSigned={handleSign}
                            />
                        </React.Fragment>
                    )}
                    <ApiProcessingModal isModalOpen={isModalOpen} hideModal={hideModal}
                        title={'Procesando datos...'}
                        description={'Estamos preparando todos los datos para autorizar la compra. No cierres la pestaña ni el navegador, por favor.'}
                    />
                </L.Main>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default ApiInternalTransfer;