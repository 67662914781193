import * as L from '../styles/AccountTableStyles';
import { RouteComponentProps } from 'react-router';
import DocumentTitle from 'react-document-title';
import React from 'react';
import AccountTableLogic from './AccountTableLogic';
import { useEffect, useState } from "react";
import UserService from '../../../../services/UserService';
import Auth from '../../../../Auth/Auth';
import { AccountsAutorized, AccountsUsed } from '../../../../services/SubscriptionService';

interface RouteParams { };

interface Props extends RouteComponentProps<RouteParams> { };

export const CurrentAccounts: React.FunctionComponent<Props> = (props, context) =>
{
    const user = Auth.getUserProfile();
    const [accountsUsed, setAccountsUsed] = useState<AccountsUsed[]>();
    const [accountsAutorized, setAccountsAutorized] = useState<AccountsAutorized[]>();
    const [error, setError] = useState({ message: false });
    const [loading, setLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        getBankAccountInfo();
    }, [refresh]);


    const getBankAccountInfo = () => {
        setLoading(true);
        Promise.all([UserService.getBankAccountsAutorized(user.id),  UserService.getBankAccountsUsed(user.id)])
        .then(([accountsDataAutorized, accountsDataUsed ]) => {
            setAccountsAutorized(accountsDataAutorized);
            setAccountsUsed(accountsDataUsed);
        })
        .catch(error => {
            setError(error);
        })
        .finally(() =>
        {
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <DocumentTitle title="IronIA - Cuentas Corrientes">
                <L.Wrapper>
                    <L.TitleContainer>
                        <L.Title>Cuentas Corrientes</L.Title>
                    </L.TitleContainer>
                    <AccountTableLogic
                        setRefresh={setRefresh}
                        dataUsed={accountsUsed}
                        dataAutorized={accountsAutorized}
                        loading={loading}
                        error={error}
                    />
                </L.Wrapper>
            </DocumentTitle>
        </React.Fragment>
    );
};