import axios from "axios";
import config from "../config";
import { ElementsPromotionType } from "./ElementsPromotionsService";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IElementPrice
{
    date: Date;
    dateString: string;
    events: number;
    price: number;
}
export type ElementPrice = IElementPrice;
interface IElementAmount
{
    userId: number;
    elementAir: number;
    elementEarth: number;
    elementFire: number;
    elementWater: number;
    elementIA: number;
    elementTransactions: IElementTransaction[];
}
export type ElementAmountType = IElementAmount;
interface IElementTransaction
{
    elementAmountId: number;
    elementAmount: ElementAmountType;
    amountType: string;
    amountElement: number;
    concept: string;
    description: string;
    transactionDate: Date;
    elementPrmotionId?: number;
    elementPromotion?: ElementsPromotionType;
}
export type ElementTransactionType = IElementTransaction;
interface IElementIA
{
    elementsWaterUsed: number;
    elementsIA: number;
    reference: string;
    subject: string;
    price: number;
    areElementsApplied: boolean;
    userId: number;
}
export type ElementIAObject = IElementIA;
interface IElementIAResponse
{
    areElementsApplied: boolean;
    isPriceEqualZero: boolean;
    hasError: boolean;
    error: string;
}
export type ElementIAResponse = IElementIAResponse;

export type UpdateElementsAmountType =
{
    userId: number;
    air?: number;
    earth?: number;
    fire?: number;
    water?: number;
    iA?: number;
    concept: string;
    description: string;
};

export type ServiceType = {
    id?: number;
    name?: string;
    serviceType: string;
    elementIA?: number;
    elementWater?: number;
    elementEarth?: number;
    elementFire?: number;
    elementAir?: number;
}

export interface UserElementsStatus {
    isSubscribed: boolean,
    isOutdatedContract: boolean,
    lastContractVersion?: string,
    sync: boolean
}

export interface ElementContract {
    name: string,
    file: string,
    version: string,
    date: string
}

class ElementsService
{
    public isUserSubscribedToElements = async (userId: number): Promise<UserElementsStatus> =>
    {
        let url: string = `${proxy}/IsUserSubscribedToElements/${userId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getElementContract = async (): Promise<any> =>
    {
        let url: string = `${proxy}/GetElementContract`;
        const req = await axios.get(url);
        return req.data;
    };
    public getElementContractByName = async (elementContractName: string): Promise<any> =>
    {
        let url: string = `${proxy}/getElementContractByName/${elementContractName}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getAllPricesData = async (): Promise<ElementPrice[]> =>
    {
        let url: string = `${proxy}/GetAllPricesData`;
        const req = await axios.get(url);
        return req.data;
    };
    public createElementsSubscription = async (signatureId: number, userId: number): Promise<boolean> =>
    {
        let toSend = {SignatureId: signatureId, UserId: userId};
        let url: string = `${proxy}/CreateElementsSubscription`;
        const req = await axios.post(url, toSend, {headers: {"content-type": "application/json"}});
        return req.data;
    };
    public subscribeToElementPromotion = async (signatureId: number, userId: number, elementContractName: string, elementPromotionSlug: string): Promise<boolean> =>
    {
        let toSend = {SignatureId: signatureId, UserId: userId, ElementPromotionSlug: elementPromotionSlug};
        let url: string = `${proxy}/SubscribeToElementPromotion/${elementContractName}`;
        const req = await axios.post(url, toSend, {headers: {"content-type": "application/json" }});
        return req.data;
    };
    public getSignedContracts = async (userId: number): Promise<any> =>
    {
        let url: string = `${proxy}/GetSignedContracts/${userId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getElementsAmountByUserId = async (userId: number): Promise<any> =>
    {
        let url: string = `${proxy}/elementsAmount/getByUserId/${userId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getOnlyElementsAmountByUserId = async (userId: number): Promise<any> =>
    {
        let url: string = `${proxy}/OnlyElementsAmountByUserId/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getAllContracts = async (userId: number): Promise<ElementContract[]> =>
    {
        let url: string = `${proxy}/GetAllContracts/${userId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getElementContractPDF = async (id: string): Promise<any> =>
    {
        let url: string = `${proxy}/GetElementContractPDF/${id}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getLastElementsPrice = async (): Promise<any> =>
    {
        let url: string = `${proxy}/elements/getLastElementsPrice`;
        const req = await axios.get(url);
        return req.data;
    };
    public elementsIA = async (toSend: ElementIAObject): Promise<ElementIAResponse> =>
    {
        let url: string = `${proxy}/ElementsIA`;
        const req = await axios.post(url, toSend, {headers: {"content-type": "application/json"}});
        return req.data;
    };
    public updateElementsAmount = async (elements: UpdateElementsAmountType): Promise<boolean> =>
    {
        let url: string = `${proxy}/elementsAmount/UpdateElementsAmount`;
        const req = await axios.patch(url, elements);
        return req.data;
    };
    public servicePrice = async (servicePriceTypeId: number): Promise<ServiceType> =>
    {
        let url: string = `${proxy}/elements/services-prices/${servicePriceTypeId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public transferElementsIA = async (data: any): Promise<any> =>
    {
        let url: string = `${proxy}/ElementsIATransferRefund`;
        const req = await axios.post(url, data, {headers: {"content-type": "application/json"}});
        return req.data;
    }
}

const ElementService = new ElementsService();
export default ElementService;