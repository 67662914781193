import * as S from '../ConvenienceTest.style';
import ConvenienceTestAccountHolderDataInputs from './ConvenienceTestAccountHolderDataInputs';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback, useContext } from 'react';
import Moment from 'moment';
import Auth from "../../../Auth/Auth";
import UserIdentitiesService, { UserIdentityType } from '../../../services/UserIdentitiesService';
import './dropDown.css';
import { useParams } from 'react-router-dom';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import SavingsPlanService, { SavingsPlanType } from '../../../services/SavingsPlanService';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { ThemeContext } from '@fluentui/react-theme-provider';

const ConvenienceTestAccountHolderData = () =>
{
    const history = useHistory();
    const user = Auth.getUserProfile();
    const theme = useContext(ThemeContext);
    const [canSubmit, setCanSubmit] = useState(true);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState({ status: false, message: '' });
    const {savingsPlanId} = useParams<any>();
    const {userIdentityId} = useParams<any>();
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [isLoadedUserIdentity, setIsLoadedUserIdentity] = useState(false);
    const [userIdentity, setUserIdentity] = useState<any>();
    const [userIdentityForm, setUserIdentityForm] = useState<any>(
    {
        firstName: false,
        lastName: false,
        birthday: false,
        email: false,
        countryPrefix: true,
        phone: false,
        operationCountryPrefix: true,
        operationPhone: false,
        proofOfMinorGuardianDescription: true
    });

    useEffect(() =>
    {
        scrollToTop();
    }, []);
    useEffect(() =>
    {
        if(userIdentityId !== null && userIdentityId !== undefined)
        {
            UserIdentitiesService.getUserIdentity(userIdentityId).then((userIdentity: UserIdentityType) =>
            {
                setUserIdentity(userIdentity);
            },
            (error) =>
            {
                setError(error);
            });
        }
        else
        {
            UserIdentitiesService.getIdentitiesByUserId(user.id).then((userIdentities: UserIdentityType[]) =>
            {
                if(userIdentities.length === 1 && userIdentities[0].updatedAt === null)
                {
                    setUserIdentity(userIdentities[0]);
                }
                else
                {
                    var userIdentityAux =
                    {
                        firstName: "",
                        lastName: "",
                        birthday: Moment().subtract(18, 'years').toDate(),
                        email: "",
                        countryPrefix: "+34",
                        phone: "",
                        operationCountryPrefix: "+34",
                        operationPhone: "",
                        userId: user.id,
                        status: "NEW",
                        main: false
                    };
                    setUserIdentity(userIdentityAux);
                }
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdentityId]);

    useEffect(() =>
    {
        if(savingsPlanId !== undefined)
        {
            SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingPlan: any) =>
            {
                setSavingsPlan(savingPlan);
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    }, [savingsPlanId]);

    useEffect(() =>
    {
        if(userIdentity !== undefined)
        {
            setIsLoadedUserIdentity(true);
        }
    }, [userIdentity]);

    const handleAccountHolderDataChange = (event: any) =>
    {
        var {name, value}: {name: string, value: string} = event.target;
        var realValue = value;
        if(event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0)
        {
            realValue = event.target.files[0];
        }
        setUserIdentity((prevState: any) =>
        ({
            ...prevState,
            [name]: realValue
        }));
    };

    const btnSubmit = useCallback(() =>
    {
        var result = Object.values(userIdentityForm).every((ob) => ob === true);
        setCanSubmit(result);
    }, [userIdentityForm]);

    useEffect(() =>
    {
        btnSubmit();
    }, [btnSubmit, userIdentity]);

    const updateUserIdentityForm = (name: string, value: any) =>
    {
        if(userIdentityForm[name] !== value)
        {
            userIdentityForm[name] = value;
            setUserIdentityForm(userIdentityForm);
            btnSubmit();
        }
    }

    const handleSubmit = () =>
    {
        if(userIdentity !== undefined)
        {
            setSending(true);

            if (userIdentity.phone.startsWith(userIdentity.countryPrefix)) {
                userIdentity.phone = userIdentity.phone.replace(userIdentity.countryPrefix, '');
            }

            if (userIdentity.operationPhone.startsWith(userIdentity.operationCountryPrefix)) {
                userIdentity.operationPhone = userIdentity.operationPhone.replace(userIdentity.operationCountryPrefix, '');
            }

            if(userIdentity.signPhone === undefined)
            {
                userIdentity.signPhone = userIdentity.phone;
                userIdentity.signCountryPrefix = userIdentity.countryPrefix;
            }

            if (userIdentity.id !== undefined)
            {
                UserIdentitiesService.updateUserIdentity(userIdentity).then(() =>
                {
                    history.push('/address/' + savingsPlanId + '/' + userIdentity.id);
                },
                (error: any) =>
                {
                    setError(error);
                });
            }
            else
            {
                UserIdentitiesService.createUserIdentity(userIdentity).then((storedUserIdentityAux: UserIdentityType) =>
                {
                    if(storedUserIdentityAux.id !== undefined)
                    {
                        history.push('/address/' + savingsPlanId + '/' + storedUserIdentityAux.id);
                    }
                },
                (error: any) =>
                {
                    setError(error);
                });
            }
        }
    };

    return(
        <DocumentTitle title={`IronIA - Editar Interviniente`}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont theme={theme}>
                    <S.MainCont>
                        <S.Steps>
                            <h3>Paso 1 <span> de 4</span></h3>
                        </S.Steps>
                        <S.Title>
                            <h1>Intervinientes</h1>
                            <h2>Aquí puedes gestionar los distintos tipos de intervinientes de tus planes de ahorro.</h2>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar />
                            <S.ProsBar />
                        </S.BarCont>
                        <S.TabletCont theme={theme}>
                            {!isLoadedUserIdentity &&
                            (
                                <Spinner size={SpinnerSize.large} />
                            )}
                            {isLoadedUserIdentity &&
                            (
                                <ConvenienceTestAccountHolderDataInputs data={userIdentity}
                                    handleChange={(event: any) => handleAccountHolderDataChange(event)}
                                    formVal={userIdentityForm}
                                    setFormVal={(name: string, value: any) => updateUserIdentityForm(name, value)}
                                />
                            )}
                        </S.TabletCont>
                        {error.message !== "" &&
                        (
                            <S.TabletCont theme={theme}>
                                <S.Error>Se ha producido un error que impide continuar con el proceso 2</S.Error>
                            </S.TabletCont>
                        )}
                        <S.BtnCont>
                            {savingsPlan === undefined && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                            {savingsPlan !== undefined &&
                            (
                                <DefaultButton className='button tiny-secondary-button'
                                    onClick={() => history.push(`/accountHolder/${savingsPlanId}`)}
                                >
                                    Volver
                                </DefaultButton>
                            )}
                            <DefaultButton type='submit'
                                onClick={() => handleSubmit()}
                                disabled={!isLoadedUserIdentity || !canSubmit || sending || error.message !== ""}
                                className="button tiny-primary-button"
                            >
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </DefaultButton>
                        </S.BtnCont>
                    </S.MainCont>
                </S.Cont>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ConvenienceTestAccountHolderData;