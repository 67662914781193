import DocumentTitle from "react-document-title";
import Auth from "../../Auth/Auth";
import {Card, CardItem, FooterCard, ImageCard, PageTitle, Title} from "./ErrorStyle";
import {Link, useHistory} from "react-router-dom";
import {ai} from "../../services/TelemetryService";
import {ThemeContext} from '@fluentui/react-theme-provider';
import {useContext} from "react";
import Logo from "../../images/logo.svg";
import PortfolioImage from "../../images/portfolio.png";
import BuscadorFondos from "../../images/buscadorFondos.png";
import SavingPlans from "../../images/savingPlans.png";

const ErrorComponent = (props: any) =>
{
    const appInsights = ai.reactPlugin;
    const history = useHistory();
    const isCancelled = Auth.isActivePlanCancelled();
    const isOutdated = Auth.isLastPlanOutdated();
    const isPromotioned = Auth.isPromotioned();
    const theme = useContext(ThemeContext);
    const urlHome = "/";
    const urlPortfolio = "/portfolio";
    const urlFunds = "/search";
    const urlSavingPlans = "/savingsPlans";
    
    const handleHomeClick = (event: any) =>
    {
        event.preventDefault();
        appInsights.trackEvent({name: "ErrorPage", properties: {link: "Inicio"}});
        history.push(urlHome);
    };
    const handlePortfolioClick = (event: any) =>
    {
        event.preventDefault();
        appInsights.trackEvent({name: "ErrorPage", properties: {link: "Portfolio"}});
        history.push(urlPortfolio);
    };
    const handleFundsClick = (event: any) =>
    {
        event.preventDefault();
        appInsights.trackEvent({name: "ErrorPage", properties: {link: "Buscador de Fondos"}});
        history.push(urlFunds);
    };
    const handleSavingPlansClick = (event: any) =>
    {
        event.preventDefault();
        appInsights.trackEvent({name: "ErrorPage", properties: {link: "Saving Plans"}});
        history.push(urlSavingPlans);
    };
    const handlePromotionedClick = (event: any) =>
    {
        event.preventDefault();
        appInsights.trackEvent({name: "ErrorPage", properties: {link: "Promotioned Plans"}});
        history.push(urlSavingPlans);
    };

    return(
        <DocumentTitle title={"Error"}>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                    <PageTitle>Esta página no está disponible...</PageTitle>
                </div>
                <CardItem className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                    <Card>
                        <Link to={{pathname: urlHome}} onClick={(event: any) => handleHomeClick(event)}>
                            <ImageCard src={Logo} alt={"Volver al Inicio"} />
                            <FooterCard className="ms-Grid-row" theme={theme}>
                                <Title className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    Volver a la Página de Inicio
                                </Title>
                            </FooterCard>
                        </Link>
                    </Card>
                </CardItem>
                {isPromotioned === false &&
                (
                    <CardItem className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                        <Card>
                            <Link to={{pathname: urlFunds}} onClick={(event: any) => handleFundsClick(event)}>
                                <ImageCard src={BuscadorFondos} alt={"Al Buscador de Fondos"} />
                                <FooterCard className="ms-Grid-row" theme={theme}>
                                    <Title className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                        Buscador de Fondos
                                    </Title>
                                </FooterCard>
                            </Link>
                        </Card>
                    </CardItem>
                )}
                {isCancelled === false && isOutdated === false &&
                (
                    <CardItem className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                        <Card>
                            <Link to={{pathname: urlPortfolio}} onClick={(event: any) => handlePortfolioClick(event)}>
                                <ImageCard src={PortfolioImage} alt={"Al Portfolio"} />
                                <FooterCard className="ms-Grid-row" theme={theme}>
                                    <Title className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                        Portfolio
                                    </Title>
                                </FooterCard>
                            </Link>
                        </Card>
                    </CardItem>
                )}
                {isPromotioned === false &&
                (
                    <CardItem className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                        <Card>
                            <Link to={{pathname: urlSavingPlans}} onClick={(event: any) => handleSavingPlansClick(event)}>
                                <ImageCard src={SavingPlans} alt={"A los Planes de Ahorro"} />
                                <FooterCard className="ms-Grid-row" theme={theme}>
                                    <Title className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                        Planes de Ahorro
                                    </Title>
                                </FooterCard>
                            </Link>
                        </Card>
                    </CardItem>
                )}
                {isPromotioned === true && 
                (
                    <CardItem className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
                        <Card>
                            <Link to={{pathname: urlHome}} onClick={(event: any) => handlePromotionedClick(event)}>
                                <ImageCard src={Logo} alt={"A la Página Promocionada"} />
                                <FooterCard className="ms-Grid-row" theme={theme}>
                                    <Title className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                        Volver al Proceso de Alta
                                    </Title>
                                </FooterCard>
                            </Link>
                        </Card>
                    </CardItem>
                )}
            </div>
        </DocumentTitle>
    );
};

export default ErrorComponent;