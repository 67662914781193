import styled from 'styled-components';

export const Wrapper = styled.div`
	.item
    {
		background: ${(props) => props.theme.palette.white};
		box-shadow: 0px 0px 16px ${(props) => props.theme.palette.shadow};
		border-radius: 16px;
		font-family: 'Barlow';
		margin: 5px;
		position: relative;
		min-height: 400px;
        &.loading
        {
            min-width: 100%;
        }
		.header
        {
			position: relative;
			text-align: center;
			font-weight: 800;
			font-size: 18px;
			line-height: 24px;
			color: #FFFFFF;
			.ms-Image
            {
				position: relative;
				max-height: 163px;
				&:after
                {
					content: "";
					width: 100%;
					height: 100%;
					background: #0F2426;
					opacity: 0.6;
					border-radius: 16px;
					position: absolute;
					top: 0;
					left: 0;
				}
				img
                {
					width: 100%;
					border-radius: 15px 15px 0 0;
					min-height: 175px;
				}
			}
			.data
            {
				position: absolute;
				top: 0;
				width: calc(100% - 20px);
				text-align: center;
				left: 0;
				margin: 35px 49px 35px 0;
				padding: 0 10px;
				.name
                {
					display block;
					color: #FFF;
					margin: 0 auto;
					width: fit-content;
				}
				a.name:hover
                {
					color: ${(props) => props.theme.palette.outdated};
				}
				.manager
                {
					display block;
					font-size: 16px;
					font-weight: 500;
					opacity: 0.75;
				}
			}
		}
		.relationship
		{
			position: absolute;
			bottom: 0;
			right: 5px;
			color: white;
			font-size: 12px;
			font-weight: 300;
		}
		.content
        {
			padding: 20px;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: ${(props) => props.theme.palette.black};
			span
            {
				display: block;
				margin: 8px 0;
			}
			.description
            {
				min-height: 80px;
			}
			.price
            {
				border: 1px solid #DFE3E3;
				border-radius: 64px;
				padding: 7px 40px;
				text-align: center;
				margin: 20.5px 0;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
			}
			.ms-Checkbox-checkbox
            {
			    margin: 8px 8px 8px 0;
			}
		}
	}
`;
export const StyledModal = styled.div`
    color: ${(props) => props.theme.palette.black};
	.name
    {
		color: ${(props) => props.theme.palette.black};
	}
`;