import React from 'react';
import * as S from './SuitabilityTest.style';
import SuitabilityTestInputs from './SuitabilityTestInputs';
import { useState, useEffect, useCallback } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import SavingsPlanService, { SavingsPlanType } from '../../services/SavingsPlanService';
import UserIdentitiesService, { UserIdentityType } from '../../services/UserIdentitiesService';
import SuitabilityTestsService,
{
    ISuitabilityTestVersion,
    ISuitabilityTest,
    IQuestion,
    IQuestionTableRow,
    IAnswer,
    IBlock,
    ISubBlock,
    IQuestionAnswer
} from '../../services/SuitabilityTestsService';

const SuitabilityTest = ({ savingsPlanId, userIdentityId, confirmUrl }: { savingsPlanId: number, userIdentityId: number, confirmUrl: string}) =>
{
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedSuitabilityTestVersion, setIsLoadedSuitabilityTestVersion] = useState(false);
    const [userIdentity, setUserIdentity] = useState<UserIdentityType>();
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [suitabilityTestVersion, setSuitabilityTestVersions] = useState<ISuitabilityTestVersion>();
    const [suitabilityTest, setSuitabilityTest] = useState<ISuitabilityTest>();
    const [error, setError] = useState({ status: false, message: '' });
    const [suitabilityTestForm, setSuitabilityTestForm] = useState<any>();
    const [questions, setQuestions] = useState<IQuestion[]>();
    const [isAnswersLoaded, setIsAnswersLoaded] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() =>
    {
        SuitabilityTestsService.getLastSuitabilityTestVersion().then((suitabilityTestVersion: ISuitabilityTestVersion) =>
        {
            setSuitabilityTestVersions(suitabilityTestVersion);
            setIsLoadedSuitabilityTestVersion(true);
        },
        (error) =>
        {
            setError(error);
            setIsLoadedSuitabilityTestVersion(true);
        });
    }, []);

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingsPlan: SavingsPlanType) =>
        {
            setSavingsPlan(savingsPlan);
        },
        (error) =>
        {
            setError(error);
            setIsLoaded(true);
        });
    }, []);

    useEffect(() => {
        if(userIdentityId !== undefined) {
            UserIdentitiesService.getUserIdentityWithSuitabilityTests(userIdentityId)
                .then((userIdentity: UserIdentityType) => {
                    setUserIdentity(userIdentity);
                    if(userIdentity.suitabilityTests !== undefined && userIdentity.suitabilityTests.length > 0) {
                        var suitabilityTest = userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1];
                        delete suitabilityTest.id;
                        setSuitabilityTest(suitabilityTest);
                    }
                    setIsLoaded(true);
                },
                (error: any) => {
                    setError(error);
                    setIsLoaded(true);
                });
        }
    }, [userIdentityId]);

    useEffect(() =>
    {
        if(suitabilityTestVersion && userIdentity !== undefined)
        {
            var subBlocks = suitabilityTestVersion.test.blocks.map((block: IBlock) => block.subBlock).reduce((prev?: ISubBlock[], next?: ISubBlock[]) =>
            {
                return (prev !== undefined ? (next !== undefined ? prev?.concat(next) : prev) : (next !== undefined ? next : []))
            });

            var questions = subBlocks.map((subBlock: ISubBlock) => subBlock.questions).reduce((prev?: IQuestion[], next?: IQuestion[]) =>
            {
                return (prev !== undefined ? (next !== undefined ? prev?.concat(next) : prev) : (next !== undefined ? next : []))
            });

            setQuestions(questions);
        }
    }, [suitabilityTestVersion, userIdentity]);

    useEffect(() =>
    {
        if(questions !== undefined
            && questions.length > 0
            && suitabilityTestVersion !== undefined
            && userIdentity !== undefined
        ) {
            setSuitabilityTestForm(questions.reduce((o: any, question: IQuestion) => {
                if (question.dependency === undefined) {
                    return { ...o, [question._id]: false };
                }
                else {
                    return { ...o, [question._id]: true };
                }
            }, {}) );

            if(userIdentity.suitabilityTests === undefined
                || userIdentity.suitabilityTests.length === 0
                || userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1].suitabilityTestVersionId !== suitabilityTestVersion.id
            ) {
                setSuitabilityTest({
                    suitabilityTestVersionId: suitabilityTestVersion.id,
                    userIdentityId: userIdentity.id,
                    answers: [],
                    status: "DRAFT"
                });
                setIsLoaded(true);
            }
            else
            {
                var suitabilityTest = userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1];
                delete suitabilityTest.id;
                setSuitabilityTest(suitabilityTest);
                setIsLoaded(true);
            }
        }
    }, [suitabilityTestVersion, questions]);

    useEffect(() =>
    {
        if(!isAnswersLoaded
            && suitabilityTest !== undefined
            && suitabilityTest
            && questions !== undefined
        ) {
            var answers: IAnswer[] = [];

            questions.forEach((question: IQuestion) =>
            {
                switch(question.questionType)
                {
                    case "radio":
                        answers.push(
                        {
                            questionId: question._id,
                            key: question.key
                        });
                        break;
                }
            });

            setSuitabilityTest((prevState: any) => {
                return {
                    ...prevState,
                    answers: [
                        ...prevState.answers,
                        ...answers
                    ]
                }
            });
            setIsAnswersLoaded(true);
        }
    }, [suitabilityTest, questions]);

    const handleTestChange = (event: any, question: IQuestion) =>
    {
        const { name, value }: { name: string, value: string } = event.target;

        var answers: IAnswer[] | undefined = [];
        var answer: IAnswer | undefined;
        var questionAnswer: IQuestionAnswer | undefined;
        switch(question.questionType)
        {
            case "radio":
                answers = suitabilityTest?.answers?.filter((answerAux: IAnswer) => answerAux.questionId !== question._id);
                answer = Object.assign({}, suitabilityTest?.answers?.find((answerAux: IAnswer) => answerAux.questionId === question._id));
                const order = Number(event.target.dataset.order);
                questionAnswer = question?.answers.find((answerAux: IQuestionAnswer) => answerAux.value === Number(value) && answerAux.order === order);

                if(answer !== undefined && questionAnswer !== undefined)
                {
                    answer.value = questionAnswer.value;
                    answer.order = questionAnswer.order;

                    setSuitabilityTest((prevState: any) => (
                        {
                            ...prevState,
                            answers: [
                                ...prevState.answers.filter((answerAux: IAnswer) => answerAux.questionId !== question._id),
                                answer
                            ]
                        }
                    ));
                }

                break;

            case "checkbox":
                var [questionId, answerId] = name.split("-");
                questionAnswer = question?.answers.find((answerAux: IQuestionAnswer) => answerAux._id === answerId);
                if(questionAnswer !== undefined)
                {
                    var answersAux = suitabilityTest?.answers?.map((answer: IAnswer) => Object.assign({}, answer)) || [];
                    if(event.target.checked)
                    {
                        answersAux.push(
                        {
                            questionId: questionAnswer._id,
                            key: question.key,
                            order: questionAnswer.order,
                            res: questionAnswer.value
                        });
                    }
                    else
                    {
                        var answerIndex = answersAux.findIndex((answerAux: IAnswer) => answerAux.questionId === answerId);
                        if(answerIndex !== undefined)
                        {
                            answersAux.splice(answerIndex, 1);
                        }
                    }

                    setSuitabilityTest((prevState: any) => (
                        {
                            ...prevState,
                            answers: answersAux
                        }
                    ));
                }
                break;

            case "multi":
                var [questionIdMulti, answerId] = name.split("-");
                questionAnswer = question?.answers.find((answerAux: IQuestionAnswer) => answerAux._id === answerId);

                if(questionAnswer !== undefined)
                {
                    if(event.target.hasOwnProperty("checked")) {
                        var answersAux = suitabilityTest?.answers || [];
                        if(event.target.checked) {
                            answersAux.push({
                                questionId: questionAnswer._id,
                                key: question.key,
                                order: questionAnswer.value,
                                res: questionAnswer.order
                            });
                        }
                        else {
                            var answerIndex = answersAux.findIndex((answerAux: IAnswer) => answerAux.questionId === answerId);
                            if(answerIndex !== undefined) {
                                answersAux.splice(answerIndex, 1);
                            }
                        }

                        setSuitabilityTest((prevState: any) => (
                            {
                                ...prevState,
                                answers: answersAux
                            }
                        ));
                    }
                    else {
                        answers = suitabilityTest?.answers?.filter((answer: IAnswer) => answer.questionId !== answerId);
                        answer = suitabilityTest?.answers?.find((answer: IAnswer) => answer.questionId === answerId);

                        if(answers !== undefined && answer !== undefined && questionAnswer !== undefined) {
                            setSuitabilityTest((prevState: any) => {
                                if(answers !== undefined && questionAnswer !== undefined) {
                                    return {
                                        ...prevState,
                                        answers: [
                                            ...answers,
                                            {
                                                ...answer,
                                                inputRes: parseFloat(value),
                                                value: questionAnswer.value * parseFloat(value) / 100
                                            }
                                        ]
                                    }
                                }
                                else {
                                    return prevState;
                                }
                            });
                        }
                    }
                }
                break;

            case "table":
                var [questionId, rowId, colIndex] = name.split("-");
                var questionRow = question?.table.rows.find((row: IQuestionTableRow) => row._id === rowId);
                if(questionRow !== undefined)
                {
                    answersAux = suitabilityTest?.answers?.map((answer: IAnswer) => Object.assign({}, answer)) || [];
                    if(event.target.checked)
                    {
                        if(colIndex === "1")
                        {
                            answersAux.push({
                                originalQuestionId: questionId,
                                questionId: questionRow.cols[parseInt(colIndex)]._id,
                                res: questionRow.cols[parseInt(colIndex)].value
                            });
                        }
                        else
                        {
                            if(!answersAux.find((answerAux: IAnswer) => questionRow !== undefined && answerAux.questionId === questionRow.cols[1]._id))
                            {
                                answersAux.push({
                                    originalQuestionId: questionId,
                                    questionId: questionRow.cols[1]._id,
                                    res: questionRow.cols[1].value
                                });
                            }

                            answersAux.push({
                                originalQuestionId: questionId,
                                questionId: questionRow.cols[2]._id,
                                res: questionRow.cols[2].value
                            });
                        }
                    }
                    else
                    {
                        if(colIndex === "1")
                        {
                            var answerIndex = answersAux.findIndex((answerAux: IAnswer) => questionRow !== undefined && answerAux.questionId === questionRow.cols[1]._id);
                            if(answerIndex !== undefined)
                            {
                                answersAux.splice(answerIndex, 1);
                            }

                            answerIndex = answersAux.findIndex((answerAux: IAnswer) => questionRow !== undefined && answerAux.questionId === questionRow.cols[2]._id);
                            if(answerIndex !== -1)
                            {
                                answersAux.splice(answerIndex, 1);
                            }
                        }
                        else
                        {
                            var answerIndex = answersAux.findIndex((answerAux: IAnswer) => questionRow !== undefined && answerAux.questionId === questionRow.cols[2]._id);
                            if(answerIndex !== -1)
                            {
                                answersAux.splice(answerIndex, 1);
                            }
                        }
                    }

                    setSuitabilityTest((prevState: any) => (
                        {
                            ...prevState,
                            answers: answersAux
                        }
                    ));
                }
                break;
        }
    };

    const btnSubmit = useCallback(() =>
    {
        if(suitabilityTestForm !== undefined)
        {
            setCanSubmit(Object.values(suitabilityTestForm).every((x) => x === true));
        }
    }, [suitabilityTestForm]);

    const updateSuitabilityTestForm = (name: string, value: any) =>
    {
        suitabilityTestForm[name] = value;
        setSuitabilityTestForm(suitabilityTestForm);
        btnSubmit();
    }

    const handleSubmit = (event: any) =>
    {
        event.preventDefault();
        if(suitabilityTest !== undefined) {
            setIsLoaded(false);
            SuitabilityTestsService.createSuitabilityTest(suitabilityTest)
                .then(() => {
                    sign();
                    window.scrollTo(0, 0);
                },
                (error: any) => {
                    setError(error);
                    setIsLoaded(true);
                });
        }
    };

    const sign = () =>
    {
        if(savingsPlanId !== undefined && userIdentity?.id !== undefined)
        {
            setIsLoaded(false);
            UserIdentitiesService.addSuitabilityTestSignatureToUserIdentity(userIdentity?.id)
                .then((userIdentity: UserIdentityType) =>
                {
                    var signature = userIdentity.suitabilityTests !== undefined
                        ? userIdentity?.suitabilityTests[userIdentity.suitabilityTests.length - 1].signature
                        : {};

                    history.push(confirmUrl,
                        {
                            signatures: [signature]
                        }
                    );
                });
        }
    };

    if(error.status)
    {
        return <S.Error>Se ha producido un error que impide continuar con el proceso. {error.message}</S.Error>
    }

    return (
        <React.Fragment>
            <S.Cont>
                {(!isLoaded || !isLoadedSuitabilityTestVersion) && (<Spinner size={SpinnerSize.large} />)}
                {isLoaded && isLoadedSuitabilityTestVersion && (
                    <React.Fragment>
                        <S.MainCont>
                            <div className="mt20">
                                <h1>Test de idoneidad de la inversión</h1>
                            </div>
                            {userIdentity !== undefined && (
                                <>
                                    <S.SubTitle>
                                        Interviniente - <span>{[userIdentity.firstName, userIdentity.lastName, userIdentity.secondLastName].join(" ")}</span>
                                    </S.SubTitle>
                                    {!isAnswersLoaded && (
                                        <Spinner size={SpinnerSize.large} />
                                    )}
                                    {suitabilityTest !== undefined && questions !== undefined && suitabilityTestForm !== undefined && (
                                        <SuitabilityTestInputs
                                            suitabilityTestVersion={suitabilityTestVersion}
                                            suitabilityTest={suitabilityTest}
                                            handleSubmit={(event: any) => handleSubmit(event)}
                                            data={suitabilityTest}
                                            handleChange={(event: any, question: IQuestion) => handleTestChange(event, question)}
                                            formVal={suitabilityTestForm}
                                            setFormVal={(name: string, value: any) => updateSuitabilityTestForm(name, value)}
                                            questions={questions}
                                            canSubmit={canSubmit}
                                        />
                                    )}
                                </>
                            )}
                        </S.MainCont>
                    </React.Fragment>
                )}
            </S.Cont>
        </React.Fragment>
    );
};

export default SuitabilityTest;