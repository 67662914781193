import React, { useEffect, useState } from "react";
import scrollToTop from "../utils/scrollToTop";
import { UserIdentityType } from "../services/UserIdentitiesService";
import * as S from "../Api/Styles/ApiContractStyles";
import DocumentTitle from "react-document-title";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import Api from "../services/ApiService";
import SavingsPlanService from "../services/SavingsPlanService";
import { useHistory, useParams } from "react-router";

const ApiContract = (props: any) =>
{
    const history = useHistory();
    const { guid } = useParams<any>();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [bottom, setBottom] = useState<boolean>(false);
    const [contractHTML, setContractHTML] = useState<string>("");
    const [isContractLoaded, setIsContractLoaded] = useState(false);
    
    React.useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (guid !== undefined) {
            Api.getUserIdentities(guid).then((response: any) => {
                setUserIdentities(response);
            });
        }
    }, []);

    useEffect(() => {
        SavingsPlanService.getHTMLContractBySignatureGuid(guid).then((contractHTML: any) => {
            setContractHTML(contractHTML);
            setIsContractLoaded(true);
        });
    }, []);

    useEffect(() =>
    {
        setPhoneNumbers(userIdentities.map((userIdentity: any) => "X".repeat(userIdentity.phone.length - 3)
            + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleClick = () =>
    {
        setIsSending(true);
        history.push(`/api/TripartiteContract/${guid}`);
    };

    const handleScroll = (event: any) =>
    {
        const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 1;
        setBottom(bottom);
    };

    return(
        <DocumentTitle title={'IronIA - Firma de Contrato'}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont>
                    <S.MainCont>
                        <S.Title>
                            <S.Header>
                                Contrato de Plan de Ahorro
                            </S.Header>
                            <S.ContractSubTitle>
                                A continuación te incluimos los detalles del contrato con nosotros. Léelo y
                                cuando estés listo, dale al botón de "Solicitar Firma". Te enviaremos un
                                código SMS por cada titular
                                {userIdentities.length > 1 ? ` a los números ${phoneNumbers} ` : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " "}
                                para realizar la firma del contrato.
                            </S.ContractSubTitle>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar />
                            <S.ProsBar />
                        </S.BarCont>
                        {isContractLoaded && (
                            <>
                                <S.ScrollableTabletCont
                                    onScroll={handleScroll}
                                    dangerouslySetInnerHTML={{ __html: contractHTML }}
                                />
                            </>
                        )}

                        {!isContractLoaded && (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        <S.BtnCont>
                            <DefaultButton className="button tiny-primary-button" onClick={handleClick} disabled={!bottom}>
                                {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </DefaultButton>
                        </S.BtnCont>
                    </S.MainCont>
                </S.Cont>
            </React.Fragment>
        </DocumentTitle>
    );
}

export default ApiContract;