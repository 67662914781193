import { useContext, useEffect, useRef } from 'react';
import * as L from './SignatureStyles';
import { Callout, Checkbox, DefaultButton, DetailsList, SelectionMode, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { useState } from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import moment from "moment";
import { Controller, useForm } from 'react-hook-form';
import SignaturesService, { ISignature } from '../../services/SignaturesService';
import { UserIdentityType } from '../../services/UserIdentitiesService';
import React from 'react';
import ElementService from '../../services/ElementsService';
import Auth from '../../Auth/Auth';
import { processHoursFunction } from '../../utils/ProcessHoursFunction';
import { ai } from '../../services/TelemetryService';
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import funds, { IInfoFundPortfolio } from '../../services/FundService';
import { PrimaryButton } from 'office-ui-fabric-react';
import { FnmContractComplexProductUserIdentityAgreementType } from '../../services/FnmContractComplexProductService';
import Api from '../../services/ApiService';
import { useDispatch } from "react-redux";
import { setUserElementsStatus } from '../../store/auth/actions';

interface ConfirmElement
{
    text: string,
    value: boolean,
    setValue: (...args: any) => void;
}
interface DisclaimerElement
{
    text: string,
    processHours: any,
    isins: any[],
    value: boolean,
    isTransfer: boolean,
    isMultiIsin: boolean,
    setValue: (...args: any) => void,
}
export type DisclaimerIsins =
{
    origin: string,
    destiny: string
}
export type DisclaimerMultiDestinyIsins =
{
    origin: string,
    destiny: string[]
}
export interface IDataCart
{
    isin: string,
    name: string,
    complexity: boolean,
    date: string
}
interface SignatureComponentProps
{
    signatures: ISignature[];
    title?: string;
    headerTextArea?: any;
    alreadySignedText?: string;
    alreadySignedSubtitle?: string;
    submitButtonText?: string;
    cancelButtonText?: string;
    requestNewCodeButtonText?: string;
    backButtonText?: string;
    allowRequestNewCode?: boolean;
    userIdentities?: UserIdentityType[];
    mainUserIdentity?: UserIdentityType;
    procedence?: string;
    confirmElement?: ConfirmElement;
    disclaimerEnabled?: DisclaimerElement;
    isMultiDestiny?: boolean;
    onSubmit?: (...args: any) => any;
    onExpired?: (...args: any) => any;
    onAllExpired?: (...args: any) => any; // Podría adaptarse para tratar un array de Signatures.
    onError?: (...args: any) => any;
    onSigned?: (...args: any) => any;
    onNewCodeRequested?: (...args: any) => any;
    onNewCodeSended?: (...args: any) => any;
    onBackButtonClicked?: (...args: any) => any;
    onCancelButtonClicked?: (...args: any) => any;
    onSignatureChanged?: (...args: any) => any;
    isElementSigning?: boolean;
    dataCart?: IDataCart[];
    complexProducts?: IDataCart[];
    complexProductAgreements?: FnmContractComplexProductUserIdentityAgreementType[],
    setComplexProductAgreements?: any,
    isApiSignature?: boolean
}

const countdown = React.createRef<Countdown>();

export const SignatureComponent = (props: SignatureComponentProps) =>
{
    let expiredDefault = false;
    let signedDefault = false;
    const dispatch = useDispatch();
    const [hour, setHour] = React.useState<string>("");
    const [tickSubmit, setTickSubmit] = useState<boolean>(false);
    const [tickEnabled, setTickEnabled] = React.useState<boolean>(false);
    const [isAttachmentsVisible, setIsAttachmentsVisible] = React.useState<boolean>(false);
    const [selectedIsin, setSelectedIsin] = React.useState<string>("");
    const [fundsInfo, setFundsInfo] = React.useState<IInfoFundPortfolio[]>([]);
    const [loadingDatesInfo, setLoadingDatesInfo] = React.useState<boolean>(true);
    const [documents, setDocuments] = React.useState<any[]>([]);
    const firstTime = React.useRef<boolean>(true);
    const firstTimeFunds = React.useRef<boolean>(true);
    const theme = useContext(ThemeContext);
    const { handleSubmit, formState: { errors, isValid, isDirty }, control } = useForm({ mode: "onChange" });
    const [signed, setSigned] = useState(signedDefault);
    const [errorMessage, setErrorMessage] = useState("");
    const [expired, setExpired] = useState(expiredDefault);
    const [sending, setSending] = useState(false);
    const [sendingNewCode, setSendingNewCode] = useState(false);
    const user = Auth.getUserProfile();
    const appInsights = ai.reactPlugin;
    const trackClicks = useTrackEvent(appInsights, "Tick Firma", "");
    const performTracking = (jsonObject: string) =>
    {
        trackClicks(jsonObject);
    };
    const nationalIsin: string = " Al tratarse de un fondo nacional, las gestoras pueden aplicar un plazo máximo de 48h para la tramitación de la orden.";
    const nationalIsins = useRef<boolean>(true);
    if(props.disclaimerEnabled !== undefined)
    {
        if(props.isMultiDestiny === true)
        {
            if(props.disclaimerEnabled.isMultiIsin === true)
            {
                nationalIsins.current = props.disclaimerEnabled.isins.some((isins: DisclaimerMultiDestinyIsins) => isins.origin.startsWith("ES") || isins.destiny.some((item: any) => item.isin.startsWith("ES")));
            }
            else
            {
                nationalIsins.current = props.disclaimerEnabled.isins.some((fund: string) => fund.startsWith("ES")) === true;
            }
        }
        else
        {
            if(props.disclaimerEnabled.isMultiIsin === true)
            {
                nationalIsins.current = props.disclaimerEnabled.isins.some((isins: DisclaimerIsins) => isins.origin.startsWith("ES") || isins.destiny.startsWith("ES"));
            }
            else
            {
                nationalIsins.current = props.disclaimerEnabled.isins.some((fund: string) => fund.startsWith("ES")) === true;
            }
        }
    }
    const infoISINS: boolean = (props.disclaimerEnabled !== undefined && props.disclaimerEnabled.isins !== undefined && props.disclaimerEnabled.isins.length > 0 && props.disclaimerEnabled.isTransfer === true);
    const isCartSignature: boolean = (props.disclaimerEnabled !== undefined && props.disclaimerEnabled.isins.length > 0 && props.disclaimerEnabled.isTransfer === false);
    const documentsColumns: any =
    [
        {key: 'name', name: 'Fichero', fieldName: 'name', minWidth: 200, isResizable: true},
        {key: 'link', name: 'Acción', fieldName: 'url', width: 50, isResizable: false},
    ];

    if(props?.signatures)
    {
        props.signatures.forEach((signature: ISignature) =>
        {
            if(signature.expiresAt)
            {
                expiredDefault = expiredDefault || (new Date()) > signature.expiresAt;
            }

            if(signature.signAt)
            {
                signedDefault = signedDefault && true;
            }
        });
    }

    if(props !== undefined && props?.alreadySignedSubtitle === null)
    {
        props.alreadySignedSubtitle = "El proceso de firma se ha completado con éxito.";
    }

    if(props.disclaimerEnabled !== undefined && props.disclaimerEnabled.isins.length > 0 && props.isMultiDestiny === undefined)
    {
        if(firstTimeFunds.current === true)
        {
            firstTimeFunds.current = false;
            var isinsArray = [];
            if(props.disclaimerEnabled.isMultiIsin === true)
            {
                isinsArray = props.disclaimerEnabled.isins.flatMap((isins) => [isins.origin, isins.destiny]);
            }
            else
            {
                isinsArray = props.disclaimerEnabled.isins;
            }

            funds.getInfoFundsPortfolio(isinsArray).then((response: any) =>
            {
                setFundsInfo(response);
                setLoadingDatesInfo(false);
            });
        }
    }

    if(props.disclaimerEnabled !== undefined && (tickEnabled === undefined || tickEnabled === false) && props.isMultiDestiny === undefined)
    {
        if(firstTime.current === true)
        {
            firstTime.current = false;
            
            var arrayIsins = [];
            if(props.disclaimerEnabled.isMultiIsin === true)
            {
                arrayIsins = props.disclaimerEnabled.isins.flatMap((isins) => [isins.origin, isins.destiny]);
            }
            else
            {
                arrayIsins = props.disclaimerEnabled.isins;
            }

            funds.getClosingHours(arrayIsins).then((response: string) =>
            {
                if(response !== null && response !== undefined)
                {
                    setHour(response);
                    setTickEnabled(processHoursFunction(props.disclaimerEnabled?.processHours, hour));
                }
                else
                {
                    setTickEnabled(processHoursFunction(props.disclaimerEnabled?.processHours, props.disclaimerEnabled?.processHours[0].hour));
                }
            })
            .catch((error: any) =>
            {
                setTickEnabled(processHoursFunction(props.disclaimerEnabled?.processHours, props.disclaimerEnabled?.processHours[0].hour));
            });
        }
    }

    const toggleAttachmentsVisible = (isin: string) =>
    {
        setSelectedIsin(isin);
        setIsAttachmentsVisible(true);
        let arr: any[] = [];
        // eslint-disable-next-line array-callback-return
        fundsInfo.map((item, idx) =>
        {
            if(item.isin === isin && item.documents !== undefined)
            {
                for(let i=0; i<item.documents.length; i++)
                {
                    arr.push([{name: item.documents[i].name, url: item.documents[i].url, type: item.documents[i].type}]);
                }
            }
        });

        setDocuments(arr);
    };

    const renderAttachmentColumn = (item: any, index: any, column: any) =>
    {
        switch(column.key)
        {
            case 'link':
                return (<a href={item[0].url} target="_blank" rel="noreferrer"><i className={'ironia-icon downloads'} /> Descargar</a>);
            case 'name': default:
                return (item[0].name);
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() =>
    {
        if(expired !== undefined)
        {
            if(expired)
            {
                props.onExpired?.();
            }
            else if(countdown?.current/* && countdown.current.isCompleted*/)
            {
                countdown.current?.start();
            }
        }
    }, [expired, props]);

    const onCountComplete = () =>
    {
        setExpired(true);
    };

    const countdownRenderer: CountdownRendererFn = ({ minutes, seconds, completed }) =>
    {
        return(
            <>
                {minutes}:{seconds.toString().padStart(2, "0")}
            </>
        );
    };

    const requestNewCode = () =>
    {
        if(props.isApiSignature === true)
        {
            setSendingNewCode(true);
            props.onNewCodeRequested?.();
            Api.refreshSignature(props.signatures).then((response: any) =>
            {
                setSendingNewCode(false);
                if(response === null || response.signatures === null || response.signatures.length === 0)
                {
                    props.onError?.(response);
                    return;
                }
                setExpired(false);
                props?.onNewCodeSended?.(response.signatures);
                countdown.current?.start();
            })
            .catch(error =>
            {
                setSendingNewCode(false);
                let signatures = error?.response?.data.signatures;
                switch(error?.response?.data?.errorCode)
                {
                    case "CODE_STILL_VALID":
                        signatures.forEach((signature: ISignature) =>
                        {
                            if(signature && typeof signature.expiresAt === "string")
                            {
                                signature.expiresAt = moment(signature.expiresAt).toDate();
                            }
                            if(signature?.expiresAt && signature?.expiresAt > (new Date()))
                            {
                                props?.onSignatureChanged?.(signatures);
                                setExpired(false);
                            }
                            countdown.current?.start();
                            var message: string = "El código aún es válido.";
                            if(signatures.length === 1)
                            {
                                message = "Los códigos aún son válidos."
                            }
                            setErrorMessage(message);
                        });
                        break;
    
                    case "ALREADY_SIGNED":
                        signatures.forEach((signature: ISignature) =>
                        {
                            if(signature.signAt)
                            {
                                props?.onSignatureChanged?.(signatures);
                                setSigned(true);
                            }
                        });
                        break;
                }
                props.onError?.(error);
            });
        }
        else
        {
            setSendingNewCode(true);
            props.onNewCodeRequested?.();
            SignaturesService.getRefreshSignature({signatureIds: props.signatures.map((signature: ISignature) => signature.id)}).then((response: any) =>
            {
                setSendingNewCode(false);
                if(!response?.data.signatures)
                {
                    props.onError?.(response);
                    return;
                }
                setExpired(false);
                props?.onNewCodeSended?.(response.data.signatures);
                countdown.current?.start();
            })
            .catch(error =>
            {
                setSendingNewCode(false);
                let signatures = error?.response?.data.signatures;
                switch(error?.response?.data?.errorCode)
                {
                    case "CODE_STILL_VALID":
                        signatures.forEach((signature: ISignature) =>
                        {
                            if(signature && typeof signature.expiresAt === "string")
                            {
                                signature.expiresAt = moment(signature.expiresAt).toDate();
                            }
                            if(signature?.expiresAt && signature?.expiresAt > (new Date()))
                            {
                                props?.onSignatureChanged?.(signatures);
                                setExpired(false);
                            }
                            countdown.current?.start();
                            var message: string = "El código aún es válido.";
                            if(signatures.length === 1)
                            {
                                message = "Los códigos aún son válidos."
                            }
                            setErrorMessage(message);
                        });
                        break;
    
                    case "ALREADY_SIGNED":
                        signatures.forEach((signature: ISignature) =>
                        {
                            if(signature.signAt)
                            {
                                props?.onSignatureChanged?.(signatures);
                                setSigned(true);
                            }
                        });
                        break;
                }
                props.onError?.(error);
            });
        }
    };

    const onSubmit = (data: any) =>
    {
        if(expired)
        {
            return false;
        }

        props.onSubmit?.(data);
        setSending(true);
        setErrorMessage("");
        var signatureIds = props.signatures.map((signature: ISignature) => signature.id);
        var signatures = signatureIds.map((id: any, index: any) =>
        {
            return {
                'Id': id,
                'SignatureCode': data.signatureCode[index]
            }
        });

        if(props.isApiSignature === true)
        {
            Api.putSignature(signatures).then((response: any) =>
            {
                setSending(false);

                if(!response.success)
                {
                    props.onError?.(response);
                    return;
                }
                
                setSigned(true);
    
                if(props.disclaimerEnabled !== undefined)
                {
                    if(tickEnabled === undefined || tickEnabled === false)
                    {
                        response.hasDisclaimerTime = 0;
                    }
                    else
                    {
                        if(tickEnabled === true && props.disclaimerEnabled.value === true)
                        {
                            response.hasDisclaimerTime = 2;
                        }
                        else
                        {
                            response.hasDisclaimerTime = 1;
                        }
                    }
                }
                
                props.onSigned?.(response);
            })
            .catch(error =>
            {
                setSending(false);
                if(error.response.status === 401)
                {
                    setErrorMessage("No tiene permisos para completar el proceso de firma. Compruebe si la sesión ha caducado.");
                }
                else
                {
                    let signatures = error?.response?.data.signatures;
                    switch(error?.response?.data?.errorCode)
                    {
                        case "WRONG_CODE":
                            signatures.forEach((signature: ISignature) =>
                            {
                                if(signature.status === 3)
                                {
                                    setErrorMessage("El código no es válido.");
                                }
                            });
                            break;
                    }
                    countdown.current?.start();
                }
                props.onError?.(error);
            });
        }
        else
        {
            SignaturesService.putSignatures(signatures).then(async (response: any) =>
            {
                if(!response?.data?.success)
                {
                    setSending(false);
                    props.onError?.(response);
                    return;
                }
    
                if(props.isElementSigning !== undefined && props.isElementSigning === true)
                {
                    await ElementService.createElementsSubscription(signatures[0].Id, user.id).then(async (responseElement: boolean) =>
                    {
                        if (responseElement) {
                            await dispatch(setUserElementsStatus(user.id));
                        }
                        else {
                            setErrorMessage("Se ha producido un error al suscribirse al programa de Elements. Compruebe si la sesión ha caducado.")
                        }
                    })
                }

                setSending(false);
                setSigned(true);
    
                if(props.disclaimerEnabled !== undefined)
                {
                    if(tickEnabled === undefined || tickEnabled === false)
                    {
                        response.hasDisclaimerTime = 0;
                    }
                    else
                    {
                        if(tickEnabled === true && props.disclaimerEnabled.value === true)
                        {
                            response.hasDisclaimerTime = 2;
                        }
                        else
                        {
                            response.hasDisclaimerTime = 1;
                        }
                    }
                }
                
                props.onSigned?.(response);
            })
            .catch(error =>
            {
                setSending(false);
                if(error.response.status === 401)
                {
                    setErrorMessage("No tiene permisos para completar el proceso de firma. Compruebe si la sesión ha caducado.");
                }
                else
                {
                    let signatures = error?.response?.data.signatures;
                    switch(error?.response?.data?.errorCode)
                    {
                        case "WRONG_CODE":
                            signatures.forEach((signature: ISignature) =>
                            {
                                if(signature.status === 3)
                                {
                                    setErrorMessage("El código no es válido.");
                                }
                            });
                            break;
                    }
                    countdown.current?.start();
                }
                props.onError?.(error);
            });
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() =>
    {
        if(props.confirmElement === undefined)
        {
            setTickSubmit(true);
        }
        else
        {
            if(props.disclaimerEnabled === undefined)
            {
                if(props.confirmElement.value === true)
                {
                    setTickSubmit(true);
                }
                else
                {
                    setTickSubmit(false);
                }
            }
            else
            {
                if(tickEnabled === true)
                {
                    if(props.confirmElement.value === true && props.disclaimerEnabled.value === true)
                    {
                        setTickSubmit(true);
                    }
                    else if(props.confirmElement.value === true && props.disclaimerEnabled.value === false)
                    {
                        setTickSubmit(false);
                    }
                    else if(props.confirmElement.value === false && props.disclaimerEnabled.value === true)
                    {
                        setTickSubmit(false);
                    }
                    else
                    {
                        setTickSubmit(false);
                    }
                }
                else
                {
                    if(props.confirmElement.value === true)
                    {
                        setTickSubmit(true);
                    }
                    else
                    {
                        setTickSubmit(false);
                    }
                }
            }
        }
    }, [props.confirmElement, props.disclaimerEnabled, tickEnabled]);

    const objToStringify =
    {
        element: "",
        isin: props.disclaimerEnabled?.isins,
        username: user.fullname,
        userId: user.id,
        signatureId: props.signatures[0].id,
        clickTime: props.signatures[0].createdAt
    }

    if(signed)
    {
        return(
            <React.Fragment>
                <L.Main theme={theme}>
                    <L.TitleContainer>
                        <h2 style={{cursor: "default"}}>{(signed && (props.alreadySignedText || "¡Ya has firmado esta orden!"))}</h2>
                    </L.TitleContainer>
                    <L.TextTop theme={theme}>
                        {props.alreadySignedSubtitle}
                    </L.TextTop>
                </L.Main>
            </React.Fragment>
        );
    }
    else
    {
        return(
            <L.Main theme={theme}>
                {props.title !== "" &&
                (
                    <L.TitleContainer>
                        <h2 style={{cursor: 'default'}}>{(props.title)}</h2>
                    </L.TitleContainer>
                )}
                <L.TextTop theme={theme}>
                    {props.headerTextArea || "Recibirás un SMS en el teléfono con la clave para confirmar esta orden."}
                </L.TextTop>
                {(infoISINS === true || isCartSignature === true) &&
                (
                    <L.SeparatorTopWithIsins />
                )}
                {infoISINS === false && isCartSignature === false &&
                (
                    <L.SeparatorTop />
                )}
                {isCartSignature === true &&
                (
                    <React.Fragment>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <L.Text theme={theme}>
                                El código que te hemos enviado tiene una duración limitada. Después de eso, dejará de ser válido.
                            </L.Text>
                            <Stack className="section-wrapper" horizontal>
                                <Stack.Item className="base-column column-count">
                                    <L.TextCount theme={theme}>
                                        <Countdown ref={countdown}
                                            date={props.signatures[0].expiresAt}
                                            precision={2}
                                            renderer={countdownRenderer}
                                            onComplete={onCountComplete}
                                        />
                                    </L.TextCount>
                                </Stack.Item>
                                <Stack.Item className="base-column">
                                    {props.signatures.map((signature: ISignature, index: number) =>
                                    {
                                        var userIdentity: any;
                                        var userIdentityIndex = index;

                                        if(props.userIdentities && props.userIdentities.length >= index - 1)
                                        {
                                            if(props.mainUserIdentity !== undefined)
                                            {
                                                if(index === 0)
                                                {
                                                    userIdentity = props.mainUserIdentity;
                                                }
                                                else
                                                {
                                                    userIdentity = props.userIdentities[index - 1];
                                                }
                                            }
                                            else
                                            {
                                                userIdentity = props.userIdentities[index];
                                            }
                                        }
                                        else if((props.userIdentities !== undefined && props.userIdentities.length === undefined))
                                        {
                                            userIdentity = props.userIdentities;
                                        }
                                        return(
                                            <div key={'signature'+index}>
                                                <Controller
                                                    name={"signatureCode["+index+"]"}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                        minLength: 4,
                                                        maxLength: 4
                                                    }}
                                                    render={({field: {onChange, onBlur, value}}) => <L.VerificationTextField
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        // eslint-disable-next-line no-useless-concat
                                                        label={"Código de verificación para el " + "Interviniente 00" + (userIdentityIndex + 1)
                                                            + (userIdentity ? (" - "+[userIdentity?.firstName, userIdentity?.lastName, userIdentity?.secondLastName].join(" ")) : "")}
                                                        placeholder="Escribe aquí el código que te hemos enviado por SMS..."
                                                        required
                                                        errorMessage={errorMessage || (isDirty && errors.signatureCode ? "El código debe tener 4 caracteres." : "")}
                                                    />}
                                                />
                                            </div>
                                        )
                                    })}
                                </Stack.Item>
                            </Stack>
                            {expired &&
                            (
                                <React.Fragment>
                                    <L.Text theme={theme} className="error" style={{marginTop: "2em"}}>
                                        El código que te hemos enviado ha expirado. Tendrás que intentarlo de nuevo.
                                    </L.Text>
                                </React.Fragment>
                            )}
                            {props !== undefined && props.confirmElement !== undefined &&
                            (
                                <React.Fragment>
                                    <Stack className="section-wrapper" horizontal>
                                        <Stack.Item className="base-column">
                                        </Stack.Item>
                                        <Stack.Item className="base-column">
                                            <Checkbox label={props.confirmElement.text}
                                                checked={props.confirmElement.value}
                                                onChange={(ev?: any, checked?: boolean) =>
                                                {
                                                    objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                                    objToStringify.element = "Documentación de Cliente";
                                                    performTracking(JSON.stringify(objToStringify));
                                                    props.confirmElement?.setValue(!!checked)
                                                }}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    {props.complexProductAgreements !== undefined && props.complexProductAgreements.length > 0 && props.complexProductAgreements.map((complexProductAgreement: FnmContractComplexProductUserIdentityAgreementType, complexProductAgreementIndex: number) =>
                                    (
                                        <Stack className="section-wrapper" horizontal key={'complexProductAgreementIndex' + complexProductAgreementIndex }>
                                            <Stack.Item className="base-column">
                                            </Stack.Item>
                                            <Stack.Item className="base-column">
                                                <Checkbox
                                                    label={complexProductAgreement.profile
                                                        ? 'Realizo esta operación de forma voluntaria conociendo que el producto ' + complexProductAgreement.name + ' ' + complexProductAgreement.isin
                                                            + ' es un producto complejo y que mi perfil es ' + complexProductAgreement.profile + '; y he sido debidamente informado por Diaphanum SL'
                                                        : 'Realizo esta operación de forma voluntaria conociendo que el producto ' + complexProductAgreement.name + ' ' +complexProductAgreement.isin
                                                            + ' es un producto complejo y he sido informado por Diaphanum SL de la NO conveniencia del producto para mi perfil de inversor. Asumiendo esta '
                                                            + 'compra mediante la escritura manuscrita del texto «Este producto es complejo y se considera no conveniente para mí.» en la fecha '
                                                            + complexProductAgreement.date + ' firmado digitalmente desde el dispositivo ' + complexProductAgreement.phone
                                                    }
                                                    checked={complexProductAgreement.value}
                                                    onChange={(ev?: any, checked?: boolean) =>
                                                    {
                                                        objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                                        objToStringify.element = `Producto complejo ${complexProductAgreement.isin} por ${complexProductAgreement.profile ? 'test' : 'acuerdo'}`;
                                                        performTracking(JSON.stringify(objToStringify));
                                                        if(props.complexProductAgreements !== undefined)
                                                        {
                                                            var complexProductAgreementsWithoutCurrent = props.complexProductAgreements.filter(cPA => cPA.isin !== complexProductAgreement.isin);
                                                            complexProductAgreement.value = !complexProductAgreement.value;
                                                            props.setComplexProductAgreements((prevState: FnmContractComplexProductUserIdentityAgreementType[]) =>
                                                            [
                                                                ...complexProductAgreementsWithoutCurrent,
                                                                complexProductAgreement
                                                            ]);
                                                        }
                                                    }}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                    ))}
                                    <Stack className="subsection-wrapper" horizontal>
                                        {props.disclaimerEnabled !== undefined && tickEnabled === true &&
                                        (
                                            <Stack.Item className="base-column">
                                                {nationalIsins.current === true &&
                                                (
                                                    <React.Fragment>
                                                        <Checkbox label={props.disclaimerEnabled.text + nationalIsin}
                                                            checked={props.disclaimerEnabled.value}
                                                            onChange={(ev?: any, checked?: boolean) =>
                                                            {
                                                                objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                                                objToStringify.element = "Hora de Gestora";
                                                                performTracking(JSON.stringify(objToStringify));
                                                                props.disclaimerEnabled?.setValue(!!checked)
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                {nationalIsins.current === false &&
                                                (
                                                    <React.Fragment>
                                                        <Checkbox label={props.disclaimerEnabled.text}
                                                            checked={props.disclaimerEnabled.value}
                                                            onChange={(ev?: any, checked?: boolean) =>
                                                            {
                                                                objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                                                objToStringify.element = "Hora de Gestora";
                                                                performTracking(JSON.stringify(objToStringify));
                                                                props.disclaimerEnabled?.setValue(!!checked)
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </Stack.Item>
                                        )}
                                    </Stack>
                                </React.Fragment>
                            )}
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <L.TextDisclaimer>
                                        Manifiesto que he sido debidamente informado y tengo pleno conocimiento de que la operación que se lleva a cabo en este momento podría no reflejar el valor liquidativo del día en curso en virtud de que la orden podría experimentar un retraso de al menos un día.
                                        Asimismo, reconozco que los sistemas de información en el proceso de carga de los ficheros pueden experimentar errores en ocasiones excepcionales, los cuales podrían llegar a influir en el cálculo del valor en la fecha de liquidación por cuanto la orden podría verse afectada por un retraso de al menos un día.
                                        Por la presente, declaro ser consciente de ese riesgo y renuncio formalmente a mi derecho de presentar cualquier reclamación futura a estos efectos.
                                        Al cursar esta orden le recordamos que ha leído y aceptado voluntariamente las siguientes condiciones:
                                        <br />
                                        - Confirmo que he recibido, leído y comprendido la documentación obligatoria relacionada con las Inversiones Colectivas (IIC) y que estoy realizando esta operación por iniciativa propia.
                                        <br />
                                        - He sido informado de los motivos por los que alguna de las operaciones realizadas pudieran no reflejar el valor liquidativo del día en curso.
                                    </L.TextDisclaimer>
                                </div>
                            </div>
                            {expired &&
                            (
                                <React.Fragment>
                                    <L.OuterFooterArea>
                                        <DefaultButton onClick={props.onBackButtonClicked}
                                            disabled={sending}
                                            className="button tiny-secondary-button"
                                        >
                                            {props.backButtonText || "Volver"}
                                        </DefaultButton>
                                        {props?.onNewCodeSended &&
                                        (
                                            <DefaultButton disabled={sendingNewCode}
                                                onClick={requestNewCode}
                                                className="button tiny-primary-button"
                                            >
                                                {sendingNewCode && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                {props.requestNewCodeButtonText || "Recibir nuevo código"}
                                            </DefaultButton>
                                        )}
                                    </L.OuterFooterArea>
                                </React.Fragment>
                            )}
                            {!expired &&
                            (
                                <L.OuterFooterArea>
                                    <DefaultButton onClick={props.onCancelButtonClicked}
                                        disabled={sending}
                                        className="button tiny-secondary-button"
                                    >
                                        {props.cancelButtonText || "Cancelar"}
                                    </DefaultButton>
                                    <DefaultButton type="submit" className="button tiny-primary-button"
                                        disabled={sending
                                            || !isDirty
                                            || !isValid
                                            || (tickEnabled === true ? !tickSubmit : props.confirmElement !== undefined && !props.confirmElement?.value)
                                            || (props.complexProducts !== undefined && props.complexProductAgreements !== undefined && props.complexProducts.length !== props.complexProductAgreements.length)
                                            || (props.complexProductAgreements !== undefined && props.complexProductAgreements.some(cPA => !cPA.value))
                                        }
                                    >
                                        {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                        {props.submitButtonText || "Firmar"}
                                    </DefaultButton>
                                </L.OuterFooterArea>
                            )}    
                        </form>
                        {props.dataCart !== undefined && loadingDatesInfo === true &&
                        (
                            <React.Fragment>
                                <div className="ms-Grid-row">
                                    <L.FundDetailCart>Cargando toda la información asociada a tu operación...</L.FundDetailCart>
                                </div>
                            </React.Fragment>
                        )}
                        {props.dataCart !== undefined && loadingDatesInfo === false &&
                        (
                            <React.Fragment>
                                <div className="ms-Grid-row">
                                    {props.dataCart.map((item: IDataCart, idx: number) =>
                                    {
                                        return(
                                            <React.Fragment key={idx}>
                                                <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3">
                                                    <L.FundDetailCart theme={theme}>
                                                        Fecha liquidativo {item.isin}: <b style={{color: "#CC214F"}}>{item.date}</b>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <PrimaryButton className="light-circled-icon" id={"buttonId" + item.isin} onClick={() => toggleAttachmentsVisible(item.isin)}>
                                                            <i className={"ironia-icon pdf"} />
                                                        </PrimaryButton>
                                                    </L.FundDetailCart>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        )}   
                        {isAttachmentsVisible &&
                        (
                            <Callout gapSpace={0} target={`#buttonId${selectedIsin}`} onDismiss={() => setIsAttachmentsVisible(false)} setInitialFocus isBeakVisible={false}>
                                <DefaultButton style={{marginTop: "0.75em", marginRight: "1em"}} onClick={() => setIsAttachmentsVisible(false)} className="closeButton">
                                    <i className='ironia-icon cross' />
                                </DefaultButton>
                                <L.TitleModalDocuments>Documentos del Fondo {selectedIsin}</L.TitleModalDocuments>
                                <DetailsList items={documents} columns={documentsColumns} onRenderItemColumn={renderAttachmentColumn} selectionMode={SelectionMode.none} />
                            </Callout>
                        )}
                    </React.Fragment>
                )}
                {isCartSignature === false && 
                (
                    <React.Fragment>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <L.Text theme={theme}>
                                El código que te hemos enviado tiene una duración limitada. Después de eso, dejará de ser válido.
                            </L.Text>
                            <Stack className="section-wrapper" horizontal>
                                <Stack.Item className="base-column column-count">
                                    <L.TextCount theme={theme}>
                                        <Countdown ref={countdown}
                                            date={props.signatures[0].expiresAt}
                                            precision={2}
                                            renderer={countdownRenderer}
                                            onComplete={onCountComplete}
                                        />
                                    </L.TextCount>
                                </Stack.Item>
                                <Stack.Item className="base-column">
                                    {props.signatures.map((signature: ISignature, index: number) =>
                                    {
                                        var userIdentity: any;
                                        var userIdentityIndex = index;

                                        if(props.userIdentities && props.userIdentities.length >= index - 1)
                                        {
                                            if(props.mainUserIdentity !== undefined)
                                            {
                                                if(index === 0)
                                                {
                                                    userIdentity = props.mainUserIdentity;
                                                }
                                                else
                                                {
                                                    userIdentity = props.userIdentities[index - 1];
                                                }
                                            }
                                            else
                                            {
                                                userIdentity = props.userIdentities[index];
                                            }
                                        }
                                        else if((props.userIdentities !== undefined && props.userIdentities.length === undefined))
                                        {
                                            userIdentity = props.userIdentities;
                                        }
                                        return(
                                            <div key={'signature' + index}>
                                                <Controller name={"signatureCode[" + index + "]"}
                                                    control={control}
                                                    rules={{required: true, minLength: 4, maxLength: 4}}
                                                    render={({field: {onChange, onBlur, value}}) =>
                                                        <L.VerificationTextField autoComplete="off"
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            selected={value}
                                                            // eslint-disable-next-line no-useless-concat
                                                            label={"Código de verificación para el " + "Interviniente 00" + (userIdentityIndex + 1)
                                                                + (userIdentity ? (" - " + [userIdentity?.firstName, userIdentity?.lastName, userIdentity?.secondLastName].join(" ")) : "")}
                                                            placeholder="Escribe aquí el código que te hemos enviado por SMS..."
                                                            required
                                                            errorMessage={errorMessage || (isDirty && errors.signatureCode ? "El código debe tener 4 caracteres." : "")}
                                                        />
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                </Stack.Item>
                            </Stack>
                            {expired &&
                            (
                                <React.Fragment>
                                    <L.Text theme={theme} className="error" style={{marginTop: "2em"}}>
                                        El código que te hemos enviado ha expirado. Tendrás que intentarlo de nuevo.
                                    </L.Text>
                                </React.Fragment>
                            )}
                            {props !== undefined && props.confirmElement !== undefined &&
                            (
                                <React.Fragment>
                                    <Stack className="section-wrapper" horizontal>
                                        <Stack.Item className="base-column">
                                        </Stack.Item>
                                        <Stack.Item className="base-column">
                                            <Checkbox label={props.confirmElement.text}
                                                checked={props.confirmElement.value}
                                                onChange={(ev?: any, checked?: boolean) =>
                                                {
                                                    objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                                    objToStringify.element = "Documentación de Cliente";
                                                    performTracking(JSON.stringify(objToStringify));
                                                    props.confirmElement?.setValue(!!checked)
                                                }}
                                            />
                                            {infoISINS === true && props?.disclaimerEnabled?.isins && props?.disclaimerEnabled?.isins.map((isins, index) =>
                                            (
                                                <React.Fragment key={index}>
                                                    <L.FundDetail theme={theme}>
                                                        Fondo origen: {isins.origin}
                                                        &nbsp;&nbsp;
                                                        <PrimaryButton className="light-circled-icon" id={"buttonId"+isins.origin} onClick={() => toggleAttachmentsVisible(isins.origin)}>
                                                            <i className={"ironia-icon pdf"} />
                                                        </PrimaryButton>
                                                        &nbsp;&nbsp;
                                                        Fondo destino: {isins.destiny}
                                                        &nbsp;&nbsp;
                                                        <PrimaryButton className="light-circled-icon" id={"buttonId" + isins.destiny} onClick={() => toggleAttachmentsVisible(isins.destiny)}>
                                                            <i className={"ironia-icon pdf"} />
                                                        </PrimaryButton>
                                                    </L.FundDetail>
                                                </React.Fragment>

                                            ))}
                                        </Stack.Item>
                                    </Stack>
                                    <Stack className="subsection-wrapper" horizontal>
                                        {props.disclaimerEnabled !== undefined && tickEnabled === true &&
                                        (
                                            <Stack.Item className="base-column">
                                                {nationalIsins.current === true &&
                                                (
                                                    <React.Fragment>
                                                        <Checkbox label={props.disclaimerEnabled.text+nationalIsin}
                                                            checked={props.disclaimerEnabled.value}
                                                            onChange={(ev?: any, checked?: boolean) =>
                                                            {
                                                                objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                                                objToStringify.element = "Hora de Gestora";
                                                                performTracking(JSON.stringify(objToStringify));
                                                                props.disclaimerEnabled?.setValue(!!checked)
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                {nationalIsins.current === false &&
                                                (
                                                    <React.Fragment>
                                                        <Checkbox label={props.disclaimerEnabled.text}
                                                            checked={props.disclaimerEnabled.value}
                                                            onClick={() =>
                                                            {
                                                                objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                                                objToStringify.element = "Hora de Gestora";
                                                                performTracking(JSON.stringify(objToStringify));
                                                            }}
                                                            onChange={(ev?: any, checked?: boolean) => props.disclaimerEnabled?.setValue(!!checked)}
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </Stack.Item>
                                        )}
                                    </Stack>
                                </React.Fragment>
                            )}
                            {expired &&
                            (
                                <React.Fragment>
                                    <L.OuterFooterArea>
                                        <DefaultButton onClick={props.onBackButtonClicked} disabled={sending} className="button tiny-secondary-button">
                                            {props.backButtonText || "Volver"}
                                        </DefaultButton>
                                        {props?.onNewCodeSended &&
                                        (
                                            <DefaultButton disabled={sendingNewCode} onClick={requestNewCode} className="button tiny-primary-button">
                                                {sendingNewCode && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                {props.requestNewCodeButtonText || "Recibir nuevo código"}
                                            </DefaultButton>
                                        )}
                                    </L.OuterFooterArea>
                                </React.Fragment>
                            )}
                            {!expired &&
                            (
                                <L.OuterFooterArea>
                                    <DefaultButton onClick={props.onCancelButtonClicked}
                                        disabled={sending}
                                        className="button tiny-secondary-button"
                                    >
                                        {props.cancelButtonText || "Cancelar"}
                                    </DefaultButton>
                                    <DefaultButton type="submit" className="button tiny-primary-button"
                                        disabled={sending || !isDirty || !isValid || (tickEnabled === true ? !tickSubmit : props.confirmElement !== undefined && !props.confirmElement?.value)}>
                                        {sending && <Spinner size={SpinnerSize.small} className="button-spinner"
                                    />}
                                        {props.submitButtonText || "Firmar"}
                                    </DefaultButton>
                                </L.OuterFooterArea>
                            )}
                            {isAttachmentsVisible &&
                            (
                                <Callout gapSpace={0} target={`#buttonId${selectedIsin}`} onDismiss={() => setIsAttachmentsVisible(false)} setInitialFocus isBeakVisible={false}>
                                    <DefaultButton style={{marginTop: "0.75em", marginRight: "1em"}} onClick={() => setIsAttachmentsVisible(false)} className="closeButton">
                                        <i className='ironia-icon cross' />
                                    </DefaultButton>
                                    <L.TitleModalDocuments>Documentos del Fondo {selectedIsin}</L.TitleModalDocuments>
                                    <DetailsList items={documents} columns={documentsColumns} onRenderItemColumn={renderAttachmentColumn} selectionMode={SelectionMode.none} />
                                </Callout>
                            )}
                        </form>
                    </React.Fragment>
                )}
            </L.Main>
        );
    }
};