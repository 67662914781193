import { ThemeContext } from "@fluentui/react-theme-provider";
import React, { useContext } from "react";
import Auth from "../../Auth/Auth";
import config from "../../config";
import ElementService from "../../services/ElementsService";
import * as IA from "./ElementStyles"; 
import { IStackTokens, ITextFieldStyles, Separator, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { SignatureComponent } from "../../components/Signature/SignatureComponent";
import scrollToTop from "../../utils/scrollToTop";
import SignaturesService, { ISignature } from "../../services/SignaturesService";
import DocumentTitle from "react-document-title";
import { useHistory } from "react-router";
import moment from "moment";
import ModalElementsIATransfer from "./ModalElementsIATransfer";
import UserIdentitiesService from "../../services/UserIdentitiesService";
import { useId } from "@fluentui/react-hooks";
import { ModalRefund } from "../Refunds/components/ModalRefund";
import orders from "../../services/OrderService";
import users from "../../services/UserService";
import { AccountsAutorized } from "../../services/SubscriptionService";
import { Accounts } from "../Refunds/Refunds";
import DropdownAccounts from "../Refunds/components/DropdownAccounts";

const ElementsIATransfer = () =>
{
    const stylesPassword: Partial<ITextFieldStyles> =
    {
        revealButton:
        {
            backgroundColor: "transparent !important",
            paddingTop: "10px"
        }
    };
    const horizontalGapStackTokens: IStackTokens =
    {
        childrenGap: 10,
    };
    const inputId = useId();
    const history = useHistory();
    const theme = useContext(ThemeContext);
    const subscribed = Auth.isSubscribed();
    const user = Auth.getUserProfile();
    const {register, setError, formState: {errors, touchedFields}, getValues} = useForm({mode: "onChange"});
    const [signatures, setSignatures] = React.useState<ISignature[]>([]);
    const [getSignature, setSignature] = React.useState<boolean>(false);
    const [showSignature, setShowSignature] = React.useState<boolean>(false);
    const [isSignatureShowed, setIsSignatureShowed] = React.useState<boolean>(false);
    const [modalOKShow, setModalOKShow] = React.useState<boolean>(false);
    const [modalKOShow, setModalKOShow] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const firstTime = React.useRef<boolean>(true);
    const [refundCode, setRefundCode] = React.useState<any>("");
    const [refundIBAN, setRefundIBAN] = React.useState<string>("");
    const [accounts, setAccounts] = React.useState<Accounts[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [processingLoading, setProcessingLoading] = React.useState<boolean>(false);

    const checkRefundCode = async (usrId: any) =>
    {
        const refund = await UserIdentitiesService.isRefundCodeDefined(user.mainUserIdentity.id).catch((error: any) => {});

        if(!!refund === refund)
        {
            firstTime.current = false;
            setLoading(false);
            if(refund === true)
            {
                setRefundCode("claveReembolso");
            }
        }
    };

    const checkDefaultIBAN = async (userId: any) =>
    {
        if(userId > 0)
        {
            users.getBankAccountsAutorized(userId).then((response: AccountsAutorized[]) =>
            {
                if(response !== null && response.length > 0)
                {
                    let accounts: Accounts[] = [];
                    
                    for(let i=0; i<response.length; i++)
                    {
                        accounts.push(
                        {
                            id: response[i].id as number,
                            value: response[i].accountOrigin,
                            default: response[i].isSelected
                        });

                        if(response[i].isSelected === true)
                        {
                            setRefundIBAN(response[i].accountOrigin);
                        }
                    }
                    setAccounts(accounts);
                }
            });
        }
    }

    const onChangeIBANValue = React.useCallback((newValue?: string) =>
    {
        setRefundIBAN(newValue as string);
    }, []);

    React.useEffect(() =>
    {
        if(firstTime.current === true)
        {
            checkRefundCode(user.id);
            checkDefaultIBAN(user.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() =>
    {
        scrollToTop();
    }, []);

    React.useEffect(() =>
    {
        if(getSignature === true && signatures.length === 0)
        {
            SignaturesService.createSignatureNoPlan(user.id).then((response: any) =>
            {
                if(response.data.id !== null)
                {
                    setSignatures([response.data]);
                    setIsSignatureShowed(true);
                    setShowSignature(true);
                }
            });
        }
    }, [signatures, user.id, getSignature]);

    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    const onChangeError = React.useCallback((value?: string) =>
    {
        let newVal = value === undefined ? "" : value;
        setErrorMessage(newVal);
    }, []);

    const handleSubmit = () =>
    {
        const values = getValues(["clave", "amount"]);

        if(refundIBAN.length !== 24)
        {
            onChangeError("El IBAN debe contener 24 dígitos, con 2 letras al comienzo y 22 números al final.");
            return;
        }

        var data =
        {
            iban: refundIBAN,
            amount: values[1],
            userId: user.id,
            signatureId: signatures[0].id
        };

        ElementService.transferElementsIA(data).then((response: any) =>
        {
            if(response.status === true)
            {
                setModalOKShow(true);
            }
            else
            {
                setModalKOShow(true);
            }
        });
    };

    const handleShow = () =>
    {
        setProcessingLoading(true);
        const values = getValues(["clave", "amount"]);
        let semaphore = true;

        if(values[0] === "")
        {
            setError("clave", {type: "required"}, {shouldFocus: true});
            semaphore = false;
        }
        if(values[1] === "")
        {
            setError("amount", {type: "required"}, {shouldFocus: true});
            semaphore = false;
        }
        if(refundIBAN === "")
        {
            setError("account", {type: "required"}, {shouldFocus: true});
            semaphore = false;
        }
        if(semaphore === true)
        {
            let model: any =
            {
                refundCode: values[0],
                userId: user.id
            };

            orders.checkRefundCode(model).then((response: any) =>
            {
                if(response === true)
                {
                    setSignature(true);
                    setProcessingLoading(false);
                }
                else
                {
                    setErrorMessage(response.message);
                    setProcessingLoading(false);
                    setError("clave", {type: "value"}, {shouldFocus: true});
                }
            });
        }
        else
        {
            setProcessingLoading(false);
        }
    };

    const handleRefund: any = (event: any, item: any) =>
    {
        setRefundCode(item);
    };

    if(subscribed === false)
    {
        localStorage.clear();
        window.location.href = config.server_host + '/login';
        return (<></>);
    }

    return(
        <DocumentTitle title='IronIA - Reembolso de ElementsIA'>
            <IA.IATransferMain theme={theme}>
                <IA.IATitleContainer>
                    <h2>Transferencia Elements IA</h2>
                </IA.IATitleContainer>
                <IA.IASeparatorTop />
                <div className="ms-Grid restarted-ms-Grid">
                    {loading &&
                    (
                        <React.Fragment>
                            <Spinner size={SpinnerSize.large} />
                        </React.Fragment>
                    )}
                    {loading === false &&
                    (
                        <React.Fragment>
                            <div className="ms-Grid-row">
                                <IA.IATransferForm className="ms-Grid-col ms-sm12 ms-xl8 ms-xxxl8" theme={theme}>
                                    <IA.IACardContainer theme={theme}>
                                        <IA.IACardHeader>
                                            <IA.IACardHeadingContainer>
                                                <h3>Transferencia de Elements IA mediante transferencia SEPA no inmediata</h3>
                                            </IA.IACardHeadingContainer>
                                            <Separator />
                                        </IA.IACardHeader>
                                        <IA.IACardBody>
                                            {refundCode === "" &&
                                            (
                                                <React.Fragment>
                                                    <Stack horizontal tokens={horizontalGapStackTokens}>
                                                        <Stack.Item order={1} style={{width:"75%"}}>
                                                            <TextField styles={stylesPassword}
                                                                disabled={true}
                                                                canRevealPassword={true}
                                                                type={"password"}
                                                                autoComplete="off"
                                                                required={true}
                                                                label={"Clave de Reembolso"}
                                                                placeholder={"Escribe aquí la Clave de Reembolso"}
                                                                errorMessage={errors.clave &&
                                                                (
                                                                    (typeof (errors.clave.type) === "string"
                                                                        && errors.clave.type === "required"
                                                                        && "Debe establecer primero su Clave de Reembolso.")
                                                                    || "Clave de Reembolso errónea"
                                                                )}
                                                            />
                                                        </Stack.Item>
                                                        <Stack.Item order={2} style={{width:"25%"}}>
                                                            <ModalRefund onChange={handleRefund} />
                                                        </Stack.Item>
                                                    </Stack>
                                                </React.Fragment>
                                            )}
                                            {refundCode !== "" &&
                                            (
                                                <React.Fragment>
                                                    <Stack>
                                                        <Stack.Item>
                                                            <TextField styles={stylesPassword}
                                                                {...register("clave", {required: true})}
                                                                canRevealPassword={true}
                                                                type={"password"}
                                                                id={inputId}
                                                                autoComplete="off"
                                                                required={true}
                                                                label={"Clave de Reembolso"}
                                                                placeholder={"Escribe aquí la Clave de Reembolso"}
                                                                errorMessage={errors.clave &&
                                                                (
                                                                    (typeof (errors.clave.type) === "string"
                                                                        && errors.clave.type === "required"
                                                                        && "Debe indicar su Clave de Reembolso.")
                                                                    || (typeof (errors.clave.type) === "string"
                                                                        && errors.clave.type === "value"
                                                                        && "La Clave de Reembolso proporcionada es incorrecta")
                                                                    || "Clave de Reembolso errónea"
                                                                )}
                                                            />
                                                        </Stack.Item>
                                                    </Stack>
                                                </React.Fragment>
                                            )}
                                            <Stack>
                                                <Stack.Item>
                                                    <DropdownAccounts accounts={accounts} dropdownChange={(newValue: string) => {onChangeIBANValue(newValue)}} errorMessage={errorMessage} alignment={'end'} />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <TextField required={true}
                                                        {...register("amount",
                                                            {
                                                                required: true,
                                                                min: 1000,
                                                                pattern: /[0-9+]/
                                                            }
                                                        )}
                                                        autoComplete="off"
                                                        label={"Importe"}
                                                        placeholder={"Introduce el importe a reembolsar. Mínimo 1000 Elements IA"}
                                                        errorMessage={errors.amount &&
                                                        (
                                                            (typeof (errors.amount.type) === "string" && errors.amount.type === "required" && "Debe indicar una cantidad")
                                                            || (typeof (errors.amount.type) === "string" && errors.amount.type === "min" && "Debe introducir una cantidad superior a 1000 Elements IA")
                                                            || "Introduzca una cantidad válida."
                                                        )}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                            <IA.IAContainerButton>
                                                {touchedFields.amount && errors.amount === undefined
                                                    && refundIBAN.length === 24 && errors.account === undefined
                                                    && touchedFields.clave && errors.clave === undefined
                                                    && !isSignatureShowed &&
                                                (
                                                    <IA.IACardButton size="lg" onClick={handleShow}>
                                                        Firmar
                                                    </IA.IACardButton>
                                                )}
                                                {((!touchedFields.amount || errors.amount !== undefined
                                                    || refundIBAN.length !== 24 || errors.account !== undefined
                                                    || !touchedFields.clave || errors.clave !== undefined)
                                                    || isSignatureShowed) &&
                                                (
                                                    <IA.IADisabledCardButton size="lg">
                                                        Firmar
                                                    </IA.IADisabledCardButton>
                                                )}
                                            </IA.IAContainerButton>
                                        </IA.IACardBody>
                                    </IA.IACardContainer>
                                </IA.IATransferForm>
                                <IA.IAInfoForm className="ms-Grid-col ms-sm12 ms-xl4 ms-xxxl4" theme={theme}>
                                    <IA.IACard theme={theme}>
                                        <IA.IACardTitle theme={theme}>
                                            <IA.IATitleCardText theme={theme}>
                                                Información de las Transferencias de Elements IA
                                            </IA.IATitleCardText>
                                        </IA.IACardTitle>
                                        <IA.IACardBody>
                                            <IA.IAText theme={theme}>
                                                Puedes reembolsar tantos Elements IA como poseas en IronIA, siempre y cuando la cantidad sea de un mínimo de 1000 Elements IA.
                                                <br />
                                                <br />
                                                La transferencia del importe se realizará mediante transferencia SEPA a una cuenta corriente previamente 
                                                autorizada en la que hayamos verificado previamente tu titularidad para cumplir con la normativa antibloqueo 
                                                de capitales y financiación del terrorismo.
                                                <br />
                                                <br />
                                                Nota: Esta transferencia no es inmediata desde que se cursa y valida la petición de transferencia.
                                            </IA.IAText>
                                        </IA.IACardBody>
                                    </IA.IACard>
                                </IA.IAInfoForm>
                            </div>
                            {showSignature === true &&
                            (
                                <React.Fragment>
                                    <div className="ms-Grid-row">
                                        <IA.IASignatureForm>
                                            <SignatureComponent
                                                signatures={signatures}
                                                alreadySignedText=" "
                                                submitButtonText="Firmar Reembolso"
                                                cancelButtonText="Volver"
                                                headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX" + signatures[0].phone?.slice(signatures[0].phone.length - 3) + " por SMS para firmar el contrato."}
                                                backButtonText="Volver"
                                                onBackButtonClicked={() => history.push('/ElementsIA')}
                                                onCancelButtonClicked={() => history.push('/ElementsIA')}
                                                requestNewCodeButtonText="Recibir nuevo código"
                                                onNewCodeSended={(signatures: any) =>
                                                {
                                                    processExpirationDate(signatures);
                                                    setSignatures(signatures);
                                                }}
                                                onSignatureChanged={(signatures: any) =>
                                                {
                                                    processExpirationDate(signatures);
                                                    setSignatures(signatures);
                                                }}
                                                onSigned={handleSubmit}
                                            />
                                        </IA.IASignatureForm>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {modalOKShow === true && <ModalElementsIATransfer setModalShow={setModalOKShow} okModal={true} />}
                    {modalKOShow === true && <ModalElementsIATransfer setModalShow={setModalKOShow} okModal={false} errorMessage={errorMessage} />}
                </div>
                {processingLoading === true &&
                (
                    <IA.IAProcessingLoadingText>
                        Comprobando los valores introducidos...
                    </IA.IAProcessingLoadingText>
                )}
            </IA.IATransferMain>
        </DocumentTitle>
    );
};

export default ElementsIATransfer;