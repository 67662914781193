import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import CompetitionsService, {
  CompetitionType,
  CompetitionTypes,
  CompetitionsUsersType,
  CompetitionsUsersTypeElement,
} from "../../services/CompetitionsService";
import { Spinner, SpinnerSize, DefaultButton } from "@fluentui/react";
import styles from "./index.module.sass";
import { currencyNeutreFormatter } from "../../utils/numberFormatter";
import { useHistory, useParams } from "react-router-dom";
import Auth from "../../Auth/Auth";
import PortfolioModelWalletService, {
  ModelWallet,
  ModelWalletSelector,
} from "../../services/PortfolioModelWalletService";
import Select from "react-dropdown-select";
import ElementsService, {
  ElementAmountType,
  ServiceType,
  UpdateElementsAmountType,
} from "../../services/ElementsService";
import BackButton from "../../components/buttons/BackButton";
import { GenericMessageModalWithoutButton } from "../../components/Modals/GenericModal";

const CompetitionParticipate = () => {
  const [error, setError] = useState({ status: false, message: "" });
  const [loading, setLoading] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);

  const { competitionId } = useParams<any>();
  const [competition, setCompetition] = useState<CompetitionType>();
  const [savingPlanModelPortfolios, setSavingPlanModelPortfolios] = useState<
    ModelWalletSelector[]
  >([]);
  const [description, setDescription] = useState<string>("");
  const [selectedModelPortfolio, setSelectedModelPortfolio] = useState("");
  const [elementsAmount, setElementsAmount] = useState<ElementAmountType>();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const history = useHistory();
  const plan = Auth.getActivePlan();
  const user = Auth.getUserProfile();

  useEffect(() => {
    CompetitionsService.getCompetition(competitionId).then(
      (response: CompetitionType) => {
        setCompetition(response);
      },
      (error: any) => {
        setError({ status: true, message: error.response.data });
      }
    );
  }, []);

  useEffect(() => {
    setLoading(true);

    PortfolioModelWalletService.getModelWallets(plan.id)
      .then((response: ModelWallet[]) => {
        let spModelPortfolios = response.map((portfolio: ModelWallet) => ({
          key: portfolio.id.toString(),
          text: portfolio.name,
          id: portfolio.id.toString(),
        }));

        setSavingPlanModelPortfolios(spModelPortfolios);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });

    ElementsService.getElementsAmountByUserId(user.id)
      .then((elementsAmountAux: ElementAmountType) => {
        setElementsAmount(elementsAmountAux);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [plan.id]);

  const participate = () => {
    setSending(true);

    const participationValues: CompetitionsUsersTypeElement = {
      competitionId: competitionId,
      userId: user.id,
      portfolioModelId: parseInt(selectedModelPortfolio),
    };

    CompetitionsService.inscribeInCompetition(participationValues)
      .then(
        (response: CompetitionType) => {
          setIsModal(true);
          setShowModal(true);
        },
        (error: any) => {
          setError({ status: true, message: error.response.data });
        }
      )
      .finally(() => {
        setSending(false);
      });
  };

  if (error.status) {
    return (
      <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
        <div>Error: {error.message}</div>
      </DocumentTitle>
    );
  }

  const options = savingPlanModelPortfolios.map((option) => ({
    value: option.id,
    label: option.text,
  }));

  return (
    <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
      <section className={`${styles.index}`}>
        <BackButton />
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <h2>Competiciones - Versión Preliminar</h2>
          </div>
        </div>
        {loading && <Spinner size={SpinnerSize.large} />}
        {!loading && (
          <div style={{ display: "flex" }}>
            <article className={`${styles.card} rounded background-background`}>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <p
                    className="descriptioncompetition"
                    style={{ marginBottom: "10px" }}
                  >
                    Si pulsa en participar se le restarán{" "}
                    {competition?.typeOfPrice == 0 ? (
                      <>
                        {currencyNeutreFormatter.format(competition.price)} de
                        Elements IA.
                      </>
                    ) : (
                      <>
                        {currencyNeutreFormatter.format(
                          competition?.price ?? 0
                        )}{" "}
                        de Elements Agua.
                      </>
                    )}
                  </p>
                  <p
                    className="descriptioncompetition"
                    style={{ marginBottom: "10px" }}
                  >
                    Selecciona la cartera modelo con la que deseas participar en
                    la competición. La rentabilidad de la cartera durante el
                    periodo de competición determinara a los ganadores.
                  </p>
                  {competition?.type == 0 ? (
                    <p
                      className="descriptioncompetition"
                      style={{ marginBottom: "10px" }}
                    >
                      Esta competición es a un solo ganador: la cartera con más
                      rentabilidad se lleva todos los Elements de los
                      participantes.
                    </p>
                  ) : (
                    <p className="descriptioncompetition">
                      Esta competición es a tres ganadores: el que más
                      rentabilidad obtenga se lleva el 50% de los Elements de
                      los participantes, el segundo el 30% y el tercero el 20%"{" "}
                    </p>
                  )}
                  <div className="ms-Grid-row" style={{ marginTop: "20px" }}>
                    <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                      Cartera Modelo
                    </p>
                    {/*//@ts-ignore */}
                    <Select
                      options={options}
                      className={`${styles.inputSelect} ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4`}
                      onChange={(selectedOptions) =>
                        setSelectedModelPortfolio(selectedOptions[0].value)
                      }
                      placeholder=""
                    />
                  </div>
                  {competition != undefined &&
                  elementsAmount != undefined &&
                  competition.typeOfPrice == 0 &&
                  competition?.price > elementsAmount?.elementIA ? (
                    <p style={{ color: "red", marginTop: "30px" }}>
                      No puede participar en la competición ya que actualmente
                      posee {elementsAmount?.elementIA} Elements IA y el precio
                      por participar es de {competition?.price} Elements IA{" "}
                    </p>
                  ) : (
                    <></>
                  )}
                  {competition != undefined &&
                  elementsAmount != undefined &&
                  competition.typeOfPrice == 1 &&
                  competition?.price > elementsAmount?.elementWater ? (
                    <p style={{ color: "red", marginTop: "30px" }}>
                      No puede participar en la competición ya que actualmente
                      posee {elementsAmount?.elementWater} Elements Agua y el
                      precio por participar es de {competition?.price} Elements
                      Agua
                    </p>
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      position: "relative",
                      top: "190px",
                    }}
                  >
                    <DefaultButton
                      className="button tiny-primary-button"
                      onClick={() => history.push("/competitions")}
                      disabled={sending}
                    >
                      Cancelar
                    </DefaultButton>

                    <DefaultButton
                      className="button tiny-primary-button"
                      type="submit"
                      disabled={
                        sending ||
                        (competition != undefined &&
                          elementsAmount != undefined &&
                          competition.typeOfPrice == 1 &&
                          competition?.price > elementsAmount?.elementWater) ||
                        (competition != undefined &&
                          elementsAmount != undefined &&
                          competition.typeOfPrice == 0 &&
                          competition?.price > elementsAmount?.elementIA)
                      }
                      onClick={participate}
                    >
                      {sending && (
                        <Spinner
                          size={SpinnerSize.small}
                          className="button-spinner"
                        />
                      )}
                      Participar
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </article>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                width: "50%",
              }}
            >
              <article
                className={`${styles.littlecard} ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 rounded background-background`}
              >
                <header>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        marginBottom: "0px !important",
                        fontSize: "16px",
                        fontWeight: "600",
                        wordBreak: "break-word",
                        alignSelf: "center",
                      }}
                    >
                      {competition?.name}
                    </p>
                  </div>
                  <p className="color-neutralTertiaryAlt">
                    Del {competition?.startDate.split("/")[1]}/
                    {competition?.startDate.split("/")[0]}/
                    {competition?.startDate.split("/")[2]}
                    {" "} al {" "}
                    {competition?.endDate.split("/")[1]}/
                    {competition?.endDate.split("/")[0]}/
                    {competition?.endDate.split("/")[2]}
                  </p>
                </header>
                <div
                  style={{
                    position: "relative",
                    width: "390x",
                    height: competition?.price == 0 ? "204px" : "190px",
                  }}
                >
                  <main
                    style={{ marginTop: "10px", wordBreak: "break-word" }}
                    dangerouslySetInnerHTML={{
                      __html: competition?.description ?? "",
                    }}
                  />
                  <footer
                    style={{
                      backgroundColor: "white",
                      width: "100%", // Opcional, ajusta el ancho según necesites
                      fontSize: "14px",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 10px", // Añade espacio horizontal entre los elementos
                      boxSizing: "border-box",
                      paddingLeft: "20px",
                      paddingRight: "20px",

                      position: "absolute",
                      bottom: "40px",
                      right: "7px",
                    }}
                  >
                    <span className={styles.type}>
                      Tipo: {CompetitionTypes[competition?.type ?? 0]}
                    </span>
                    <span className={styles.price}>
                      {competition?.price === 0 ? (
                        <>Inscripción gratuita</>
                      ) : (
                        <>
                          {competition != undefined
                            ? currencyNeutreFormatter.format(competition.price)
                            : 0}{" "}
                          Elements{" "}
                          {competition?.typeOfPrice == 1 ? "Agua" : "IA"}
                        </>
                      )}
                    </span>
                  </footer>
                </div>
              </article>
            </div>
            {isModal && (
              <GenericMessageModalWithoutButton
                title="Inscripción registrada"
                body={
                  <p>
                    Tu inscripción ha sido registrada. Recuerda que por
                    participar, has utilizado {competition?.price} Elements{" "}
                    {competition?.typeOfPrice == 1 ? "Agua" : "IA"}.
                  </p>
                }
                btnSubmitText="Aceptar"
                showModal={showModal}
                onSubmit={() => {
                  setShowModal(false);
                  history.push("/competitions");
                }}
                onClose={() => {
                  setShowModal(false);
                  history.push("/competitions");
                }}
              ></GenericMessageModalWithoutButton>
            )}
          </div>
        )}
      </section>
    </DocumentTitle>
  );
};

export default CompetitionParticipate;
