export const ResourcesHelpPlans =
{
    MENU_PRINCIPAL: "Nuestro menú principal estará siempre presente para que puedas navegar entre las distintas secciones de IronIA Fintech tu Personal Financial Store."+
                    " En IronIA Fintech podrás elegir entre diferentes productos financieros y diferentes formas de invertir o ahorrar.",
    PLANES_AHORRO:  "Los planes de ahorro te servirán para organizar tus inversiones por objetivos, estrategias o simplemente porque te gusta tener las cosas a tu manera."+
                    " Cada plan de ahorro está asociado a un perfil de inversión, los perfiles te ayudan a crear y mantener tu cartera de inversión,"+
                    " además cada plan tiene una suscripción asociada, que es la forma de pagar el servicio de IronIA Fintech."+
                    " ¿La suscripción es lo único que tengo que pagar? Es lo único que pagas a IronIA Fintech,"+
                    " dependiendo del producto que elijas los creadores del mismo tiene sus comisiones de gestión, la ventaja es que en IronIA Fintech tenemos los productos con las comisiones más bajas...",
    PERFIL_AHORRADOR: "Nuestros perfiles te ayudan a seleccionar una cartera de fondos en el 'Perfil ahorrador', solo tendrás que seleccionar qué profesionales quieren que gestionen tu carretera por ti."+
                      " Y si en algún momento quieres cambiar de profesionales podrás hacerlo sin necesidad de nada más.",
    PERFIL_ME_GUSTA_DECIDIR: "Nuestros perfiles te ayudan a seleccionar una cartera de fondos en el perfil 'Me gusta decidir' podrás seleccionar entre distintos profesionales que te realizarán propuestas cada cierto tiempo que podrás decidir aceptar o no."+
                             " Tu cartera evolucionará solo decidiendo sobre las propuestas de los asesores.",
    PERFIL_TENGO_EXPERIENCIA: "Ya tienes conocimientos del mundo de inversión, sabes que las clases limpias, las más baratas, te proporcionan el mismo producto con menos comisiones."+
                              " Te devolvemos las retrocesiones y con nuestras suscripciones pagarás lo mismo inviertas la cantidad que inviertas, porque el dinero es tuyo y tus decisiones también.",
    PERFIL_GURU: "El perfil para los más expertos, selecciona los fondos que quieras utilizando nuestros buscadores y además nuestras soluciones analíticas te ayudarán a tomar las mejores decisiones para tu inversión."+
                 " Todo lo que necesitas para elegir las clases de fondos más baratas, las clases limpias."
}