import styled from 'styled-components';

export const DivRowPrincipal = styled.div``;
export const PrincipalHeader = styled.h2`
    margin: 0;
    cursor: default;
`;
export const PrincipalDescription = styled.p`
    margin-bottom: 0;
    color: #556769;
    font-size: 24px;
    line-height: 32px;
    cursor: default;
`;
export const PrincipalElementsDescription = styled.p`
    color: #556769;
    font-size: 24px;
    line-height: 32px;
    cursor: default;
    margin: 0 !important;
`;
export const Error = styled.p`
    margin: 0 !important;
    cursor: default;
    color: #CC214F;
    font-size: 20px;
    padding-top: 1rem;
    font-weight: 700;
`;
export const ButtonMoreSubscription = styled.div`
    background-color: #CC214F !important;
    padding: 0.8em;
    border-radius: 16px;
    color: white;
    cursor: pointer;

    @media(min-width: 1474.99px)
    {
        margin-left: 48%;
    }
`;
export const DivRowBody = styled.div`
    padding: 0 1em 1em 1em;
    margin-bottom: 3em;
`;
export const DivRowElementsWater = styled.div`
    padding-bottom: 1em;
    padding-right: 1em;
`;
export const DivRowElementsIA = styled.div`
    padding-bottom: 1em;
    padding-right: 1em;

    @media (max-width: 639.99px)
    {
        padding-top: 0.5em;
    }
    @media (min-width: 640px) and (max-width: 1023.99px)
    {
        padding-top: 1em;
    }
`;
export const DivColElementsImg = styled.div`
    margin: 0;
    text-align: center;
    position: relative;
    -ms-transform: translateY(40%);
    transform: translateY(40%);
`;
export const DivColElementsPrice = styled.div`
    margin: 0;
`;
export const DescriptionElementsPrice = styled.p`
    margin: 0;
    padding-bottom: 2px;
    cursor: default;
`;
export const DescriptionPrice = styled.p`
    margin: 0;
    cursor: default;
`;
export const DescriptionMaxElements = styled.p`
    margin: 0;
    cursor: default;
`;
export const DivColInput = styled.div`
    text-align: center;
    padding-bottom: 1px;
    padding-left: 1.6em;
    transform: translateY(40%);
`;
export const InputElements = styled.input`
    width: 90%;
    height: 25px;
    border: 1px solid black;
    text-align: end;
`;
export const DivButtonFull = styled.div`
    padding-bottom: 1px;
    transform: translateY(40%);

    @media (max-width: 639.99px)
    {
        padding-top: 0.5em;
    }
`;
export const ButtonFull = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: #CC214F;
    color: white;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: pointer;
`;
export const ButtonFullDisabled = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: lightgray;
    color: black;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: progress;
`;
export const DivButtonApply = styled.div`
    padding-bottom: 1px;
    transform: translateY(40%);

    @media (max-width: 639.99px)
    {
        padding-top: 0.5em;
    }
    @media (min-width: 640px) and (max-width: 1023.99px)
    {
        padding-top: 0.5em;
    }
`;
export const ButtonUseElements = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: #CC214F;
    color: white;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: pointer;
`;
export const ButtonUseElementsDisabled = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: lightgray;
    color: black;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: progress;
`;
export const DivButtonRestore = styled.div`
    padding-bottom: 1px;
    transform: translateY(40%);

    @media (max-width: 639.99px)
    {
        padding-top: 0.5em;
    }
    @media (min-width: 640px) and (max-width: 1023.99px)
    {
        padding-top: 0.5em;
    }
`;
export const ButtonRestore = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: #CC214F;
    color: white;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: pointer;
`;
export const ButtonRestoreDisabled = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: lightgray;
    color: black;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: progress;
`;
export const DivRowInfoElements = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
`;
export const DivColInfoElements = styled.div`
    padding-left: 2.5em;
`;
export const InfoElements = styled.p`
    font-size: 15px;
    margin: 0;
    padding-left: 1em;
    cursor: default;
`;
export const DivColInfo = styled.div`
    padding-top: 1em;
`;
export const DivCardInfo = styled.div`
    border-radius: 24px !important;
    background: #F3F7F9 !important;
    color: ${(props) => props.theme.palette.lightBlack}; 
    padding: 1.25em;
`;
export const DivRowCard = styled.div``;
export const DivLinkRePayment = styled.div`
    text-align: center;
    margin-bottom: 1em;
`;
export const UL = styled.ul`
    margin: 1rem !important;
`;
export const LI = styled.li`
    margin: 0;
`;
export const DivColTitleBeneficiario = styled.div`
    cursor: default;
    font-size: 18px;
    font-weight: 600;
`;
export const DivColBeneficiario = styled.div`
    cursor: default;
`;
export const DivColTitleReference = styled.div`
    cursor: default;
    font-size: 18px;
    font-weight: 600;
`;
export const DivColReference = styled.div`
    cursor: default;
`;
export const DivColTitleSubscription = styled.div`
    cursor: default;
    font-size: 18px;
    font-weight: 600;
`;
export const DivColSubscription = styled.div`
    cursor: default;
`;
export const DivColTitlePrice = styled.div`
    cursor: default;
    font-size: 18px;
    font-weight: 600;
`;
export const DivColPrice = styled.div`
    font-size: 24px;
    cursor: default;
`;
export const DivColOutdatedInfo = styled.div`
    padding-top: 0.5em;
`;
export const OutdatedParagraph = styled.p`
    color: #CC214F;
    cursor: default;
    margin: 0;
    font-size: 17px;
    padding-left: 1em;
`;
export const DivColPeriodBank = styled.div``;
export const DivColSelectBank = styled.div``;
export const DivColAnnualBank = styled.div``;
export const DivColSubscriptionPeriodMessage = styled.div``;
export const DivColBanks = styled.div`
    padding-top: 1em;
`;
export const DivContainerPayment = styled.div``;
export const DivTabs = styled.div``;
export const ButtonPuntual = styled.button``;
export const ButtonPeriodic = styled.button``;
export const Form = styled.form``;
export const ButtonPay = styled.button`
    background-color: #CC214F;
    color: #FFFFFF;
    display: inline-block;
    box-shadow: 0px 0px 16px rgba(8,84,117,0.08);
    font-family: Barlow;
    font-weight: 600 !important;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 100%;
    box-sizing: content-box;
    border: 2px solid #CC214F;
    margin: 0 !important;
    border-radius: 24px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
`;
export const ButtonPayDisabled = styled.button`
    background-color: lightgray;
    color: black;
    display: inline-block;
    box-shadow: 0px 0px 16px rgba(8,84,117,0.08);
    font-family: Barlow;
    font-weight: 600 !important;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 100%;
    box-sizing: content-box;
    border: 2px solid lightgray;
    margin: 0 !important;
    border-radius: 24px;
    line-height: 20px;
    text-align: center;
    cursor: not-allowed;
`;
export const ButtonPayPeriod = styled.button`
    background-color: #CC214F;
    color: #FFFFFF;
    display: inline-block;
    box-shadow: 0px 0px 16px rgba(8,84,117,0.08);
    font-family: Barlow;
    font-weight: 600 !important;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 100%;
    box-sizing: content-box;
    border: 2px solid #CC214F;
    margin: 0 !important;
    border-radius: 24px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
`;
export const ButtonPayPeriodDisabled = styled.button`
    background-color: lightgray;
    color: black;
    display: inline-block;
    box-shadow: 0px 0px 16px rgba(8,84,117,0.08);
    font-family: Barlow;
    font-weight: 600 !important;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 100%;
    box-sizing: content-box;
    border: 2px solid lightgray;
    margin: 0 !important;
    border-radius: 24px;
    line-height: 20px;
    text-align: center;
    cursor: not-allowed;
`;