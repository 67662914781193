import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react';
import { useHistory } from 'react-router';

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = {ariaLabel: 'Más acciones'};

const ElementsMenu = () =>
{
    const history = useHistory();
    const _items =
    [
        {
            text: 'Elements',
            key: 'elements',
            onClick: () => history.push('/elements'),
            className: window.location.pathname === '/elements' ? 'is-selected' : ''
        },
        {
            text: 'Ranking de carteras',
            key: 'sharedPortfolios',
            onClick: () => history.push('/elements/sharedPortfolios'),
            className: window.location.pathname === '/elements/sharedPortfolios' ? 'is-selected' : ''
        },
        {
            text: 'Programa Lealtad',
            key: 'FrameworkAgreement',
            onClick: () => history.push('/elements/frameworkAgreement'),
            className: window.location.pathname === '/elements/frameworkAgreement' ? 'is-selected' : ''
        },
        {
            text: 'Compra Elements IA',
            key: 'ElementsIA',
            onClick: () => history.push('/ElementsIA'),
            className: window.location.pathname === '/ElementsIA' ? 'is-selected' : ''
        }
    ];

    return(
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="main-menu">
                <CommandBar
                    // @ts-ignore
                    items={_items}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
                />
            </div>
        </div>
    );
};

export default ElementsMenu;