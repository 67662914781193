import { Link } from "react-router-dom";
import DocumentTitle from 'react-document-title';
import styled from "styled-components";
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { ReactComponent as SaverImage } from '../../images/i_am_a_saver_cropped.svg';
import { ReactComponent as DecideImage } from '../../images/i_like_to_decide_cropped.svg';
import { ReactComponent as ExperienceImage } from '../../images/i_have_experience_cropped.svg';
import { ReactComponent as ProfessionalImage } from '../../images/i_am_professional_cropped.svg';
import { ResourcesHelpPlans } from "../../resources/ResourcesHelpPlans";
import { useState } from "react";
import { TeachingBubble } from "@fluentui/react";

const NewSavingsPlanContainer = styled.div`
    .ms-Grid-row
    {
        display: flex;
        flex-flow: row wrap;
        .ms-Grid-col
        {
            padding-right: 32px;
            display: flex;
            flex: 1 0 100px;
            flex-flow: column;

            .shadow
            {
                position: relative;
                padding: 0 0 100px 0;
                border-radius: 24px;
                height: 100%;
                > svg
                {
                    width: 100%;
                    height: auto;
                }

                .blue-circled
                {
                    background: #D9E6FA;
                    padding: 4px 14px 8px;
                    border-radius: 100px;
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    color: #4284E6;
                    font-weight: 500;
                    line-height: 20px;
                }

                h3,p
                {
                    margin: 0 32px;
                }

                h3
                {
                    font-size: 24px;
                    font-weight: 800;
                    line-height: 32px;
                    margin: 16px 32px 8px;
                }

                p
                {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }

                > a
                {
                    width: 100%;
                    margin-top: 32px;
                    display: block;
                    box-sizing: border-box;
                    border-radius: 0 0 24px 24px;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 500 !important;
                    position: absolute;
                    bottom: 0;
                    max-width: unset;
                    margin: 0;
                }
            }
        }
    }
`;

const NewSavingsPlan = () =>
{
    const [tooltipSavingPlan, setTooltipSavingPlan] = useState(false);
    const savingPlanTooltip = ResourcesHelpPlans.PLANES_AHORRO;
    const [tooltipSaver, setTooltipSaver] = useState(false);
    const saverTooltip = ResourcesHelpPlans.PERFIL_AHORRADOR;
    const [tooltipAlchemy, setTooltipAlchemy] = useState(false);
    const alchemyTooltip = ResourcesHelpPlans.PERFIL_ME_GUSTA_DECIDIR;
    const [tooltipExperience, setTooltipExperience] = useState(false);
    const experienceTooltip = ResourcesHelpPlans.PERFIL_TENGO_EXPERIENCIA;
    const [tooltipGuru, setTooltipGuru] = useState(false);
    const guruTooltip = ResourcesHelpPlans.PERFIL_GURU;
    const [isAllowedTooltip, setIsAllowedTooltip] = useState(true);

    const handleDismissTooltip = () =>
    {
        var cookies = document.cookie;

        if(!cookies.includes("tooltip"))
        {
            document.cookie = "tooltipShowing=0; expires=Thu, 25 May 2032 12:00:00 UTC; path =/";
            cookies = document.cookie;
        }

        var allCookies = cookies.split(";");
        var elementAt: number = allCookies.findIndex((element) => element.includes("tooltipShowing"));

        //@ts-ignore
        var beforeTrim = allCookies[elementAt].trim();
        var numberOfTimes = beforeTrim[15];

        if((numberOfTimes === "1" && beforeTrim[16] !== undefined) || (numberOfTimes === "2" && beforeTrim[16] !== undefined))
        {
            numberOfTimes = numberOfTimes + beforeTrim[16];
        }

        if(numberOfTimes !== undefined && parseInt(numberOfTimes) >= 25)
        {
            setIsAllowedTooltip(false);
        }

        if(numberOfTimes !== undefined && parseInt(numberOfTimes) < 25)
        {
            document.cookie = "tooltipShowing=" + ((parseInt(numberOfTimes)) + 1) + "; expires=Thu, 25 May 2032 12:00:00 UTC; path =/";
        }

        setTooltipSavingPlan(false);
        setTooltipSaver(false);
        setTooltipAlchemy(false);
        setTooltipExperience(false);
        setTooltipGuru(false);
    }

    return (
        <DocumentTitle title='IronIA - Nuevo plan de ahorro'>
            <NewSavingsPlanContainer className="ms-Grid-row savings-plans options">
                {tooltipSavingPlan === true &&
                    <TeachingBubble target="#savingPlanTitle h2"
                        hasCloseButton={true}
                        headline="Planes de Ahorro"
                        onDismiss={() => handleDismissTooltip()}
                    >
                        {savingPlanTooltip}
                    </TeachingBubble>
                }
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12" id="savingPlanTitle">
                        <h2 style={{margin: 0}} onMouseEnter={() => setTooltipSavingPlan(isAllowedTooltip)}>Selecciona tu perfil ahorro</h2>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12">
                        <p className="description">Selecciona bajo qué tipo de perfil te gustaría realizar un plan de ahorro y configura tu cartera de inversiones.</p>
                    </div>
                </div>
                <Separator />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md4">
                        <div className="shadow">
                            <SaverImage />
                            {tooltipSaver === true &&
                                <TeachingBubble
                                    target="#saver"
                                    hasCloseButton={true}
                                    headline="Perfil Ahorrador"
                                    onDismiss={() => handleDismissTooltip()}
                                >
                                    {saverTooltip}
                                </TeachingBubble>
                            }
                            <span className='blue-circled' id="saver" onMouseEnter={() => setTooltipSaver(isAllowedTooltip)}>Ahorrar</span>
                            <h3>Soy ahorrador</h3>
                            <p>Tengo un dinero ahorrado y me gustaría que me diera beneficios, pero no me gusta complicarme que me lo den todo hecho.</p>
                            <Link to='/savingsPlans/iAmASaver' className="button primary-button">Ver alternativas</Link>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className="shadow">
                            <DecideImage />
                            {tooltipAlchemy === true &&
                                <TeachingBubble
                                    target="#alchemy"
                                    hasCloseButton={true}
                                    headline="Me Gusta Decidir"
                                    onDismiss={() => handleDismissTooltip()}
                                >
                                    {alchemyTooltip}
                                </TeachingBubble>
                            }
                            <span className='blue-circled' id="alchemy" onMouseEnter={() => setTooltipAlchemy(isAllowedTooltip)}>Asesoramiento</span>
                            <h3>Me gusta decidir</h3>
                            <p>Me gusta que me hagan propuestas de inversión atractivas y yo decidir qué opción es mejor para mí en cada momento.</p>
                            <Link to='/savingsPlans/iLikeToDecide' className="button primary-button">Ver alternativas</Link>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4">
                        <div className="shadow">
                            <ExperienceImage />
                            {tooltipExperience === true &&
                                <TeachingBubble
                                    target="#experience"
                                    hasCloseButton={true}
                                    headline="Tengo Experiencia"
                                    onDismiss={() => handleDismissTooltip()}
                                >
                                    {experienceTooltip}
                                </TeachingBubble>
                            }
                            <span className='blue-circled' id="experience" onMouseEnter={() => setTooltipExperience(isAllowedTooltip)}>Experto</span>
                            <h3>Tengo experiencia</h3>
                            <p>Me gusta gestionarme a mí, elegir mis inversiones y gestionar qué hago y cómo lo hago. Aunque una ayudita no viene mal.</p>
                            <Link to='/savingsPlans/iHaveExperience' className="button primary-button">Ver alternativas</Link>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4">
                        <div className="shadow">
                            <ProfessionalImage />
                            {tooltipGuru === true &&
                                <TeachingBubble
                                    target="#guru"
                                    hasCloseButton={true}
                                    headline="Gurú Financiero"
                                    onDismiss={() => handleDismissTooltip()}
                                >
                                    {guruTooltip}
                                </TeachingBubble>
                            }
                            <span className='blue-circled' id="guru" onMouseEnter={() => setTooltipGuru(isAllowedTooltip)}>Gurú</span>
                            <h3>Gurú financiero</h3>
                            <p>Controlo el riesgo, conozco los mercados y necesito información diferencial para analizar lo que yo quiero, cuando yo quiero.</p>
                            <Link to='/savingsPlans/advanced' className="button primary-button">Ver alternativas</Link>
                        </div>
                    </div>
                </div>
            </NewSavingsPlanContainer>
        </DocumentTitle>
    );
}

export default NewSavingsPlan;