import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    a
    {
        color: rgb(66, 132, 230);
    }
    .is-selected.ms-GroupHeader{background: none !important;}
    button
    {
        background: none;
    }
    .expanded
    {
        background: none;
        :hover
        {
            border-bottom: none !important;
        }
    }
    input
    {
        background-color: ${(props, // @ts-ignore
        ) => props.theme.palette.borderSecondary} !important;
        &.ms-SearchBox-field
        {
            border: ${(
                props, // @ts-ignore
            ) => "1px solid " + props.theme.palette.neutralBorder} !important
        }
    }
    .ms-Card
    {
        background: ${(props: {theme: {palette: {neutralLighter: any}}}) =>props.theme.palette.neutralLighter};
    }

    .ms-Slider.riskSlider > div > div {
        padding: 0 50px;
    }

    .ms-DatePicker *[class^='ms-DatePicker-'],
    .ms-DatePicker-day-button span {
        color: ${(props, // @ts-ignore
        ) => props.theme.palette.neutralTertiaryAlt} !important;
    }

    .ms-Slider-thumb
    {
        display: none
    }
    .ms-Slider-slideBox
    {
        &[aria-valuenow="0"]
        {
            .ms-Slider-thumb
            {
                display: block
            }
        }
        .ms-Slider-line
        {
            background: #FAE9ED;
            border-radius: 20px;
            padding: 0 2px;

            .ms-Slider-active
            {
                height: 8px;
                margin-top: 2px;
                background: #CC214F;
            }
        }
        &[aria-valuenow="100"]
        {
            .ms-Slider-line
            {
                background: #BEFFD6;

                .ms-Slider-active
                {
                    background: #1BB2AD;
                }
            }
        }
    }
    .ms-Nav
    {
        &.main-menu,
        &.profile-actions
        {
            margin-top: 20px;
            .ms-Nav-compositeLink
            {
                button,
                a
                {
                    span
                    {
                        margin-left: 20px;
                    }
                }
                &.is-selected
                {
                    button,
                    a
                    {
                        border-radius: 0;
                        background: ${(props, // @ts-ignore
                        ) => props.theme.palette.tertiary};
                        &:after
                        {
                            border-left-width: 6px;
                        }
                        span
                        {
                            .ironia-icon
                            {
                                color: ${(props, // @ts-ignore
                                ) => props.theme.palette.themePrimary};
                            }
                            .ms-Nav-linkText
                            {
                                color: ${(props, // @ts-ignore
                                ) => props.theme.palette.themePrimary} !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .ms-Button
    {
        min-width: unset;
        &.icon-button
        {
            border: 0;
            padding: 0;
            background: none;
            color: ${(props, // @ts-ignore
            ) => props.theme.palette.white};
        }
    }
    .ms-Tabs-Container
    {
        .ms-Tab
        {
            background: ${(props, // @ts-ignore
                ) => props.theme.palette.white};
            color: ${(props, // @ts-ignore
                ) => props.theme.palette.black};
        }
    }
    .shadow
    {
        background: ${(props, // @ts-ignore
        ) => props.theme.palette.shadowBackground};
        box-shadow: ${(props) => "0px 0px 16px " + // @ts-ignore
        props.theme.palette.shadow};
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
    }
    .shadowNoMargin
    {
        background: ${(props, // @ts-ignore
        ) => props.theme.palette.shadowBackground};
        box-shadow: ${(props) => "0px 0px 16px " + // @ts-ignore
        props.theme.palette.shadow};
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
    }
    .ms-DetailsRow
    {
        &.position
        {
            background: ${(props, // @ts-ignore
            ) => props.theme.palette.tertiary};
        }
    }
    .ms-DetailsRow-cell
    {
        color: ${(props, // @ts-ignore
        ) => props.theme.palette.black};

        &:first-child {
            border-left: 1px solid rgb(233, 239, 241)
        }
    }

    .highcharts-legend-item text
    {
        fill: ${(props, // @ts-ignore
        ) => props.theme.palette.black} !important;
    }
    #root
    {
        background: ${(props, // @ts-ignore
        ) => props.theme.palette.body} !important;
        #header
        {
            background: ${(props, // @ts-ignore
            ) => props.theme.palette.background};
            button
            {
                .ironia-icon
                {
                    color: ${(props, // @ts-ignore
                    ) => props.theme.palette.black};
                }
            }
        }
        footer
        {
            background: ${(props, // @ts-ignore
            ) => props.theme.palette.darkBackground};
        }
    }
    h2
    {
        &.highlighted
        {
            color: ${(props, // @ts-ignore
            ) => props.theme.palette.neutralHeader};
        }
    }
    h3
    {
        &.red
        {
            color: #CC214F;
        }
    }
    p
    {
        &.description
        {
            color: #556769;
        }

        &.error
        {
            color: ${(props, // @ts-ignore
                ) => props.theme.palette.themePrimary};
            font-weight: bold;
        }
    }
    dt
    {
        color: #2A4143;
    }
    dd
    {
        color: #556769
    }
    .transparentThreeDots
    {
        padding: 0;
        border: 0px;
        background: transparent;
    }
    .primary-button,
    .tiny-stretch-button,
    .tiny-primary-button,
    .secondary-button,
    .tiny-secondary-button,
    .mini-primary-button,
    .mini-secondary-button
    {
        display: inline-block;
        background: #CC214F;
        box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
        color: #FFFFFF;
        font-family: Barlow;
        font-weight: 600 !important;
        padding: 19px 62px !important;
        max-width: 100%;
        box-sizing: content-box;
        border-width: 2px !important;
        border-style: solid;
        border-color: #CC214F;
        margin: 0 5px;
        &:focus {
            background: #CC214F;
            border-color: #CC214F;
        }
        &:hover
        {
            background: #D64D73;
            border-color: #D64D73;
            box-shadow: 0px 2px 64px rgba(8, 84, 117, 0.12), 0px 1px 20px rgba(8, 84, 117, 0.12);
            color: #FFFFFF;
        }
        &:active
        {
            background: #B31540;
            border-color: #B31540;
            color: #FFFFFF;
        }
        &.disabled,
        &.disabled:hover,
        &.disabled:active
        &.is-disabled,
        &.is-disabled:hover,
        &.is-disabled:disabled
        {
            background: #DFE3E3;
            border-color: #DFE3E3;
            color: #93AEB9;
            box-shadow: none;
        }
        &.inactive
        {
            background: #DFE3E3;
            border-color: #DFE3E3;
            color: #93AEB9;
        }
        span
        {
            font-weight: 600 !important;
            line-height: unset;
        }
    }
    .primary-button,
    .secondary-button
    {
        max-width: calc(100% - 124px);
    }
    .secondary-button,
    .tiny-secondary-button,
    .mini-secondary-button
    {
        background: #FFFFFF;
        border: 2px solid #CC214F;
        color: #CC214F;
        &:hover
        {
            background: #FAE9ED;
            color: #CC214F;
            border: 2px solid #F1BAC9;
            box-shadow: 0px 2px 64px rgba(8, 84, 117, 0.12), 0px 1px 20px rgba(8, 84, 117, 0.12);
        }
        &:active
        {
            background: #FFFFFF;
            border: 2px solid #B31540;
        }
        &.disabled,
        &.disabled:hover,
        &.disabled:active
        {
            background: #FFFFFF;
            border-color: #DFE3E3
            color: #93AEB9;
            box-shadow: none;
        }
    }
    .tiny-stretch-button
    {
        padding: 0px 32px !important;
    }
    .tiny-primary-button,
    .tiny-secondary-button
    {
        padding: 8px 32px !important;
    }
    .mini-primary-button,
    .mini-secondary-button
    {
        padding: 0 8px !important;
    }
    div
    {
        &.blue
        {
            background: rgba(217,230,250,0.5);
            color: #556769;
            > span
            {
                color: #556769;
            }
        }
        &.green
        {
            color: #1BB3AD;
        }
        &.grey
        {
            background: ${(props, // @ts-ignore
            ) => props.theme.palette.greyBackground};
        }
    }
    span
    {
        font-family: Barlow;
        &.circled
        {
            display: inline-block;
            border-radius: 20px;
            padding: 3.5px 10.5px;
        }
        &.red
        {
            background: #CC214F;
            color: white;
        }
        &.blue
        {
            color: #4284E6;
        }
        &.legend
        {
            color: #95A0A1;
            margin-bottom: 24px;
        }
    }
    .ms-Stack:focus
    {
        border: 1px solid #cc214f;
    }
    .ms-TextField-field
    {
        color: ${(props, // @ts-ignore
        ) => props.theme.palette.greyText};
        background: ${(props, // @ts-ignore
        ) => props.theme.palette.inputBackground};
        border-width: 1px;
        border-style: solid;
        border-color: ${(props, // @ts-ignore
        ) => props.theme.palette.inputBorder};
    }
    .number-control
    {
        > *
        {
            vertical-align: middle;
        }
        .ms-TextField-field
        {
            text-align: right;
        }
        .ms-Button
        {
            border: 0;
            padding: 0 7px !important;
            height: 38px;
            &.circled
            {
                margin: 0 10px !important;
                border-radius: 25px;
                background: ${(props, // @ts-ignore
                ) => props.theme.palette.themePrimary};

                .ironia-icon
                {
                    font-weight: 100;
                }
            }
            .ironia-icon
            {
                &.information
                {
                    color: #D4DFE3;
                }
                &.protectedDocument
                {
                    font-size: 50px;
                }
            }
        }
    }
    .ironia-tooltip
    {
        box-shadow: none;
        > *
        {
            &.ms-Callout-main
            {
                background: #0078d4 !important;
                margin: -10px 0;
                .ms-Tooltip-content
                {
                    padding: 10px;
                    color: #333;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    > div
                    {
                        display: flex;

                        p {
                            text-wrap: balance;
                        }
                    }
                    i
                    {
                        margin-right: 10px;
                        &:after
                        {
                            font-size: 14px;
                        }
                    }
                }
            }
            &.ms-Callout-beak
            {
                background: #0078d4 !important;
                box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
            }
        }
    }
    .ms-Callout
    {
        &:not(.ms-TeachingBubble)
        {
            border-radius: 24px;
            .ms-Callout-beak
            {
                background: #FFFFFF !important;
            }
            .ms-Callout-beakCurtain
            {
                border-radius: 24px;
                box-shadow: 0 0 1px 1px white;
            }
            .ms-Callout-main
            {
                border-radius: 12px;
                padding: 24px;
                background: ${(props, // @ts-ignore
                    ) => props.theme.palette.white} !important;
                h4
                {
                    color: ${(props, // @ts-ignore
                        ) => props.theme.palette.neutralPrimary};
                }
                .closeButton
		        {
			        position: absolute;
			        right: 13px;
			        top: 13px;
			        padding: 0;
			        border-radius: 50%;
			        border: 0;
			        height: auto;
			        span
			        {
				        height: auto;
				        i
				        {
					        &:after
					        {
						        font-size: 21px;
						        padding: 0 1px;
						        color: ${(props, // @ts-ignore
                                ) => props.theme.palette.inputColor};
					        }
					        &:active
					        {
						        &:after
						        {
							        color: white;
						        }
					        }
				        }
			        }
		        }
                .header
                {
                    box-sizing: content-box;
                    border-radius: 16px;
                    background: #F3F7F9;
                    padding: 16px;
                    margin-bottom: 16px;
                    .ms-Persona-initials
                    {
                        background: #FFF1BE;
                        span
                        {
                            color: #FFBD70;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            vertical-align: 0px;
                        }
                    }
                    .ms-Persona-details
                    {
                        .ms-Persona-primaryText
                        {
                            height: 24px;
                            margin-bottom: 2px;
                            .ms-TooltipHost
                            {
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 700;
                                color: #2A4143;
                            }
                        }
                        .ms-Persona-secondaryText
                        {
                            height: 20px;
                            .ms-TooltipHost
                            {
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 500;
                                color: #CC214F;
                                &:after
                                {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .ms-FocusZone
                {
                    .ms-Nav
                    {
                        border: 0;
                        .ms-Nav-group
                        {
                            .ms-Nav-groupContent
                            {
                                margin-bottom: 0;
                                ul.ms-Nav-navItems
                                {
                                    li.ms-Nav-navItem
                                    {
                                        height: 66px;
                                        margin: 0;
                                        .ms-Button
                                        {
                                            border-radius: 0 !important;
                                            height: 66px;
                                            .ms-Nav-linkText
                                            {
                                                color: ${(props, // @ts-ignore
                                                    ) => props.theme.palette.black} !important;
                                                font-size: 14px;
                                                font-weight: 500;
                                                line-height: 66px;
                                            }

                                            &:hover
                                            {
                                                background: ${(props, // @ts-ignore
                                                    ) => `${props.theme.palette.black}`};

                                                .ms-Nav-linkText
                                                {
                                                    color: ${(props, // @ts-ignore
                                                        ) => `${props.theme.palette.white}`} !important;
                                                }
                                            }
                                        }
                                        &:last-child
                                        {
                                            border-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.without-spacing
                {
                    padding: 8px;
                    border-radius: 12px;
                    button
                    {
                        border-radius: 4px;
                    }
                }
            }
        }
        &.ms-TeachingBubble
        {
            .ms-Callout-beak,
            .ms-Callout-main
            {
                background: #0078d4;
                a
                {
                    color: white;
                }
                .ms-TeachingBubble-closebutton
                {
                    margin: 0 5px 0 0;
                    border: 1px solid transparent;
                    &:hover
                    {
                        background: ${(props, // @ts-ignore
                        ) => `${props.theme.palette.white}`};
                        color: #0078d4;
                    }
                    &:active
                    {
                        background: ${(props, // @ts-ignore
                        ) => `${props.theme.palette.white}`};
                        color: #0078d4;
                        i
                        {
                            font-weight: bold;
                        }
                    }
                    &:focus
                    {
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }
    .ms-Dropdown-container
    {
        span.ms-Dropdown-title
        {
            background: ${(props, // @ts-ignore
            ) => `${props.theme.palette.inputBackground}`};
            border-width: 1px;
            border-style: solid;
            border-color: ${(props, // @ts-ignore
            ) => `${props.theme.palette.inputBorder}`};
            color: ${(props, // @ts-ignore
            ) => `${props.theme.palette.inputColor}`};
        }
    }
    .ms-Checkbox
    {
        label
        {
            color: ${(props, // @ts-ignore
            ) => `${props.theme.palette.neutralLighter}`};
        }
        &.is-checked
        {
            label
            {
                color: ${(props, // @ts-ignore
                ) => `${props.theme.palette.white}`};
            }
            .ms-Checkbox-checkbox
            {
                border-color: ${(props, // @ts-ignore
                ) => `${props.theme.palette.white}`};
            }
        }
        &.cicled
        {
            .ms-Checkbox-checkbox
            {
                border-radius: 10px;
                border-width: 1.5px;
                border-color: ${(props, // @ts-ignore
                ) => `${props.theme.palette.middleBorder}`};
            }
            &.is-checked
            {
                .ms-Checkbox-checkbox
                {
                    background: none;
                    i
                    {
                        margin-left: -1px;
                        color: ${(props, // @ts-ignore
                        ) => `${props.theme.palette.themePrimary}`};
                    }
                }
            }
        }
    }
    .ms-Panel-main
    {
        background: ${(props, // @ts-ignore
        ) => `${props.theme.palette.linearGradient}`};
        box-shadow: 0px 2px 64px rgba(8, 84, 117, 0.12), 0px 1px 20px rgba(8, 84, 117, 0.12);
        .ms-Panel-commands
        {
            display: none;
        }
        .ms-Panel-content
        {
            padding-left: 56px;
            padding-bottom: 56px;
            width: 400px;
            color: ${(props, // @ts-ignore
            ) => `${props.theme.palette.white}`};
            .icon-button
            {
                margin-top: 16px;
            }
            .ms-Image
            {
                width: auto;
                display: inline-block;
                margin-top: -2px;
            }
            .separator
            {
                opacity: 0.2;
                height: 32px;
                margin: 0;
                &.vertical
                {
                    margin: 16px 20px 0 30px;
                }
            }
            .separatorMenu
            {
                width: 75%;
            }
            span
            {
                &.fs12
                {
                    font-size: 12px;
                    line-height: 26px;
                }
                &.fs16
                {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            label
            {
                color: ${(props, // @ts-ignore
                ) => `${props.theme.palette.white}`};
            }
            .ms-Toggle
            {
                margin-top: 10px;
            }
            .primary-button
            {
                width: fit-content;
                padding: 28px 58px;
                border: 0;
                margin-top: 24px;
                border-radius: 100px;
                .ms-Button-label
                {
                    font-weight: 600 !important;
                }
            }
            h4
            {
                margin-top: 56px;
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
            }
            .social-networks
            {
                display: block;
                margin-top: 10px;
                button
                {
                    min-width: 0;
                    width: 56px;
                    height: 32px;
                    text-align: center;
                    background: ${(props, // @ts-ignore
                    ) => `${props.theme.palette.quinary}`};
                    border-radius: 32px;
                    margin-left: 16px;
                    padding: 0;
                    border: 0;
                    &:first-of-type
                    {
                        margin-left: 0;
                    }
                    span
                    {
                        color: ${(props, // @ts-ignore
                        ) => `${props.theme.palette.senary}`};
                        svg
                        {
                            path
                            {
                                fill: currentColor;
                            }
                        }
                    }
                }
            }
            .ms-Nav
            {
                border: 0;
                .ms-Nav-navItem
                {
                    margin-bottom: 20px;
                    .ms-Nav-compositeLink
                    {
                        button,
                        a
                        {
                            padding-left: 18px;
                            color: ${(props, // @ts-ignore
                            ) => `${props.theme.palette.dust}`};
                            .ms-Nav-linkText
                            {
                                font-size: 28px;
                                line-height: 36px;
                                font-weight: 800;
                            }
                            &:hover
                            {
                                background: ${(props, // @ts-ignore
                                ) => `${props.theme.palette.borderPrimary}`};
                                color: ${(props, // @ts-ignore
                                ) => `${props.theme.palette.black}`};
                            }
                        }
                        &.is-selected
                        {
                            background: transparent;
                            button,
                            a
                            {
                                background: transparent;
                                &:after
                                {
                                    border: 0;
                                    width: 6px;
                                    background: ${(props, // @ts-ignore
                                    ) => `${props.theme.palette.themePrimary}`};
                                    border-radius: 40px;
                                    left: 2px;
                                }
                            }
                        }
                        &.is-disabled
                        {
                            button,
                            a
                            {
                                color: ${(props, // @ts-ignore
                                ) => `${props.theme.palette.dust}`};
                                opacity: 0.2;
                            }
                            &:hover
                            {
                                background: none;
                                button,
                                a
                                {
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    button.light-circled-icon
    {
        width: 32px;
        height: 32px;
        border: ${(props, // @ts-ignore
        ) => `1px solid ${props.theme.palette.inputColor}`};
        border-radius: 100%;
        background: transparent;
        i.ironia-icon
        {
            &:after
            {
                color: ${(props, // @ts-ignore
                ) => `${props.theme.palette.neutralDark}`};
                font-size: 16px;
            }
        }
        &:hover
        {
            border: ${(props, // @ts-ignore
            ) => `1px solid ${props.theme.palette.inputBorder}`};
            i.ironia-icon
            {
                &:after
                {
                    color: white;
                }
            }
        }
        .ms-Spinner
        {
            margin: 0 auto;
        }
    }
    .highcharts-title
    {
        color: ${(props, // @ts-ignore
        ) => `${props.theme.palette.black} !important`};
        fill: ${(props, // @ts-ignore
        ) => `${props.theme.palette.black} !important`};
    }
    #main-element
    {
        background: ${(props, // @ts-ignore
        ) => `${props.theme.palette.white}`};
        color: ${(props, // @ts-ignore
        ) => `${props.theme.palette.black}`};
    }

    .background-shadow {
        background: ${(props, // @ts-ignore
        ) => `${props.theme.palette.shadow}`} !important;
    }

    .background-background {
        background: ${(props, // @ts-ignore
        ) => `${props.theme.palette.background}`};
    }

    .background-tertiary {
        background: ${(props, // @ts-ignore
        ) => `${props.theme.palette.tertiary}`};
    }

    .color-black {
        color: ${(props, // @ts-ignore
        ) => `${props.theme.palette.black}`};
    }

    .color-neutralTertiaryAlt {
        color: ${(props, // @ts-ignore
        ) => `${props.theme.palette.neutralTertiaryAlt}`};
    }

    .warning-container {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        background: #FFF1BE;
        color: #F69A2D;
        padding: 24px;
        gap: 10px;
        font-weight: 500;

        p {
            line-height: 20px;
            margin: 0;
        }
    }

    @media only screen and (max-width: 1024px)
    {
        .ms-Panel-main
        {
            background: ${(props, // @ts-ignore
            ) => `${props.theme.palette.linearGradientSmall}`};
            .ms-Panel-content
            {
                width: 100%;
                box-sizing: border-box;
                padding-left: 16px;
                padding-right: 16px;
                .ms-Stack
                {
                    .separator
                    {
                        margin-left: 22px;
                        margin-right: 0;
                    }
                }
                .ms-SearchBox
                {
                    border-radius: 100px;
                    border: 0;
                    height: 40px;
                    margin: 26px 0;
                    background: ${(props, // @ts-ignore
                    ) => `${props.theme.palette.nonary}`};
                    .ms-SearchBox-icon
                    {
                        i.ironia-icon:after
                        {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                    input
                    {
                        border: 0;
                        border-radius: 100px;
                        line-height: 40px;
                        background: ${(props, // @ts-ignore
                        ) => `${props.theme.palette.nonary}`} !important;

                        &::placeholder
                        {
                            color: #556769;
                        }
                    }
                    &:after
                    {
                        border-radius: 100px;
                    }
                }
                h4
                {
                    margin-top: 16px;
                    font-size: 14px;
                    line-height: 20px;
                }
                span
                {
                    &.fs16
                    {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                .persona-component
                {
                    width: 100%;
                    border-color: ${(props, // @ts-ignore
                    ) => `${props.theme.palette.octary}`};
                    .ms-Persona
                    {
                        width: calc(100% - 50px);
                        .ms-Persona-coin
                        {
                            .ms-Persona-presence
                            {
                                border-color: ${(props, // @ts-ignore
                                ) => `${props.theme.palette.black}`};
                            }
                        }
                        .ms-Persona-details
                        {
                            padding-left: 15px;
                            .ms-Persona-primaryText
                            {
                                .ms-TooltipHost
                                {
                                    color: ${(props, // @ts-ignore
                                    ) => `${props.theme.palette.white}`} !important;
                                }
                            }
                        }
                    }
                    button
                    {
                        background: ${(props, // @ts-ignore
                        ) => `${props.theme.palette.quinary}`};
                        color: ${(props, // @ts-ignore
                        ) => `${props.theme.palette.septary}`};
                        border: 0 !important;
                        transform: rotate(-90deg);
                    }
                }
                .ms-Nav
                {
                    margin: 0 0 0 -16px;
                    .ms-Nav-navItem
                    {
                        .ms-Nav-compositeLink
                        {
                            button,
                            a
                            {
                                padding-left: 16px;
                                span
                                {
                                    margin: 0;
                                    .ms-Nav-linkText
                                    {
                                        font-size: 24px;
                                        line-height: 32px;
                                    }
                                }
                            }
                        }
                    }
                }
                > .ms-Stack
                {
                    margin-left: 14px;
                    margin-right: 14px;
                    &:first-of-type
                    {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
                .separator,
                > span
                {
                    margin-left: 14px;
                    margin-right: 14px;
                }
            }
        }
    }

    .ms-DetailsList
    {
        .ms-DetailsHeader
        {
            background: ${(props, // @ts-ignore
            ) => `${props.theme.palette.tertiary}`};
        }

        .ms-DetailsRow {
            &.is-selected {
                background: none;
                border-bottom: 1px solid rgb(233, 239, 241);
            }
        }
    }
`;
export default GlobalStyle;
export const ButtonGreyStyle =
{
    root:
    {
        display: 'block',
        border: '2px solid #cc214f',
        borderRadius: '30px !important',
        width: '225px',
        height: '55px',
        marginLeft: '20px',
    },
    rootPressed:
    {
        color: '#cc214f',
        backgroundColor: '#E9EFF1',
    },
    rootHovered:
    {
        color: '#cc214f',
    },
    textContainer:
    {
        color: '#cc214f',
        fontFamily: 'inherit',
        fontSize: '14px',
    },
};
export const ButtonMoreStyle =
{
    root:
    {
        display: 'block',
        border: '2px solid #cc214f',
        borderRadius: '30px !important',
        maxWidth: '310px',
        height: '44px',
        marginLeft: '20px',
        width: '300px',
        marginTop: '30px',
        color: '#cc214f',
    },
    rootPressed:
    {
        backgroundColor: '#ffffff',
    },
    rootHovered:
    {
        color: '#cc214f',
    },
    textContainer:
    {
        padding: '0',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 600,
    },
};
export const MoreIconStyle =
{
    root:
    {
        position: 'relative',
        top: '1px',
        marginLeft: '5px',
        fontSize: '14px',
        fontWeight: 500,
    },
};
export const ArrowIconStyle =
{
    root:
    {
        position: "relative",
        top: "2px",
        fontSize: '12px',
        fontWeight: 800,
        marginTop: '2px',
        color: '#93aeb9',
    },
};
export const InputStyles =
{
    root:
    {
        label:
        {
            display: 'block',
            fontWeight: 500,
            color: '#556769',
            fontSize: '14px',
        },
        div:
        {
            border: 'none !important',
        },
        boxSizing: 'border-Box',
        marginTop: '8px',
        marginBottom: '20px',
        width: '100%',
    },
    fieldGroup:
    {
        width: '100%',
        height: '40px',
        backgroundColor: '#f3f7f9',
    },
    field:
    {
        borderRadius: '5px !important',
        border: '1px solid #95A0A1 !important',
        fontSize: '16px',
        selectors:
        {
            '::placeholder':
            {
                display: 'block',
                fontWeight: 500,
                color: '#d1d8d9',
                fontSize: '14px',
            },
        },
    },
};
export const DatePickerStyle =
{
    border: 'none',
    root:
    {
        marginTop: '8px',
        width: '100%',
        marginBottom: '20px',
        input:
        {
            borderRadius: '5px',
            border: '1px solid #95A0A1',
        },
    },
    textField:
    {
        label:
        {
            display: 'block',
            fontWeight: 500,
            color: '#556769',
            fontSize: '14px',
        },
        div:
        {
            border: 'none',
            div:
            {
                width: '100%',
                height: '40px',
                backgroundColor: '#f3f7f9',
            },
        },
    },
    rootFocused:
    {
        borderRadius: '5px',
        border: '2px solid #cc214f',
    },
};
export const closeBtnStyle =
{
    root:
    {
        fontSize: '12px',
        fontWeight: 1000,
        position: 'relative',
        top: '8px',
        right: '5px',
        color: '#BFC6C7',
    },
};