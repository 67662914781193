import { useEffect, useRef, useState } from "react";
import subscriptionService, { SubscriptionType } from "../../services/SubscriptionService";
import { useLocation } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { MoreResuscriptionPlansItem } from "./MoreResuscriptionPlansItem";

export const MoreResuscriptionPlans = () =>
{
    const { state } = useLocation<any>();
    const prevSubscription = state?.subscriptionId;
    const usersSubscriptionId = state?.userSubscriptionId;
    const limitAmount = state?.limitAmount;
    const isLoading = useRef<boolean>(false);
    const errorMini = useRef<string>("");
    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>();

    useEffect(() =>
    {
        if(subscriptions === undefined)
        {
            subscriptionService.getSubscriptionsByType("experience").then((response: SubscriptionType[]) =>
            {
                var prevSubscriptionId = [2, 4, 8].includes(prevSubscription) ? prevSubscription : 4;
                var indexSelected: number = response.findIndex((element: SubscriptionType) => element.id === prevSubscriptionId);
                var indexMini: number = response.findIndex((element: SubscriptionType) => element.name === "IronIA Mini");

                if(limitAmount.current > 0)
                {
                    if(indexSelected === indexMini)
                    {
                        errorMini.current = "Tu cartera actualmente tiene un valor superior a 3000€, por lo que no puedes "
                            + "renovar tu suscripción en IronIA Mini.Selecciona uno de los otros planes para resuscribirte.";
                    }
                    response.splice(indexMini, 1);
                    if(indexSelected >= indexMini)
                    {
                        indexSelected--;
                    }
                }

                var selectedSubscription = response[indexSelected];
                response.splice(indexSelected, 1);
                response.splice(1, 0, selectedSubscription);

                isLoading.current = true;
                setSubscriptions(response);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevSubscription, limitAmount]);

    if(isLoading.current === false)
    {
        return (<Spinner size={SpinnerSize.large} />);
    }
    else
    {
        return(
            <div className="row">
                {subscriptions !== undefined && subscriptions.map((subscription: SubscriptionType, subscriptionIndex: number) =>
                (
                    <div className={`ms-Grid-col ms-sm${subscriptions?.length === 2 ? 6 : 4}`} key={'subscriptionIndex' + subscriptionIndex}>
                        {subscriptionIndex === 0 &&
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3"></div>
                        }
                        <MoreResuscriptionPlansItem
                            subscription={subscription}
                            usersSubscriptionId={usersSubscriptionId}
                            limitAmount={limitAmount}
                            className={`${subscriptionIndex === 1 ? 'selected' : ''} ${subscriptions?.length === 2 ? 'spaced' : ''}`}
                        />
                        {subscriptionIndex === subscriptions.length - 1 && (
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3"></div>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}