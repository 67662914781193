import * as S from './SuitabilityTest.style';
import { useCallback, useState, useEffect } from 'react';
import { Separator } from '@fluentui/react';
import {
    ISuitabilityTestVersion,
    ISuitabilityTest,
    IQuestion,
    IQuestionTableRow,
    IAnswer,
    IBlock,
    ISubBlock,
    IQuestionAnswer
} from '../../services/SuitabilityTestsService';
import { groupBy } from '../../utils/groupBy';

const SuitabilityTestInputs = (
{
    handleSubmit,
    suitabilityTestVersion,
    suitabilityTest,
    handleChange,
    data,
    formVal,
    setFormVal,
    questions,
    canSubmit
}: {
    handleSubmit: any,
    suitabilityTestVersion?: ISuitabilityTestVersion,
    suitabilityTest: ISuitabilityTest | undefined,
    handleChange: any,
    data: any,
    formVal: any,
    setFormVal: any,
    questions: IQuestion[],
    canSubmit: boolean
}) =>
{
    const [collegue, setCollegue] = useState<boolean>();
    const [errors, setErrors] = useState({});
    const [barCount, setBarCount] = useState(0);

    const barIncreaser = useCallback(async () =>
    {
        var cal = 0;
        const total = questions.filter((question: IQuestion) => !["checkbox", "table"].includes(question.questionType) && question.dependency === null).length;
        const amount = data.answers.filter((anwerAux: IQuestionAnswer) => anwerAux.value !== undefined).length;
        console.log(`${amount} de ${total}`)
        if(amount > 0)
        {
            cal = amount * 100 / total;
        }
        setBarCount(cal);
    }, [data.answers]);

    const getErrorMessage = (question: IQuestion, field: string, value: any, order?: string) =>
    {
        switch(question.questionType)
        {
            case 'radio':
                return (value === undefined || order === undefined) ? <S.Error>Este campo no puede estar vacio</S.Error> : "";

            case 'multi':
                var excludedAnswerId = question.answers?.find((anwerAux: IQuestionAnswer) => anwerAux._id === field)?._id;
                var inputNumberIds = question.answers.filter((answerAux) => answerAux._id !== excludedAnswerId).map((answerAux) => answerAux._id);
                var summatory = suitabilityTest?.answers?.filter((answerAux) => inputNumberIds.includes(answerAux.questionId)).reduce((prev, current) => prev + (current?.inputRes || 0), 0);
                if(summatory === undefined || summatory + parseFloat(value) !== 100)
                {
                    return <S.Error className="floating">La suma de los porcentajes debe ser igual a 100</S.Error>;
                }
                return "";

            case 'checkbox':
                return "";

            case "table":
                return "";

            default:
                throw new Error('Error en el switch');
        }
    };

    useEffect(() =>
    {
        questions.forEach((question: IQuestion) =>
        {
            var answer = data.answers.find((answer: IAnswer) => answer.questionId === question._id);
            switch(question.questionType)
            {
                case "radio":
                    if (question.dependency === null) {
                        var error: any = getErrorMessage(question, "", answer?.value, answer?.order);

                        setErrors((prevState: any) =>
                        {
                            return {
                                ...prevState,
                                [question._id]: error
                            }
                        });
                        setFormVal(question._id, error === "");
                    }
                    break;

                case "multi":
                    var error: any = getErrorMessage(question, "", 0);
                    setErrors((prevState: any) =>
                    {
                        return {
                            ...prevState,
                            [question._id]: error
                        }
                    });
                    setFormVal(question._id, error === "");
                    break;

                case "checkbox":
                case "table":
                    setErrors((prevState: any) =>
                    {
                        return {
                            ...prevState,
                            [question._id]: ""
                        }
                    });
                    setFormVal(question._id, true);
                    break;
            }
        });
        barIncreaser();
    }, []);

    const handleInputChange = (event: any, question: IQuestion, group?: IQuestion[]) =>
    {
        let { name, value }: { name: string, value: any } = event.target;

        if(event.target !== undefined)
        {
            if (group !== undefined) {
                var dependecyQuestions = group?.filter((questionAux: IQuestion) => questionAux.dependency === question.order);
                if (dependecyQuestions !== undefined) {
                    dependecyQuestions.forEach((questionAux: IQuestion) => {
                        let auxError:string|JSX.Element = "";
                        if ((question.questionType === "radio" && Number(value) > 0) || (question.questionType === "checkbox" && event.target.checked)) {
                            var answer = data.answers.find((answer: IAnswer) => answer.questionId === questionAux._id);
                            auxError = getErrorMessage(questionAux, questionAux._id, answer?.value, answer?.order);
                        }
                        setErrors((prevState: any) =>
                        {
                            return {
                                ...prevState,
                                [questionAux._id]: auxError
                            }
                        });
                        setFormVal(questionAux._id, auxError === "");
                    });
                }
            }

            var error: any;
            switch(question.questionType) {
                case "checkbox":
                case "multi":
                    var [questionId, answerId] = name.split("-");
                    if (question.questionType === 'multi' && event.target.type === 'checkbox') {
                        value = 0;
                    }
                    error = getErrorMessage(question, answerId, value);
                    break;

                case "radio":
                    const order = event.target.dataset.order;
                    error = getErrorMessage(question, question._id, value, order);
                    break;

                default:
                    error = getErrorMessage(question, question._id, value);
            }

            setErrors((prevState: any) =>
            {
                return {
                    ...prevState,
                    [question._id]: error
                }
            });

            setFormVal(question._id, error === "");
            barIncreaser();
            handleChange(event, question);
        }
    };

    return (
        <>
            <S.BarCont>
                <S.BgBar />
                <S.ProsBar
                    // @ts-ignore
                    barCount={barCount}
                />
            </S.BarCont>
            {suitabilityTestVersion && (
                <S.TableCont>
                    {suitabilityTestVersion.test.blocks.map((block: IBlock, blockIndex: number) => (
                        <S.Block key={`b-${blockIndex}`}>
                            {block.subBlock.map((subBlock: ISubBlock, subBlockIndex: number) => {
                                const groups = groupBy(subBlock.questions, (questionAux: IQuestion) => {
                                    return (questionAux.dependency !== undefined && questionAux.dependency !== null)
                                        ? questionAux.dependency.toString()
                                        : (questionAux.text.indexOf("¿Riesgo de") !== -1
                                            ? "¿Riesgo de"
                                            : questionAux.order.toString())
                                });
                                return (
                                    <div key={`qG-${blockIndex}-${subBlockIndex}`}>
                                        {blockIndex !== 0 && <Separator />}
                                        <S.Title>
                                            {subBlock.name !== "" && (
                                                <h1>{subBlock.name.replace(" (Marcar con una X una sola respuesta)", "")}</h1>
                                            )}
                                            {subBlock.description !== "" && (
                                                <h2>{subBlock.description}</h2>
                                            )}
                                        </S.Title>
                                        {Object.keys(groups).map((groupIndex: string) => {
                                            const group = groups[groupIndex];
                                            return group === undefined ? <div key={`gr-${blockIndex}-${subBlockIndex}-${groupIndex}`} className="empty"></div> : (
                                                <S.FieldSet key={`g-${blockIndex}-${subBlockIndex}-${groupIndex}`} className={group[0].title === null ? '': 'grouped'}>
                                                    {group[0].title !== null && (<legend>{group[0].title}</legend>)}
                                                    {group.map((question: IQuestion, questionIndex: number) => {
                                                        let disabledQuestion = false;
                                                        if (question.dependency !== null) {
                                                            const dependencyQuestion = subBlock.questions.find((questionAux: IQuestion) => questionAux.order === question.dependency);
                                                            if (dependencyQuestion !== undefined) {
                                                                if (dependencyQuestion.questionType === 'radio') {
                                                                    const dependencyAnswer = data.answers.find((answerAux: IAnswer) => dependencyQuestion._id === answerAux.questionId);
                                                                    disabledQuestion = !Boolean(dependencyAnswer?.value);
                                                                }
                                                                else {
                                                                    const dependencyAnswer = data.answers.find((answerAux: IAnswer) => dependencyQuestion.answers[0]._id === answerAux.questionId);
                                                                    disabledQuestion = dependencyAnswer === undefined;
                                                                }
                                                            }
                                                        }
                                                        return disabledQuestion ? <div key={`q-r-${blockIndex}-${subBlockIndex}-${groupIndex}-${questionIndex}`} className="empty"></div> : (
                                                            <div key={`q-r-${blockIndex}-${subBlockIndex}-${groupIndex}-${questionIndex}`}>
                                                                {question.questionType === "radio" && (
                                                                    <S.RadioCont>
                                                                        <S.Question>{question.text}</S.Question>
                                                                        <S.Description>{question.description}</S.Description>
                                                                        {question.img !== null && (
                                                                            <img src={question.img} style={{ width: "100%", maxWidth: "800px", margin: "20px 0" }} />
                                                                        )}
                                                                        {question.answers.map((answer: IQuestionAnswer, optionIndex: number) => {
                                                                            const answered = data.answers.find((answerAux: IAnswer) => question._id === answerAux.questionId);
                                                                            return (
                                                                            <S.RadioSelect key={`r-${blockIndex}-${subBlockIndex}-${groupIndex}-${questionIndex}-${optionIndex}`}>
                                                                                <S.RadioLabel>
                                                                                    {answer.text}
                                                                                    <S.RadioInput
                                                                                        id={`radio-${blockIndex}-${subBlockIndex}-${questionIndex}-${optionIndex}`}
                                                                                        type='radio'
                                                                                        value={answer.value}
                                                                                        name={question._id}
                                                                                        data-order={answer.order}
                                                                                        onChange={(event) => handleInputChange(event, question, group)}
                                                                                        checked={(answered?.value === answer.value && answered?.order === answer.order) || false}
                                                                                    />
                                                                                    <S.RadioCheck />
                                                                                </S.RadioLabel>
                                                                            </S.RadioSelect>
                                                                        )})}
                                                                    </S.RadioCont>
                                                                )}
                                                                {question.questionType === "checkbox" && (
                                                                    <S.RadioCont>
                                                                        <S.Question>{question.text}</S.Question>
                                                                        <S.Description>{question.description}</S.Description>
                                                                        {question.answers.map((answer: IQuestionAnswer, answerIndex: number) => (
                                                                            <S.CheckSelect key={`ca-${blockIndex}-${subBlockIndex}-${groupIndex}-${questionIndex}-${answerIndex}`}>
                                                                                <S.CheckLabel>
                                                                                    {answer.text}
                                                                                    <S.CheckInput
                                                                                        id={`radio-${blockIndex}-${subBlockIndex}-${questionIndex}-${answerIndex}`}
                                                                                        type='checkbox'
                                                                                        value={answer.value}
                                                                                        name={question._id + '-' + answer._id}
                                                                                        onChange={(event) => handleInputChange(event, question, group)}
                                                                                        checked={(data.answers.find((answerAux: IAnswer) => answer._id === answerAux.questionId)?.res.toString() === answer.value.toString()
                                                                                            && data.answers.find((answerAux: IAnswer) => answer._id === answerAux.questionId)?.order.toString() === answer.order.toString()) || false}
                                                                                    />
                                                                                    <S.CheckMark />
                                                                                </S.CheckLabel>
                                                                            </S.CheckSelect>
                                                                        ))}
                                                                    </S.RadioCont>
                                                                )}
                                                                {question.questionType === "multi" && (
                                                                    <S.Multi>
                                                                        <S.Question>{question.text}</S.Question>
                                                                        <S.Description>{question.description}</S.Description>
                                                                        {question.answers.map((answer: IQuestionAnswer, answerIndex: number) => (
                                                                            <div key={`ch-${blockIndex}-${subBlockIndex}-${groupIndex}-${questionIndex}-${answerIndex}`}>
                                                                                <div
                                                                                    className='checkbox'
                                                                                >
                                                                                    <S.CheckLabel>
                                                                                        {answer.text}.
                                                                                        <S.CheckInput
                                                                                            id={`checkbox-${blockIndex}-${subBlockIndex}-${questionIndex}-${answerIndex}`}
                                                                                            type='checkbox'
                                                                                            value={answer.value}
                                                                                            name={question._id + '-' + answer._id}
                                                                                            onChange={(event) => handleInputChange(event, question)}
                                                                                            checked={data.answers.find((answerAux: IAnswer) => answer._id === answerAux.questionId) || false}
                                                                                        />
                                                                                        <S.CheckMark />
                                                                                    </S.CheckLabel>
                                                                                </div>
                                                                                <div
                                                                                    className='extra'
                                                                                >
                                                                                    {data.answers.some((answerAux: IAnswer) => answer._id === answerAux.questionId) && (
                                                                                        <label>
                                                                                            <input
                                                                                                type="number"
                                                                                                min="0"
                                                                                                max="100"
                                                                                                id={`number-${blockIndex}-${subBlockIndex}-${questionIndex}-${answerIndex}`}
                                                                                                name={question._id + '-' + answer._id}
                                                                                                step="any"
                                                                                                onChange={(event) => handleInputChange(event, question)}
                                                                                                disabled={!data.answers.some((answerAux: IAnswer) => answer._id === answerAux.questionId)}
                                                                                                value={data.answers.find((answerAux: IAnswer) => answer._id === answerAux.questionId).inputRes || 0}
                                                                                            />
                                                                                        </label>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        {// @ts-ignore
                                                                            errors[question._id] !== "" && errors[question._id]
                                                                        }
                                                                    </S.Multi>
                                                                )}
                                                                {question.questionType === "table" && (
                                                                    <S.RadioCont>
                                                                        <S.Question>
                                                                            {question.text}
                                                                        </S.Question>
                                                                        {question.description}
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    {question.table.headers.map((header: any, headerIndex: number) => (
                                                                                        <th key={`th-${blockIndex}-${subBlockIndex}-${groupIndex}-${questionIndex}-${headerIndex}`}>{question.extraData[headerIndex - 1]}</th>
                                                                                    ))}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {question.table.rows.map((row: IQuestionTableRow, rowIndex: number) => (
                                                                                    <tr key={`tr-${blockIndex}-${subBlockIndex}-${groupIndex}-${questionIndex}-${rowIndex}`}>
                                                                                        <td>{row.cols[0].text}</td>
                                                                                        <td>
                                                                                            <S.CheckLabel>
                                                                                                <S.CheckInput
                                                                                                    id={`table-checkbox-${blockIndex}-${subBlockIndex}-${questionIndex}-${rowIndex}-a`}
                                                                                                    type='checkbox'
                                                                                                    name={question.id + '-' + row._id + '-1'}
                                                                                                    value={row.cols[1].value}
                                                                                                    onChange={(event) => handleInputChange(event, question)}
                                                                                                    checked={data.answers.find((answerAux: IAnswer) => row.cols[1]._id === answerAux.questionId)?.res === row.cols[1].value || false}
                                                                                                />
                                                                                                <S.CheckMark />
                                                                                            </S.CheckLabel>
                                                                                        </td>
                                                                                        <td>
                                                                                            <S.CheckLabel>
                                                                                                <S.CheckInput
                                                                                                    id={`table-td-${blockIndex}-${subBlockIndex}-${questionIndex}-${rowIndex}-b`}
                                                                                                    type='checkbox'
                                                                                                    name={question._id + '-' + row._id + '-2'}
                                                                                                    value={row.cols[2].value}
                                                                                                    onChange={(event) => handleInputChange(event, question)}
                                                                                                    checked={data.answers.find((answerAux: IAnswer) => row.cols[2]._id === answerAux.questionId)?.res === row.cols[2].value || false}
                                                                                                />
                                                                                                <S.CheckMark />
                                                                                            </S.CheckLabel>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </S.RadioCont>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </S.FieldSet>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </S.Block>
                    ))}
                    <Separator />
                    <S.AproveCont>
                        <S.CheckLabel>
                            <S.CheckInput
                                id={'collegue'}
                                type='checkbox'
                                name={'collegue'}
                                value={collegue ? 1 : 0}
                                onClick={() =>
                                {
                                    setCollegue(prevState => !prevState)
                                }}
                            />
                            <S.CheckMarkFinal />
                        </S.CheckLabel>

                        <S.AproveDescription>
                            <p>Confirmo expresamente con mi firma de la presente Encuesta MiFID - Conveniencia e Idoneidad que he recibido y comprendido la información pre-contractual sobre los instrumentos financieros para cuya contratación Diaphanum ha realizado este Cuestionario.</p>
                            <p>El arriba firmante confirma que ha comprendido las cuestiones que integran la presente Encuesta MiFID - Conveniencia e Idoneidad y la naturaleza y riesgos del Servicio que Diaphanum va a prestarle en el marco del mismo.</p>
                            <p>Le informamos de que dadas las característicasdel servicio que Ud., solicita a Diaphanum, nuestra Entidad está obligada a evaluar la idoneidad del mismo para usted; es decir evaluar si, a nuestro juicio, usted posee conocimientos y experiencia necesarios para comprender la naturaleza y riesgos del servicio que se le va a prestar por Diaphanum y si éste se ajusta a su situación financiera y a sus objetivos de inversión.</p>
                            <p>Diaphanum ha preparado esta Encuesta MiFID - Conveniencia e Idoneidad en base a la información que el cliente ha facilitado a Diaphanum y, en consecuencia, da su conformidad al el resultado de este Cuestionario de Evaluación. En caso de que se produzcan cambios que afecten a la información facilitada a Diaphanum reconozco mi obligación de informar inmediatamente y de los riesgos que no hacerlo podría conllevar en relación al servicio que Diaphanum me va a prestar.</p>
                        </S.AproveDescription>
                    </S.AproveCont>
                </S.TableCont>
            )}
            <S.BtnCont>
                <S.ContinueBtn
                    onClick={(event) => handleSubmit(event)}
                    disabled={!canSubmit || !collegue}
                    className="button tiny-primary-button"
                >
                    Siguiente
                </S.ContinueBtn>
            </S.BtnCont>
        </>
    );
};

export default SuitabilityTestInputs;