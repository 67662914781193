import * as S from '../ConvenienceTest.style';
import ModalComplete from './ModalComplete';
import { useContext, useEffect, useState } from 'react';
import Auth from "../../../Auth/Auth";
import { UserIdentityType } from '../../../services/UserIdentitiesService';
import SavingsPlanService, { SavingsPlanType, SavingsPlanTypes } from '../../../services/SavingsPlanService';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { SignatureComponent } from '../../../components/Signature/SignatureComponent';
import Moment from "moment";
import { ISignature } from "../../../services/SignaturesService";
import { Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch } from 'react-redux';
import { setActiveSavingsPlan } from '../../../store/auth/actions';
import { ThemeContext } from '@fluentui/react-theme-provider';

const ConvenienceTestConfirm = () =>
{
    const { savingsPlanId } = useParams<any>();
    const history = useHistory();
    const isPromotioned = Auth.isPromotioned();
    const theme = useContext(ThemeContext);
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();
    const { state } = useLocation<any>();
    const dispatch = useDispatch();

    const processExpirationDate = (signatures: ISignature[]) =>
    {
        if(signatures !== undefined && signatures.length > 0)
        {
            signatures.forEach((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = Moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    let defaultSignatures: any = state?.signatures;
    processExpirationDate(defaultSignatures);

    const [signatures, setSignatures] = useState(defaultSignatures);

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingsPlan: SavingsPlanType) =>
        {
            setSavingsPlan(savingsPlan);
        },
        (error) =>
        {
            setError(error);
            setIsLoaded(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(savingsPlan !== undefined && savingsPlan.savingsPlansUserIdentities !== undefined)
        {
            setUserIdentities(savingsPlan.savingsPlansUserIdentities.map(savingsPlanUserIdentity => savingsPlanUserIdentity.userIdentity));
            setIsLoaded(true);
        }
    }, [savingsPlan]);

    useEffect(() =>
    {
        setPhoneNumbers(userIdentities.filter(uI =>
        {
            var currentDate = Moment();
            var birthdayDate = Moment(uI.birthday);
            return currentDate.diff(birthdayDate, 'years') > 17;
        }).map((userIdentity: any) =>
            "X".repeat(userIdentity.phone.length - 3) + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleSubmit = async () =>
    {
        if(savingsPlan !== undefined)
        {
            if (savingsPlan?.status !== 4) {
                SavingsPlanService.patchSavingsPlan(savingsPlanId, { status: 1, configurationMapStep: "3.1" }).then(() => {
                    history.push('/configurationMap', {
                        savingPlanId: savingsPlan.id,
                        savingPlanName: savingsPlan.name,
                    });
                },
                    (error: any) => {
                        setError(error);
                    });
            }
            else {
                const activePlan = Auth.getActivePlan();
                Auth.setActivePlan({ ...activePlan, outdatedContract: false });
                await dispatch(setActiveSavingsPlan({ ...activePlan, outdatedContract: false, sync: true }));
                history.push('/configurationMap', {
                    savingPlanId: savingsPlan.id,
                    savingPlanName: savingsPlan.name,
                });
            }
        }
        if(!state?.signatures || !modalShow)
        {
            scrollToTop();
            if (savingsPlan?.status !== 4) {
                setModalShow(true)
            }
        }
    };

    return(
        <DocumentTitle title={`IronIA - Confirmación`}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont theme={theme}>
                    <S.MainCont>
                        {!isLoaded &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {isLoaded &&
                        (
                            <SignatureComponent
                                signatures={signatures}
                                userIdentities={userIdentities}
                                title="Firma del contrato"
                                submitButtonText="Firmar contrato"
                                cancelButtonText="Volver"
                                alreadySignedText="Enhorabuena, ¡ya tienes tu plan de ahorro!"
                                headerTextArea={"A continuación te incluimos los detalles del contrato con nosotros. Léelo y cuando estés listo, dale al botón "
                                    + "de 'Solicitar SMS'. Te enviaremos un código SMS por cada interviniente"
                                    + (userIdentities.length > 1 ? ` a los números ${phoneNumbers} ` : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " ")
                                    + "para realizar la firma del contrato."}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push('/contract/' + savingsPlanId)}
                                onCancelButtonClicked={() => history.push('/contract/' + savingsPlanId)}
                                requestNewCodeButtonText="Recibir nuevo código"
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSigned={handleSubmit}
                            />
                        )}
                        {error &&
                        (
                            <div>Alguno de los códigos enviados no es válido</div>
                        )}
                    </S.MainCont>
                </S.Cont>
                {modalShow && <ModalComplete setModalShow={setModalShow} isPromotioned={isPromotioned} />}
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ConvenienceTestConfirm;