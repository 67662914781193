import { useContext, useState, useEffect } from "react";
import { Container, CardButton } from "./styles";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { translate } from "../../utils/i18n";

const MANAGERS = {
    "m&g": [
        "M&G LUXEMBOURG S.A",
        "M&G LUXEMBOURG S.A.",
        "M&G SECURITIES LIMITED*"
    ],
    fidelity: [
        "FIDELITY INTERNATIONAL",
        "FIDELITY UCITS II ICAV"
    ],
    vontobel: [
        "VONTOBEL AM",
        "VONTOBEL FUND II"
    ],
    gam: [
        "GAM STERLING MANAGEMENT LIMITED",
        "GAM (LUXEMBOURG) S.A."
    ],
    janusHenderson: [
        "JANUS HENDERSON CAPITAL",
        "JANUS HENDERSON INVESTORS",
        "JANUS HENDERSON FUND",
        "JANUS HENDERSON HORIZON",
        "HENDERSON GARTMORE SICAV",
        "HENDERSON GLOBAL INVESTORS",
        "HENDERSON INVESTMENT FUNDS LIMITED (GBP)",
        "HENDERSON INVESTMENT FUNDS LIMITED (EUR)",
        "HENDERSON INVESTMENT FUNDS LIMITED (USD)"
    ],
    allianz: ["ALLIANZ GLOBAL INVESTORS"],
    candriam: [
        "CANDRIAM LUX",
        "CANDRIAM (AI)",
        "CANDRIAM FRANCE",
        "CANDRIAM LUX (CACEIS)"
    ],
    jupiter: ["JUPITER GLOBAL FUND"],
    axa: [
        "AXA IM FIIS",
        "AXA INVESTMENT MANAGERS",
        "AXA INVESTMENT MANAGERS PARIS",
        "AXA IM WORLD ACCESS VEHICLE ICAV",
        "AXA ROSENBERG INVESTMENT MANAGEMENT LTD"
    ]
};

const FeaturedManagers = ({selectedManagers, onClick, readOnly}) =>
{
    const theme = useContext(ThemeContext);
    const [selection, setSelection]= useState([]);

    useEffect(() => {
        if (selectedManagers !== undefined) {
            var selection = Object.keys(MANAGERS).filter((manager) => {
                const translatedManager = translate(MANAGERS[manager][0]);
                const selected = selectedManagers.some((name) => translate(name) === translatedManager);
                return selected;
            }).map((manager) => translate(MANAGERS[manager][0]));

            setSelection(selection);
        }
    }, [selectedManagers]);

    return(
        <Container>
            {Object.keys(MANAGERS).map((manager, manageIndex) =>
            {
                return(
                    <CardButton theme={theme}
                        selected={selection.some((name) => name === translate(MANAGERS[manager][0]))}
                        anyselected={selection.length}
                        key={'manageIndex' + manageIndex}
                        readOnly={readOnly}
                    >
                        <picture onClick={() => onClick(MANAGERS[manager])}>
                            <source
                                srcSet={`${process.env.PUBLIC_URL}/images/${manager}.avif`}
                                alt="Añadir/eliminar del filtro de gestoras"
                                type="image/avif"
                            />
                            <source
                                srcSet={`${process.env.PUBLIC_URL}/images/${manager}.webp`}
                                alt="Añadir/eliminar del filtro de gestoras"
                                type="image/webp"
                            />
                            <img
                                src={`${process.env.PUBLIC_URL}/images/${manager}.jpg`}
                                alt="Añadir/eliminar del filtro de gestoras"
                                type="image/jpeg"
                            />
                        </picture>
                    </CardButton>
                );
            })}
        </Container>
    );
};

export default FeaturedManagers;