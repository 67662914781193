export const PROFILE = "PROFILE";
export const TOKEN = "TOKEN";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const AUTHENTICATED = "AUTHENTICATED";
export const PROFILERESET = "PROFILERESET";
export const ROLES = "ROLES";
export const LOGINFAILE = "LOGINFAILE";
export const COMPLETE_USER_DATA = "COMPLETE_USER_DATA";
export const CHECK_SUBSCRIPTIONS = "CHECK_SUBSCRIPTIONS";
export const CHECK_PORTFOLIOS = "CHECK_PORTFOLIOS";
export const USER_ACTIVE_PLAN = "USER_ACTIVE_PLAN";
export const USER_ELEMENTS_STATUS = "USER_ELEMENTS_STATUS";