import { useState, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { DefaultButton, DetailsList, DirectionalHint, IColumn, ITooltipHostStyles, ITooltipProps, Link, Separator, Spinner, SpinnerSize, TooltipDelay, TooltipHost } from "@fluentui/react";
import Auth from "../../Auth/Auth";
import FirstSteps from "./FirstSteps";
import Highstock, { GraphicType } from "../Highstock/Highstock";
import PieHighstock from "../Highstock/PieHighstock";
import SavingsPlanService, { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import FinametrixService from '../../services/FinametrixService';
import FundService from '../../services/FundService';
import { ReactComponent as SaverImage } from '../../images/i_am_a_saver.svg';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router';
import "./DecideSavingsPlan.sass";
import { useConst } from '@fluentui/react-hooks';
import Moment from 'moment'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCart, addLine } from '../../store/cart/actions';
import { useId } from '@fluentui/react-hooks';
import { InstrumentPriceType, PortfolioCompositionItemType, PortfolioDistributionItemType, QuantType } from '../../services/ManagedPortfolioService';
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../services/TelemetryService';
import { percentFormatter } from '../../utils/numberFormatter';

const debounce = require('lodash/debounce');

interface IInfoFund
{
    allFundsId: number,
    benchmarkFinametrixId: number,
    isin: string,
    managementFee?: number,
    minimunInitialInvestment?: number,
    minimunAdditionalInvestment?: number,
    noMin?: boolean,
    ongoingCharges?: number,
    rebate?: number
}

const tooltipProps: ITooltipProps =
{
    onRenderContent: () =>
    (
        <div>Cálculos realizados con la información de los últimos 5 años</div>
    ),
};

const hostStyles: Partial<ITooltipHostStyles> = {root: {display: 'inline-block', backgroundColor: "transparent !important"}};

const SaverSavingsPlan = () =>
{
    const appInsights = ai.reactPlugin;
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState({ message: "" });
    const [isLoaded, setIsLoaded] = useState(false);
    const user = Auth.getUserProfile();
    const [subscription, setSubscription] = useState<SubscriptionType>();
    const trackClicks = useTrackEvent(appInsights, "We Will Rock You", subscription?.name);
    const performTracking = () =>
    {
        trackClicks("We Will Rock You");
    };
    const { savingsPlanId } = useParams<any>();
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>(
    {
        userId: user.id ? user.id : null,
        name: "Soy ahorrador",
        initialContribution: 10000,
        periodicContribution: 0,
        periodicContributionPeriod: 1,
        type: SavingsPlanTypes.Saver,
        status: 0
    });

    const [modelPortfolioSerie, setModelPortfolioSerie] = useState<InstrumentPriceType[]>([]);
    const isLoadedModelPortfolioSerie: React.MutableRefObject<boolean> = useRef<boolean>(false);
    const [modelPortfolioComposition, setModelPortfolioComposition] = useState<PortfolioCompositionItemType[]>([]);
    const [isLoadedModelPortfolioComposition, setIsLoadedModelPortfolioComposition] = useState(false);
    const [modelPortfolioDistribution, setModelPortfolioDistribution] = useState<PortfolioDistributionItemType[]>([]);
    const tooltipId = useId('tooltip');

    const highstockOptions = useConst(
    {
        rangeSelector:
        {
            selected: 6,
            buttons:
            [
                {
                    type: 'year',
                    count: 1,
                    text: '1y'
                },
                {
                    type: 'year',
                    count: 2,
                    text: '2y'
                },
                {
                    type: 'year',
                    count: 3,
                    text: '3y'
                },
                {
                    type: 'year',
                    count: 4,
                    text: '4y'
                },
                {
                    type: 'year',
                    count: 5,
                    text: '5y'
                },
                {
                    type: 'ytd',
                    text: 'YTD'
                },
                {
                    type: 'all',
                    text: 'Todo'
                }
            ]
        }
    });

    const columns: IColumn[] =
    [
        {
            key: 'name',
            name: 'Fondo',
            fieldName: 'name',
            minWidth: 300
        },
        {
            key: 'asset_class',
            name: 'Categoría',
            fieldName: 'category',
            minWidth: 200,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{item.category}</span>
            },
        },
        {
            key: 'asset_subclass',
            name: 'Subcategoría',
            fieldName: 'subcategory',
            minWidth: 200,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{item.subcategory}</span>
            },
        },
        {
            key: 'weight',
            name: 'Peso',
            fieldName: 'weight',
            minWidth: 100,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{percentFormatter.format(item.weight/100)}</span>
            },
        }
    ];

    useEffect(() =>
    {
        if(subscription === undefined)
        {
            SubscriptionService.getSubscriptionsByType("saver").then((subscriptions: any) =>
            {
                if(subscriptions.length > 0)
                {
                    setSubscription(subscriptions[0]);
                }
            },
            (error) =>
            {
                setError(error);
            });
        }
    }, [subscription]);

    useEffect(() =>
    {
        if(savingsPlanId)
        {
            SavingsPlanService.getSavingsPlan(savingsPlanId).then((storedSavingsPlan: any) =>
            {
                setSavingsPlan(storedSavingsPlan);
                setIsLoaded(true);
            },
            (error) =>
            {
                setIsLoaded(true);
                setError(error);
            });
        }
        else
        {
            setIsLoaded(true);
        }
    }, [savingsPlanId]);

    useEffect(() =>
    {
        if(savingsPlan !== undefined && savingsPlan.managedPortfolio !== undefined)
        {
            FinametrixService.getModelPortfolio(savingsPlan.managedPortfolio.finametrixId).then((modelPortfolio: any) =>
            {
                let maxDate = Moment();
                let minDate = Moment().subtract(5, 'years');
                var maxDateTxt = maxDate.format("YYYYMMDD");
                var minDateTxt = minDate.format("YYYYMMDD");
                var lastComposition = modelPortfolio.compositions[modelPortfolio.compositions.length - 1];

                FinametrixService.postBenchmarksQuant(modelPortfolio.compositions, minDateTxt, maxDateTxt).then((quant: QuantType) =>
                {
                    var modelPortfolioPriceData: InstrumentPriceType =
                    {
                        name: modelPortfolio.name,
                        value: modelPortfolio.finametrixId,
                        // @ts-ignore
                        data: quant.serie.map((price) =>
                        {
                            let date = Moment(price.dateAsString);
                            let serieItem: [number, number] = [date.valueOf(), price.value];
                            return serieItem;
                        })
                    }
                    var aux: InstrumentPriceType[] = [modelPortfolioPriceData];
                    setModelPortfolioSerie(aux);
                    isLoadedModelPortfolioSerie.current = true;
                },
                (error: any) =>
                {
                    setError(error);
                    isLoadedModelPortfolioSerie.current = true;
                });

                var instrumentIds = lastComposition.items.map((instrument: any) => instrument.instrumentId);
                FinametrixService.getInstruments(instrumentIds).then((items: any) =>
                {
                    var aux: any = [];
                    var proccesed = 0;
                    lastComposition.items.forEach(async (compositionItem: any) =>
                    {
                        compositionItem.name = items[compositionItem.instrumentId].name;
                        compositionItem.isin = items[compositionItem.instrumentId].isin;

                        await FinametrixService.getInstrumentAssetclass(compositionItem.instrumentId).then((assetclass: any) =>
                        {
                            compositionItem.category = assetclass?.relationData?.parent?.name;
                            compositionItem.subcategory = assetclass?.relationData?.name;

                            var existentCategory = aux.find((item: any) => item.name === compositionItem.category);
                            if(existentCategory?.level2 !== undefined)
                            {
                                existentCategory.value += compositionItem.weight;
                                var existentSubcategory = existentCategory.level2.find((item: any) => item.name === compositionItem.subcategory);
                                if(existentSubcategory)
                                {
                                    existentSubcategory.value += compositionItem.weight;
                                }
                                else
                                {
                                    existentCategory.level2.push({name: compositionItem.subcategory, value: compositionItem.weight});
                                }
                            }
                            else
                            {
                                aux.push({name: compositionItem.category, value: compositionItem.weight, level2: [{name: compositionItem.subcategory, value: compositionItem.weight}]});
                            }
                            proccesed++;
                        });

                        if(Object.keys(items).length === proccesed)
                        {
                            setModelPortfolioDistribution(aux);
                            setModelPortfolioComposition(lastComposition.items);
                            setIsLoadedModelPortfolioComposition(true);
                        }
                    });
                },
                (error: any) =>
                {
                    setError(error);
                });
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    }, [savingsPlan]);

    const onChangeQuantity = (values: any) =>
    {
        const { value } = values;
        if(savingsPlan.initialContribution === undefined || value !== savingsPlan.initialContribution.toString())
        {
            setSavingsPlan((prevState: any) =>
            (
                {
                    ...prevState,
                    initialContribution: parseFloat(value)
                }
            ));
        }
    };

    const onChangePeriodicQuantity = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContribution: event.target.value }
        ))
    };

    const onChangeContributionPeriod = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContributionPeriod: event.target.value }
        ))
    };

    const addFundsToCart = async () =>
    {
        var instrumentIsins = modelPortfolioComposition.map((instrument: any) => instrument.isin);
        var infoFunds: IInfoFund[] = await FundService.getMultipleFunds(instrumentIsins);
        await dispatch(clearCart());
        modelPortfolioComposition.forEach(async (item: any) =>
        {
            var fund = infoFunds.find(infoFund => infoFund.isin === item.isin);
            await dispatch(addLine(
            {
                fund: fund,
                amount: (savingsPlan.initialContribution || 0) * item.weight / 100,
                readOnly: true,
                savingsPlanId: parseInt(savingsPlanId),
                source: "rebalancing"
            }));
        });
        return history.push("/cart");
    };

    if(error.message !== "")
    {
        return (<div>Error: {error.message}</div>)
    }

    return(
        <DocumentTitle title='IronIA - Configurando plan de ahorro'>
            <div className="saver-savings-plan-container">
                <Link to="/savingsPlans/new" className="back-link">Volver atrás</Link>
                <div className="ms-Grid-row savings-plans section-header">
                    <div className="ms-Grid-col ms-md3">
                        <SaverImage />
                    </div>
                    <div className="ms-Grid-col ms-md9">
                        <h2 className="highlighted">Soy ahorrador</h2>
                        <p className="description">Selecciona cuánto dinero te gustaría invertir para en función del plan poder darte una
                            estimación de tu rentabilidad futura:</p>
                        <div className="number-control big-font">
                            <label className="ms-Label">¿Cuál sería tu inversión inicial?</label>
                            {!isLoaded &&
                            (
                                <Spinner size={SpinnerSize.large} />
                            )}
                            {isLoaded &&
                            (
                                <NumberFormat thousandSeparator="."
                                    decimalSeparator=","
                                    // @ts-ignore
                                    label="¿Cuál es tu dinero base?"
                                    placeholder="Escribe una cantidad..."
                                    value={savingsPlan.initialContribution}
                                    onValueChange={debounce(
                                        (values: any) => onChangeQuantity(values),
                                        500
                                    )}
                                    suffix="€"
                                    className="ms-TextField-field"
                                />
                            )}
                            <TooltipHost tooltipProps={tooltipProps}
                                delay={TooltipDelay.zero}
                                id={tooltipId}
                                directionalHint={DirectionalHint.topCenter}
                                styles={hostStyles}
                                className="ironia-tooltip"
                            >
                                <DefaultButton aria-describedby={tooltipId}>
                                    <i className="ironia-icon information" />
                                </DefaultButton>
                            </TooltipHost>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row savings-plans options">
                    <Separator className="separator" />
                    <div className="ms-Grid-col ms-sm12">
                        <h3>Cartera de inversiones recomendada: <a href="https://www.ironia.tech/post/wewillrockyou1977" target="_blank" rel="noopener noreferrer" onClick={performTracking}>{subscription !== undefined && subscription.name}</a></h3>
                        {!isLoadedModelPortfolioSerie.current &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {isLoadedModelPortfolioSerie.current &&
                        (
                            <Highstock series={modelPortfolioSerie} customOptions={highstockOptions} graphicType={GraphicType.linearGraphic} />
                        )}
                    </div>
                </div>
                <Separator className="separator" />
                <div className="ms-Grid-row savings-plans options">
                    <div className="ms-Grid-col ms-sm8">
                        {modelPortfolioComposition.length === 0 &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {modelPortfolioComposition.length !== 0 &&
                        (
                            <DetailsList items={modelPortfolioComposition} columns={columns} selectionMode={0} />
                        )}
                    </div>
                    <div className="ms-Grid-col ms-md4">
                        <h3 className="text-center">Tipología de inversiones</h3>
                        {modelPortfolioDistribution.length === 0 &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {modelPortfolioDistribution.length !== 0 &&
                        (
                            <PieHighstock chartSerie={modelPortfolioDistribution} options={{showSubcategories: true}} />
                        )}
                    </div>
                </div>
                <Separator className="separator" />
                <div className="ms-Grid-row savings-plans options">
                    <FirstSteps savingsPlan={savingsPlan}
                        subscription={subscription}
                        isSavingsPlanReady={savingsPlan.managedPortfolio !== undefined}
                        onChangeQuantity={onChangeQuantity}
                        onChangePeriodicQuantity={onChangePeriodicQuantity}
                        onChangeContributionPeriod={onChangeContributionPeriod}
                        publicVersion={false}
                        user={user}
                        savingsPlanIsCompleted={true}
                        isLoadedModelPortfolioComposition={isLoadedModelPortfolioComposition}
                        addFundsToCart={addFundsToCart}
                    />
                </div>
            </div>
        </DocumentTitle>
    );
}

export default SaverSavingsPlan;