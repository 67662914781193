import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { ElementTransactionType } from "./ElementsService";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;
interface IElementsPromotion
{
    id?: number,
    name: string,
    description: string,
    amountType: string,
    amountElement: number,
    slug: string,
    active: boolean,
    elementsButton: string,
    startDate: string,
    endDate: string,
    elementTransaction: ElementTransactionType,
    parent: IElementsPromotion,
    childrens: IElementsPromotion[]
}

export type ElementsPromotionType = IElementsPromotion;

class ElementsPromotionsService
{
    public getElementsPromotions = async (): Promise<ElementsPromotionType[]> =>
    {
        let url: string = `${proxy}/elementsPromotions`;
        const req = await axios.get(url);
        return req.data;
    }
    public getActiveElementsPromotions = async (): Promise<ElementsPromotionType[]> =>
    {
        let url: string = `${proxy}/elementsPromotions?active=true`;
        const req = await axios.get(url);
        return req.data;
    }
    public getElementsPromotionsAppliedToUser = async (userId: number): Promise<ElementsPromotionType[]> =>
    {
        let url: string = `${proxy}/elementsPromotions/getAppliedToUser/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getElementsPromotionsAvailableForUser = async (userId: number): Promise<ElementsPromotionType[]> =>
    {
        let url: string = `${proxy}/elementsPromotions/getAvailableForUser/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getElementsPromotion = async (elementsPromotionId: number): Promise<ElementsPromotionType> =>
    {
        let url: string = `${proxy}/elementsPromotions/${elementsPromotionId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public createElementsPromotion = async (elementsPromotion: ElementsPromotionType): Promise<ElementsPromotionType> =>
    {
        let url: string = `${proxy}/elementsPromotions`;
        const req = await axios.post(url, elementsPromotion);
        return req.data;
    }
    public updateElementsPromotion = async (elementsPromotion: ElementsPromotionType): Promise<ElementsPromotionType> =>
    {
        let url: string = `${proxy}/elementsPromotions/${elementsPromotion.id}`;
        const req = await axios.put(url, elementsPromotion);
        return req.data;
    }
    public patchElementsPromotion = async (elementsPromotionId: number, elementsPromotion: any): Promise<ElementsPromotionType> =>
    {
        let url: string = `${proxy}/elementsPromotions/${elementsPromotionId}`;
        const req = await axios.patch(url, elementsPromotion);
        return req.data;
    }
    public deleteElementsPromotion = async (elementsPromotionId: number): Promise<ElementsPromotionType> =>
    {
        let url: string = `${proxy}/elementsPromotions/${elementsPromotionId}`
        const req = await axios.delete<any>(url)
        return req.data
    }
}

const elementsPromotions = new ElementsPromotionsService();
export default elementsPromotions;