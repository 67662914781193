import React, { useEffect, useState } from "react";
import { ResourcesOptimizer } from "../../resources/ResourcesOptimizer";
import {
	OptimizerBody,
	OptimizerButton
} from "./styles";
import chartImg from "../../images/mock-chart.png";
import chartImg2 from "../../images/mock-chart-2.png";
import { ThemeContext } from "@fluentui/react-theme-provider";
import ElementsIAIcon from "../../images/ia.svg";
import { Link, useLocation } from "react-router-dom";
import ElementService, { ElementAmountType, ServiceType, UpdateElementsAmountType } from "../../services/ElementsService";
import Auth from "../../Auth/Auth";
import { DefaultButton, CommandBar, DetailsList, IDetailsRowBaseProps, DetailsRow, IButtonProps, ICommandBarItemProps, IDetailsFooterProps, Modal, Selection, SelectionMode, Spinner, SpinnerSize, IColumn } from "@fluentui/react";
import OptimizerService, { OptimizerResponse, OrderData, OrdersResponse, PortfolioType, Year } from "../../services/OptimizerService";
import { useHistory } from "react-router";
import ElementsService from "../../services/ElementsService";
import { WarningContainer, WarningText } from "../Elements/ElementStyles";
import PortfolioModelWalletService, { CartLinesFromDB, DataAndCartLines } from "../../services/PortfolioModelWalletService";
import moment from "moment";
import { ai } from "../../services/TelemetryService";
import { currencyFormatter, decimalMaximumLimitFormatter } from "../../utils/numberFormatter";
import OrderService, { OrderType } from "../../services/OrderService";
import { ISignature } from "../../services/SignaturesService";
import OptimizerSignature from "./Signature";
import { percentFormatter, longPercentFormatter } from '../../utils/numberFormatter';

export const Types = {
	Mark: "Markowitz",
	Litter: "Black-Litterman"
};

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = { ariaLabel: 'Más acciones' };

const Optimizer = () => {
	const location = useLocation();
	const themeContext = React.useContext(ThemeContext);
	const user = Auth.getUserProfile();
	const userElementsStatus = Auth.getUserElementsStatus();
	const history = useHistory();
	const appInsights = ai.reactPlugin;
	const activePlan = Auth.getActivePlan();
	const [loadingPage, setLoadingPage] = React.useState<boolean>(true);
	const [funds] = React.useState<CartLinesFromDB[]>((location.state as any)?.funds ?? []);
	const [optimizeMethod, setOptimizeMethod] = React.useState<typeof Types.Mark | typeof Types.Litter>(Types.Mark);
	const [elementsAmount, setElementsAmount] = React.useState<ElementAmountType>();
	const [isLoadingElementsAmount, setIsLoadingElementsAmount] = React.useState<boolean>(true);
	const [portfolio, setPortfolio] = React.useState<PortfolioType | undefined>(undefined);
	const [view, setView] = React.useState<PortfolioType>();
	const [optimizerResponse, setOptimizerResponse] = React.useState<Year[]>();
	const [simulateOptimzierLoading, setSimulateOptimzierLoading] = React.useState<boolean>(false);
	const [optimzierLoading, setOptimzierLoading] = React.useState<boolean>(false);
	const [isOptimizerSimulated, setIsOptimizerSimulated] = React.useState<boolean | undefined>(undefined);
	const [payedOptimizer, setPayedOptimizer] = React.useState<boolean>(false);
	const [showSignContract, setShowSignContract] = React.useState<boolean>(false);
	const [selectedYear, setSelectedYear] = React.useState<number>(0);
	const [payOptimizerError, setPayOptimizerError] = React.useState(false);
	const [optimizerApiError, setOptimizerApiError] = React.useState<string>("");
	const [optimizerOrdersApiError, setOptimizerOrdersApiError] = React.useState<string>("");
	const [showModelPortfolioGenerated, setShowModelPortfolioGenerated] = React.useState<boolean>(false);
	const [modelPortfolioName, setModelPortfolioName] = React.useState<string>("");
	const [loadingYearActions, setLoadingYearActions] = React.useState<boolean>(false);
	const [generatedOrders, setGeneratedOrders] = React.useState<OrdersResponse[]>();
	const [loadingInternalMovements, setLoadingInternalMovements] = React.useState<boolean>(false);
	const [signatures, setSignatures] = React.useState<ISignature[]>([]);
	const [optimizeElementsIAPrice, setOptimizeElementsIAPrice] = React.useState<number>();
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const selection = new Selection({
		onSelectionChanged: () => {
			updateSelection()
		}
	});

	useEffect(() => {
		if (portfolio === undefined && view === undefined) {
			window.scrollTo(0, 0);
			let newPortfolio: PortfolioType = {};
			let newview: PortfolioType = {};
			funds.forEach(fund => {
				newPortfolio[fund.isin] = Number(fund.weight.replace("%", "").replace(",", ".").trim());
				newview[fund.isin] = 0;
			});
			setPortfolio(newPortfolio);
			setView(newview);
			setLoadingPage(false);
		}
	}, [funds, portfolio, view]);

	useEffect(() => {
		let optimizerServiceTypeid = 0
		ElementService.servicePrice(optimizerServiceTypeid).then((response: ServiceType) => {
			setOptimizeElementsIAPrice(response.elementIA);
		});
	}, []);

	useEffect(() => {
		if (userElementsStatus.isSubscribed) {
			setOptimizerApiError("");
			ElementService.getElementsAmountByUserId(user.id)
				.then((elementsAmountAux: ElementAmountType) => {
					setElementsAmount(elementsAmountAux);
				})
				.catch(() => {
					setOptimizerApiError(
						"Lo sentimos ha ocurrido un error inesperado, intentaremos resolverlo lo antes posible, disculpe las molestias."
					);
				})
				.finally(() => {
					setIsLoadingElementsAmount(false);
				});
		}
	}, [user.id, userElementsStatus.isSubscribed, payedOptimizer]);

	const simulateOptimizer = () => {
		appInsights.trackEvent({ name: "Optimizador IronIA", properties: { userId: user.id, simulated: true, method: optimizeMethod } });
		setIsOptimizerSimulated(true);
		setSimulateOptimzierLoading(true);
		optimize();
	};

	const buyOptimizer = async () => {
		appInsights.trackEvent({ name: "Optimizador IronIA", properties: { userId: user.id, simulated: false, method: optimizeMethod } });
		setOptimizerResponse([]);
		setSelectedYear(0);
		setIsOptimizerSimulated(false);
		setOptimzierLoading(true);
		setPayOptimizerError(false);
		setOptimizerApiError("");
		let elements: UpdateElementsAmountType = {
			userId: user.id,
			iA: -1 * optimizeElementsIAPrice!,
			concept: "Adquirido optimizador Ironia",
			description: `Método utilizado: ${optimizeMethod}`
		};
		let enoughElements: boolean | void = (elementsAmount?.elementIA ?? 0) >= optimizeElementsIAPrice!;
		let correctTransaction: boolean | void = false;
		if (enoughElements) {
			correctTransaction = await ElementsService.updateElementsAmount(elements).catch(() => {
				setOptimzierLoading(false);
				setOptimizerApiError(
					"Lo sentimos ha ocurrido un error inesperado al optimizar tu cartera, intentaremos resolverlo lo antes posible, disculpe las molestias."
				);
			});
		} else {
			setOptimzierLoading(false);
			setPayOptimizerError(true);
		}

		if (correctTransaction) {
			setPayedOptimizer(true);
			optimize();
		} else {
			setOptimzierLoading(false);
		}
	};

	const optimize = () => {
		if (optimizeMethod === Types.Mark && portfolio) {
			OptimizerService.optmizeMarkowitz({ Portfolio: portfolio })
				.then((response: OptimizerResponse) => {
					const roundedResponse = roundToTwoDecimals(response.Years);
					const fixedResponse = checkTotalWeight(roundedResponse);
					setOptimizerResponse(fixedResponse);
				})
				.catch(() => {
					setOptimizerApiError(
						"Lo sentimos ha ocurrido un error inesperado al optimizar tu cartera, intentaremos resolverlo lo antes posible, disculpe las molestias."
					);
				})
				.finally(() => {
					setOptimzierLoading(false);
					setSimulateOptimzierLoading(false);
				});
		}

		if (optimizeMethod === Types.Litter && portfolio && view) {
			OptimizerService.optmizeLitterman({ Portfolio: portfolio }, { Views: view })
				.then((response: OptimizerResponse) => {
					const roundedResponse = roundToTwoDecimals(response.Years);
					const fixedResponse = checkTotalWeight(roundedResponse);
					setOptimizerResponse(fixedResponse);
				})
				.catch(() => {
					setOptimizerApiError(
						"Lo sentimos ha ocurrido un error inesperado al optimizar tu cartera, intentaremos resolverlo lo antes posible, disculpe las molestias."
					);
				})
				.finally(() => {
					setOptimzierLoading(false);
					setSimulateOptimzierLoading(false);
				});
		}
	};

	function roundToTwoDecimals(years: Year[]): Year[] {
		const updatedYears = years.map((year) => {
			const { Year: currentYear, AnnualReturn: annualReturn, AnnualVolatility: annualVolatility, ...isinValues } = year;
			const roundedIsinValues: { [isin: string]: number } = {};

			for (const key in isinValues) {
				if (typeof isinValues[key] === "number" && !isNaN(isinValues[key])) {
					roundedIsinValues[key] = Number(isinValues[key].toFixed(4));
				} else {
					roundedIsinValues[key] = 0;
				}
			}

			return {
				Year: currentYear,
				AnnualReturn: annualReturn,
				AnnualVolatility: annualVolatility,
				...roundedIsinValues
			};
		});

		return updatedYears;
	}

	function checkTotalWeight(years: Year[]): Year[] {
		const updatedYears = years.map((year) => {
			const { Year: currentYear, AnnualReturn, AnnualVolatility, ...isinValues } = year;
			const values = Object.values(isinValues).filter((value) => typeof value === 'number' && !isNaN(value));
			const roundedValues = values.map((value) => Number(value.toFixed(4)));
			const sum = roundedValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

			if (sum !== 1) {
				const maxIsinValue = Math.max(...values);
				const difference = 1 - sum;
				const keyToUpdate = Object.keys(isinValues).find((key) => isinValues[key] === maxIsinValue);
				if (keyToUpdate) {
					year[keyToUpdate] = Number((year[keyToUpdate] + difference).toFixed(4));
				}
			}

			return year;
		});

		return updatedYears;
	}

	const buttonIAClickEvent = () => {
		if (!userElementsStatus.isSubscribed) {
			history.push("/Elements/Signature");
		} else {
			history.push("/ElementsIA");
		}
	};

	const blackLittermanRadioButtonOnClickHandler = (isin: string, value: number) => {
		setView(prevState => ({
			...prevState,
			[isin]: value
		}));
	};

	const generateModelPortfolio = () => {
		setOptimizerApiError("");
		let modelPortfolio = funds.map(fund => ({ ...fund }));

		let selectedResponse = optimizerResponse?.find(x => x.Year === selectedYear);

		if (selectedResponse && modelPortfolio.length > 0) {
			let dataAndCartLines: DataAndCartLines[] = [];

			modelPortfolio.forEach(fund => {
				const weight = selectedResponse![fund.isin] ?? 0;
				fund.weight = (weight * 100).toFixed(2) + " %";
				dataAndCartLines.push({
					fund: fund,
					savingPlanId: activePlan.id,
					readOnly: false,
					modelPercentage: +(weight * 100).toFixed(2)
				});
			});

			let currentDate = moment().format("DD/MM/YYYY HH:mm:ss");
			let walletName = `Cartera Modelo Optimizada - método ${optimizeMethod} - ${currentDate}`;
			setModelPortfolioName(walletName);

			PortfolioModelWalletService.saveModelWallet(activePlan.id, walletName, 0, dataAndCartLines).finally(() => {
				setShowModelPortfolioGenerated(true);
				setLoadingYearActions(false);
			});
		}
	};

	const generateOrders = () => {
		setOptimizerApiError("");
		setSignatures([]);
		let modelPortfolio = funds.map(fund => ({ ...fund }));
		let selectedResponse = optimizerResponse?.find(x => x.Year === selectedYear);
		let orders: OrderData[] = [];
		if (selectedResponse && modelPortfolio.length > 0) {
			modelPortfolio.forEach(fund => {
				orders.push({
					isin: fund.isin,
					amount: 0,
					weight: Number(((selectedResponse![fund.isin] ?? 0) * 100).toFixed(2))
				});
			});

			OptimizerService.generateOrders(activePlan.id, orders)
				.then(response => setGeneratedOrders(response))
				.catch(() => {
					setOptimizerApiError(
						"Lo sentimos ha ocurrido un error inesperado al generar las ordenes, intentaremos resolverlo lo antes posible, disculpe las molestias."
					);
				})
				.finally(() => {
					setLoadingYearActions(false);
				});
		}
	};

	const updateSelection = () => {
		setSelectedItems(selection.getSelectedIndices());
	}

	useEffect(() => {
		selection.setAllSelected(true);
	}, [generatedOrders]);

	const createOrdersMovements = () => {
		setOptimizerOrdersApiError("");
		let orders: OrderType[] = [];
		generatedOrders
			?.filter((order, index) => selectedItems.includes(index) && order.skip !== true)
			?.forEach(order => {
				let orderType: any = {
					userIdentityId: user?.mainUserIdentity?.id,
					fnmPortfolioId: activePlan?.portfolio?.id,
					orderType: 2, //Internal
					status: 0,
					values: {
						FundName: order.sourceName,
						FundIsin: order.sourceIsin,
						Fund: order.sourceId,
						Amount: order.titles,
						AmountType: "titles",
						type: "titles",
						newInstrument: order.targetId,
						newInstrumentName: order.targetName
					},
					finametrixId: order.sourceId
				};
				orders.push(orderType);
			});

		OrderService.createInternalTransfersOrders(orders)
			.then(response => {
				setSignatures([response]);
			})
			.catch(() => {
				setOptimizerOrdersApiError(
					"Lo sentimos ha ocurrido un error inesperado al procesar las ordenes, intentaremos resolverlo lo antes posible, disculpe las molestias."
				);
				appInsights.trackEvent({
					name: "Optimizador IronIA Orders Failed",
					properties: { userId: user.id, orders: orders }
				});
			})
			.finally(() => {
				setLoadingInternalMovements(false);
			});
	};

	const tabItems = [
		{
			text: Types.Mark,
			key: Types.Mark,
			onClick: () => {
				if (!payedOptimizer) {
					setOptimizeMethod(Types.Mark);
					setOptimizerResponse([]);
					setIsOptimizerSimulated(false);
				}
			},
			className: optimizeMethod === Types.Mark ? 'is-selected' : ''
		},
		{
			text: Types.Litter,
			key: Types.Litter,
			onClick: () => {
				if (!payedOptimizer) {
					setOptimizeMethod(Types.Litter);
					setOptimizerResponse([]);
					setIsOptimizerSimulated(false);
				}
			},
			className: optimizeMethod === Types.Litter ? 'is-selected' : ''
		}
	];

	const markColumns: any = [
		{ key: 'source', name: 'Fondo Origen', fieldName: 'source', minWidth: 300, width: 300, isResizable: true },
		{ key: 'weight', name: 'Peso', fieldName: 'weight', width: 100 }
	];

	const litterColumns: any = [
		{ key: 'source', name: 'Fondo Origen', fieldName: 'source', minWidth: 300, width: 300, isResizable: true },
		{ key: 'weight', name: 'Peso', fieldName: 'weight', width: 100 },
		{ key: 'worse', name: 'Peor', fieldName: 'worse', width: 100 },
		{ key: 'equal', name: 'Igual', fieldName: 'equal', width: 100 },
		{ key: 'better', name: 'Mejor', fieldName: 'better', width: 100 }
	];

	const resultsColumns: any = [
		{ key: '1year', name: '1 año', fieldName: 'years1', width: 100 },
		{ key: '2years', name: '2 años', fieldName: 'years2', width: 100 },
		{ key: '3years', name: '3 años', fieldName: 'years3', width: 100 },
		{ key: '4years', name: '4 años', fieldName: 'years4', width: 100 },
		{ key: '5years', name: '5 años', fieldName: 'years5', width: 100 }
	];

	const columns: any = [
		{ key: 'source', name: 'Fondo Origen', fieldName: 'order.sourceName', minWidth: 300, width: 300, isResizable: true },
		{ key: 'target', name: 'Fondo Destino', fieldName: 'order.targetName', minWidth: 300, width: 300, isResizable: true },
		{ key: 'amount', name: 'Cantidad', fieldName: 'amount', minWidth: 150, width: 150, isResizable: true },
		{ key: 'titles', name: 'Títulos', fieldName: 'titles', minWidth: 150, width: 150, isResizable: true },
	];

	const renderItemColumn = (item: any, index: any, column: any) => {
		switch (column.key) {
			case "source":
				return <React.Fragment>
					<Link to={`/${item.isin}`}>
						<span>
							{item.name}
						</span>
					</Link>
					<br />
					<span>
						{item.isin}
					</span>
				</React.Fragment>

			case "weight":
				return <span className="text-right full-width block column-title">
					{item.weight}
				</span>

			case "worse":
				return <span className="column-title text-center full-width block" data-selection-disabled={true}>
					<input
						type="radio"
						name={item.isin}
						disabled={payedOptimizer}
						value={-0.2}
						checked={view !== undefined && view[item.isin] === -0.2}
						onChange={() => blackLittermanRadioButtonOnClickHandler(item.isin, -0.2)}
					></input>
				</span>

			case "equal":
				return <span className="column-title text-center full-width block" data-selection-disabled={true}>
					<input
						type="radio"
						name={item.isin}
						disabled={payedOptimizer}
						value={0}
						checked={view !== undefined && view[item.isin] === 0}
						onChange={() => blackLittermanRadioButtonOnClickHandler(item.isin, 0)}
					></input>
				</span>

			case "better":
				return <span className="column-title text-center full-width block" data-selection-disabled={true}>
					<input
						type="radio"
						name={item.isin}
						disabled={payedOptimizer}
						value={0.2}
						checked={view !== undefined && view[item.isin] === 0.2}
						onChange={() => blackLittermanRadioButtonOnClickHandler(item.isin, 0.2)}
					></input>
				</span>

			case "1year":
			case "2years":
			case "3years":
			case "4years":
			case "5years":
				var dataIndex = parseInt(column.key.charAt(0)) - 1;
				return <>
					{!isOptimizerSimulated && optimizerResponse && optimizerResponse.length > 0 && (
						<span className="text-right full-width block column-title">
							{optimizerResponse[dataIndex][item.isin] !== 0
								? longPercentFormatter.format(optimizerResponse[dataIndex][item.isin])
								: '-'}
						</span>
					)}
				</>
		}
	}

	const renderDataFooterItemColumn = (column: IColumn | undefined, sourceName: string, sourceDataAttribute: string) => {
		if (column === undefined) {
			return <></>
		}

		switch (column.key) {
			case "source":
				return <span className="text-right full-width block text-wrap">
					{sourceName}:
				</span>

			case "1year":
			case "2years":
			case "3years":
			case "4years":
			case "5years":
				var dataIndex = parseInt(column.key.charAt(0)) - 1;
				return <>
					{optimizerResponse && (
						<span className="text-right full-width block">
							{percentFormatter.format(optimizerResponse[dataIndex][sourceDataAttribute])}
						</span>
					)}
				</>

			default:
				return <></>
		}
	};

	const renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
		if (column === undefined) {
			return <></>
		}

		switch (column.key) {
			case "source":
				return <span className="text-right full-width block text-wrap">
					{isOptimizerSimulated
						? <>Utiliza Elements IA para ver como quedaria distribuido el peso de tus fondos al optimizar tu cartera</>
						: <>Seleccionar un año:</>
					}
				</span>

			case "1year":
			case "2years":
			case "3years":
			case "4years":
			case "5years":
				var value = parseInt(column.key.charAt(0)) - 1;
				return <>
					{optimizerResponse && optimizerResponse.length > 0 && !isOptimizerSimulated && (
						<span className="text-center full-width block">
							<input
								type="radio"
								disabled={loadingYearActions}
								name="selectedYear"
								value={value}
								defaultChecked={false}
								onChange={() => {
									setSelectedYear(value);
								}}
							></input>
						</span>
					)}
				</>

			default:
				return <></>
		}
	};

	const onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps | undefined) => {
		if (detailsFooterProps === undefined || !optimizerResponse || optimizerResponse.length === 0) {
			return <></>;
		}

		return (
			<>
				<DetailsRow
					{...detailsFooterProps}
					item={{}}
					itemIndex={funds.length}
					selectionMode={SelectionMode.none}
					onRenderItemColumn={(item, index, column) => renderDataFooterItemColumn(
						column,
						"Rentabilidad",
						"AnnualReturn"
					)}
				/>
				<DetailsRow
					{...detailsFooterProps}
					item={{}}
					itemIndex={funds.length}
					selectionMode={SelectionMode.none}
					onRenderItemColumn={(item, index, column) => renderDataFooterItemColumn(
						column,
						"Volatilidad",
						"AnnualVolatility"
					)}
				/>
				<DetailsRow
					{...detailsFooterProps}
					item={{}}
					itemIndex={funds.length}
					selectionMode={SelectionMode.none}
					onRenderItemColumn={renderDetailsFooterItemColumn}
				/>
			</>
		);
	};

	const renderOrderItemColumn = (item: any, index: any, column: any) => {
		switch (column.key) {
			case "source":
				return (
					<React.Fragment>
						<Link to={`/${item.sourceIsin}`}>
							<span className={"column-title " + item.type}>
								{item.sourceName}
							</span>
						</Link>
						<br />
						<span className={"column-title " + item.type}>
							{item.sourceIsin}
						</span>
					</React.Fragment>
				);
			case "target":
				return (
					<React.Fragment>
						<Link to={`/${item.targetIsin}`}>
							<span className={"column-title " + item.type}>
								{item.targetName}
							</span>
						</Link>
						<br />
						<span className={"column-title " + item.type}>
							{item.targetIsin}
						</span>
					</React.Fragment>
				);

			case "amount":
				return (
					<span className="text-right block full-width">
						{currencyFormatter.format(item.amount)}
					</span>
				);

			case "titles":
				return (
					<span className="text-right block full-width">
						{decimalMaximumLimitFormatter.format(item.titles)}
					</span>
				);
		}
	}

	return (
		<OptimizerBody>
			<title>{ResourcesOptimizer.QUE_ES_HEADER}</title>
			<meta name="descripción" content={ResourcesOptimizer.META_DESCRIPCION}></meta>
			<section id="header">
				<h2>{ResourcesOptimizer.QUE_ES_HEADER}</h2>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md9">
						<p>{ResourcesOptimizer.QUE_ES_P1.replace("[MODELO]", optimizeMethod)}</p>
						<div id="main-menu">
							<CommandBar
								items={tabItems}
								overflowItems={_overflowItems}
								overflowButtonProps={overflowProps}
								ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
							/>
						</div>
						{optimizeMethod === Types.Mark && (
							<>
								<p dangerouslySetInnerHTML={{ __html: ResourcesOptimizer.MARK_QUE_ES_P2 }}></p>
								<p>{ResourcesOptimizer.MARK_QUE_ES_P3}</p>
								<p>{ResourcesOptimizer.MARK_QUE_ES_P4}</p>
							</>
						)}
						{optimizeMethod === Types.Litter && (
							<p>{ResourcesOptimizer.LITTER_QUE_ES_P2}</p>
						)}
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md3 text-right mt20">
						{optimizeMethod === Types.Mark && (
							<video height="400" width="280" controls>
								<source src="https://ironiastorage.azureedge.net/video/Optimizer%C2%A0Markowitz%2001.mp4" type="video/mp4" />
							</video>
						)}
						{optimizeMethod === Types.Litter && (
							<video height="400" width="280" controls>
								<source src="https://ironiastorage.azureedge.net/video/Optimizer%20Black-Litterman%2001%C2%A0.mp4" type="video/mp4" />
							</video>
						)}
					</div>
				</div>
			</section>

			<section>
				<h2>{ResourcesOptimizer.INTRODUCCION}</h2>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md9">
						{optimizeMethod === Types.Mark && (
							<>
								<p>{ResourcesOptimizer.MARK_INTRODUCCION_P1}</p>
								<div className="ms-Grid-row cards">
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_1}</p>
									</div>
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_2}</p>
									</div>
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_3}</p>
									</div>
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_4}</p>
									</div>
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_5}</p>
									</div>
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_6}</p>
									</div>
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_7}</p>
									</div>
								</div>
								<p>{ResourcesOptimizer.MARK_INTRODUCCION_P2}</p>
								<div className="ms-Grid-row cards">
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_B_1}</p>
									</div>
									<div className="ms-Grid-col ms-lg6 ms-xl4 ms-xxxl3">
										<p>{ResourcesOptimizer.MARK_INTRODUCCION_BOX_B_2}</p>
									</div>
								</div>
								<p>{ResourcesOptimizer.MARK_INTRODUCCION_P3}</p>
							</>
						)}
						{optimizeMethod === Types.Litter && (
							<>
								<p>{ResourcesOptimizer.LITTER_INTRODUCCION_P1}</p>
								<p>{ResourcesOptimizer.LITTER_INTRODUCCION_P2}</p>
								<p>{ResourcesOptimizer.LITTER_INTRODUCCION_P3}</p>
								<p>{ResourcesOptimizer.LITTER_INTRODUCCION_P4}</p>
							</>
						)}
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md3 text-right mt20">
						{optimizeMethod === Types.Mark && (
							<video height="400" width="280" controls>
								<source src="https://ironiastorage.azureedge.net/video/Optimizer%20Markowitz%2002.mp4" type="video/mp4" />
							</video>
						)}
						{optimizeMethod === Types.Litter && (
							<video height="400" width="280" controls>
								<source src="https://ironiastorage.azureedge.net/video/Optimizer%20Black-Litterman%2002.mp4" type="video/mp4" />
							</video>
						)}
					</div>
				</div>
			</section>
			{optimizeMethod === Types.Mark && (
				<>
					<section>
						<h2>{ResourcesOptimizer.DETERMINACION_CONJUNTO}</h2>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md4 ms-xl3 mt10">
								<img src={chartImg} alt="chart" style={{ height: 'fit-content' }} />
							</div>
							<div className="ms-Grid-col ms-sm12 ms-md8 ms-xl9">
								<p>{ResourcesOptimizer.DETERMINACION_CONJUNTO_P1}</p>
								<p>{ResourcesOptimizer.DETERMINACION_CONJUNTO_P2}</p>
								<p>{ResourcesOptimizer.DETERMINACION_CONJUNTO_P3}</p>
								<p>{ResourcesOptimizer.DETERMINACION_CONJUNTO_P4}</p>
								<p>{ResourcesOptimizer.DETERMINACION_CONJUNTO_P5}</p>
							</div>
						</div>
					</section>
					<section>
						<h2>{ResourcesOptimizer.MEJOR_CARTERA}</h2>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md6 ms-xl3 ms-lg6 mt20">
								<img src={chartImg2} alt="chart" />
							</div>
							<div className="ms-Grid-col ms-sm12 ms-lg6 text-right mt20 ms-hiddenXlUp">
								<video height="400" width="280" controls>
									<source src="https://ironiastorage.azureedge.net/video/Optimizer%20Markowitz%2003.mp4" type="video/mp4" />
								</video>
							</div>
							<div className="ms-Grid-col ms-lg12 ms-xl6">
								<p>{ResourcesOptimizer.MEJOR_CARTERA_P1}</p>
								<p>{ResourcesOptimizer.MEJOR_CARTERA_P2}</p>
								<p>{ResourcesOptimizer.MEJOR_CARTERA_P3}</p>
							</div>
							<div className="ms-Grid-col ms-xl3 text-right mt20 ms-hiddenLgDown">
								<video height="400" width="280" controls>
									<source src="https://ironiastorage.azureedge.net/video/Optimizer%20Markowitz%2003.mp4" type="video/mp4" />
								</video>
							</div>
						</div>
						<div className="ms-Grid-row">
							<p>{ResourcesOptimizer.MEJOR_CARTERA_P4}</p>
							<p>{ResourcesOptimizer.MEJOR_CARTERA_P5}</p>
							<p>{ResourcesOptimizer.MEJOR_CARTERA_P6}</p>
							<p>{ResourcesOptimizer.MEJOR_CARTERA_P7}</p>
						</div>
					</section>
					<section>
						<h2>{ResourcesOptimizer.QUE_OBTENGO}</h2>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col">
								<p>{ResourcesOptimizer.QUE_OBTENGO_P1}</p>
								<p>{ResourcesOptimizer.QUE_OBTENGO_P2.replace("[Price]", (optimizeElementsIAPrice ?? 1000).toString())}</p>
							</div>
						</div>
					</section>
				</>
			)}
			{loadingPage === true && <Spinner size={SpinnerSize.large} />}
			{loadingPage !== true && (
				<>
					<section>
						<h2>Cartera Actual</h2>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-xl6">
								La implementación del modelo {optimizeMethod} que hemos utilizado toma los valores liquidativos de los fondos para los periodos
								de 1,2,3,4 y 5 años calculando la frontera eficiente para cada uno de ellos. La volatilidad y rentabilidad expresan los valores
								esperados en el siguiente periodo.
							</div>
							<div className="ms-Grid-col ms-sm12 ms-xl6">
								<div className="ms-Grid-row cards">
									<div className="ms-Grid-col ms-sm12 ms-md4">
										<p className="tiny">
											<b>Simular Optimizar Cartera</b>
											<DefaultButton
												className="button mini-primary-button"
												disabled={
													isLoadingElementsAmount ||
													simulateOptimzierLoading ||
													isOptimizerSimulated ||
													optimzierLoading ||
													funds.length === 0 ||
													!userElementsStatus.isSubscribed ||
													payedOptimizer ||
													optimizeElementsIAPrice === undefined
												}
												onClick={() => {
													setShowSignContract(false);
													simulateOptimizer();
												}}
											>
												{simulateOptimzierLoading && <Spinner size={SpinnerSize.xSmall} style={{ paddingRight: "5px" }} />}
												Simular
											</DefaultButton>
										</p>
									</div>
									<div className="ms-Grid-col ms-sm12 ms-md4">
										<p className="tiny">
											<b>Optimizar Cartera</b>
											<img alt="IA" src={ElementsIAIcon} />
											<b className="bottomLeft">{optimizeElementsIAPrice}</b>
											<DefaultButton
												className="button mini-primary-button"
												disabled={
													isLoadingElementsAmount ||
													simulateOptimzierLoading ||
													optimzierLoading ||
													funds.length === 0 ||
													showSignContract ||
													!userElementsStatus.isSubscribed ||
													payedOptimizer ||
													optimizeElementsIAPrice === undefined
												}
												onClick={() => {
													setShowSignContract(true);
												}}
											>
												{optimzierLoading && <Spinner size={SpinnerSize.xSmall} style={{ paddingRight: "5px" }} />}Generar
											</DefaultButton>
										</p>
									</div>
									<div className="ms-Grid-col ms-sm12 ms-md4">
										<p className="tiny">
											<b>Elements IA</b>
											<img alt="IA" src={ElementsIAIcon} />
											{isLoadingElementsAmount && <Spinner size={SpinnerSize.xSmall} />}
											{!isLoadingElementsAmount && <b className="bottomLeft">{elementsAmount?.elementIA ?? 0}</b>}
											<DefaultButton
												className="button mini-primary-button"
												disabled={isLoadingElementsAmount || optimzierLoading}
												onClick={() => {
													buttonIAClickEvent();
												}}
											>
												{userElementsStatus.isSubscribed && <>Comprar</>}
												{!userElementsStatus.isSubscribed && <>Suscríbete a Elements</>}
											</DefaultButton>
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					{payOptimizerError && (
						<div style={{ padding: "10px 0px 10px 0px" }}>
							<WarningContainer theme={themeContext}>
								<WarningText>
									Ha ocurrido un error al pagar el optimizador, asegurese de tener suficientes Elements IA para poder generar tu cartera
									optimizada.
								</WarningText>
							</WarningContainer>
						</div>
					)}
					{optimizerApiError !== "" && (
						<div style={{ padding: "10px 0px 10px 0px" }}>
							<WarningContainer theme={themeContext}>
								<WarningText>{optimizerApiError}</WarningText>
							</WarningContainer>
						</div>
					)}
					<section>
						<DetailsList
							items={funds}
							columns={[...(optimizeMethod === Types.Mark ? markColumns : litterColumns), ...(optimizerResponse && optimizerResponse.length > 0 ? resultsColumns : [])]}
							selectionMode={SelectionMode.none}
							onRenderItemColumn={renderItemColumn}
							onRenderDetailsFooter={onRenderDetailsFooter}
						/>
						{payedOptimizer && selectedYear > 0 && (
							<div className="ms-Grid-row">
								<div className="ms-Grid-col text-right full-width mt20">
									<DefaultButton
										id='generateOrders'
										className="button tiny-primary-button"
										disabled={loadingYearActions}
										onClick={() => {
											setLoadingYearActions(true);
											generateOrders();
										}}
									>
										{loadingYearActions && <Spinner size={SpinnerSize.xSmall} style={{ paddingRight: "5px" }} />}
										Generar órdenes
									</DefaultButton>
									<DefaultButton
										id='modelWallet'
										className="button tiny-primary-button"
										disabled={loadingYearActions}
										onClick={() => {
											setLoadingYearActions(true);
											generateModelPortfolio();
										}}
									>
										{loadingYearActions && <Spinner size={SpinnerSize.xSmall} style={{ paddingRight: "5px" }} />}
										Generar cartera modelo
									</DefaultButton>
								</div>
							</div>
						)}
					</section>
					{generatedOrders && generatedOrders.length > 0 && (
						<section>
							{generatedOrders.length > 0 && (
								<>
									<h2 style={{ paddingBottom: "20px" }}>Conjunto de operaciones de traspasos</h2>
									<DetailsList
										items={generatedOrders}
										columns={columns}
										selectionMode={SelectionMode.multiple}
										onRenderItemColumn={renderOrderItemColumn}
										selection={selection}
										selectionPreservedOnEmptyClick={true}
									/>
									{optimizerOrdersApiError !== "" && (
										<div style={{ padding: "10px 0px 10px 0px" }}>
											<WarningContainer theme={themeContext}>
												<WarningText>{optimizerOrdersApiError}</WarningText>
											</WarningContainer>
										</div>
									)}
									<div style={{ float: "inline-end", paddingTop: "10px", paddingBottom: "20px" }}>
										<DefaultButton
											className="button tiny-primary-button"
											disabled={loadingInternalMovements || signatures.length > 0 || selectedItems.length === 0}
											onClick={() => {
												setLoadingInternalMovements(true);
												createOrdersMovements();
											}}
										>
											<div style={{ display: "flex" }}>
												{loadingInternalMovements && <Spinner size={SpinnerSize.xSmall} style={{ paddingRight: "5px" }} />}
												{!loadingInternalMovements && selectedItems.length === 1 && <>Generar 1 traspaso interno</>}
												{!loadingInternalMovements && selectedItems.length !== 1 && <>Generar {selectedItems.length} traspasos internos</>}
											</div>
										</DefaultButton>
									</div>
								</>
							)}
							{signatures.length > 0 && (
								<OptimizerSignature
									defaultSignatures={signatures}
									isins={generatedOrders && generatedOrders
										.filter((order, index) => selectedItems.includes(index) && order.skip !== true)
										.map(order => {
											return { origin: order.sourceIsin, destiny: order.targetIsin };
										})
									}
									userIdentities={[user.mainUserIdentity]}
									onCancelSignature={(cancel: boolean) => {
										if (cancel === true) {
											setSignatures([]);
										}
									}}
								></OptimizerSignature>
							)}
						</section>
					)}
				</>
			)}
			<Modal
				styles={{
					main: {
						width: "400px !important",
						minWidth: "400px !important",
						height: "auto !important",
						minHeight: "auto !important",
						padding: "2em !important"
					}
				}}
				isOpen={showSignContract}
				onDismiss={() => {
					setShowSignContract(false);
				}}
				isBlocking={false}
			>
				<h3>¿Quieres utilizar {optimizeElementsIAPrice} Elements IA para optimizar tu cartera?</h3>
				<div style={{ display: "inline-flex", float: "right", position: "relative", bottom: "0" }}>
					<OptimizerButton
						style={{ marginRight: "10px" }}
						onClick={() => {
							setShowSignContract(false);
							buyOptimizer();
						}}
					>
						Aceptar
					</OptimizerButton>
					<OptimizerButton
						style={{ backgroundColor: "#cdc8ca" }}
						onClick={() => {
							setShowSignContract(false);
						}}
					>
						Cancelar
					</OptimizerButton>
				</div>
			</Modal>
			<Modal
				styles={{
					main: {
						width: "400px !important",
						minWidth: "400px !important",
						height: "auto !important",
						minHeight: "auto !important",
						padding: "2em !important"
					}
				}}
				isOpen={showModelPortfolioGenerated}
				onDismiss={() => {
					setShowModelPortfolioGenerated(false);
				}}
				isBlocking={false}
			>
				<h3>Se ha generado una nueva cartera modelo con nombre:</h3>
				<h3>{modelPortfolioName}</h3>
				<div style={{ display: "inline-flex", float: "right", position: "relative", bottom: "0" }}>
					<OptimizerButton
						style={{ marginRight: "10px" }}
						onClick={() => {
							setShowModelPortfolioGenerated(false);
						}}
					>
						Aceptar
					</OptimizerButton>
				</div>
			</Modal>
		</OptimizerBody>
	);
};

export default Optimizer;
