import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react';
import { useHistory } from 'react-router';

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = { ariaLabel: 'Más acciones' };

const SavingsPlansMenu = () => {
    const history = useHistory();
    const _items = [
        {
            text: 'Planes de ahorro',
            key: 'savingsPlans',
            onClick: () => history.push('/savingsPlans'),
            className: window.location.pathname === '/savingsPlans' ? 'is-selected' : ''
        },
        {
            text: 'Nuevo plan de ahorro',
            key: 'newSavingsPlan',
            onClick: () => history.push('/savingsPlans/new'),
            className: window.location.pathname === '/' || window.location.pathname === '/savingsPlans/new' ? 'is-selected' : ''
        }
    ];

    return (
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="main-menu">
                <CommandBar
                    // @ts-ignore
                    items={_items}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
                />
            </div>
        </div>
    );
};

export default SavingsPlansMenu;