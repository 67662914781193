import Cookies from "universal-cookie";
import { IProcessHours } from "../services/SignaturesService";
import { UserElementsStatus } from "../services/ElementsService";

class Auth
{
    constructor()
    {
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getToken = this.getToken.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    getUserProfile()
    {
        var rawProfile = localStorage.getItem("userprofile");
        if(rawProfile != null)
        {
            return JSON.parse(rawProfile);
        }
        return {};
    }
    setUserProfile(authResponse: any)
    {
        var userProfile = this.getUserProfile();

        var fullName: string[] = [];
        var initials: string = "";
        if(authResponse.mainUserIdentity !== undefined && authResponse.mainUserIdentity != null)
        {
            const fields = ['firstName', 'lastName', 'secondLastName'];

            for(var fieldIndex in fields)
            {
                var field = fields[fieldIndex];

                if(authResponse.mainUserIdentity[field] !== undefined && authResponse.mainUserIdentity[field] !== null)
                {
                    fullName.push(`${authResponse.mainUserIdentity[field]}`);
                    var fullNameWords = authResponse.mainUserIdentity[field].split(" ");
                    if(fullNameWords.length > 0)
                    {
                        for(var wordIndex in fullNameWords)
                        {
                            if(initials.length < 3 && fullNameWords[wordIndex].length > 0)
                            {
                                initials += fullNameWords[wordIndex][0].toUpperCase();
                            }
                        }
                    }
                    else
                    {
                        initials = "--";
                    }
                }
            }
        }
        else
        {
            if(fullName.length === 0)
            {
                fullName = [userProfile.email];
                initials = '@';
            }
        }

        authResponse.fullName = fullName.join(" ");
        authResponse.initials = initials;

        localStorage.setItem("userprofile", JSON.stringify({ ...userProfile, ...Object.fromEntries(Object.entries(authResponse).filter(([_, v]) => v != null)) }));
    }
    getProcessHours()
    {
        var rawProfile = localStorage.getItem("processHours");
        if(rawProfile != null)
        {
            return JSON.parse(rawProfile);
        }

        return {};
    }
    setProcessHours(response: any)
    {
        localStorage.removeItem("processHours");
        var processHours: IProcessHours[] = [];

        for(let i=0; i<response.length; i++)
        {
            processHours.push(response[i]);
        }

        localStorage.setItem("processHours", JSON.stringify({...processHours}));
    }
    getActivePlan()
    {
        var rawProfile = localStorage.getItem("userActivePlan");
        if(rawProfile != null)
        {
            return JSON.parse(rawProfile);
        }
        return { sync : false };
    }
    setActivePlan(authResponse: any)
    {
        var activePlan = this.getActivePlan();

        activePlan.id = authResponse.planId || authResponse.id;
        activePlan.name = authResponse.planName || authResponse.name;
        activePlan.statusName = authResponse.status || authResponse.statusName;
        activePlan.userId = authResponse.userId;
        activePlan.type = authResponse.type;
        activePlan.outdated = authResponse.outdated;
        activePlan.outdatedContract = authResponse.outdatedContract;
        activePlan.cancelled = authResponse.cancelled;
        activePlan.portfolio = authResponse.portfolio;
        activePlan.fnmContractId = authResponse.fnmContractId;
        activePlan.finametrixContract = authResponse.finametrixContract;
        activePlan.userSubscriptionId = authResponse.userSubscriptionId;
        activePlan.allUserIdentitiesHaveSuitabilityTest = authResponse.allUserIdentitiesHaveSuitabilityTest;
        activePlan.sync = true;

        localStorage.setItem("userActivePlan", JSON.stringify({ ...activePlan }));
    }
    getActiveSubscriptions()
    {
        var rawProfile = localStorage.getItem("activeSubscriptions");
        if(rawProfile != null)
        {
            return JSON.parse(rawProfile);
        }
        return {};
    }
    setActiveSubscriptions(options: any)
    {
        localStorage.removeItem("activeSubscriptions");
        var activeSubscriptions: any[] = [];

        for(let i = 0; i < options.length; i++)
        {
            activeSubscriptions.push(
            {
                key: options[i].id,
                id: options[i].id.toString(),
                text: options[i].text,
                selected: options[i].selected
            });
        }

        localStorage.setItem("activeSubscriptions", JSON.stringify({...activeSubscriptions}));
    }
    setSession(authResult: any)
    {
        localStorage.setItem("email", authResult.email);
        localStorage.setItem("oid", authResult.oid);
    }
    getToken()
    {
        if(localStorage.getItem('access_token') === null)
        {
            return "";
        }

        return localStorage.getItem('access_token')
    }
    getProfile()
    {
        let user;
        let apiProfile;

        if(localStorage.getItem("email") && localStorage.getItem("oid") !== 'undefined')
        {
            user = JSON.parse(localStorage.getItem("profile") as string);
        }
        else
        {
            user = {};
        }

        const profile = Object.assign(user, apiProfile);
        return profile || {};
    }
    getRoles()
    {
        const roles = localStorage.getItem("roles");

        if(roles)
        {
            return JSON.stringify(roles);
        }
        else
        {
            return null;
        }
    }
    logout()
    {
        // TODO: además de limpiar localstorage, hay que llamar a oauth para cerrar sesión allí también
        const cookies = new Cookies();
        cookies.remove("statusBarNotifications", {path: "/"});
        localStorage.clear();
    }
    isAuthenticated()
    {
        if(localStorage.getItem("email") && localStorage.getItem("oid") !== 'undefined')
        {
            return true;
        }
        else
        {
            return false
        }
    }
    isSubscribed()
    {
        var userProfile = this.getUserProfile()
        if(userProfile.status !== undefined && userProfile.status !== null)
        {
            let result: boolean = userProfile.status.toString().startsWith("1");
            return result;
        }
        else
        {
            return false;
        }
    }
    isActiveManagementPlan()
    {
        var activePlan = this.getActivePlan();
        if(activePlan.type !== undefined && activePlan.type !== null)
        {
            let result = [0, 1].includes(activePlan.type);
            return result;
        }
        else
        {
            return false;
        }
    }
    isAlchemyPlan()
    {
        var activePlan = this.getActivePlan();
        if(activePlan.type !== undefined && activePlan.type !== null)
        {
            return activePlan.type === 1;
        }
        else
        {
            return false;
        }
    }
    isPromotioned()
    {
        var userProfile = this.getUserProfile()

        if(userProfile.status !== undefined && userProfile.status !== null)
        {
            return (userProfile.status.toString().startsWith("2") || userProfile.status.toString().startsWith("3"));
        }
        else
        {
            return false;
        }
    }
    isLastPlanOutdated()
    {
        var plan = this.getActivePlan();

        if(plan.outdated === false)
        {
            return false;
        }
        else
        {
            return true;
        }
    }
    isActivePlanCancelled()
    {
        var plan = this.getActivePlan();

        if(plan.cancelled === false)
        {
            return false;
        }
        else
        {
            return true;
        }
    }
    bubbleVisible()
    {
        var userProfile = this.getUserProfile();

        if(userProfile.status !== undefined && userProfile.status !== null)
        {
            return (userProfile.status.toString().startsWith("0") || (userProfile.status.toString().startsWith("2") && userProfile.mainUserIdentity === null))
        }
        else
        {
            return false;
        }
    }
    setQtyPage(number: number)
    {
        localStorage.setItem("qtyPage", String(number));
    }
    getQtyPage()
    {
        if(localStorage.getItem("qtyPage") !== null)
        {
            return localStorage.getItem("qtyPage");
        }
        else
        {
            return 10;
        }
    }
    getExperienceSubscription()
    {
        if(localStorage.getItem("subscriptionPlan") != null)
        {
            return localStorage.getItem("subscriptionPlan");
        }
        else
        {
            return "";
        }
    }
    setExperienceSubscription(value: any)
    {
        localStorage.setItem("subscriptionPlan", String(value));
    }
    getRepaymentSubscription()
    {
        if(localStorage.getItem("repaymentSubscription") != null)
        {
            return localStorage.getItem("repaymentSubscription");
        }
        else
        {
            return "";
        }
    }
    setRepaymentSubscription(value: any)
    {
        localStorage.setItem("repaymentSubscription", String(value));
    }

    getUserElementsStatus(): UserElementsStatus
    {
        var rawUserElementsStatus = localStorage.getItem("userElementsStatus");
        if(rawUserElementsStatus !== null)
        {
            return JSON.parse(rawUserElementsStatus);
        }

        return{
            isSubscribed: false,
            isOutdatedContract: false,
            sync: false
        };
    }

    setUserElementsStatus(elementsStatus: UserElementsStatus)
    {
        localStorage.setItem("userElementsStatus", JSON.stringify(elementsStatus));
    }
}

const authClient = new Auth();

export default authClient;