import styled from "styled-components";
import Auth from "../../Auth/Auth";
import { SubscriptionType } from "../../services/SubscriptionService";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Link } from "react-router-dom";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../services/TelemetryService';
import { useContext } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";

const limitText = "El límite marca el volumen total de la cartera. Si se supera el límite no se pueden realizar más compras de fondos o traspasos.";

export const ItemDiv = styled.div`
    background-color: ${(props) => props.theme.palette.greyMorePlans};
    padding-right: 0;
    border-radius: 12px;
    border: 3px solid transparent;
    text-overflow: ellipsis;
    margin-top: 3em;
    h2
    {
        text-align: center;
        display: block !important;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 1em;
        font-size: 25px !important;
        color: ${(props) => props.theme.palette.black};
    }
    p
    {
        margin: 0;
        overflow-y: hidden;
        max-height: 10em;
        padding: 1.25em 0.75em;
        font-size: 23px;
        text-align: justify;
        &:last-child
        {
            text-align: center;
            margin-top: 1.5em;
        }
    }
    a
    {
        margin: 0 auto;
    }
    &.spaced
    {
        margin-top: 0;
    }
    &.selected
    {
        background-color: ${(props) => props.theme.palette.greyMorePlansSelected};
        border: 3px solid #CC214F;
        margin-top: 0;
        h2
        {
            font-size: 26px !important;
        }
        p
        {
            padding: 1.75em 1em;
            font-size: x-large;
            &:last-child
            {
                padding-bottom: 1em;
            }
        }
    }
`;

interface MoreResuscriptionPlansItemProps
{
    subscription: SubscriptionType,
    usersSubscriptionId: number,
    limitAmount: number
    className: string

}

export const MoreResuscriptionPlansItem = (props: MoreResuscriptionPlansItemProps) =>
{
    const appInsights = ai.reactPlugin;
    const theme = useContext(ThemeContext);

    function getPrice()
    {
        var result: string = String(props.subscription.price);
        result = result.replace(".", ",");
        return result;
    }
    function getFrequency()
    {
        var result: string = String(props.subscription.period);
        if(result === "month")
        {
            return "mes";
        }
        else
        {
            return "año";
        }
    }
    function getLimit()
    {
        if(props.subscription.limit === null)
        {
            return "Sin Límite";
        }
        else
        {
            return String(props.subscription.limit)+"€";
        }
    }
    function getName()
    {
        if(props.subscription.name === undefined || props.subscription.name === null)
        {
            return "";
        }
        else
        {
            return String(props.subscription.name);
        }
    }
    function getDescription()
    {
        if(props.subscription.description === undefined || props.subscription.description === null)
        {
            return "";
        }
        else
        {
            return String(props.subscription.description);
        }
    }
    function setSubValue()
    {
        Auth.setRepaymentSubscription(props.subscription.id);
    }
    const trackClicks = useTrackEvent(appInsights, props.subscription.name, props.subscription.name);
    const performTracking = () =>
    {
        trackClicks(props.subscription.name);
    };

    return (
        <ItemDiv theme={theme} className={`ms-Grid-col ms-sm${props.className.includes('spaced') ? 9 : 12} ${props.className}`}>
            <h2>{getName()}</h2>
            <h2>{getPrice()}€ / {getFrequency()}</h2>
            <p>{getDescription()}</p>
            <h2>Límite: {getLimit()}</h2>
            <p>{limitText}</p>
            <p>
                <Link to={{
                    pathname: "/Repayment", state:
                    {
                        subscriptionId: props.subscription.id,
                        subscriptionType: props.subscription.type,
                        userSubscriptionId: props.usersSubscriptionId,
                        limitAmount: props.limitAmount
                    }
                }}>
                    <DefaultButton text="Seleccionar" className="button tiny-primary-button" onClick={() =>
                    {
                        setSubValue();
                        performTracking();
                    }} />
                </Link>
            </p>
        </ItemDiv>
    );
}