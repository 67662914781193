import React, { useContext } from "react";
import DocumentTitle from "react-document-title";
import * as Element from "./PeriodicSavingStyles";
import './PeriodicSavingStyle.sass';
import { Separator, Modal, IDropdownStyles, IDropdownOption } from "@fluentui/react";
import { ActiveSubscriptionAndSavingPlans } from "../../services/SavingsPlanService";
import Auth from "../../Auth/Auth";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { RouteComponentProps, useHistory } from "react-router";
import { ChevronDownIcon, ChevronUpIcon } from "@fluentui/react-icons-mdl2";
import ReactPlayer from "react-player";
import { useBoolean } from "@fluentui/react-hooks";
import myMoneyGym from '../../images/MyMoneyGym04.png';
import periodicPicture from '../../images/AhorroPeriodico.png';
import SavingsPlanService from "../../services/SavingsPlanService";
import UserService from "../../services/UserService";
import { ITableSPlansActive } from "../../components/SavePlans/TableActiveSavingPlans";
import portfolioModelWallets, { PortfolioModelAssociated } from "../../services/PortfolioModelWalletService";
import { useDispatch } from "react-redux";
import { setActiveSavingsPlan } from '../../store/auth/actions'

interface RouteParams {};
interface Props extends RouteComponentProps<RouteParams> {};

export const PeriodicSaving: React.FunctionComponent<Props> = () =>
{
    const userId = Auth.getUserProfile().id;
    const savingPlan = Auth.getActivePlan();
    const history = useHistory();
    const theme = useContext(ThemeContext);
    const localTheme = window.localStorage.getItem("theme") || "light";
    const firstLoad = React.useRef<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isPeriodicSavingExpanded, setIsPeriodicSavingExpanded] = React.useState<boolean>(false);
    const [isPortfolioModelExpanded, setIsPortfolioModelExpanded] = React.useState<boolean>(false);
    const [isPeriodicContributionsExpanded, setIsPeriodicContributionsExpanded] = React.useState<boolean>(false);
    const [data, setData] = React.useState<ITableSPlansActive[]>();
    const [isSavingPlansExpanded, setIsSavingPlansExpanded] = React.useState<boolean>(false);
    const [isExperiencePlan, setIsExperiencePlan] = React.useState<boolean>(savingPlan.type === 3 || savingPlan.type === 4);
    const [isVideoOpen, {setTrue: showVideo, setFalse: hideVideo}] = useBoolean(false);
    const [isPortfolioModelVideoOpen, {setTrue: showPortfolioVideo, setFalse: hidePortfolioVideo}] = useBoolean(false);
    const [options, setOptions] = React.useState<IDropdownOption[]>();
    const [optionSelected, setOptionSelected] = React.useState<string>("-1");
    const [windowSize, setWindowSize] = React.useState<any>(window.innerWidth);
    const [finametrixContract, setFinametrixContract] = React.useState<string>(savingPlan.finametrixContract);
    const [linkPeriodicContribution, setLinkPeriodicContribution] = React.useState<string>("");
    const [portfolioModelId, setPortfolioModelId] = React.useState<number>(-1);
    const [portfolioModelName, setPortfolioModelName] = React.useState<string>("");
    const dispatch = useDispatch();

    const dropdownLightStyles: Partial<IDropdownStyles> =
    {
        caretDown:
        {
            color: "#CC214F",
            fontSize: "14px !important",
            fontWeight: 700
        },
        dropdownItems:
        {
            color: "#CC214F",
            borderColor: "transparent !important",
            backgroundColor: "transparent !important",
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "5px",
        },
        title:
        {
            color: "#CC214F !important",
            borderColor: "transparent !important",
            fontWeight: 500,
            fontSize: "17px",
            marginTop: "5px",
            lineHeight: "40px !important"
        },
        dropdownItemSelected:
        {
            backgroundColor: "#F3F7F9 !important",
            color: "#CC214F !important"
        },
        dropdownOptionText:
        {
            selectors:
            {
                ":hover":
                {
                    color: "#000000 !important"
                }
            },
            color: "#CC214F !important"
        }
    };
    const dropdownDarkStyles: Partial<IDropdownStyles> =
    {
        caretDown:
        {
            color: "#CC214F",
            fontSize: "14px !important",
            fontWeight: 700
        },
        dropdownItems:
        {
            color: "#CC214F",
            borderColor: "transparent !important",
            backgroundColor: "transparent !important",
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "5px"
        },
        title:
        {
            color: "#CC214F !important",
            borderColor: "transparent !important",
            fontWeight: 500,
            fontSize: "17px",
            marginTop: "5px",
            lineHeight: "40px !important"
        },
        dropdownItemSelected:
        {
            backgroundColor: "#F3F7F9 !important",
            color: "#CC214F !important"
        },
        dropdownOptionText:
        {
            selectors:
            {
                ":hover":
                {
                    color: "#000000 !important"
                }
            },
            color: "#CC214F !important"
        }
    };

    const notifyChangedActivePlan = async (activePlan: any) => {
        await dispatch(setActiveSavingsPlan({ ...activePlan, sync: true }));
    }

    React.useEffect(() =>
    {
        if(firstLoad.current === true)
        {
            firstLoad.current = false;
            var optionsActive = Auth.getActiveSubscriptions();
            var options: any = Object.values(optionsActive);
    
            if(options === undefined)
            {
                SavingsPlanService.getActiveSubscriptionAndSavingPlan(userId).then((response: ActiveSubscriptionAndSavingPlans[]) =>
                {
                    var aux: IDropdownOption[] = [];
                    for(var i=0; i<response.length; i++)
                    {
                        if(savingPlan.id.toString() === options[i].id)
                        {
                            aux.push(
                            {
                                key: options[i].id,
                                text: options[i].text,
                                selected: true,
                                id: options[i].id.toString()
                            });
                        }
                        else
                        {
                            aux.push(
                            {
                                key: options[i].id,
                                text: options[i].text,
                                selected: false,
                                id: options[i].id.toString()
                            });
                        }
                    }
                    if(aux.length > 0)
                    {
                        var idx: number = aux.findIndex(p => p.selected === true);
                        var elementSelected: string = aux[idx].key as string;
                        setOptionSelected(elementSelected);
                    }
                    else
                    {
                        setOptionSelected("-1");
                    }
                    setOptions(aux);
                });
            }
            else
            {
                var aux: IDropdownOption[] = [];
                for(var i=0; i<options.length; i++)
                {
                    if(savingPlan.id.toString() === options[i].id)
                    {
                        aux.push(
                        {
                            key: options[i].id,
                            text: options[i].text,
                            selected: true,
                            id: options[i].id.toString()
                        });
                    }
                    else
                    {
                        aux.push(
                        {
                            key: options[i].id,
                            text: options[i].text,
                            selected: false,
                            id: options[i].id.toString()
                        });
                    }
                }
                if(aux.length > 0)
                {
                    var idx: number = aux.findIndex(p => p.selected === true);
                    var elementSelected: string = aux[idx].key as string;
                    setOptionSelected(elementSelected);
                }
                else
                {
                    setOptionSelected("-1");
                }
                setOptions(aux);
            }
    
            SavingsPlanService.getActiveSavingPlans(userId).then((response: ITableSPlansActive[]) =>
            {
                setData(response);
            });
    
            setIsLoading(false);
        }
    }, [userId, savingPlan]);

    React.useEffect(() =>
    {
        if (savingPlan.id !== undefined) {
            portfolioModelWallets.savingPlanHasPortfolioModel(savingPlan.id).then((response: PortfolioModelAssociated) => {
                if (response.portfolioModelCount <= 0) {
                    setLinkPeriodicContribution("");
                }
                else if (response.portfolioModelCount === 1) {
                    setPortfolioModelId(response.portfolioModelData[0].portfolioModelId);
                    setPortfolioModelName(response.portfolioModelData[0].portfolioModelName);
                    setLinkPeriodicContribution("/ModelPortfolio/PeriodicContributions/" + savingPlan.id);
                }
                else {
                    setLinkPeriodicContribution("/savingsPlans");
                }
            });
        }
    }, [savingPlan.id]);

    const updateActivePlan = (event: React.FormEvent<HTMLDivElement>, item: any) =>
    {
        UserService.selectActivePlan(userId, parseInt(item.id)).then((response: any) =>
        {
            if(response === true)
            {
                var auxData: ITableSPlansActive[] = [];
                
                if(data !== undefined)
                {
                    for(var i=0; i < data?.length; i++)
                    {
                        auxData.push(data[i]);
                        if(auxData[i].id === parseInt(item.id))
                        {
                            auxData[i].selected = true;
                            var dataItem: any =
                            {
                                planId: item.id,
                                planName: auxData[i].planName,
                                userId: userId,
                                status: 4,
                                outdated: auxData[i].outdated,
                                outdatedContract: auxData[i].outdatedContract,
                                cancelled: auxData[i].cancelled,
                                type: auxData[i].type,
                                portfolio: auxData[i].portfolio,
                                finametrixContract: auxData[i].finametrixContract,
                                managedPortfolioFinametrixId: auxData[i].managedPortfolioFinametrixId,
                                usersSubscriptionId: auxData[i].userSubscriptionId
                            };
                            
                            Auth.setActivePlan(dataItem);
                            setFinametrixContract(dataItem.finametrixContract);
                            setIsExperiencePlan(dataItem.type === 3 || dataItem.type === 4);
                            notifyChangedActivePlan(dataItem);
                        }
                        else
                        {
                            auxData[i].selected = false;
                        }
                    }
                    
                    setData(auxData);
                }
            }
        });
    }
    const displayArrow = (expanded: boolean): JSX.Element =>
    {
        switch(expanded)
        {
            case true:
            {
                return <ChevronUpIcon className="tableArrow" />;
            }
            case false:
            {
                return <ChevronDownIcon className="tableArrow" />;
            }
        }
    }
    const handleWindowResize = React.useCallback(event =>
    {
        setWindowSize(window.innerWidth);

    }, []);
    React.useEffect(() =>
    {
        window.addEventListener('resize', handleWindowResize);

        return () =>
        {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    const handleEndedVideo = () =>
    {
        hideVideo();
    }
    const handlePortfolioEndVideo = () =>
    {
        hidePortfolioVideo();
    }
    return(
        <React.Fragment>
            <DocumentTitle title="IronIA - Ahorro Periódico">
                <React.Fragment>
                    {isLoading === true &&
                    (
                        <React.Fragment>
                            <figure className='full-loading-image'>
                                <div>
                                    <svg width="50%" viewBox="0 0 182 72" fill="none">
                                        <path d="M 36.375,27.1719 28.0521,40.9062 H 47.9863 L 45.7968,37.5225" stroke="#cc214f" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" id="path10" />
                                        <path d="m 43.1375,33.4593 -0.633,-0.9782 -0.8494,-1.278" stroke="#cc214f" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" id="path12" />
                                        <path d="m 40.7812,24.875 c 0,1.7086 -1.3851,3.0938 -3.0937,3.0938 -1.7086,0 -3.0937,-1.3852 -3.0937,-3.0938 0,-1.7086 1.3851,-3.0938 3.0937,-3.0938 1.7086,0 3.0937,1.3852 3.0937,3.0938 z m -4.0031,0 c 0,0.5023 0.4071,0.9094 0.9094,0.9094 0.5023,0 0.9094,-0.4071 0.9094,-0.9094 0,-0.5023 -0.4071,-0.9094 -0.9094,-0.9094 -0.5023,0 -0.9094,0.4071 -0.9094,0.9094 z" fill="#2a4143" id="path14" />
                                        <path d="M 61.2153,41.7401 V 22.2338 h 4.7805 v 19.5063 z" fill="#2a4143" id="path2" />
                                        <path d="m 82.3294,33.4259 3.3435,8.3031 H 80.4636 L 77.5661,34.3174 H 74.0205 V 41.729 H 69.2837 V 22.2513 h 9.8985 c 3.3155,0 6.0462,2.7028 6.0462,6.0184 -0.0027,1.0354 -0.2706,2.053 -0.7781,2.9556 -0.5074,0.9026 -1.2376,1.6602 -2.1209,2.2006 z M 80.4622,28.2711 C 80.4583,27.9324 80.322,27.6087 80.0826,27.3693 79.8431,27.1298 79.5194,26.9935 79.1807,26.9896 h -5.1602 v 2.5914 h 5.1617 c 0.6966,0 1.2814,-0.5853 1.2814,-1.3099 z" fill="#2a4143" id="path16" />
                                        <path d="m 106.626,31.9492 c 0.002,1.3103 -0.255,2.608 -0.756,3.8188 -0.501,1.2109 -1.235,2.311 -2.162,3.2376 -0.926,0.9265 -2.027,1.6612 -3.237,2.1621 -1.2112,0.5008 -2.5089,0.7579 -3.8192,0.7566 -5.4889,0 -9.975,-4.4582 -9.975,-9.9751 0,-5.4889 4.4837,-9.9751 9.975,-9.9751 5.5172,0 9.9742,4.4862 9.9742,9.9751 z m -4.736,0 c 0.001,-0.6881 -0.134,-1.3696 -0.397,-2.0055 -0.263,-0.6359 -0.649,-1.2136 -1.135,-1.7002 -0.487,-0.4866 -1.0648,-0.8724 -1.7007,-1.1354 -0.6359,-0.2629 -1.3174,-0.3979 -2.0055,-0.3971 -2.87,0 -5.2382,2.3407 -5.2382,5.2382 0,2.8975 2.3682,5.2382 5.2382,5.2382 0.6881,8e-4 1.3696,-0.1342 2.0055,-0.3972 0.6359,-0.2629 1.2137,-0.6488 1.7007,-1.1353 0.486,-0.4866 0.872,-1.0643 1.135,-1.7002 0.263,-0.6359 0.398,-1.3174 0.397,-2.0055 z" fill="#2a4143" id="path18" />
                                        <path d="M 108.96,41.7401 V 22.2338 h 4.093 l 8.407,11.9236 V 22.2338 h 4.588 v 19.5063 h -4.093 l -8.407,-11.9236 v 11.9236 z" fill="#2a4143" id="path4" />
                                        <path d="M 128.6,41.7402 V 22.2339 h 4.781 v 19.5063 z" fill="#cc214f" id="path6" />
                                        <path d="m 135.385,41.7402 7.72,-19.5063 h 5.083 l 7.72,19.5063 h -4.946 l -1.373,-3.7914 h -8.05 l -1.374,3.7914 z m 7.61,-7.8026 h 5.138 L 145.55,26.822 Z" fill="#cc214f" id="path8" />
                                        <text>
                                            <tspan id="tspan9286" x="60.555046" y="53.544037">TU PERSONAL STORE FINANCIERO</tspan>
                                        </text>
                                    </svg>
                                </div>
                            </figure>
                            <Element.LoadingContainer>
                                <Element.LoadingTitle>Cargando Mapa de Ahorro Periódico...</Element.LoadingTitle>
                            </Element.LoadingContainer>
                        </React.Fragment>
                    )}
                    {isLoading === false &&
                    (
                        <React.Fragment>
                            <Element.PageTitle>
                                Mapa de Ahorro Periódico {savingPlan.name !== undefined && " - "+savingPlan.name}
                            </Element.PageTitle>
                            <Separator className="separator mt0 mb0" />        
                            <Element.DivColRectanglePeriodicSaving className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl3 ms-xxxl3">
                                {windowSize < 1024 &&
                                (
                                    <React.Fragment>
                                        {isPeriodicSavingExpanded === true &&
                                        (
                                            <Element.FirstRectangleSmall onClick={() =>
                                            {
                                                setIsPeriodicSavingExpanded(!isPeriodicSavingExpanded);
                                            }}>
                                                <Element.FirstTextRectangle>
                                                    {displayArrow(isPeriodicSavingExpanded)}
                                                    Ahorro Periódico
                                                </Element.FirstTextRectangle>
                                            </Element.FirstRectangleSmall>
                                        )}
                                        {!isPeriodicSavingExpanded &&
                                        (
                                            <Element.FirstRectangle onClick={() =>
                                            {
                                                setIsPeriodicSavingExpanded(!isPeriodicSavingExpanded);
                                            }}>
                                                <Element.FirstTextRectangle>
                                                    {displayArrow(isPeriodicSavingExpanded)}
                                                    Ahorro Periódico
                                                </Element.FirstTextRectangle>
                                            </Element.FirstRectangle>
                                        )}
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.FirstRectangle>
                                            <Element.FirstTextRectangle>
                                                Ahorro Periódico
                                            </Element.FirstTextRectangle>
                                        </Element.FirstRectangle>
                                    </React.Fragment>
                                )}
                                {windowSize < 1024 && isPeriodicSavingExpanded === true &&
                                (
                                    <React.Fragment>
                                        <Element.DivContainerPeriodicSaving className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 pr0">
                                            <Element.RowPanel className="ms-Grid-row" theme={theme}>
                                                <Element.SmallPanelContainer>
                                                    <Element.DivRowSmallPanel className="ms-Grid-row">
                                                        <Element.DivColFirstPanels className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.Panel theme={theme}>
                                                                <Element.DivRow11Title className="ms-Grid-row">
                                                                    <Element.DivColFirstPanelTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.SmallTitle11>Ahorro Periódico</Element.SmallTitle11>
                                                                    </Element.DivColFirstPanelTitle>
                                                                </Element.DivRow11Title>
                                                                <Element.DivRow11Text className="ms-Grid-row">
                                                                    <Element.DivColFirstPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.TextInside11>
                                                                            El ahorro periódico permite añadir una cantidad de dinero a un conjunto de fondos determinados en un periodo de tiempo.
                                                                        </Element.TextInside11>
                                                                    </Element.DivColFirstPanelText>
                                                                </Element.DivRow11Text>
                                                            </Element.Panel>
                                                        </Element.DivColFirstPanels>
                                                    </Element.DivRowSmallPanel>
                                                </Element.SmallPanelContainer>
                                                <Element.SmallPanelContainer>
                                                    <Element.DivRowTogglePanel className="ms-Grid-row">
                                                        <Element.DivContainerCol11 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.ConfigurationPanel theme={theme}>
                                                                <Element.DivRowToggleTitle className="ms-Grid-row">
                                                                    <Element.DivColFirstPanelBadge className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                        <Element.SmallTitle11>Plan de Ahorro - Cartera</Element.SmallTitle11>
                                                                    </Element.DivColFirstPanelBadge>
                                                                    <Element.DivCol11Badge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                        <Element.MiniBadge11 theme={theme}>1.1</Element.MiniBadge11>
                                                                    </Element.DivCol11Badge>
                                                                </Element.DivRowToggleTitle>
                                                                <Element.DivRow11Text>
                                                                    <Element.DivCol11Text>
                                                                        <Element.TextInside11>
                                                                            Selecciona el Plan de Ahorro sobre el que quieras realizar tu ahorro periódico.
                                                                        </Element.TextInside11>
                                                                    </Element.DivCol11Text>
                                                                </Element.DivRow11Text>
                                                                <Element.DivRowPortfolioSelector className="ms-Grid-row">
                                                                    {localTheme === "light" &&
                                                                    (
                                                                        <Element.PortfolioSelector
                                                                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12"
                                                                            options={options as IDropdownOption[]}
                                                                            defaultSelectedKey={optionSelected}
                                                                            onChange={(event, item) => updateActivePlan(event, item)}
                                                                            styles={dropdownLightStyles}
                                                                        />
                                                                    )}
                                                                    {localTheme === "dark" &&
                                                                    (
                                                                        <Element.PortfolioSelector
                                                                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12"
                                                                            options={options as IDropdownOption[]}
                                                                            defaultSelectedKey={optionSelected}
                                                                            onChange={(event, item) => updateActivePlan(event, item)}
                                                                            styles={dropdownDarkStyles}
                                                                        />
                                                                    )}
                                                                </Element.DivRowPortfolioSelector>
                                                            </Element.ConfigurationPanel>
                                                        </Element.DivContainerCol11>
                                                    </Element.DivRowTogglePanel>
                                                </Element.SmallPanelContainer>
                                                <Element.LastSmallPanelContainer>
                                                    <Element.DivRowSmallPanel className="ms-Grid-row">
                                                        <Element.DivColFirstPanels className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.Panel theme={theme}>
                                                                <Element.DivRowVideo1Title className="ms-Grid-row">
                                                                    <Element.DivColFirstVideoTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.SmallTitleVideo1>Ahorro Periódico</Element.SmallTitleVideo1>
                                                                    </Element.DivColFirstVideoTitle>
                                                                </Element.DivRowVideo1Title>
                                                                <Element.DivRowVideo1Text className="ms-Grid-row">
                                                                    <Element.DivColFirstVideoText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.TextInsideVideo1>
                                                                            <Element.LinkToYoutube href="https://www.youtube.com/channel/UChJiXVzR0iwllShhaJTnTaw" target="_blank" rel="noopener noreferrer" theme={theme}>
                                                                                ¿Quieres saber más sobre el Ahorro Periódico? En MyMoneyGym 04 te lo explicamos.
                                                                            </Element.LinkToYoutube>
                                                                        </Element.TextInsideVideo1>
                                                                    </Element.DivColFirstVideoText>
                                                                </Element.DivRowVideo1Text>
                                                                <Element.DivRowImageVideo1>
                                                                    <Element.ImageVideo1 alt={"MyMoneyGym04"} src={myMoneyGym} onClick={showVideo} />
                                                                    <Modal isOpen={isVideoOpen} onDismiss={hideVideo} isBlocking={false} containerClassName="video-container">
                                                                        <ReactPlayer playing={true} controls={true}
                                                                            url={`https://www.youtube.com/watch?v=psCuUPhEapY&origin=${window.location.href}`}
                                                                            // @ts-ignore
                                                                            onEnded={handleEndedVideo}
                                                                        />
                                                                    </Modal>
                                                                </Element.DivRowImageVideo1>
                                                            </Element.Panel>
                                                        </Element.DivColFirstPanels>
                                                    </Element.DivRowSmallPanel>
                                                </Element.LastSmallPanelContainer>
                                            </Element.RowPanel>
                                        </Element.DivContainerPeriodicSaving>
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.DivContainerPeriodicSaving className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 pr0">
                                            <Element.SecondRowPanel className="ms-Grid-row" theme={theme}>
                                                <Element.SmallPanelContainer>
                                                    <Element.DivRowSmallPanel className="ms-Grid-row">
                                                        <Element.DivColFirstPanels className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.Panel theme={theme}>
                                                                <Element.DivRow11Title className="ms-Grid-row">
                                                                    <Element.DivColFirstPanelTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.SmallTitle11>Ahorro Periódico</Element.SmallTitle11>
                                                                    </Element.DivColFirstPanelTitle>
                                                                </Element.DivRow11Title>
                                                                <Element.DivRow11Text className="ms-Grid-row">
                                                                    <Element.DivColFirstPanelText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.TextInside11>
                                                                            El ahorro periódico permite añadir una cantidad de dinero a un conjunto de fondos determinados en un periodo de tiempo.
                                                                        </Element.TextInside11>
                                                                    </Element.DivColFirstPanelText>
                                                                </Element.DivRow11Text>
                                                            </Element.Panel>
                                                        </Element.DivColFirstPanels>
                                                    </Element.DivRowSmallPanel>
                                                </Element.SmallPanelContainer>
                                                <Element.SmallPanelContainer>
                                                    <Element.DivRowTogglePanel className="ms-Grid-row">
                                                        <Element.DivContainerCol11 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.ConfigurationPanel theme={theme}>
                                                                <Element.DivRowToggleTitle className="ms-Grid-row">
                                                                    <Element.DivColFirstPanelBadge className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                        <Element.SmallTitle11>Plan de Ahorro - Cartera</Element.SmallTitle11>
                                                                    </Element.DivColFirstPanelBadge>
                                                                    <Element.DivCol11Badge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                        <Element.MiniBadge11 theme={theme}>1.1</Element.MiniBadge11>
                                                                    </Element.DivCol11Badge>
                                                                </Element.DivRowToggleTitle>
                                                                <Element.DivRow11Text>
                                                                    <Element.DivCol11Text>
                                                                        <Element.TextInside11>
                                                                            Selecciona el Plan de Ahorro sobre el que quieras realizar tu ahorro periódico.
                                                                        </Element.TextInside11>
                                                                    </Element.DivCol11Text>
                                                                </Element.DivRow11Text>
                                                                <Element.DivRowPortfolioSelector className="ms-Grid-row">
                                                                    {localTheme === "light" &&
                                                                    (
                                                                        <Element.PortfolioSelector
                                                                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12"
                                                                            options={options as IDropdownOption[]}
                                                                            defaultSelectedKey={optionSelected}
                                                                            onChange={(event, item) => updateActivePlan(event, item)}
                                                                            styles={dropdownLightStyles}
                                                                        />
                                                                    )}
                                                                    {localTheme === "dark" &&
                                                                    (
                                                                        <Element.PortfolioSelector
                                                                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12"
                                                                            options={options as IDropdownOption[]}
                                                                            defaultSelectedKey={optionSelected}
                                                                            onChange={(event, item) => updateActivePlan(event, item)}
                                                                            styles={dropdownDarkStyles}
                                                                        />
                                                                    )}
                                                                </Element.DivRowPortfolioSelector>
                                                            </Element.ConfigurationPanel>
                                                        </Element.DivContainerCol11>
                                                    </Element.DivRowTogglePanel>
                                                </Element.SmallPanelContainer>
                                                <Element.LastSmallPanelContainer>
                                                    <Element.DivRowSmallPanel className="ms-Grid-row">
                                                        <Element.DivColFirstPanels className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.Panel theme={theme}>
                                                                <Element.DivRowVideo1Title className="ms-Grid-row">
                                                                    <Element.DivColFirstVideoTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.SmallTitleVideo1>Ahorro Periódico</Element.SmallTitleVideo1>
                                                                    </Element.DivColFirstVideoTitle>
                                                                </Element.DivRowVideo1Title>
                                                                <Element.DivRowVideo1Text className="ms-Grid-row">
                                                                    <Element.DivColFirstVideoText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.TextInsideVideo1>
                                                                            <Element.LinkToYoutube href="https://www.youtube.com/channel/UChJiXVzR0iwllShhaJTnTaw" target="_blank" rel="noopener noreferrer" theme={theme}>
                                                                                ¿Quieres saber más sobre el Ahorro Periódico? En MyMoneyGym 04 te lo explicamos.
                                                                            </Element.LinkToYoutube>
                                                                        </Element.TextInsideVideo1>
                                                                    </Element.DivColFirstVideoText>
                                                                </Element.DivRowVideo1Text>
                                                                <Element.DivRowImageVideo1>
                                                                    <Element.ImageVideo1 alt={"MyMoneyGym04"} src={myMoneyGym} onClick={showVideo} />
                                                                    <Modal isOpen={isVideoOpen} onDismiss={hideVideo} isBlocking={false} containerClassName="video-container">
                                                                        <ReactPlayer playing={true} controls={true} key={"MyMoneyGym"}
                                                                            url={`https://www.youtube.com/watch?v=psCuUPhEapY&origin=${window.location.href}`}
                                                                            // @ts-ignore
                                                                            onEnded={handleEndedVideo}
                                                                        />
                                                                    </Modal>
                                                                </Element.DivRowImageVideo1>
                                                            </Element.Panel>
                                                        </Element.DivColFirstPanels>
                                                    </Element.DivRowSmallPanel>
                                                </Element.LastSmallPanelContainer>
                                            </Element.SecondRowPanel>
                                        </Element.DivContainerPeriodicSaving>
                                    </React.Fragment>
                                )}
                            </Element.DivColRectanglePeriodicSaving>
                            <Element.DivColRectangleModelPortfolio className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl3 ms-xxxl3">
                                {windowSize < 1024 &&
                                (
                                    <React.Fragment>
                                        {isPortfolioModelExpanded === true &&
                                        (
                                            <Element.SecondRectangleSmall onClick={() =>
                                            {
                                                setIsPortfolioModelExpanded(!isPortfolioModelExpanded);
                                            }}>
                                                <Element.SecondTextRectangle>
                                                    {displayArrow(isPortfolioModelExpanded)}
                                                    Cartera Modelo
                                                </Element.SecondTextRectangle>
                                            </Element.SecondRectangleSmall>
                                        )}
                                        {!isPortfolioModelExpanded &&
                                        (
                                            <Element.SecondRectangle onClick={() =>
                                            {
                                                setIsPortfolioModelExpanded(!isPortfolioModelExpanded);
                                            }}>
                                                <Element.SecondTextRectangle>
                                                    {displayArrow(isPortfolioModelExpanded)}
                                                    Cartera Modelo
                                                </Element.SecondTextRectangle>
                                            </Element.SecondRectangle>
                                        )}
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.SecondRectangle>
                                            <Element.SecondTextRectangle>
                                                Cartera Modelo
                                            </Element.SecondTextRectangle>
                                        </Element.SecondRectangle>
                                    </React.Fragment>
                                )}
                                {windowSize < 1024 && isPortfolioModelExpanded === true &&
                                (
                                    <React.Fragment>
                                        <Element.SecondRowPanel className="ms-Grid-row" theme={theme}>
                                            <Element.SmallPortfolioPanel>
                                                <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                    <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.PortfolioPanel theme={theme}>
                                                            <Element.DivRowPortfolioTitle className="ms-Grid-row">
                                                                <Element.DivColPortfolioTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.SmallTitlePortfolio>
                                                                        Crear Cartera Modelo
                                                                    </Element.SmallTitlePortfolio>
                                                                </Element.DivColPortfolioTitle>
                                                            </Element.DivRowPortfolioTitle>
                                                            <Element.DivRowPortfolioText className="ms-Grid-row">
                                                                <Element.DivColPortfolioText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsidePortfolio>
                                                                        Las Carteras Modelo te permiten decidir a qué fondos y en qué porcentaje vas a realizar tu aportación.
                                                                    </Element.TextInsidePortfolio>
                                                                </Element.DivColPortfolioText>
                                                            </Element.DivRowPortfolioText>
                                                        </Element.PortfolioPanel>
                                                    </Element.DivColPortfolioPanel>
                                                </Element.DivRowSmallPortfolioPanel>
                                            </Element.SmallPortfolioPanel>
                                            <Element.SmallPortfolioVideoPanel>
                                                <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                    <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.PortfolioPanelVideo theme={theme}>
                                                            <Element.DivRowImageVideoPortfolio>
                                                                <Element.ImageVideoPortfolio alt={"Ahorro Periodico"} src={periodicPicture} onClick={showPortfolioVideo} />
                                                                <Modal isOpen={isPortfolioModelVideoOpen} onDismiss={hidePortfolioVideo} isBlocking={false} containerClassName="video-container">
                                                                    <ReactPlayer key={"AhorroPeriodico"} playing={true} controls={true}
                                                                        url={`https://www.youtube.com/watch?v=oGSPMZ1QGsA&origin=${window.location.href}`}
                                                                        // @ts-ignore
                                                                        onEnded={handlePortfolioEndVideo}
                                                                    />
                                                                </Modal>
                                                            </Element.DivRowImageVideoPortfolio>
                                                        </Element.PortfolioPanelVideo>
                                                    </Element.DivColPortfolioPanel>
                                                </Element.DivRowSmallPortfolioPanel>
                                            </Element.SmallPortfolioVideoPanel>
                                            {isExperiencePlan === true &&
                                            (
                                                <React.Fragment>
                                                    <Element.SmallPortfolioPanel>
                                                        <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                            <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                <Element.PortfolioPanel theme={theme}>
                                                                    <Element.DivRowCartTitle className="ms-Grid-row">
                                                                        <Element.DivColCartTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                            <Element.SmallCartTitle>
                                                                                Carrito
                                                                            </Element.SmallCartTitle>
                                                                        </Element.DivColCartTitle>
                                                                        <Element.DivColCartBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                            <Element.MiniBadgeCart theme={theme}>2.1</Element.MiniBadgeCart>
                                                                        </Element.DivColCartBadge>
                                                                    </Element.DivRowCartTitle>
                                                                    <Element.DivRowCartText className="ms-Grid-row">
                                                                        <Element.DivColCartText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                            <Element.TextInsideCart>
                                                                                Selecciona Fondos llevándolos al Carrito y después pulsa en la Cartera Modelo para establecer una asignación porcentual a tu elección.
                                                                            </Element.TextInsideCart>
                                                                        </Element.DivColCartText>
                                                                        <Element.DivContainerCartButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                            <Element.MiniCartButton onClick={() => history.push({pathname: "/Search"})}>Buscador de Fondos</Element.MiniCartButton>
                                                                        </Element.DivContainerCartButton>
                                                                    </Element.DivRowCartText>
                                                                </Element.PortfolioPanel>
                                                            </Element.DivColPortfolioPanel>
                                                        </Element.DivRowSmallPortfolioPanel>
                                                    </Element.SmallPortfolioPanel>
                                                </React.Fragment>
                                            )}
                                            <Element.LastSmallPortfolioPanel>
                                                <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                    <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.PortfolioPanel theme={theme}>
                                                            <Element.DivRowFromPortfolioTitle className="ms-Grid-row">
                                                                <Element.DivColFromPortfolioTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                    <Element.SmallFromPortfolioTitle>
                                                                        Desde tu Cartera
                                                                    </Element.SmallFromPortfolioTitle>
                                                                </Element.DivColFromPortfolioTitle>
                                                                <Element.DivColFromPortfolioBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                    <Element.MiniBadgeFromPortfolio theme={theme}>2.1</Element.MiniBadgeFromPortfolio>
                                                                </Element.DivColFromPortfolioBadge>
                                                            </Element.DivRowFromPortfolioTitle>
                                                            <Element.DivRowFromPortfolioText className="ms-Grid-row">
                                                                <Element.DivColFromPortfolioText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsideFromPortfolio>
                                                                        Desde tu Cartera puedes crear una Cartera Modelo partiendo de tus fondos en tu Plan de Ahorro.
                                                                    </Element.TextInsideFromPortfolio>
                                                                </Element.DivColFromPortfolioText>
                                                                <Element.DivContainerFromPortfolioButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                    <Element.MiniFromPortfolioButton onClick={() => history.push({pathname: "/Portfolio"})}>
                                                                        Cartera Modelo
                                                                    </Element.MiniFromPortfolioButton>
                                                                </Element.DivContainerFromPortfolioButton>
                                                            </Element.DivRowFromPortfolioText>
                                                        </Element.PortfolioPanel>
                                                    </Element.DivColPortfolioPanel>
                                                </Element.DivRowSmallPortfolioPanel>
                                            </Element.LastSmallPortfolioPanel>
                                        </Element.SecondRowPanel>
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.DivContainerModelPortfolio className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 pr0">
                                            <Element.SecondRowPanel className="ms-Grid-row" theme={theme}>
                                                <Element.SmallPortfolioPanel>
                                                    <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                        <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.PortfolioPanel theme={theme}>
                                                                <Element.DivRowPortfolioTitle className="ms-Grid-row">
                                                                    <Element.DivColPortfolioTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.SmallTitlePortfolio>
                                                                            Crear Cartera Modelo
                                                                        </Element.SmallTitlePortfolio>
                                                                    </Element.DivColPortfolioTitle>
                                                                </Element.DivRowPortfolioTitle>
                                                                <Element.DivRowPortfolioText className="ms-Grid-row">
                                                                    <Element.DivColPortfolioText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.TextInsidePortfolio>
                                                                            Las Carteras Modelo te permiten decidir a qué fondos y en qué porcentaje vas a realizar tu aportación.
                                                                        </Element.TextInsidePortfolio>
                                                                    </Element.DivColPortfolioText>
                                                                </Element.DivRowPortfolioText>
                                                            </Element.PortfolioPanel>
                                                        </Element.DivColPortfolioPanel>
                                                    </Element.DivRowSmallPortfolioPanel>
                                                </Element.SmallPortfolioPanel>
                                                <Element.SmallPortfolioVideoPanel>
                                                    <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                        <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.PortfolioPanelVideo theme={theme}>
                                                                <Element.DivRowImageVideoPortfolio>
                                                                    <Element.ImageVideoPortfolio alt={"Ahorro Periodico"} src={periodicPicture} onClick={showPortfolioVideo} />
                                                                    <Modal isOpen={isPortfolioModelVideoOpen} onDismiss={hidePortfolioVideo} isBlocking={false} containerClassName="video-container">
                                                                        <ReactPlayer key={"AhorroPeriodico"} playing={true} controls={true}
                                                                            url={`https://www.youtube.com/watch?v=oGSPMZ1QGsA&origin=${window.location.href}`}
                                                                            // @ts-ignore
                                                                            onEnded={handlePortfolioEndVideo}
                                                                        />
                                                                    </Modal>
                                                                </Element.DivRowImageVideoPortfolio>
                                                            </Element.PortfolioPanelVideo>
                                                        </Element.DivColPortfolioPanel>
                                                    </Element.DivRowSmallPortfolioPanel>
                                                </Element.SmallPortfolioVideoPanel>
                                                {isExperiencePlan === true &&
                                                (
                                                    <React.Fragment>
                                                        <Element.SmallPortfolioPanel>
                                                            <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                                <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.PortfolioPanel theme={theme}>
                                                                        <Element.DivRowCartTitle className="ms-Grid-row">
                                                                            <Element.DivColCartTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                                <Element.SmallCartTitle>
                                                                                    Carrito
                                                                                </Element.SmallCartTitle>
                                                                            </Element.DivColCartTitle>
                                                                            <Element.DivColCartBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                                <Element.MiniBadgeCart theme={theme}>2.1</Element.MiniBadgeCart>
                                                                            </Element.DivColCartBadge>
                                                                        </Element.DivRowCartTitle>
                                                                        <Element.DivRowCartText className="ms-Grid-row">
                                                                            <Element.DivColCartText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                                <Element.TextInsideCart>
                                                                                    Selecciona Fondos llevándolos al Carrito y después pulsa en la Cartera Modelo para establecer una asignación porcentual a tu elección.
                                                                                </Element.TextInsideCart>
                                                                            </Element.DivColCartText>
                                                                            <Element.DivContainerCartButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                                <Element.MiniCartButton onClick={() => history.push({pathname: "/Search"})}>Buscador de Fondos</Element.MiniCartButton>
                                                                            </Element.DivContainerCartButton>
                                                                        </Element.DivRowCartText>
                                                                    </Element.PortfolioPanel>
                                                                </Element.DivColPortfolioPanel>
                                                            </Element.DivRowSmallPortfolioPanel>
                                                        </Element.SmallPortfolioPanel>
                                                    </React.Fragment>
                                                )}
                                                <Element.LastSmallPortfolioPanel>
                                                    <Element.DivRowSmallPortfolioPanel className="ms-Grid-row">
                                                        <Element.DivColPortfolioPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                            <Element.PortfolioPanel theme={theme}>
                                                                <Element.DivRowFromPortfolioTitle className="ms-Grid-row">
                                                                    <Element.DivColFromPortfolioTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                        <Element.SmallFromPortfolioTitle>
                                                                            Desde tu Cartera
                                                                        </Element.SmallFromPortfolioTitle>
                                                                    </Element.DivColFromPortfolioTitle>
                                                                    <Element.DivColFromPortfolioBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                        <Element.MiniBadgeFromPortfolio theme={theme}>2.1</Element.MiniBadgeFromPortfolio>
                                                                    </Element.DivColFromPortfolioBadge>
                                                                </Element.DivRowFromPortfolioTitle>
                                                                <Element.DivRowFromPortfolioText className="ms-Grid-row">
                                                                    <Element.DivColFromPortfolioText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                        <Element.TextInsideFromPortfolio>
                                                                            Desde tu Cartera puedes crear una Cartera Modelo partiendo de tus fondos en tu Plan de Ahorro.
                                                                        </Element.TextInsideFromPortfolio>
                                                                    </Element.DivColFromPortfolioText>
                                                                    <Element.DivContainerFromPortfolioButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                        <Element.MiniFromPortfolioButton onClick={() => history.push({pathname: "/Portfolio"})}>
                                                                            Cartera Modelo
                                                                        </Element.MiniFromPortfolioButton>
                                                                    </Element.DivContainerFromPortfolioButton>
                                                                </Element.DivRowFromPortfolioText>
                                                            </Element.PortfolioPanel>
                                                        </Element.DivColPortfolioPanel>
                                                    </Element.DivRowSmallPortfolioPanel>
                                                </Element.LastSmallPortfolioPanel>
                                            </Element.SecondRowPanel>
                                        </Element.DivContainerModelPortfolio>
                                    </React.Fragment>
                                )}
                            </Element.DivColRectangleModelPortfolio>
                            <Element.DivColRectanglePeriodicContributions className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl3 ms-xxxl3">
                                {windowSize < 1024 &&
                                (
                                    <React.Fragment>
                                        {isPeriodicContributionsExpanded === true &&
                                        (
                                            <Element.ThirdRectangleSmall onClick={() =>
                                            {
                                                setIsPeriodicContributionsExpanded(!isPeriodicContributionsExpanded);
                                            }}>
                                                <Element.ThirdTextRectangle>
                                                    {displayArrow(isPeriodicContributionsExpanded)}
                                                    Aportación Periódica
                                                </Element.ThirdTextRectangle>
                                            </Element.ThirdRectangleSmall>
                                        )}
                                        {!isPeriodicContributionsExpanded &&
                                        (
                                            <Element.ThirdRectangle onClick={() =>
                                            {
                                                setIsPeriodicContributionsExpanded(!isPeriodicContributionsExpanded);
                                            }}>
                                                <Element.ThirdTextRectangle>
                                                    {displayArrow(isPeriodicContributionsExpanded)}
                                                    Aportación Periódica
                                                </Element.ThirdTextRectangle>
                                            </Element.ThirdRectangle>
                                        )}
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.ThirdRectangle>
                                            <Element.ThirdTextRectangle>
                                                Aportación Periódica
                                            </Element.ThirdTextRectangle>
                                        </Element.ThirdRectangle>
                                    </React.Fragment>
                                )}
                                {windowSize < 1024 && isPeriodicContributionsExpanded === true &&
                                (
                                    <React.Fragment>
                                        <Element.ThirdRowPanel className="ms-Grid-row" theme={theme}>
                                            <Element.SmallPeriodicContributionsPanel>
                                                <Element.DivRowSmallPeriodicContributionsPanel className="ms-Grid-row">
                                                    <Element.DivColPeriodicContributionsPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.PeriodicContributionsPanel theme={theme}>
                                                            <Element.DivRowPeriodicContributionsTitle className="ms-Grid-row">
                                                                <Element.DivColPeriodicContributionsTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.SmallTitlePeriodicContributions>
                                                                        Aportación Periódica
                                                                    </Element.SmallTitlePeriodicContributions>
                                                                </Element.DivColPeriodicContributionsTitle>
                                                            </Element.DivRowPeriodicContributionsTitle>
                                                            <Element.DivRowPeriodicContributionsText className="ms-Grid-row">
                                                                <Element.DivColPeriodicContributionsText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsidePeriodicContributions>
                                                                        Cada Cartera Modelo puede tener varias aportaciones periódicas estas crean una transferencia PSD2
                                                                        automática en tu banco por la cantidad que desees.
                                                                    </Element.TextInsidePeriodicContributions>
                                                                </Element.DivColPeriodicContributionsText>
                                                            </Element.DivRowPeriodicContributionsText>
                                                        </Element.PeriodicContributionsPanel>
                                                    </Element.DivColPeriodicContributionsPanel>
                                                </Element.DivRowSmallPeriodicContributionsPanel>
                                            </Element.SmallPeriodicContributionsPanel>
                                            <Element.LastSmallPeriodicContributionsPanel>
                                                <Element.DivRowSmallPeriodicContributionsPanel className="ms-Grid-row">
                                                    <Element.DivColPeriodicContributionsPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.PeriodicContributionsPanel theme={theme}>
                                                            <Element.DivRowLastPeriodicContributionsTitle className="ms-Grid-row">
                                                                <Element.DivColLastPeriodicContributionsTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                    <Element.SmallLastPeriodicContributionsTitle>
                                                                        Crear Aportación Periódica
                                                                    </Element.SmallLastPeriodicContributionsTitle>
                                                                </Element.DivColLastPeriodicContributionsTitle>
                                                                <Element.DivColLastPeriodicContributionsBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                    <Element.MiniBadgeLastPeriodicContributions theme={theme}>3.1</Element.MiniBadgeLastPeriodicContributions>
                                                                </Element.DivColLastPeriodicContributionsBadge>
                                                            </Element.DivRowLastPeriodicContributionsTitle>
                                                            <Element.DivRowLastPeriodicContributionsText className="ms-Grid-row">
                                                                <Element.DivColLastPeriodicContributionsText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsideLastPeriodicContributions>
                                                                        Ahora sólo tienes que crear tu Aportación Periódica. Crearemos una transferencia PSD2 periódica en tu propio banco.
                                                                    </Element.TextInsideLastPeriodicContributions>
                                                                </Element.DivColLastPeriodicContributionsText>
                                                                <Element.DivContainerPeriodicContributionsButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                    {linkPeriodicContribution === "" &&
                                                                    (
                                                                        <Element.MiniPeriodicContributionsButtonDisabled disabled={true}>Crear Aportación Periódica</Element.MiniPeriodicContributionsButtonDisabled>
                                                                    )}
                                                                    {linkPeriodicContribution === "/savingsPlans" &&
                                                                    (
                                                                        <Element.MiniPeriodicContributionsButton onClick={() => history.push({pathname: linkPeriodicContribution})}>Crear Aportación Periódica</Element.MiniPeriodicContributionsButton>
                                                                    )}
                                                                    {linkPeriodicContribution.startsWith("/Model") === true &&
                                                                    (
                                                                        <Element.MiniPeriodicContributionsButton onClick={() => history.push(
                                                                        {
                                                                            pathname: linkPeriodicContribution,
                                                                            state:
                                                                            {
                                                                                portfolioModelId: portfolioModelId,
                                                                                savingPlanId: savingPlan.id,
                                                                                portfolioModelName: portfolioModelName,
                                                                                contract: finametrixContract
                                                                            }
                                                                        })}>
                                                                            Cartera Modelo
                                                                        </Element.MiniPeriodicContributionsButton>
                                                                    )}
                                                                </Element.DivContainerPeriodicContributionsButton>
                                                            </Element.DivRowLastPeriodicContributionsText>
                                                        </Element.PeriodicContributionsPanel>
                                                    </Element.DivColPeriodicContributionsPanel>
                                                </Element.DivRowSmallPeriodicContributionsPanel>
                                            </Element.LastSmallPeriodicContributionsPanel>
                                        </Element.ThirdRowPanel>
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.ThirdRowPanel className="ms-Grid-row" theme={theme}>
                                            <Element.SmallPeriodicContributionsPanel>
                                                <Element.DivRowSmallPeriodicContributionsPanel className="ms-Grid-row">
                                                    <Element.DivColPeriodicContributionsPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.PeriodicContributionsPanel theme={theme}>
                                                            <Element.DivRowPeriodicContributionsTitle className="ms-Grid-row">
                                                                <Element.DivColPeriodicContributionsTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.SmallTitlePeriodicContributions>
                                                                        Aportación Periódica
                                                                    </Element.SmallTitlePeriodicContributions>
                                                                </Element.DivColPeriodicContributionsTitle>
                                                            </Element.DivRowPeriodicContributionsTitle>
                                                            <Element.DivRowPeriodicContributionsText className="ms-Grid-row">
                                                                <Element.DivColPeriodicContributionsText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsidePeriodicContributions>
                                                                        Cada Cartera Modelo puede tener varias aportaciones periódicas estas crean una transferencia PSD2 
                                                                        automática en tu banco por la cantidad que desees.
                                                                    </Element.TextInsidePeriodicContributions>
                                                                </Element.DivColPeriodicContributionsText>
                                                            </Element.DivRowPeriodicContributionsText>
                                                        </Element.PeriodicContributionsPanel>
                                                    </Element.DivColPeriodicContributionsPanel>
                                                </Element.DivRowSmallPeriodicContributionsPanel>
                                            </Element.SmallPeriodicContributionsPanel>
                                            <Element.LastSmallPeriodicContributionsPanel>
                                                <Element.DivRowSmallPeriodicContributionsPanel className="ms-Grid-row">
                                                    <Element.DivColPeriodicContributionsPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.PeriodicContributionsPanel theme={theme}>
                                                            <Element.DivRowLastPeriodicContributionsTitle className="ms-Grid-row">
                                                                <Element.DivColLastPeriodicContributionsTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                    <Element.SmallLastPeriodicContributionsTitle>
                                                                        Crear Aportación Periódica
                                                                    </Element.SmallLastPeriodicContributionsTitle>
                                                                </Element.DivColLastPeriodicContributionsTitle>
                                                                <Element.DivColLastPeriodicContributionsBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                    <Element.MiniBadgeLastPeriodicContributions theme={theme}>3.1</Element.MiniBadgeLastPeriodicContributions>
                                                                </Element.DivColLastPeriodicContributionsBadge>
                                                            </Element.DivRowLastPeriodicContributionsTitle>
                                                            <Element.DivRowLastPeriodicContributionsText className="ms-Grid-row">
                                                                <Element.DivColLastPeriodicContributionsText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsideLastPeriodicContributions>
                                                                        Ahora sólo tienes que crear tu Aportación Periódica. Crearemos una transferencia PSD2 periódica en tu propio banco.
                                                                    </Element.TextInsideLastPeriodicContributions>
                                                                </Element.DivColLastPeriodicContributionsText>
                                                                <Element.DivContainerPeriodicContributionsButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                    {linkPeriodicContribution === "" &&
                                                                    (
                                                                        <Element.MiniPeriodicContributionsButtonDisabled disabled={true}>Crear Aportación Periódica</Element.MiniPeriodicContributionsButtonDisabled>
                                                                    )}
                                                                    {linkPeriodicContribution === "/savingsPlans" &&
                                                                    (
                                                                        <Element.MiniPeriodicContributionsButton onClick={() => history.push({pathname: linkPeriodicContribution})}>Crear Aportación Periódica</Element.MiniPeriodicContributionsButton>
                                                                    )}
                                                                    {linkPeriodicContribution.startsWith("/Model") === true &&
                                                                    (
                                                                        <Element.MiniPeriodicContributionsButton onClick={() => history.push(
                                                                        {
                                                                            pathname: linkPeriodicContribution,
                                                                            state:
                                                                            {
                                                                                portfolioModelId: portfolioModelId,
                                                                                savingPlanId: savingPlan.id,
                                                                                portfolioModelName: portfolioModelName,
                                                                                contract: finametrixContract
                                                                            }
                                                                        })}>
                                                                            Cartera Modelo
                                                                        </Element.MiniPeriodicContributionsButton>
                                                                    )}
                                                                </Element.DivContainerPeriodicContributionsButton>
                                                            </Element.DivRowLastPeriodicContributionsText>
                                                        </Element.PeriodicContributionsPanel>
                                                    </Element.DivColPeriodicContributionsPanel>
                                                </Element.DivRowSmallPeriodicContributionsPanel>
                                            </Element.LastSmallPeriodicContributionsPanel>
                                        </Element.ThirdRowPanel>
                                    </React.Fragment>
                                )}
                            </Element.DivColRectanglePeriodicContributions>
                            <Element.DivColRectangleSavingPlans className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl3 ms-xxxl3">
                                {windowSize < 1024 &&
                                (
                                    <React.Fragment>
                                        {isSavingPlansExpanded === true &&
                                        (
                                            <Element.FourthRectangleSmall onClick={() =>
                                                {
                                                    setIsSavingPlansExpanded(!isSavingPlansExpanded);
                                                }}
                                            >
                                                <Element.FourthTextRectangle>
                                                    {displayArrow(isSavingPlansExpanded)}
                                                    Planes Ahorro
                                                </Element.FourthTextRectangle>
                                            </Element.FourthRectangleSmall>
                                        )}
                                        {!isSavingPlansExpanded &&
                                        (
                                            <Element.FourthRectangle onClick={() =>
                                                {
                                                    setIsSavingPlansExpanded(!isSavingPlansExpanded);
                                                }}
                                            >
                                                <Element.FourthTextRectangle>
                                                    {displayArrow(isSavingPlansExpanded)}
                                                    Planes Ahorro
                                                </Element.FourthTextRectangle>
                                            </Element.FourthRectangle>
                                        )}
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.FourthRectangle>
                                            <Element.FourthTextRectangle>
                                                Planes Ahorro
                                            </Element.FourthTextRectangle>
                                        </Element.FourthRectangle>
                                    </React.Fragment>
                                )}
                                {windowSize < 1024 && isSavingPlansExpanded === true &&
                                (
                                    <React.Fragment>
                                        <Element.FourthRowPanel className="ms-Grid-row" theme={theme}>
                                            <Element.SmallSavingPlanPanel>
                                                <Element.DivRowSmallSavingPlanPanel className="ms-Grid-row">
                                                    <Element.DivColSavingPlanPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.SavingPlanPanel theme={theme}>
                                                            <Element.DivRowSavingPlanTitle className="ms-Grid-row">
                                                                <Element.DivColSavingPlanTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.SmallTitleSavingPlan>
                                                                        Planes de Ahorro
                                                                    </Element.SmallTitleSavingPlan>
                                                                </Element.DivColSavingPlanTitle>
                                                            </Element.DivRowSavingPlanTitle>
                                                            <Element.DivRowSavingPlanText className="ms-Grid-row">
                                                                <Element.DivColSavingPlanText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsideSavingPlan>
                                                                        Los planes de ahorro te permiten crear Carteras Modelo para crear Aportaciones Periódicas sobre ellas.
                                                                        Esta es la manera de añadir una cantidad de dinero en un periodo de tiempo determinado a un conjunto de
                                                                        fondos que tenemos en IronIA Fintech.
                                                                    </Element.TextInsideSavingPlan>
                                                                </Element.DivColSavingPlanText>
                                                            </Element.DivRowSavingPlanText>
                                                        </Element.SavingPlanPanel>
                                                    </Element.DivColSavingPlanPanel>
                                                </Element.DivRowSmallSavingPlanPanel>
                                            </Element.SmallSavingPlanPanel>
                                            <Element.LastSmallSavingPlanPanel>
                                                <Element.DivRowSmallSavingPlanPanel className="ms-Grid-row">
                                                    <Element.DivColSavingPlanPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.SavingPlanPanel theme={theme}>
                                                            <Element.DivRowLastSavingPlanTitle className="ms-Grid-row">
                                                                <Element.DivColLastSavingPlanTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                    <Element.SmallLastSavingPlanTitle>
                                                                        Planes de Ahorro
                                                                    </Element.SmallLastSavingPlanTitle>
                                                                </Element.DivColLastSavingPlanTitle>
                                                                <Element.DivColLastSavingPlanBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                    <Element.MiniBadgeLastSavingPlan theme={theme}>4.1</Element.MiniBadgeLastSavingPlan>
                                                                </Element.DivColLastSavingPlanBadge>
                                                            </Element.DivRowLastSavingPlanTitle>
                                                            <Element.DivRowLastSavingPlanText className="ms-Grid-row">
                                                                <Element.DivColLastSavingPlanText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsideLastSavingPlan>
                                                                        Las Aportaciones Periódicas son parte de las Carteras Modelo que puedes gestionar en tus Planes de Ahorro
                                                                    </Element.TextInsideLastSavingPlan>
                                                                </Element.DivColLastSavingPlanText>
                                                                <Element.DivContainerSavingPlanButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                    <Element.MiniSavingPlanButton onClick={() => history.push({ pathname: "/savingsPlans" })}>Planes de Ahorro</Element.MiniSavingPlanButton>
                                                                </Element.DivContainerSavingPlanButton>
                                                            </Element.DivRowLastSavingPlanText>
                                                        </Element.SavingPlanPanel>
                                                    </Element.DivColSavingPlanPanel>
                                                </Element.DivRowSmallSavingPlanPanel>
                                            </Element.LastSmallSavingPlanPanel>
                                        </Element.FourthRowPanel>
                                    </React.Fragment>
                                )}
                                {windowSize >= 1024 &&
                                (
                                    <React.Fragment>
                                        <Element.FourthRowPanel className="ms-Grid-row" theme={theme}>
                                            <Element.SmallSavingPlanPanel>
                                                <Element.DivRowSmallSavingPlanPanel className="ms-Grid-row">
                                                    <Element.DivColSavingPlanPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.SavingPlanPanel theme={theme}>
                                                            <Element.DivRowSavingPlanTitle className="ms-Grid-row">
                                                                <Element.DivColSavingPlanTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.SmallTitleSavingPlan>
                                                                        Planes de Ahorro
                                                                    </Element.SmallTitleSavingPlan>
                                                                </Element.DivColSavingPlanTitle>
                                                            </Element.DivRowSavingPlanTitle>
                                                            <Element.DivRowSavingPlanText className="ms-Grid-row">
                                                                <Element.DivColSavingPlanText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsideSavingPlan>
                                                                        Los planes de ahorro te permiten crear Carteras Modelo para crear Aportaciones Periódicas sobre ellas. 
                                                                        Esta es la manera de añadir una cantidad de dinero en un periodo de tiempo determinado a un conjunto de 
                                                                        fondos que tenemos en IronIA Fintech.
                                                                    </Element.TextInsideSavingPlan>
                                                                </Element.DivColSavingPlanText>
                                                            </Element.DivRowSavingPlanText>
                                                        </Element.SavingPlanPanel>
                                                    </Element.DivColSavingPlanPanel>
                                                </Element.DivRowSmallSavingPlanPanel>
                                            </Element.SmallSavingPlanPanel>
                                            <Element.LastSmallSavingPlanPanel>
                                                <Element.DivRowSmallSavingPlanPanel className="ms-Grid-row">
                                                    <Element.DivColSavingPlanPanel className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                        <Element.SavingPlanPanel theme={theme}>
                                                            <Element.DivRowLastSavingPlanTitle className="ms-Grid-row">
                                                                <Element.DivColLastSavingPlanTitle className="ms-Grid-col ms-sm10 ms-md11 ms-lg11 ms-xl11 ms-xxl10 ms-xxxl11">
                                                                    <Element.SmallLastSavingPlanTitle>
                                                                        Planes de Ahorro
                                                                    </Element.SmallLastSavingPlanTitle>
                                                                </Element.DivColLastSavingPlanTitle>
                                                                <Element.DivColLastSavingPlanBadge className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 ms-xl1 ms-xxl2 ms-xxxl1">
                                                                    <Element.MiniBadgeLastSavingPlan theme={theme}>4.1</Element.MiniBadgeLastSavingPlan>
                                                                </Element.DivColLastSavingPlanBadge>
                                                            </Element.DivRowLastSavingPlanTitle>
                                                            <Element.DivRowLastSavingPlanText className="ms-Grid-row">
                                                                <Element.DivColLastSavingPlanText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                                    <Element.TextInsideLastSavingPlan>
                                                                        Las Aportaciones Periódicas son parte de las Carteras Modelo que puedes gestionar en tus Planes de Ahorro
                                                                    </Element.TextInsideLastSavingPlan>
                                                                </Element.DivColLastSavingPlanText>
                                                                <Element.DivContainerSavingPlanButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 rightData">
                                                                    <Element.MiniSavingPlanButton onClick={() => history.push({pathname: "/savingsPlans"})}>Planes de Ahorro</Element.MiniSavingPlanButton>
                                                                </Element.DivContainerSavingPlanButton>
                                                            </Element.DivRowLastSavingPlanText>
                                                        </Element.SavingPlanPanel>
                                                    </Element.DivColSavingPlanPanel>
                                                </Element.DivRowSmallSavingPlanPanel>
                                            </Element.LastSmallSavingPlanPanel>
                                        </Element.FourthRowPanel>
                                    </React.Fragment>
                                )}
                            </Element.DivColRectangleSavingPlans>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}