import { Separator, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useRef } from "react";
import DocumentTitle from "react-document-title";
import * as Element from '../Elements/ElementStyles';
//import ElementsWaterIcon from "../../images/water.svg";
import ElementService, { ElementIAObject, ElementIAResponse } from "../../services/ElementsService";
import Auth from "../../Auth/Auth";
import Moment from 'moment';
import InespayService, { InespayType } from "../../services/InespayService";
import { ThemeContext } from "@fluentui/react-theme-provider";
import ElementsIAIcon from "../../images/ia.svg";
import { Link, useHistory } from "react-router-dom";

const ElementsIA = () =>
{
    const history = useHistory();
    //const [elementsWater, setElementsWater] = React.useState<number>(0);
    const [error, setError] = React.useState({status: false, message: ''});
    //const [elementWaterPrice, setElementWaterPrice] = React.useState<any>();
    //const [elementsUsed, setElementsUsed] = React.useState<number>(0);
    //const [areElementsApplied, setAreElementsApplied] = React.useState<boolean>(false);
    const [elementsIA, setElementsIA] = React.useState<number>(100);
    //const [priceInfo, setPriceInfo] = React.useState<string>("");
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [price, setPrice] = React.useState<number>(0.01*100);
    const oldPrice = useRef<number>(0.01*100);
    const user = Auth.getUserProfile();
    //const userElementsStatus = Auth.getUserElementsStatus();
    const today = new Date();
    const todayText = Moment(today).format('YYYY-MM-DD');
    const reference = useRef<string>(`${user.id}-IA-${today.valueOf().toString()}`);
    //const [windowSize, setWindowSize] = React.useState<any>(window.innerWidth);
    const themeContext = React.useContext(ThemeContext);

    // const handleWindowResize = React.useCallback(event =>
    // {
    //     setWindowSize(window.innerWidth);
    // }, []);
    React.useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);
    // React.useEffect(() =>
    // {
    //     window.addEventListener('resize', handleWindowResize);

    //     return () =>
    //     {
    //         window.removeEventListener('resize', handleWindowResize);
    //     };
    // }, [handleWindowResize]);
    const sendPuntualPayment = async () =>
    {
        setIsSubmitted(true);

        var puntualPrice: number = parseFloat(price.toFixed(2));

        var elementObject: ElementIAObject =
        {
            elementsWaterUsed: 0,//elementsUsed as number,
            elementsIA: elementsIA,
            reference: reference.current,
            subject: `Compra Elements IA-${user.id}-${todayText}`,
            price: price,
            areElementsApplied: false,//areElementsApplied,
            userId: user.id
        };

        ElementService.elementsIA(elementObject).then((response: ElementIAResponse) =>
        {
            if(!response.hasError)
            {
                if(response.isPriceEqualZero)
                {
                    history.push("/Elements");
                }
                else
                {
                    var inespayData: InespayType =
                    {
                        subject: `Compra Elements IA-${user.id}-${todayText}`,
                        reference: reference.current,
                        amount: (puntualPrice * 100).toString(),
                        subscription: false,
                        isIA: true,
                        userId: user.id
                    };
                    // if(response.areElementsApplied)
                    // {
                    //     inespayData.elements =
                    //     {
                    //         water: -1 * (elementsUsed || 0),
                    //         air: 0,
                    //         earth: 0,
                    //         fire: 0,
                    //         ia: elementsIA
                    //     }
                    // }
                    // else
                    // {
                        inespayData.elements =
                        {
                            water: 0,
                            air: 0,
                            earth: 0,
                            fire: 0,
                            ia: elementsIA
                        }
                    // }
    
                    sendToInespay(inespayData);
                }
            }
            else
            {
                setError({status: true, message: response.error});
                setIsSubmitted(false);
            }
        },
        (error: any) =>
        {
            setError(error);
        });
    };
    const sendToInespay = (inespayData: InespayType) =>
    {
        if(parseFloat(inespayData.amount) > 0 && parseFloat(inespayData.amount) < 100)
        {
            setError(
            {
                status: true,
                message: "El valor de compra debe ser superior a 1€ para poder procesar la transacción."
            });

            setIsSubmitted(false);
        }
        else
        {
            InespayService.postInespay(inespayData).then((dataInespay: any) =>
            {
                const inespay_result = JSON.parse(dataInespay.Result);
                if(inespay_result.status === '200')
                {
                    window.location.href = inespay_result.url;
                }
                else
                {
                    setError(
                    {
                        status: true,
                        message: inespay_result.description
                    });
    
                    setIsSubmitted(false);
                }
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    };
    // const handleInputChange = (value: any) =>
    // {
    //     var elements: number = value.target.value;

    //     if(elements > elementsWater)
    //     {
    //         setElementsUsed(elementsWater);
    //     }
    //     else
    //     {
    //         setElementsUsed(elements);
    //     }
    // }
    const handleElementsChange = (value: any) =>
    {
        var elemIA: number = value.target.value;

        if(elemIA > 30000)
        {
            setElementsIA(30000);
        }
        else
        {
            setElementsIA(elemIA);
        }
        
        let provPrice: number = parseFloat((0.01 * elemIA).toFixed(2));
        // if(areElementsApplied === true)
        // {
        //     var valueDiscount = (elementWaterPrice * (elementsUsed as number));

        //     if(oldPrice.current !== 0)
        //     {
        //         oldPrice.current = provPrice;
        //         if(oldPrice.current <= valueDiscount)
        //         {
        //             var maxElementsUsed: number = parseFloat((oldPrice.current / elementWaterPrice).toFixed(0));
        //             var newValueDiscount = (elementWaterPrice * maxElementsUsed);
        //             setElementsUsed(maxElementsUsed);
        //             var maxNewPrice = parseFloat((oldPrice.current - newValueDiscount).toFixed(2));
        //             var newElementsDiscount = oldPrice.current - maxNewPrice;
        //             setPrice(maxNewPrice);
        //             let infoPrice: string = `${oldPrice.current} - ${newElementsDiscount.toFixed(2)} = ${maxNewPrice}`;
        //             setPriceInfo(infoPrice);
        //         }
        //         else
        //         {
        //             var newPrice = parseFloat((oldPrice.current - valueDiscount).toFixed(2));
        //             var elementsDiscount = oldPrice.current - newPrice;
        //             setPrice(newPrice);
        //             let infoPrice: string = `${oldPrice.current} - ${elementsDiscount.toFixed(2)} = ${newPrice}`;
        //             setPriceInfo(infoPrice);
        //         }
        //     }
        // }
        //else
        //{
            setPrice(provPrice);
            oldPrice.current = provPrice;
        //}
    }
    // const applyElements = () =>
    // {
    //     if(elementsUsed !== undefined && elementsUsed !== 0)
    //     {
    //         setAreElementsApplied(true);
    //         var valueDiscount = (elementWaterPrice * elementsUsed);

    //         if(oldPrice.current !== 0)
    //         {
    //             if(oldPrice.current <= valueDiscount)
    //             {
    //                 var maxElementsUsed: number = parseFloat((oldPrice.current / elementWaterPrice).toFixed(0));
    //                 var newValueDiscount = (elementWaterPrice * maxElementsUsed);
    //                 setElementsUsed(maxElementsUsed);
    //                 var maxNewPrice = parseFloat((oldPrice.current - newValueDiscount).toFixed(2));
    //                 var newElementsDiscount = oldPrice.current - maxNewPrice;
    //                 setPrice(maxNewPrice);
    //                 let infoPrice: string = `${oldPrice.current} - ${newElementsDiscount.toFixed(2)} = ${maxNewPrice}`;
    //                 setPriceInfo(infoPrice);
    //             }
    //             else
    //             {
    //                 var newPrice = parseFloat((oldPrice.current - valueDiscount).toFixed(2));
    //                 var elementsDiscount = oldPrice.current - newPrice;
    //                 setPrice(newPrice);
    //                 let infoPrice: string = `${oldPrice.current} - ${elementsDiscount.toFixed(2)} = ${newPrice}`;
    //                 setPriceInfo(infoPrice);
    //             }
    //         }
    //         else
    //         {
    //             if(price === 0 && parseInt(elementsIA.toString()) === 0)
    //             {
    //                 oldPrice.current = price;
    //                 setPrice(price);
    //                 let infoPrice: string = `${price}`;
    //                 setElementsUsed(0);
    //                 setAreElementsApplied(false);
    //                 setPriceInfo(infoPrice);
    //             }
    //             else
    //             {
    //                 var firstNewPrice = parseFloat((price - valueDiscount).toFixed(2));
    //                 oldPrice.current = price;
    //                 var elementsDisc = price - firstNewPrice;
    //                 setPrice(firstNewPrice);
    //                 let infoPrice: string = `${price} - ${elementsDisc.toFixed(2)} = ${firstNewPrice}`;
    //                 setPriceInfo(infoPrice);
    //             }
    //         }
    //     }
    // }
    // const restoreElementsUsed = () =>
    // {
    //     setElementsUsed(0);
    //     setPrice(oldPrice.current);
    //     setAreElementsApplied(false);
    //     setPriceInfo("");
    // }

    return(
        <DocumentTitle title='IronIA - Compra Elements IA'>
            <React.Fragment>
                <div className="ms-Grid-row">
                    <Element.MainTitle>Compra de Elements IA</Element.MainTitle>
                        <Element.MainDescription>
                            Los Elements IA tienen un valor constante de 0,01€ y se utilizan para la compra de Servicios dentro de la plataforma de IronIA Fintech.
                            Por motivos de blanqueo de capitales no se pueden tener más de 30.000 Elements IA.
                        </Element.MainDescription>
                    <Separator className="separatorRepayment" style={{marginTop: "0 !important"}} />
                </div>
                <Element.RowBody className="ms-Grid-row">
                    {/* <React.Fragment>
                        <Element.RowWaterElements className="ms-Grid-row">
                            {windowSize >= 320 && windowSize <= 639 &&
                            (
                                <React.Fragment>
                                    <Element.DivSMAuxiliarRow className="ms-Grid-row">
                                        <Element.ColImageWaterIcon className="ms-Grid-col ms-sm2 ms-md2">
                                            <img src={ElementsWaterIcon} alt="ElementsWater" />
                                        </Element.ColImageWaterIcon>
                                        <Element.ColElementsDescription className="ms-Grid-col ms-sm10 ms-md10">
                                            <Element.ElementsWater>
                                                ¿Deseas utilizar Elements Agua? Tienes {elementsWater} Elements Agua. Precio Element Agua hoy: {elementWaterPrice}€.
                                            </Element.ElementsWater>
                                        </Element.ColElementsDescription>
                                    </Element.DivSMAuxiliarRow>
                                    <Element.DivSMAuxiliarRow2 className="ms-Grid-row">
                                        <Element.ColElementsInput className="ms-Grid-col ms-sm3 ms-md3">
                                            <Element.InputWaterElements disabled={isSubmitted} type="number" min={0} max={elementsWater} onChange={handleInputChange} value={elementsUsed} />
                                        </Element.ColElementsInput>
                                        <Element.ColElementsUsingButton className="ms-Grid-col ms-sm4 ms-md4">
                                            <Element.ButtonUseElements disabled={isSubmitted} onClick={applyElements}>
                                                Utilizar
                                            </Element.ButtonUseElements>
                                        </Element.ColElementsUsingButton>
                                        <Element.ColRestoreElements className="ms-Grid-col ms-sm5 ms-md5">
                                            <Element.ButtonRestoreElements disabled={isSubmitted} onClick={restoreElementsUsed}>
                                                Restablecer
                                            </Element.ButtonRestoreElements>
                                        </Element.ColRestoreElements>
                                    </Element.DivSMAuxiliarRow2>
                                </React.Fragment>
                            )}
                            {windowSize >= 640 &&
                            (
                                <React.Fragment>
                                    <Element.ColImageWaterIcon className="ms-Grid-col ms-lg1 ms-xl1 ms-xxl1 ms-xxxl1">
                                        <img src={ElementsWaterIcon} alt="ElementsWater" />
                                    </Element.ColImageWaterIcon>
                                    <Element.ColElementsDescription className="ms-Grid-col ms-lg4 ms-xl5 ms-xxl5 ms-xxxl5">
                                        <Element.ElementsWater>
                                            ¿Deseas utilizar Elements Agua? Tienes {elementsWater} Elements Agua. Precio Element Agua hoy: {elementWaterPrice}€.
                                        </Element.ElementsWater>
                                    </Element.ColElementsDescription>
                                    <Element.ColElementsInput className="ms-Grid-col ms-lg3 ms-xl3 ms-xxl4 ms-xxxl4">
                                        <Element.InputWaterElements disabled={isSubmitted} type="number" min={0} max={elementsWater} onChange={handleInputChange} value={elementsUsed} />
                                    </Element.ColElementsInput>
                                    <Element.ColElementsUsingButton className="ms-Grid-col ms-lg2 ms-xl1 ms-xxl1">
                                        <Element.ButtonUseElements disabled={isSubmitted} onClick={applyElements}>
                                            Utilizar
                                        </Element.ButtonUseElements>
                                    </Element.ColElementsUsingButton>
                                    <Element.ColRestoreElements className="ms-Grid-col ms-lg2 ms-xl2 ms-xxl1">
                                        <Element.ButtonRestoreElements disabled={isSubmitted} onClick={restoreElementsUsed}>
                                            Restablecer
                                        </Element.ButtonRestoreElements>
                                    </Element.ColRestoreElements>
                                </React.Fragment>
                            )}
                        </Element.RowWaterElements>
                    </React.Fragment> */}
                    <Element.ColDataOrder className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 ms-xl4 ms-xxl4 ms-xxxl4">
                        <Element.DivCardOrder>
                            <Element.DlOrder>
                                <Element.DtOrder>Ordenante</Element.DtOrder>
                                <Element.DdOrder>
                                    <Element.UlOrder>
                                        <Element.LiOrder>DIAPHANUM VALORES, SV, S.A.U | A87637450</Element.LiOrder>
                                        <Element.LiOrder>Maria de Molina 40, Planta 7 - Madrid 28006</Element.LiOrder>
                                    </Element.UlOrder>
                                </Element.DdOrder>
                                <Element.DtReference>Referencia</Element.DtReference>
                                <Element.DdReference>Compra Elements IA {reference.current}</Element.DdReference>
                                <Element.DtImporte>Importe</Element.DtImporte>
                                <Element.DdImporte>
                                    {/* {!areElementsApplied && price} */}
                                    {/* {areElementsApplied && priceInfo} */}
                                    {price}€
                                </Element.DdImporte>
                            </Element.DlOrder>
                        </Element.DivCardOrder>
                    </Element.ColDataOrder>
                    <Element.ColPuntual className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-xl8 ms-xxl8 ms-xxxl8">
                        <Element.DivPayment>
                            <Element.TabContainer className="ms-Tabs-Container">
                                <Element.ButtonTab className={"ms-Tab active"}>
                                    <i className="ironia-icon calendar" />
                                    Pago puntual
                                </Element.ButtonTab>
                                <Element.Form>
                                    <Element.DivRowForm className="ms-Grid-row">
                                        <Element.DivColDisclaimer className="ms-Grid-col ms-sm12 ms-xxxl12">
                                            <Element.WarningContainer theme={themeContext}>
                                                <Element.WarningText>
                                                    Los Elements IA se utilizan para diversos servicios dentro de IronIA, como visualizar vídeos generados por IA o adquirir tu documentación oficial.
                                                </Element.WarningText>
                                            </Element.WarningContainer>
                                        </Element.DivColDisclaimer>
                                        <Element.DivColImageIA className="ms-Grid-col ms-sm2 ms-xl2 ms-xxxl1">
                                            <Element.ImageIA alt="IA" src={ElementsIAIcon} width={"22px"} />
                                        </Element.DivColImageIA>
                                        <Element.DivColText className="ms-Grid-col ms-sm10 ms-xl10 ms-xxxl11">
                                            <Element.InfoText>
                                                Introduce el número de ElementsIA que deseas adquirir (mínimo 100 Elements IA) y pulsa "Comprar" cuando te hayas decidido.
                                            </Element.InfoText>
                                        </Element.DivColText>
                                        <Element.DivColBuyElements className="ms-Grid-col ms-sm12 ms-xl8 ms-xxxl8">
                                            <Element.InputSubmitElements disabled={isSubmitted} type="number" min={0} max={30000} onChange={handleElementsChange} value={elementsIA} />
                                        </Element.DivColBuyElements>
                                        <Element.DivColButtonSubmit className="ms-Grid-col ms-sm12 ms-xl4 ms-xxxl4">
                                            <Element.ButtonSubmit disabled={isSubmitted} onClick={async () => await sendPuntualPayment()}>
                                                <Element.DivRowButtonSubmit className="ms-Grid-row">
                                                    {isSubmitted &&
                                                    (
                                                        <React.Fragment>
                                                            <Element.DivSpinner className="ms-Grid-col ms-sm4 ms-xxxl4">
                                                                <Spinner size={SpinnerSize.small} styles={{root: {marginTop: "1px"}}} />
                                                            </Element.DivSpinner>
                                                            <Element.DivTextButton className="ms-Grid-col ms-sm8 ms-xxxl8">
                                                                Comprar
                                                            </Element.DivTextButton>
                                                        </React.Fragment>
                                                    )}
                                                    {!isSubmitted &&
                                                    (
                                                        <React.Fragment>
                                                            <Element.DivTextButtonNoSpinner className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                                Comprar
                                                            </Element.DivTextButtonNoSpinner>
                                                        </React.Fragment>
                                                    )}
                                                </Element.DivRowButtonSubmit>
                                            </Element.ButtonSubmit>
                                        </Element.DivColButtonSubmit>
                                        {isSubmitted &&
                                        (
                                            <Element.DivProcessPayment className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                <Element.ProcessPayment>
                                                    Estás comprando {elementsIA} Elements IA por un importe de {price} €. Conectando con la pasarela PSD2. No cierres el navegador.
                                                </Element.ProcessPayment>
                                            </Element.DivProcessPayment>
                                        )}
                                    </Element.DivRowForm>
                                </Element.Form>
                            </Element.TabContainer>
                        </Element.DivPayment>
                    </Element.ColPuntual>
                    {error !== undefined && error.status === true &&
                    (
                        <Element.ErrorBarCol className="ms-Grid-col ms-sm12 ms-xxxl12">
                            <Element.ErrorText>
                                {error.message}
                            </Element.ErrorText>
                        </Element.ErrorBarCol>
                    )}
                </Element.RowBody>
                <Separator />
                <Element.RowInfo className="ms-Grid-row">
                    <Element.ColInfoTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                        <Element.InfoTitle theme={themeContext}>
                            ¿Qué son los Elements IA?
                        </Element.InfoTitle>
                    </Element.ColInfoTitle>
                    <Element.ColInfoSubTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                        <Element.InfoSubTitle theme={themeContext}>
                            Los Elements IA son una forma de adquirir servicios dentro de la plataforma IronIA Store a un precio fijo,
                            a diferencia del resto de los Elements que varían su valor dependiendo de la actividad de la plataforma.
                            <br />
                            El valor de los Elements IA es siempre el mismo: 100 Elements IA son 1 €.
                        </Element.InfoSubTitle>
                    </Element.ColInfoSubTitle>
                    <Element.ColCards className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                        <Element.RowInsideCards className="ms-Grid-row">
                            <Element.ColFirstCard className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl3 ms-xxxl3">
                                <Element.Card>
                                    <Element.CardTitle theme={themeContext}>
                                        <Element.TitleCardText theme={themeContext}>
                                            ¿Para qué puedo utilizarlos?
                                        </Element.TitleCardText>
                                    </Element.CardTitle>
                                    <Element.CardBody>
                                        <Element.BodyCardText theme={themeContext}>
                                            Para adquirir servicios dentro de la plataforma, como, por ejemplo, la generación de videos de inteligencia artificial (IA)
                                            con la explicación de un fondo de inversión o la adquisición de tu documentación oficial, entre otros servicios.
                                            <br />
                                            <br />
                                            Además, también pueden utilizarse para el pago de suscripciones en IronIA Store.
                                        </Element.BodyCardText>
                                    </Element.CardBody>
                                </Element.Card>
                            </Element.ColFirstCard>
                            <Element.ColSecondCard className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl4 ms-xxxl4">
                                <Element.Card>
                                    <Element.CardTitle theme={themeContext}>
                                        <Element.TitleCardText theme={themeContext}>
                                            ¿Cómo puedo adquirirlos?
                                        </Element.TitleCardText>
                                    </Element.CardTitle>
                                    <Element.CardBody>
                                        <Element.BodyCardText theme={themeContext}>
                                            Los Elements IA se obtienen a través de la automatización del pago de las retrocesiones, dividendos y diferencias en la operación.
                                            Además de estos procesos pueden realizar comprar Elements IA haciendo una trasferencia PSD2. 
                                            <br />
                                            <br />
                                            Puedes ver toda la información sobre los Elements IA en <a href="https://blog.ironia.tech/post/elements2" target="_blank" rel="noopener noreferrer"> ¿Cómo funcionan los Elements IA?</a>
                                        </Element.BodyCardText>
                                    </Element.CardBody>
                                </Element.Card>
                            </Element.ColSecondCard>
                            <Element.ColThirdCard className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl5 ms-xxxl5">
                                <Element.Card>
                                    <Element.CardTitle theme={themeContext}>
                                        <Element.TitleCardText theme={themeContext}>
                                            ¿Puedo convertir mis retrocesiones, dividendos... en Elements IA?
                                        </Element.TitleCardText>
                                    </Element.CardTitle>
                                    <Element.CardBody>
                                        <Element.BodyCardText theme={themeContext}>
                                            Por supuesto que sí. Firmando el siguiente contrato puedes hacer que las cantidades de retrocesiones, dividendos y otras cantidades derivadas
                                            de la operativa de IronIA se conviertan automáticamente en Elements IA.
                                            <br />
                                            <br />
                                            Además, los Elements IA comprados se pueden traspasar a € en cualquier momento mediante una trasferencia SEPA al IBAN que nos indiques.
                                        </Element.BodyCardText>
                                        <Element.RowButtonGetMoney className="ms-Grid-row">
                                            <Element.ColButtonTextGetMoney className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8 ms-xxxl8">
                                                <Element.TextGetMoney theme={themeContext}>
                                                    ¡Ya disponible!
                                                </Element.TextGetMoney>
                                            </Element.ColButtonTextGetMoney>
                                            <Element.ColButtonGetMoney className="ms-Grid-col ms-sm12 ms-md12 ms-xl12 ms-xl4 ms-xxl4 ms-xxxl4">
                                                <Link to="/ElementsIA/Transfer">
                                                    <Element.ButtonGetMoney>
                                                        Retirar Elements IA
                                                    </Element.ButtonGetMoney>
                                                </Link>
                                            </Element.ColButtonGetMoney>
                                        </Element.RowButtonGetMoney>
                                        {/* <Element.RowContract className="ms-Grid-row">
                                            <Element.ColContractText className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8 ms-xxxl8">
                                                <Element.ContractText theme={themeContext}>
                                                    Puedes firmar el contrato de conversión automática a Elements IA y finalizarlo en cualquier momento.
                                                </Element.ContractText>
                                            </Element.ColContractText>
                                            <Element.ColContractButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ms-xxl4 ms-xxxl4">
                                                <Element.ButtonContract>
                                                    Próximamente
                                                </Element.ButtonContract>
                                            </Element.ColContractButton>
                                        </Element.RowContract> */}
                                    </Element.CardBody>
                                </Element.Card>
                            </Element.ColThirdCard>
                        </Element.RowInsideCards>
                    </Element.ColCards>
                </Element.RowInfo>
            </React.Fragment>
        </DocumentTitle>
    );
}

export default ElementsIA;